import { useHistory } from 'react-router-dom';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  ApplicationFor,
  ApplicationInput,
  ApplicationType,
  EditMyApplications_GetApplicationQuery,
  EmploymentType,
  PartnerStatus,
  PositionType,
  ProcessingStatus,
  useNewMyApplications_CreateApplicationMutation,
  useNewMyApplications_GetPersondataQuery,
} from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { useUserName, useEmail, useKID } from 'context/UserInfoContext';
import { ApplicationForm } from 'components/applicationForm/ApplicationForm';
import { useTranslation } from 'react-i18next';

export const defaultFormData: EditMyApplications_GetApplicationQuery['application'] =
  {
    id: '',
    createdAt: new Date().toISOString(),
    bookedOfferName: '',
    processingStatus: ProcessingStatus.Created,
    applicantKID: '',
    applicationFor: ApplicationFor.Me,
    applicant: {
      name: '',
      staffNumber: '',
      email: '',
      accountingArea: '',
    },
    guest: {
      name: '',
      staffNumber: '',
      email: '',
      accountingArea: '',
    },
    applicationType: ApplicationType.VacationOffer,
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    phone: '',
    disability: {
      present: false,
      degree: 20,
      walkingDisability: false,
      markBOnPassport: false,
    },
    allergies: undefined,
    singleParent: false,
    companyPosition: {
      position: PositionType.Employee,
      entranceYear: undefined,
      employment: EmploymentType.Permanent,
    },
    dog: false,
    furtherGuests: {
      familyChildrenAdult: 0,
      furtherAdults: 0,
      familyChildrenUnderage: [],
      nonFamilyChildrenUnderage: [],
    },
    comment: '',
    partner: {
      status: PartnerStatus.Married,
    },
    hawBookings: [],
    eonBookings: [],
  };

export const NewMyApplications = (): ReactElement => {
  const { t } = useTranslation();
  const userName = useUserName();
  const email = useEmail();

  const history = useHistory();
  const onCompleted = () => {
    history.goBack();
  };
  const kid = useKID();
  const { data, loading, error } = useNewMyApplications_GetPersondataQuery({
    variables: {
      kid: kid,
    },
  });
  const personData = data?.getPersondata || {
    accountingArea: '',
    staffNumber: '',
  };

  const [createApplication, { called, reset }] =
    useNewMyApplications_CreateApplicationMutation({
      onCompleted,
      onError: () => {
        reset();
      },
      context: {
        debounceKey: 'useNewMyApplications_CreateApplicationMutation',
        notification: {
          success: t('pages.myApplications.notifications.create.success'),
          error: t('pages.myApplications.notifications.create.error'),
        },
      },
    });
  const handleSave = (data: ApplicationInput) => {
    if (called === false) {
      const payload = {
        ...data,
        applicantKID: kid || 'no_kid',
        processingStatus: ProcessingStatus.Created,
      };
      createApplication({
        variables: {
          createApplication: payload,
        },
      });
    }
  };
  const handleSubmit = (data: ApplicationInput) => {
    if (called === false) {
      const payload = {
        ...data,
        applicantKID: kid || 'no_kid',
        processingStatus: ProcessingStatus.Submitted,
      };
      createApplication({
        variables: {
          createApplication: payload,
        },
      });
    }
  };

  return (
    <div className="NewMyApplications">
      <LoadingOrError loading={loading} error={error}>
        <ApplicationForm
          adminView={false}
          initialData={{
            ...defaultFormData,
            applicant: {
              name: userName,
              email: email,
              staffNumber: personData.staffNumber || '',
              accountingArea: personData.accountingArea || '',
            },
          }}
          onSubmit={handleSubmit}
          onSave={handleSave}
        />
      </LoadingOrError>
    </div>
  );
};
