import { ReactElement, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import VacationNameForm from './VacationNameForm';
import EditKeyFormButton from 'components/applicationForm/EditKeyFormButton';
import config from 'config/config';
import HouseChoiceModal from './HouseChoiceModal';
import DeleteWithConfirmationIconButton from 'components/base/DeleteWithConfirmationIconButton';
import { ToggleActiveButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';
import { Icon } from 'components/base/Icon';
import { useHistory } from 'react-router-dom';
import {
  refetchVacationOfferForm_GetVacationOfferQuery,
  useHeader_DeleteVacationOfferMutation,
  useHeader_UpdateVacationOfferMutation,
} from 'gql/generated/types-and-hooks';

const Header = ({
  offer,
}: {
  offer: {
    id: string;
    name: string;
    activated: boolean;
    service: string;
    house?: {
      id: string;
      name: string;
    };
    bookingOffers?: {
      isBooked: boolean;
    }[];
  };
}): ReactElement | null => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [updateVacationOffer] = useHeader_UpdateVacationOfferMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useHeader_UpdateVacationOfferMutation',
      notification: {
        success: t(
          'components.VacationOffers.Header.notifications.update.success',
        ),
        error: t('components.VacationOffers.Header.notifications.update.error'),
      },
    },
  });
  const [deleteVacationOffer] = useHeader_DeleteVacationOfferMutation({
    onCompleted: () => {
      history.replace(config.localRoutes.vacationOffers.index);
    },
    context: {
      debounceKey: 'useHeader_DeleteVacationOfferMutation',
      notification: {
        success: t(
          'components.VacationOffers.Header.notifications.delete.success',
        ),
        error: t('components.VacationOffers.Header.notifications.delete.error'),
      },
    },
  });

  const handleCloseModal = () => setModalOpen(false);

  const handleDelete = (offerId: string) =>
    deleteVacationOffer({
      variables: {
        id: offerId,
      },
    });

  if (offer == null || offer.house == null) return null;

  const handleToggleVisibility = () => {
    if (offer == null || offer.house == null) return null;
    updateVacationOffer({
      variables: {
        id: offer.id,
        offer: {
          activated: !offer.activated,
          houseId: offer.house.id,
          name: offer.name,
          service: offer.service,
        },
      },
    });
  };

  const hasBookedBookings =
    offer.bookingOffers?.some(booking => booking.isBooked) || false;

  return (
    <div className="Header mb-8">
      <div className="flex items-center gap-2">
        <HouseChoiceModal
          offer={{
            ...offer,
            name: offer.name || '',
            service: offer.service || '',
            house: offer.house,
          }}
          open={modalOpen}
          onClose={handleCloseModal}
        />
        <Link className="p-2" to={config.localRoutes.vacationOffers.index}>
          <Icon icon={'ChevronUpIcon'} />
        </Link>
        <div className="flex items-center">
          <div>{offer.house.name}</div>
          <div>
            {isAdmin && (
              <EditKeyFormButton onClick={() => setModalOpen(true)} />
            )}
          </div>
        </div>

        <div className="flex flex-1 items-center">
          <VacationNameForm
            offer={{
              ...offer,
              name: offer.name || '',
              service: offer.service || '',
              house: offer.house,
            }}
          />
        </div>
        <KeyFormArea>
          {isAdmin && (
            <div className="flex">
              {!hasBookedBookings && (
                <DeleteWithConfirmationIconButton
                  onDelete={() => handleDelete(offer.id)}
                  title={t(
                    'components.VacationOffers.Header.DeleteWithConfirmation.title',
                  )}
                  text={t(
                    'components.VacationOffers.Header.DeleteWithConfirmation.text',
                    {
                      offerName: offer.name,
                      houseName: offer.house.name || '---',
                    },
                  )}
                />
              )}
              <ToggleActiveButton
                active={offer.activated}
                onClick={handleToggleVisibility}
              />
            </div>
          )}
        </KeyFormArea>
      </div>
    </div>
  );
};

export default Header;
