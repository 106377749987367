import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftButton, ChevronRightButton } from './base/IconButton';
import TextInput from './applicationForm/TextInput';

export type Pagination = {
  limit: number;
  offset: number;
};

type PaginationProps = {
  offset: number;
  limit: number;
  total: number;
  pageSizes: number[];
  onChange: ({ limit, offset }: Pagination) => void;
};

const currentPage = (limit: number, offset: number) =>
  Math.ceil((offset + 1) / limit);
const maxPage = (limit: number, total: number) => Math.ceil(total / limit);
const pageToOffset = (page: number, limit: number) => (page - 1) * limit;
const nextOffset = (limit: number, offset: number) => offset + limit;
const prevOffset = (limit: number, offset: number) => offset - limit;

const PaginationBar = ({
  offset,
  total,
  limit,
  pageSizes,
  onChange,
}: PaginationProps): ReactElement => {
  const [pageInput, setPageInput] = useState(
    currentPage(limit, offset).toString(),
  );
  useEffect(() => {
    setPageInput(currentPage(limit, offset).toString());
  }, [limit, offset]);
  const { t } = useTranslation();
  return (
    <div className="PaginationBar flex h-16 items-center gap-4">
      <div className="w-1/8 flex items-baseline gap-4">
        <span>{t('components.pagination.goTo')}</span>
        <span className="w-16 flex-grow-0">
          <TextInput
            onKeyPress={e => {
              if (e.key === 'Enter') {
                const page = parseInt(pageInput);
                if (isNaN(page) || page < 1 || page > maxPage(limit, total))
                  return;
                onChange({
                  limit,
                  offset: pageToOffset(page, limit),
                });
              }
            }}
            name="pageInput"
            value={pageInput}
            onChange={e => {
              setPageInput(e.currentTarget.value);
            }}
          />
        </span>
      </div>
      <div className="w-1/8 flex items-baseline gap-4">
        <span>
          {t('components.pagination.resultsPerPage', {
            type: t('pages.employeeApplications.bookings'),
          })}
        </span>
        <select
          name="limit"
          value={limit}
          onChange={e => {
            const newLimit = parseInt(e.currentTarget.value);
            onChange({ limit: newLimit, offset: 0 });
          }}
        >
          {(pageSizes?.length ? pageSizes : [limit]).map(value => (
            <option key={value} value={value}>
              {value === Number.MAX_SAFE_INTEGER
                ? t('components.pagination.all')
                : value}
            </option>
          ))}
        </select>
      </div>
      <div className="w-2/8 flex items-baseline gap-4">
        <span className="items-center">
          {t('components.pagination.currentResults', {
            type: t('pages.employeeApplications.bookings'),
            from: Math.min(offset + 1, total),
            to: Math.min(offset + limit, total),
            total,
          })}
        </span>
      </div>
      <div className="w-2/8 flex items-baseline gap-4">
        <ChevronLeftButton
          onClick={() => {
            if (currentPage(limit, offset) > 1) {
              const newOffset = prevOffset(limit, offset);
              onChange({ limit, offset: newOffset });
              setPageInput(currentPage(limit, newOffset).toString());
            }
          }}
          disabled={currentPage(limit, offset) <= 1}
        />
        <ChevronRightButton
          onClick={() => {
            if (currentPage(limit, offset) < maxPage(limit, total)) {
              const newOffset = nextOffset(limit, offset);
              onChange({ limit, offset: newOffset });
              setPageInput(currentPage(limit, newOffset).toString());
            }
          }}
          disabled={currentPage(limit, offset) >= maxPage(limit, total)}
        />
      </div>
    </div>
  );
};

export default PaginationBar;
