import { FEApplicationInput } from 'api/mappers';
import {
  ApplicationForm_GetVactionOffersHousesQuery,
  BoardType,
  BookingConfirmationStatus,
  Maybe,
  ApplicationFor,
  EditMyApplications_GetApplicationQuery,
  HawBooking,
  EonBooking,
} from 'gql/generated/types-and-hooks';
import { TaccountingAreas } from 'interfaces/accountingAreas';
import {
  HawCatalogueData,
  HawCatalogueEntry,
} from 'interfaces/HawCatalogueData';
import { getDate } from './dateHelper';
import { isEmpty, unique } from './util';

export function getOfferForBooking(
  catalogue: HawCatalogueData,
  bookingId: string,
): HawCatalogueEntry['offers'][0] | null {
  const offers = catalogue.flatMap(c =>
    c.offers.filter(o => o.bookings.some(b => b.id === bookingId)),
  );
  if (offers && offers.length > 0) {
    return offers[0];
  }
  return null;
}

export const hasBookings = (
  application: EditMyApplications_GetApplicationQuery['application'],
): boolean => {
  const hasHawBooking =
    application.hawBookings && application.hawBookings.length > 0;
  const hasEonBooking =
    application.eonBookings && application.eonBookings.length > 0;
  return hasHawBooking || hasEonBooking;
};

export const getEarliestBooking = (
  application: EditMyApplications_GetApplicationQuery['application'],
): {
  booking?:
    | EditMyApplications_GetApplicationQuery['application']['hawBookings'][0]
    | EditMyApplications_GetApplicationQuery['application']['eonBookings'][0];
  arrival?: Date;
} => {
  const bookings = [
    ...(application.hawBookings || []),
    ...(application.eonBookings || []),
  ];
  return bookings.reduce<{
    booking?:
      | EditMyApplications_GetApplicationQuery['application']['hawBookings'][0]
      | EditMyApplications_GetApplicationQuery['application']['eonBookings'][0];
    arrival?: Date;
  }>(
    ({ booking, arrival }, current) => {
      const currentArrival = getEarliestArrivalFromBooking(current);
      return minDate(currentArrival, arrival) === arrival
        ? {
            booking,
            arrival,
          }
        : {
            booking: current,
            arrival: currentArrival,
          };
    },
    {
      booking: undefined,
      arrival: undefined,
    },
  );
};

export const isHawBooking = (
  booking: Pick<HawBooking, '__typename'> | Pick<EonBooking, '__typename'>,
): booking is HawBooking => {
  return booking['__typename'] === 'HawBooking';
};

export const getEarliestArrivalFromBooking = (
  booking?: BookingArrivals,
): Date | undefined => {
  if (booking == null) {
    return undefined;
  }
  const arrival = getArrivalFromBooking(booking);
  const arrivalDate = getDate(arrival);
  return isValidDate(arrivalDate) ? arrivalDate : undefined;
};

export const minDate = (d1?: Date, d2?: Date): Date | undefined => {
  if (isValidDate(d1) && isValidDate(d2)) return d1 > d2 ? d2 : d1;
  if (isValidDate(d1)) return d1;
  if (isValidDate(d2)) return d2;
  return undefined;
};

export const isValidDate = (d?: Date): d is Date =>
  d instanceof Date && !isNaN(d.valueOf());

export function getAssignedOffer(
  assignedBooking: ReturnType<typeof getAssignedBooking>,
  eonHouses: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'],
  hawCatalogue: HawCatalogueData,
):
  | {
      name: string;
      house: {
        name: string;
      };
    }
  | undefined {
  let assignedOffer:
    | {
        name: string;
        house: { name: string };
      }
    | undefined = undefined;
  if (assignedBooking) {
    if (assignedBooking.isEon) {
      const assignedHouse = eonHouses.find(
        house => house.id === assignedBooking.houseId,
      ) || { name: '' };
      assignedOffer = {
        name: '',
        house: assignedHouse,
      };
    } else {
      const offer = getOfferForBooking(
        hawCatalogue,
        assignedBooking.bookingChoiceId,
      );
      if (offer) {
        assignedOffer = {
          name: offer.name,
          house: offer.house,
        };
      }
    }
  }
  return assignedOffer;
}

export const getHawCatalogue = (
  houses: ApplicationForm_GetVactionOffersHousesQuery['houses'],
  offers: ApplicationForm_GetVactionOffersHousesQuery['vacationOffers'],
): HawCatalogueData => {
  if (houses == null || offers == null) return [];
  let houseIds = houses.map(house => house.id);

  const offersWithHouses = offers.filter(
    x => x.house_id && houseIds.indexOf(x.house_id) > -1,
  );

  // Only offers which has bookings
  const offersFiltered = offersWithHouses.filter(
    x => x.bookingOffers && x.bookingOffers.length > 0,
  );

  houseIds = unique(offersFiltered.map(x => x.house_id || ''));
  const catalogue = houseIds.map<HawCatalogueEntry | undefined>(id => {
    const house = houses.find(x => x.id === id);
    if (house) {
      return {
        id,
        name: house.name,
        activated: house.activated ? true : false,
        dogAllowed: house.dogAllowed ? true : false,
        offers: offersFiltered
          .filter(x => x.house_id === id)
          .map<HawCatalogueEntry['offers'][0]>(offer => {
            return {
              id: offer.id,
              name: offer.name || '---',
              active: offer.activated,
              bookings:
                offer.bookingOffers?.map(booking => ({
                  id: booking.id,
                  active: booking.active,
                  from: getDate(booking.bookingDate.arrival) || new Date(0),
                  to: getDate(booking.bookingDate.departure) || new Date(0),
                  countervailingBenefit: booking.countervailingBenefit,
                })) || [],
              house: house,
              charges: offer.charges,
            };
          }),
      };
    }
  });

  return catalogue.filter<HawCatalogueEntry>(
    (x): x is HawCatalogueEntry => x !== undefined,
  );
};

function getBookingChoiceId(
  booking:
    | EditMyApplications_GetApplicationQuery['application']['hawBookings'][number]
    | EditMyApplications_GetApplicationQuery['application']['eonBookings'][number],
): string {
  if (booking.bookingStatus === BookingConfirmationStatus.Confirmed) {
    return booking.bookingChoiceId || '';
  }
  return '';
}

type BookingOffer =
  EditMyApplications_GetApplicationQuery['application']['hawBookings'][0]['bookingChoice'];

export function getAssignedBooking(
  application: EditMyApplications_GetApplicationQuery['application'],
):
  | {
      id: string;
      isEon: boolean;
      priority: number;
      board: BoardType;
      arrival: Date;
      departure: Date;
      socialPoints: boolean;
      summer: boolean;
      bookingChoiceId: string;
      houseId: string;
    }
  | undefined {
  let assignedBooking: ReturnType<typeof getAssignedBooking> = undefined;

  const assignedHawBooking = application.hawBookings?.find(
    hawBooking =>
      hawBooking.bookingStatus === BookingConfirmationStatus.Confirmed,
  );

  if (assignedHawBooking) {
    const bookingChoice: Maybe<BookingOffer> | undefined =
      assignedHawBooking.bookingChoice;

    const bookingChoiceId = getBookingChoiceId(assignedHawBooking);

    if (
      bookingChoice &&
      assignedHawBooking.board &&
      isEmpty(bookingChoiceId) === false
    ) {
      assignedBooking = {
        id: assignedHawBooking.id,
        isEon: false,
        priority: assignedHawBooking.priority,
        board: assignedHawBooking.board,
        arrival: getDate(bookingChoice.bookingDate.arrival) || new Date(0),
        departure: getDate(bookingChoice.bookingDate.departure) || new Date(0),
        summer: bookingChoice?.summerHolidays,
        socialPoints: bookingChoice?.socialCreditPoints,
        bookingChoiceId: bookingChoiceId,
        houseId: '',
      };
    }
  } else {
    const assignedEonBooking = application.eonBookings?.find(
      eonBooking =>
        eonBooking.bookingStatus === BookingConfirmationStatus.Confirmed,
    );
    if (assignedEonBooking) {
      const bookingChoice = assignedEonBooking.bookingChoice;

      const { arrival, departure } = getBookingTimeSpanEon(assignedEonBooking);

      // Old eon bookings don't have a bookingChoice
      if (bookingChoice == null) {
        if (assignedEonBooking.board) {
          assignedBooking = {
            id: assignedEonBooking.id,
            isEon: true,
            priority: assignedEonBooking.priority,
            board: assignedEonBooking.board,
            arrival: getDate(arrival) || new Date(0),
            departure: getDate(departure) || new Date(0),
            summer: true,
            socialPoints: true,
            bookingChoiceId: '',
            houseId: assignedEonBooking.houseId,
          };
        }
      } else {
        // Case for new bookings with eon offers/bookingChoice
        const bookingChoiceId = getBookingChoiceId(assignedEonBooking);

        if (
          bookingChoice &&
          assignedEonBooking.board &&
          isEmpty(bookingChoiceId) === false
        ) {
          assignedBooking = {
            id: assignedEonBooking.id,
            isEon: true,
            priority: assignedEonBooking.priority,
            board: assignedEonBooking.board,
            arrival: getDate(arrival) || new Date(0),
            departure: getDate(departure) || new Date(0),
            summer: bookingChoice?.summerHolidays ?? true,
            socialPoints: bookingChoice?.socialCreditPoints ?? true,
            bookingChoiceId: bookingChoiceId,
            houseId: assignedEonBooking.houseId,
          };
        }
      }
    }
  }
  return assignedBooking;
}

export function applicantAccountingArea(
  application: Pick<
    FEApplicationInput,
    'applicationFor' | 'applicant' | 'guest'
  >,
): TaccountingAreas {
  if (
    application.applicationFor === ApplicationFor.Me &&
    application.applicant.accountingArea
  ) {
    return application.applicant.accountingArea as TaccountingAreas;
  }
  if (
    application.applicationFor === ApplicationFor.OtherPerson &&
    application.guest?.accountingArea
  ) {
    return application.guest?.accountingArea as TaccountingAreas;
  }
  return 'Sonstige Gesellschaft';
}

export function applicantIsInAccountingArea(
  application: Pick<
    FEApplicationInput,
    'applicationFor' | 'applicant' | 'guest'
  >,
  areas: TaccountingAreas[],
): boolean {
  const applicantAcountingArea = applicantAccountingArea(application);
  return applicantAcountingArea && areas.includes(applicantAcountingArea);
}

export function applicantIsNotInAccountingArea(
  application: Pick<
    FEApplicationInput,
    'applicationFor' | 'applicant' | 'guest'
  >,
  areas: TaccountingAreas[],
): boolean {
  return !applicantIsInAccountingArea(application, areas);
}

export const getBookingTimeSpanEon = (
  booking: EditMyApplications_GetApplicationQuery['application']['eonBookings'][number],
): {
  arrival: string | undefined | null;
  departure: string | undefined | null;
} => ({
  arrival: getArrivalFromBooking(booking),
  departure:
    booking.bookingChoice?.bookingDate.departure || booking.period?.departure,
});

const getArrivalFromBooking = (booking?: BookingArrivals) =>
  booking?.bookingChoice?.bookingDate?.arrival || booking?.period?.arrival;

type BookingArrivals = {
  bookingChoice?: Maybe<{
    bookingDate?: Maybe<{
      arrival?: Maybe<string>;
    }>;
  }>;
  period?: {
    arrival?: string | undefined | null;
  } | null;
};
