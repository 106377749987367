import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const HealthOffers = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="HealthOffers">
      <div className="mb-8 flex items-center justify-between">
        <div>{t('pages.healthOffers.noOfferDesc')}</div>
      </div>
    </div>
  );
};

export default HealthOffers;
