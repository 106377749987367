import { AuthenticationResult } from '@azure/msal-browser';
import { acquireToken } from 'index';
import { FunctionComponent, useEffect, useState } from 'react';
import LoadingOrError from 'components/base/LoadingOrError';
import { IdTokenContext } from 'context/IdTokenContext';
import AuthorizedApolloClientProvider from 'authorizedApolloClientProvider';

const AccessTokenProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<{
    auth: AuthenticationResult | undefined;
    loading: boolean;
    error?: Error;
  }>({
    auth: undefined,
    loading: true,
  });

  useEffect(() => {
    acquireToken()
      .then(authResult => {
        if (authResult) {
          setState({
            auth: authResult,
            loading: false,
          });
        }
      })
      .catch(error =>
        setState(current => ({ ...current, loading: false, error })),
      );
  }, []);

  if (state.error) {
    throw state.error;
  }

  return (
    <LoadingOrError
      loading={state.loading && state.auth == null}
      error={undefined}
      fullscreen={true}
    >
      <IdTokenContext.Provider
        value={{
          accessToken: state.auth?.accessToken || '',
          ...state.auth?.idTokenClaims,
        }}
      >
        <AuthorizedApolloClientProvider
          onAuthChanged={authResult => {
            setState({
              auth: authResult,
              loading: false,
            });
          }}
        >
          {children}
        </AuthorizedApolloClientProvider>
      </IdTokenContext.Provider>
    </LoadingOrError>
  );
};
export default AccessTokenProvider;
