import { ApolloError } from '@apollo/client';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenterItemsContainer } from './CenterItemsContainer';
import { Icon } from './Icon';

const SHOW_LOADING_SPINNER_AFTER_MS = 350;

type LoadingErrorProps<T> = PropsWithChildren<{
  error?: ApolloError | boolean;
  loading: boolean;
  fullscreen?: boolean;
  data?: T;
  render?: (data: NonNullable<T>) => ReactElement;
  customErrorMessage?: string;
}>;

const LoadingOrError = <T,>({
  children,
  loading,
  error,
  fullscreen,
  data,
  render,
  customErrorMessage,
}: LoadingErrorProps<T>): ReactElement | null => {
  if (error)
    return (
      <ErrorMsg
        fullscreen={fullscreen}
        customErrorMessage={customErrorMessage}
      />
    );

  if (loading) {
    return <Loading fullscreen={fullscreen} loading={loading} />;
  }
  return render == null || data == null ? (
    <>{children}</>
  ) : (
    render(data as NonNullable<T>)
  );
};

export const Loading = ({
  fullscreen,
  loading,
}: {
  fullscreen?: boolean;
  loading: boolean;
}): ReactElement | null => {
  const { t } = useTranslation();
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      const newTimer = setTimeout(() => {
        setShowLoading(true);
      }, SHOW_LOADING_SPINNER_AFTER_MS);
      return () => {
        clearTimeout(newTimer);
      };
    } else {
      setShowLoading(false);
    }
  }, [loading]);

  return !showLoading ? null : (
    <CenterItemsContainer fullscreen={fullscreen}>
      <div className="loading-icon scale-75 transform" />
      <div>{t('pages.common.loading')}</div>
    </CenterItemsContainer>
  );
};

export const ErrorMsg = ({
  customErrorMessage,
  fullscreen,
}: {
  fullscreen?: boolean;
  customErrorMessage?: string | null;
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <CenterItemsContainer fullscreen={fullscreen}>
      <Icon icon={'ExclamationIcon'} className="my-4 h-16 w-16 text-red-700" />
      <div>{customErrorMessage || t('pages.common.error')}</div>
    </CenterItemsContainer>
  );
};

export default LoadingOrError;
