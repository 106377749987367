import LoadingOrError from 'components/base/LoadingOrError';
import HouseEntry from 'components/Houses/HouseEntry';
import HouseForm from 'components/Houses/HouseForm';
import { useEditHaw_GetListQuery } from 'gql/generated/types-and-hooks';
import { getString } from 'helpers/util';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Intro from '../../components/Houses/Intro';

type EditHawParams = {
  id: string;
};

export const EditHaw = (): ReactElement => {
  const { data, loading, error } = useEditHaw_GetListQuery();
  const { id } = useParams<EditHawParams>();
  const houses = data?.houses ? [...data.houses] : [];

  return (
    <div className="EditHaw">
      <LoadingOrError loading={loading} error={error}>
        <Intro />
        {houses.map(house => {
          if (house.id === id) {
            return <HouseForm key={house.id} houseId={house.id} />;
          }
          return (
            <HouseEntry
              key={house.id}
              house={{
                ...house,
                activated: house.activated ? true : false,
                isEonHome: house.isEonHome ? true : false,
                city: getString(house.city),
                region: getString(house.region),
              }}
            />
          );
        })}
      </LoadingOrError>
    </div>
  );
};
