import {
  Charges,
  PositionType as PositionTypeCalculation,
} from 'moin-ferienwerk-shared/dist/types/priceCalculation';
import {
  BoardType,
  CountervailingBenefit,
  FixedCharges,
  HouseCharge,
  PositionType,
  VacationOfferBoard,
  VacationOfferCharge,
} from 'gql/generated/types-and-hooks';
import { Boards } from 'api/mappers';

export function getPositionType(
  position: PositionType,
): PositionTypeCalculation {
  switch (position) {
    case PositionType.Executive:
      return PositionTypeCalculation.EXECUTIVE;
    case PositionType.Retiree:
      return PositionTypeCalculation.RETIREE;
    case PositionType.Employee:
    default:
      return PositionTypeCalculation.EMPLOYEE;
  }
}

export function getChargesForBoard({
  charges,
  board,
  countervailingBenefit,
}: {
  charges: {
    house: HouseCharge;
    vacationPackage?: VacationOfferCharge;
  };
  board: BoardType;
  countervailingBenefit?: CountervailingBenefit | null;
}): Charges | undefined {
  const houseBoard = getDataForBoard(charges.house, board);

  const vacationOfferBoard: VacationOfferBoard | undefined =
    charges.vacationPackage
      ? getDataForBoard(charges.vacationPackage, board)
      : undefined;

  return {
    house: {
      adult: houseBoard.accommodation.adult,
      benefitAdult1: countervailingBenefit?.firstAdult ?? 0,
      benefitAdult2: countervailingBenefit?.secondAdult ?? 0,
      benefitOldChild: countervailingBenefit?.childOld ?? 0,
      benefitYoungChild: countervailingBenefit?.childYoung ?? 0,
      cleaning: houseBoard.furtherPosition.cleaning,
      costsPerNight: houseBoard.furtherPosition.costsPerNight,
      countervailingBenefitPerNight: countervailingBenefit?.perNight ?? 0,
      executive: houseBoard.accommodation.executive,
      externalAdult: houseBoard.accommodation.externalAdult,
      externalChild: houseBoard.accommodation.externalChild,
      oldChild: houseBoard.accommodation.childOld,
      retired: houseBoard.accommodation.retiree,
      summerTax: houseBoard.furtherPosition.visitorsTaxSummer,
      winterTax: houseBoard.furtherPosition.visitorsTaxWinter,
      youngChild: houseBoard.accommodation.childYoung,
    },
    vactionPackage:
      vacationOfferBoard && vacationOfferBoard.bundle
        ? {
            adult: vacationOfferBoard.bundle.adult,
            externalAdult: vacationOfferBoard.bundle.externalAdult,
            externalChild: vacationOfferBoard.bundle.externalChild,
            oldChild: vacationOfferBoard.bundle.childOld,
            youngChild: vacationOfferBoard.bundle.childYoung,
          }
        : undefined,
  };
}

export function getFixedChargesForBoard({
  charges,
  board,
}: {
  charges: FixedCharges;
  board: BoardType;
}): Charges {
  const houseBoard = getDataForBoard(charges.house, board);
  const vacationOfferBoard = charges.vacationPackage
    ? getDataForBoard(charges.vacationPackage, board)
    : undefined;

  return {
    house: {
      adult: houseBoard.accommodation.adult,
      benefitAdult1: houseBoard.countervailingBenefit.firstAdult,
      benefitAdult2: houseBoard.countervailingBenefit.secondAdult,
      benefitOldChild: houseBoard.countervailingBenefit.childOld,
      benefitYoungChild: houseBoard.countervailingBenefit.childYoung,
      cleaning: houseBoard.furtherPosition.cleaning,
      costsPerNight: houseBoard.furtherPosition.costsPerNight,
      countervailingBenefitPerNight: houseBoard.countervailingBenefit.perNight,
      executive: houseBoard.accommodation.executive,
      externalAdult: houseBoard.accommodation.externalAdult,
      externalChild: houseBoard.accommodation.externalChild,
      oldChild: houseBoard.accommodation.childOld,
      retired: houseBoard.accommodation.retiree,
      summerTax: houseBoard.furtherPosition.visitorsTaxSummer,
      winterTax: houseBoard.furtherPosition.visitorsTaxWinter,
      youngChild: houseBoard.accommodation.childYoung,
    },
    vactionPackage: vacationOfferBoard
      ? {
          adult: vacationOfferBoard.adult,
          externalAdult: vacationOfferBoard.externalAdult,
          externalChild: vacationOfferBoard.externalChild,
          oldChild: vacationOfferBoard.childOld,
          youngChild: vacationOfferBoard.childYoung,
        }
      : undefined,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getDataForBoard<T extends { [k in Boards]: any }>(
  input: T,
  board: BoardType,
): T extends { [k in Boards]: infer V } ? V : unknown {
  switch (board) {
    case BoardType.SelfSupply:
      return input.selfSupply;
    case BoardType.OvernightBreakfast:
      return input.overnightBreakfast;
    case BoardType.HalfBoard:
      return input.halfBoard;
    case BoardType.FullBoard:
      return input.fullBoard;
  }
}
