import {
  Disability,
  EditMyApplications_GetApplicationQuery,
  EmploymentType,
  FurtherGuests,
  PointsTable_GetPassedApplicationsQuery,
  Position,
  PositionType,
} from 'gql/generated/types-and-hooks';

import {
  calculateApplicationBasedCreditPoints,
  getTotalFromApplicationBasedCreditPoints,
} from 'moin-ferienwerk-shared';

type PassedApplications =
  PointsTable_GetPassedApplicationsQuery['getPassedApplications'];

export function getPointsForApplication(
  application: EditMyApplications_GetApplicationQuery['application'],
  passedApplications?: PassedApplications,
): {
  calculation: CalculationResult;
  total: number;
} {
  const criterias = collectCreditPointCriterias(application);
  const calculation = calculateApplicationBasedCreditPoints({
    dateOfApplicationSubmit: criterias.dateOfApplicationSubmit,
    disabilityPresent: criterias.personalData.disability,
    familyChildrenDatesOfBirthTimestamps:
      criterias.travelers.childrenDateOfBirthTimestamps,
    single: criterias.personalData.single,
    contractType: criterias.personalData.contractType,
  });
  const total = getTotalFromCalculationResult(calculation, passedApplications);

  return {
    calculation: {
      ...calculation,
      ...(passedApplications || defaultPassedApplications),
    },
    total,
  };
}

const defaultPassedApplications: PassedApplications = {
  lastYear: [],
  twoYearsAgo: [],
  threeYearsAgo: [],
};

const collectCreditPointCriterias = (application: {
  companyPosition: Pick<Position, 'employment' | 'position'>;
  furtherGuests: Pick<FurtherGuests, 'familyChildrenUnderage'>;
  disability: Pick<Disability, 'present'>;
  singleParent: boolean;
  createdAt: string;
}): ApplicationBasedCalculationInput => {
  const companyPosition = application.companyPosition.position;

  const personalData = {
    contractType:
      companyPosition === PositionType.Retiree
        ? undefined
        : application.companyPosition.employment,
    disability: application.disability.present,
    single: application.singleParent,
  };
  const travelers = {
    childrenDateOfBirthTimestamps: [
      ...application.furtherGuests.familyChildrenUnderage.map(
        child => child.dateOfBirth,
      ),
    ],
  };

  return {
    travelers,
    personalData,
    dateOfApplicationSubmit: new Date(application.createdAt),
  };
};

type ApplicationBasedCalculationInput = {
  dateOfApplicationSubmit: Date;
  personalData: {
    disability: boolean;
    single: boolean;
    contractType?: EmploymentType | null;
  };
  travelers: {
    childrenDateOfBirthTimestamps: number[];
  };
};

type ApplicationBasedCalculationResult = ReturnType<
  typeof calculateApplicationBasedCreditPoints
>;
export type CalculationResult = PassedApplications &
  ApplicationBasedCalculationResult;

function getTotalFromCalculationResult(
  applicationBasedCalculationResult: ApplicationBasedCalculationResult,
  pointsHistory?: PassedApplications | null,
): number {
  const sumLastYear =
    pointsHistory?.lastYear.reduce(
      (prev, { credit_points }) => prev + credit_points,
      0,
    ) || 0;
  const sumTwoYearsAgo =
    pointsHistory?.twoYearsAgo.reduce(
      (prev, { credit_points }) => prev + credit_points,
      0,
    ) || 0;
  const sumThreeYearsAgo =
    pointsHistory?.threeYearsAgo.reduce(
      (prev, { credit_points }) => prev + credit_points,
      0,
    ) || 0;
  const applicationBasedCreditPoints = getTotalFromApplicationBasedCreditPoints(
    applicationBasedCalculationResult,
  );
  return (
    applicationBasedCreditPoints +
    sumLastYear +
    sumTwoYearsAgo +
    sumThreeYearsAgo
  );
}
