import useReactTooltip from 'hooks/useReactTooltip';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconName } from './Icon';

export const VisibleIcon = ({
  visible,
}: {
  visible: boolean;
}): ReactElement => (
  <div className="VisibleIcon inline-block text-sm">
    <Icon icon={visible ? 'EyeIcon' : 'EyeOffIcon'} />
  </div>
);

const colorStyles = primary => (primary ? 'bg-primary text-white' : '');
const buttonStyle = primary =>
  `px-4 py-2 ${colorStyles(primary)} text-sm rounded-3xl focus:outline-none`;

type BaseIconButtonType = {
  primary?: boolean;
  icon: IconName;
  tooltip?: string;
  onClick?: () => void;
  customStyles?: string;
};

const BaseIconButton = ({
  primary,
  icon,
  tooltip,
  onClick,
  customStyles,
}: BaseIconButtonType) => {
  useReactTooltip();

  return (
    <button
      className={customStyles || buttonStyle(primary)}
      onClick={onClick}
      data-tip={tooltip}
    >
      <Icon icon={icon} />
    </button>
  );
};

export const CloseButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <BaseIconButton
    icon={'XIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.close')}
    onClick={onClick}
  />
);

export const CheckButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <BaseIconButton
    primary
    icon={'CheckIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.confirm')}
    onClick={onClick}
  />
);

export const DeleteButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <BaseIconButton
    icon={'TrashIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.delete')}
    onClick={onClick}
  />
);

const ActivateButton = ({ onClick }: { onClick: () => void }) => (
  <BaseIconButton
    icon={'EyeOffIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.activate')}
    onClick={onClick}
  />
);

const DeactivateButton = ({ onClick }: { onClick: () => void }) => (
  <BaseIconButton
    icon={'EyeIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.deactivate')}
    onClick={onClick}
  />
);

export const ToggleActiveButton = ({
  active,
  onClick,
}: {
  active: boolean;
  onClick: () => void;
}): ReactElement =>
  active ? (
    <DeactivateButton onClick={onClick} />
  ) : (
    <ActivateButton onClick={onClick} />
  );

export const VisibleButton = ({
  visible,
}: {
  visible: boolean;
}): ReactElement => (
  <BaseIconButton icon={visible ? 'EyeIcon' : 'EyeOffIcon'} />
);

export const EditButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <BaseIconButton
    icon={'PencilIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.edit')}
    onClick={onClick}
  />
);

export const UploadIconButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <BaseIconButton
    icon={'UploadIcon'}
    tooltip={useTranslation().t('components.IconButton.tooltip.upload')}
    onClick={onClick}
  />
);

export const ChevronLeftButton = ({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}): ReactElement => (
  <BaseIconButton
    icon={'ChevronLeftIcon'}
    onClick={onClick}
    customStyles={buttonStyle(false) + (disabled ? ' text-gray-200' : '')}
  />
);

export const ChevronRightButton = ({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}): ReactElement => (
  <BaseIconButton
    icon={'ChevronRightIcon'}
    onClick={onClick}
    customStyles={buttonStyle(false) + (disabled ? ' text-gray-200' : '')}
  />
);
