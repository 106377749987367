import Card from 'components/base/Card';
import LoadingOrError from 'components/base/LoadingOrError';
import { EonOffers } from 'components/Eon/offers/EonOffers';
import Description from 'components/Houses/Description';
import Header from 'components/Houses/Header';
import { KeyFormProvider } from 'context/KeyFormContext';
import { useHouseForm_GetHouseQuery } from 'gql/generated/types-and-hooks';
import { getString } from 'helpers/util';
import { ReactElement } from 'react';
import Charges from './Charges';
import HouseAttachments from './HouseAttachments';
import Photos from './Photos';

const HouseForm = ({ houseId }: { houseId: string }): ReactElement => {
  const { data, loading, error } = useHouseForm_GetHouseQuery({
    variables: { id: houseId },
  });
  const house = data?.house || undefined;

  return (
    <LoadingOrError loading={loading} error={error}>
      {house ? (
        <KeyFormProvider>
          <Card>
            <Header
              house={{
                ...house,
                activated: house.activated ? true : false,
                isEonHome: house.isEonHome ? true : false,
              }}
            />
            <Description
              house={{
                ...house,
                isEonHome: house.isEonHome ? true : false,
                dogAllowed: house.dogAllowed ? true : false,
                type: getString(house.type),
                region: getString(house.region),
                city: getString(house.city),
                board: getString(house.board),
                additionalEquipment: getString(house.additionalEquipment),
                streetAndHouseNo: getString(house.streetAndHouseNo),
                postalCode: getString(house.postalCode),
                telephone: getString(house.telephone),
                email: getString(house.email),
                facilities: getString(house.facilities),
                furtherInformation: getString(house.furtherInformation),
              }}
            />
            <div className="mt-12">
              <Photos
                house={{
                  id: house.id,
                  files: house.files || [],
                }}
                editable={true}
              />
            </div>
            <div className="mt-12">
              <HouseAttachments houseId={houseId} files={house.files || []} />
            </div>
            <div className="mt-12">
              <Charges house={house} editable />
            </div>
            {house.isEonHome ? (
              <div className="mt-8">
                <EonOffers
                  houseId={house.id}
                  offers={
                    house.eonOffers?.map(x => {
                      return {
                        ...x,
                        countervailingBenefit: x.countervailingBenefit ?? {
                          childOld: 0,
                          childYoung: 0,
                          firstAdult: 0,
                          perNight: 0,
                          secondAdult: 0,
                        },
                        arrival: new Date(x.bookingDate.arrival || ''),
                        departure: new Date(x.bookingDate.departure || ''),
                      };
                    }) || []
                  }
                />
              </div>
            ) : null}
          </Card>
        </KeyFormProvider>
      ) : null}
    </LoadingOrError>
  );
};

export default HouseForm;
