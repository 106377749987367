import { EditableInput } from 'components/applicationForm/editableInputs';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import {
  refetchVacationOfferForm_GetVacationOfferQuery,
  useVacationNameForm_UpdateVacationOfferMutation,
} from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';

const VacationNameForm = ({
  offer,
}: {
  offer: {
    id: string;
    activated: boolean;
    name: string;
    service: string;
    house: {
      id: string;
    };
  };
}): ReactElement => {
  const { t } = useTranslation();
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);
  const { isAdmin } = useContext(UserInfoContext);

  const [updateVacationOffer] = useVacationNameForm_UpdateVacationOfferMutation(
    {
      refetchQueries: [
        refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
      ],
      context: {
        debounceKey: 'useVacationNameForm_UpdateVacationOfferMutation',
        notification: {
          success: t(
            'components.VacationOffers.VacationNameForm.notifications.success',
          ),
          error: t(
            'components.VacationOffers.VacationNameForm.notifications.error',
          ),
        },
      },
    },
  );

  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { name: offer.name },
  });

  const handleConfirm = handleSubmit(() => {
    const name = getValues('name');
    updateVacationOffer({
      variables: {
        id: offer.id,
        offer: {
          name,
          activated: offer.activated,
          houseId: offer.house.id,
          service: offer.service,
        },
      },
    });
    leaveEditMode();
  });

  const handleCancel = () => {
    reset({ name: offer.name });
    leaveEditMode();
  };
  const validationHint = t('pages.elements.formInputValidator.required');

  return (
    <div className="HolidayName flex flex-grow items-center">
      <div className={`mr-2 ${editMode ? 'w-1/3' : ''}`}>
        <ValidationFeedback error={errors.name}>
          <EditableInput
            nonEditableValue={offer.name || ''}
            editMode={editMode}
            {...register('name', {
              validate: v => !isEmpty(v) || validationHint,
            })}
            placeholder={t('components.description.placeholder.offerName')}
          />
        </ValidationFeedback>
      </div>
      {isAdmin && (
        <EditControls
          editMode={editMode}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          onEdit={enterEditMode}
        />
      )}
    </div>
  );
};

export default VacationNameForm;
