import {
  LogoutIcon,
  XIcon,
  EyeIcon,
  EyeOffIcon,
  CheckIcon,
  TrashIcon,
  PencilIcon,
  UploadIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ExclamationIcon,
  CalendarIcon,
  PlusIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  MailIcon,
  CursorClickIcon,
  XCircleIcon,
  DuplicateIcon,
} from '@heroicons/react/outline/esm';
import { ReactElement } from 'react';

export type IconName =
  | 'LogoutIcon'
  | 'XIcon'
  | 'EyeIcon'
  | 'EyeOffIcon'
  | 'CheckIcon'
  | 'TrashIcon'
  | 'PencilIcon'
  | 'UploadIcon'
  | 'ChevronLeftIcon'
  | 'ChevronRightIcon'
  | 'ChevronUpIcon'
  | 'ChevronDownIcon'
  | 'ExclamationIcon'
  | 'CalendarIcon'
  | 'PlusIcon'
  | 'InformationCircleIcon'
  | 'CheckCircleIcon'
  | 'EmailIcon'
  | 'XCircleIcon'
  | 'CursorClickIcon'
  | 'DuplicateIcon';

type IconProps = {
  icon: IconName;
  className?: string;
};

const baseClassName = 'Icon w-5 h-5';

export const Icon = ({ icon, className }: IconProps): ReactElement => {
  const finalClassName = `${baseClassName} ${className}`;
  switch (icon) {
    case 'LogoutIcon':
      return <LogoutIcon className={finalClassName} />;
    case 'XIcon':
      return <XIcon className={finalClassName} />;
    case 'EyeIcon':
      return <EyeIcon className={finalClassName} />;
    case 'EyeOffIcon':
      return <EyeOffIcon className={finalClassName} />;
    case 'CheckIcon':
      return <CheckIcon className={finalClassName} />;
    case 'TrashIcon':
      return <TrashIcon className={finalClassName} />;
    case 'PencilIcon':
      return <PencilIcon className={finalClassName} />;
    case 'UploadIcon':
      return <UploadIcon className={finalClassName} />;
    case 'ChevronLeftIcon':
      return <ChevronLeftIcon className={finalClassName} />;
    case 'ChevronRightIcon':
      return <ChevronRightIcon className={finalClassName} />;
    case 'ChevronUpIcon':
      return <ChevronUpIcon className={finalClassName} />;
    case 'ChevronDownIcon':
      return <ChevronDownIcon className={finalClassName} />;
    case 'ExclamationIcon':
      return <ExclamationIcon className={finalClassName} />;
    case 'CalendarIcon':
      return <CalendarIcon className={finalClassName} />;
    case 'PlusIcon':
      return <PlusIcon className={finalClassName} />;
    case 'InformationCircleIcon':
      return <InformationCircleIcon className={finalClassName} />;
    case 'CheckCircleIcon':
      return <CheckCircleIcon className={finalClassName} />;
    case 'EmailIcon':
      return <MailIcon className={finalClassName} />;
    case 'CursorClickIcon':
      return <CursorClickIcon className={finalClassName} />;
    case 'XCircleIcon':
      return <XCircleIcon className={finalClassName} />;
    case 'DuplicateIcon':
      return <DuplicateIcon className={finalClassName} />;
  }
};
