import { useMemo, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMsg, Loading } from 'components/base/LoadingOrError';
import { Show } from 'components/base/Show';
import { FileDropArea } from 'components/base/FileDropArea';
import UploadButton from 'components/base/UploadButton';
import Button from 'components/base/Button';
import { Icon } from 'components/base/Icon';
import { ACCEPTED_FILE_TYPES, ImportEntry } from './UploadController';
import { currencyNumberFormat } from 'helpers/util';
import { YesNoIcon } from './YesNoIcon';
import { dateMonthYearFormat } from 'helpers/dateFormats';

const NBR_OF_PREVIEWDATA = 25;

type UploadStepProps = {
  data?: {
    filename: string;
    entries: ImportEntry[];
  };
  hasInvalidEntries?: boolean;
  error?: string | null;
  loading: boolean;
  handleUpload: (files: FileList | File[]) => void;
};

export const UploadStep = memo(function UploadStep({
  error,
  data,
  loading,
  hasInvalidEntries,
  handleUpload,
}: UploadStepProps) {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    showAll: boolean;
  }>({
    showAll: false,
  });

  const previewData = useMemo(
    () => data?.entries.slice(0, NBR_OF_PREVIEWDATA),
    [data],
  );

  return (
    <>
      <Show
        when={(data?.entries.length ?? 0) > 0}
        fallback={
          <article className="flex h-full flex-col">
            <header>
              <h2 className="text-xl font-bold">
                {t(
                  'components.VacationOffers.import.steps.upload.uploadButton',
                )}
              </h2>
              <p>
                {t('components.VacationOffers.import.steps.upload.description')}
              </p>
            </header>
            <FileDropArea
              className="flex h-full flex-col items-center justify-center gap-4"
              onDrop={handleUpload}
            >
              <Loading loading={loading} />
              <Show when={error != null}>
                <ErrorMsg customErrorMessage={error} />
                <UploadButton
                  label={t(
                    'components.VacationOffers.import.steps.upload.reuploadButton',
                  )}
                  onChange={handleUpload}
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                />
              </Show>
              <Show when={!loading && error == null}>
                <Icon
                  icon={'CursorClickIcon'}
                  className="h-14 w-14 text-grey"
                />
                <h2 className="text-2xl text-grey">
                  {t(
                    'components.VacationOffers.import.steps.upload.dragAndDropTitle',
                  )}
                </h2>
                <UploadButton
                  label={t(
                    'components.VacationOffers.import.steps.upload.uploadButton',
                  )}
                  onChange={handleUpload}
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                />
              </Show>
            </FileDropArea>
          </article>
        }
      >
        <article className="flex h-full flex-col">
          <header className="relative py-4">
            <h2>
              <span className="text-2xl font-bold">{data?.filename}</span>
              <span className="text-ml-2 font-normal">
                (
                {t('components.VacationOffers.import.steps.upload.entries', {
                  count: data?.entries.length,
                })}
                )
              </span>
              <span className="absolute right-0 top-0">
                <UploadButton
                  label={t(
                    'components.VacationOffers.import.steps.upload.reuploadButton',
                  )}
                  onChange={handleUpload}
                  accept={ACCEPTED_FILE_TYPES.join(',')}
                />
              </span>
            </h2>
            <Show when={hasInvalidEntries}>
              <p className="text-red-500">
                {t('components.VacationOffers.import.errors.invalidData')}
              </p>
            </Show>
          </header>

          <FileDropArea
            className="h-full overflow-y-auto border-none"
            onDrop={handleUpload}
          >
            <Show when={state.showAll} fallback={RenderPreview(previewData)}>
              {RenderPreview(data?.entries)}
            </Show>
            <Show
              when={state.showAll === false && (data?.entries.length ?? 0) > 25}
            >
              <div className="flex justify-center pt-2">
                <Button
                  label={t(
                    'components.VacationOffers.import.steps.upload.showAllButton',
                  )}
                  styleOptions={{ inverted: true }}
                  onClick={() => {
                    setState({ showAll: true });
                  }}
                />
              </div>
            </Show>
          </FileDropArea>
        </article>
      </Show>
    </>
  );
});

const RenderPreview = (entries: ImportEntry[] = []) => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-[2fr_4fr_4fr_4fr_2fr_1fr]">
      {entries.flatMap(
        ({
          arrival,
          departure,
          bookingId,
          charge,
          house,
          region,
          isValid,
          validationResult,
        }) => {
          const cols = Object.entries({
            bookingId,
            house,
            region,
            period:
              validationResult.arrival && validationResult.departure
                ? dateMonthYearFormat.format(arrival) +
                  ' - ' +
                  dateMonthYearFormat.format(departure)
                : t('components.VacationOffers.import.errors.invalidPeriod'),
            charge: validationResult.charge
              ? currencyNumberFormat.format(charge)
              : t('components.VacationOffers.import.errors.invalidCharge'),
          }).map(([key, value]) => (
            <span
              className={!isValid ? 'text-red-500' : ''}
              key={key + '_' + bookingId}
            >
              {value}
            </span>
          ));
          return [
            ...cols,
            isValid == false ? (
              <YesNoIcon key={'valid_' + bookingId} value={isValid} />
            ) : (
              <span key={'valid_' + bookingId} />
            ),
          ];
        },
      )}
    </div>
  );
};
