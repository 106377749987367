import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { KeyFormArea } from 'context/KeyFormContext';
import { PropsWithChildren, ReactElement, useState } from 'react';
import AddButton from './AddButton';

export interface SliderProps<Type> {
  /**
   * This button will only be rendered, when the slider is editable
   */
  addButton?: ReactElement;
  render: (props: { item: Type; isDragging: boolean }) => ReactElement;
  isSortable?: boolean;
  editable: boolean;
  onChange: (files: FileList) => void;
  onSortOrderChanged?: (itemsUpdated: Type[]) => void;
  itemsToShow: number;
  items: Type[];
}

const SortableItem = ({ id, children }: PropsWithChildren<{ id: string }>) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        touchAction: 'none',
        transform: CSS.Transform.toString(transform),
        transition: transition,
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};

function Slider<Type extends { id: string }>({
  render,
  items,
  onChange,
  editable,
  isSortable,
  onSortOrderChanged,
}: SliderProps<Type>): ReactElement {
  const [dragging, setDragging] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setDragging(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (onSortOrderChanged && over && active.id !== over.id) {
      const activeItem = items.find(item => item.id === active.id);
      if (!activeItem) return;
      const oldIndex = items.indexOf(activeItem);
      const newIndex = items.findIndex(item => item.id === over.id);

      const updatedArray = arrayMove(items, oldIndex, newIndex).map(
        (file, index) => {
          return {
            ...file,
            order: index + 1, // Start with order = 1, new items will get order 0 and appear at the first position
          };
        },
      );

      onSortOrderChanged(updatedArray);
      setTimeout(() => setDragging(null), 100);
    }
  };

  return (
    <div className="grid w-full grid-cols-4 items-stretch justify-items-stretch gap-3 lg:grid-cols-5 xl:grid-cols-6">
      {editable && (
        <KeyFormArea>
          <AddButton onChange={onChange} />
        </KeyFormArea>
      )}
      <DndContext
        sensors={isSortable ? sensors : []}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          {items.map((item: Type) => (
            <SortableItem id={item.id} key={item.id}>
              {render({ item, isDragging: dragging === item.id })}
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
export default Slider;
