import { useTranslation } from 'react-i18next';
import { defaultFilter } from 'components/application/FilterEntries';
import Button from 'components/base/Button';
import LoadingOrError from 'components/base/LoadingOrError';
import PaginationBar, { Pagination } from 'components/PaginationBar';
import { ReactElement, useCallback, useState, useMemo } from 'react';
import {
  BoardBillingSortableColumn,
  SortingOrder,
  useBoardBilling_GetApplicationsVactionOffersHousesQuery,
  useBoardBilling_UpdateMutation,
} from 'gql/generated/types-and-hooks';
import { BoardBillingTable } from 'components/boardBilling/Table';
import { ColumnSorting } from 'types/ColumnSorting';
import { BoardBillingRowProps } from 'components/boardBilling/Row';
import { BoardBillingFilter, Filter } from 'components/boardBilling/Filter';

export type BoardBillingSorting = ColumnSorting<BoardBillingSortableColumn>;

const useBoardBillingRelevantApplications = (
  pagination?: Pagination,
  sorting?: BoardBillingSorting,
  filter?: BoardBillingFilter,
) =>
  useBoardBilling_GetApplicationsVactionOffersHousesQuery({
    variables: {
      skip: pagination?.offset,
      take: pagination?.limit,
      column: sorting?.column,
      order: sorting?.order,
      filterByHouse: filter?.house,
      filterByMonth: filter?.month,
      filterByYear: filter?.year,
      filterByOffer: filter?.offer,
    },
    fetchPolicy: 'cache-and-network',
  });

export const BoardBilling = (): ReactElement => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<BoardBillingFilter>(defaultFilter);
  const [sorting, setSorting] = useState<BoardBillingSorting>({
    column: BoardBillingSortableColumn.Period,
    order: SortingOrder.Descending,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: 25,
    offset: 0,
  });

  const { data, loading, error, refetch } = useBoardBillingRelevantApplications(
    {
      ...pagination,
      limit:
        pagination.limit === Number.MAX_SAFE_INTEGER ? NaN : pagination.limit,
    },
    sorting,
    filter,
  );

  const [update] = useBoardBilling_UpdateMutation({
    onCompleted: () => refetch(),
    context: {
      debounceKey: 'useBoardBilling_UpdateMutation',
      notification: {
        success: t('components.charges.notifications.success'),
        error: t('components.charges.notifications.error'),
      },
    },
  });

  const total = data?.applicationsForBoardBilling?.total ?? 0;

  const bookings: BoardBillingRowProps[] = useMemo(
    () =>
      data?.applicationsForBoardBilling?.items?.map<BoardBillingRowProps>(
        item => {
          return {
            applicationId: item.applicationId,
            houseName: item.houseName,
            vacationOfferName: item.vacationOfferName,
            employeeName: item.employeeName,
            bookingDate: {
              arrival: item.arrival,
              departure: item.departure,
            },
            boardPriceChange: item.priceChange,
            onUpdate: change => {
              update({
                variables: {
                  applicationID: item.applicationId,
                  change,
                },
              });
            },
          };
        },
      ) || [],
    [data?.applicationsForBoardBilling?.items, update],
  );

  const onSetFilter = useCallback(
    (newFilter: BoardBillingFilter) => {
      setFilter({ ...newFilter });
      setPagination({ limit: pagination.limit, offset: 0 });
    },
    [pagination.limit],
  );

  const onSetPagination = useCallback((pagination: Pagination) => {
    setPagination(pagination);
  }, []);

  const onSetSorting = useCallback((sorting: BoardBillingSorting) => {
    setSorting(sorting);
  }, []);

  return (
    <div className="BoardBilling">
      <div className="flex flex-row">
        <Filter onSetFilter={onSetFilter} filter={filter} />
        <div className="flex flex-1 flex-col overflow-x-auto">
          <div className="flex flex-row items-baseline justify-between">
            <PaginationBar
              limit={pagination.limit}
              pageSizes={[25, 50, 75, 100, Number.MAX_SAFE_INTEGER]}
              offset={pagination.offset}
              total={total}
              onChange={onSetPagination}
            />
            <NumberOfApplications numberOfApplications={total} />
          </div>

          <LoadingOrError loading={loading} error={error}>
            {!total ? (
              <div className="bg-gray-200 px-4 py-6 text-center">
                {t('components.applications.filterAndExport.noData')}
              </div>
            ) : bookings.length === 0 ? (
              <div className="flex flex-col items-center bg-gray-200 px-4 py-6">
                <div className="mb-2">
                  {t('components.applications.filterAndExport.noDataFilter')}
                </div>
                <Button
                  onClick={() => onSetFilter({})}
                  label={t(
                    'components.applications.filterAndExport.resetFilter',
                  )}
                />
              </div>
            ) : (
              <BoardBillingTable
                data={bookings}
                onSort={onSetSorting}
                sorting={sorting}
              />
            )}
          </LoadingOrError>
        </div>
      </div>
    </div>
  );
};

const NumberOfApplications = ({
  numberOfApplications,
}: {
  numberOfApplications?: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className={numberOfApplications == undefined ? 'invisible' : ''}>
      {t('components.applications.filterAndExport.numberOfApplications', {
        numberOfApplications: numberOfApplications,
      })}
    </div>
  );
};
