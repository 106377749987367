import NewVacationOfferButton from 'components/VacationOffers/NewVacationOfferButton';
import VacationOfferForm from 'components/VacationOffers/VacationOfferForm';
import { useParams } from 'react-router-dom';
import VacationOfferEntry from 'components/VacationOffers/VacationOfferEntry';
import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { getAlphanumericSort } from 'helpers/util';
import { useEditVacationOffer_GetVacationOffersListQuery } from 'gql/generated/types-and-hooks';
import LoadingOrError from 'components/base/LoadingOrError';

const alphanumericSort = getAlphanumericSort();

type EditVacationOfferParams = {
  id: string;
};

const EditVacationOffer = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams<EditVacationOfferParams>();
  const { isAdmin } = useContext(UserInfoContext);
  const { loading, error, data } =
    useEditVacationOffer_GetVacationOffersListQuery();
  const vacationOffers = data?.vacationOffersForIndex
    ? [...data.vacationOffersForIndex]
    : [];

  return (
    <div className="EditVacationOffer">
      <div className="mb-8 flex items-center justify-between">
        <p>{t('pages.vacationOffers.EditVacationOffer.moreDetailsHint')}</p>
        {isAdmin && <NewVacationOfferButton />}
      </div>
      <LoadingOrError loading={loading} error={error}>
        {vacationOffers
          .sort((a, b) => {
            const a_houseName = a.house?.name || '';
            const b_houseName = b.house?.name || '';
            return alphanumericSort(a_houseName + a.name, b_houseName + b.name);
          })
          .map(offer =>
            offer.id === id ? (
              <VacationOfferForm key={offer.id} vacationOfferId={offer.id} />
            ) : (
              <VacationOfferEntry key={offer.id} offer={offer} />
            ),
          )}
      </LoadingOrError>
    </div>
  );
};

export default EditVacationOffer;
