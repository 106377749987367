import { PropsWithChildren, ReactElement } from 'react';

export const CenterItemsContainer = ({
  children,
  fullscreen,
}: PropsWithChildren<{ fullscreen?: boolean }>): ReactElement => (
  <div
    className={`flex h-full flex-col items-center justify-center p-4 ${
      fullscreen && 'h-screen'
    }`}
  >
    {children}
  </div>
);
