import { Link } from 'react-router-dom';
import Card from 'components/base/Card';
import { PropsWithChildren, ReactElement } from 'react';

type LinkCardProps = PropsWithChildren<{ to: string }>;

const LinkCard = ({ children, to }: LinkCardProps): ReactElement => {
  return (
    <Link className="LinkCard block" to={to}>
      <Card>{children}</Card>
    </Link>
  );
};

export default LinkCard;
