export type TaccountingAreas =
  | 'E.ON Grid Solutions GmbH'
  | 'E.ON Energie Deutschland GmbH'
  | 'ElbEnergie GmbH'
  | 'HanseGas GmbH'
  | 'HanseWerk AG'
  | 'HanseWerk Natur GmbH'
  | 'HGC Hamburg Gas Consult GmbH'
  | 'Holsteiner Wasser GmbH'
  | 'Schleswig-Holstein Netz GmbH'
  | 'SERVICE plus GmbH'
  | 'Sonstige Gesellschaft'
  | 'Rentner';

export const AccountingAreasNotAllowedForEonActive: TaccountingAreas[] = [
  'E.ON Grid Solutions GmbH',
  'E.ON Energie Deutschland GmbH',
  'Sonstige Gesellschaft',
];

export const AccountingAreas: TaccountingAreas[] = [
  'E.ON Grid Solutions GmbH',
  'E.ON Energie Deutschland GmbH',
  'ElbEnergie GmbH',
  'HanseGas GmbH',
  'HanseWerk AG',
  'HanseWerk Natur GmbH',
  'HGC Hamburg Gas Consult GmbH',
  'Holsteiner Wasser GmbH',
  'Schleswig-Holstein Netz GmbH',
  'SERVICE plus GmbH',
  'Sonstige Gesellschaft',
  'Rentner',
];
