import { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConfig, ApplicationActions } from './interfaces';

export const renderActions = (
  actionConfigs: Record<ApplicationActions, ActionConfig>,
): ReactElement => {
  const createProps = ([type, { handler, isAvailable }]: [
    ApplicationActions,
    ActionConfig,
  ]): ActionProps => ({
    handleAction: handler,
    isAvailable,
    type,
  });
  return (
    <>
      {(Object.entries(actionConfigs) as [ApplicationActions, ActionConfig][])
        .map(createProps)
        .map(props => (
          <Action {...props} key={props.type} />
        ))}
    </>
  );
};

type ActionProps = {
  type: ApplicationActions;
  isAvailable: boolean;
  handleAction: () => void;
};

export const Action = ({
  type,
  handleAction,
  isAvailable,
}: ActionProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <ActionButton
      isAvailable={isAvailable}
      handleAction={() => handleAction()}
      text={t(`components.applications.actionHeader.${type}`)}
    />
  );
};

type ActionButtonProps = {
  isAvailable: boolean;
  handleAction: () => void;
  text: string;
};

const ActionButton = ({
  isAvailable,
  handleAction,
  text,
}: ActionButtonProps) => (
  <button
    className={`${
      isAvailable ? 'bg-primary text-white' : 'cursor-default text-gray-500'
    }  h-10 flex-grow`}
    onClick={() => handleAction()}
    disabled={!isAvailable}
  >
    {text}
  </button>
);

const ActionHeader: FunctionComponent = ({ children }) => {
  return (
    <div className="mb-4 flex w-full flex-row flex-wrap place-content-around space-x-2 bg-gray-100">
      {children}
    </div>
  );
};

export default ActionHeader;
