import { isEmpty } from 'helpers/util';
import { FunctionComponent, ReactElement } from 'react';
import { FieldError } from 'react-hook-form';

export const ErrorText = ({
  error,
}: {
  error?: FieldError;
}): ReactElement | null =>
  error ? (
    <div className="text-sm text-red-700">
      <span>{error.message}</span>
    </div>
  ) : null;

export const RedBorderOnInput: FunctionComponent<{ error?: FieldError }> = ({
  error,
  children,
}) => (
  <div
    className={`RedBorderOnInput ${
      !isEmpty(error?.message) ? 'invalid-input' : ''
    }`}
  >
    {children}
  </div>
);

export const ValidationFeedback: FunctionComponent<{ error?: FieldError }> = ({
  error,
  children,
}) => (
  <>
    <RedBorderOnInput error={error}>{children}</RedBorderOnInput>
    <ErrorText error={error} />
  </>
);
