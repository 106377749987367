import { forwardRef, SelectHTMLAttributes } from 'react';
import { TFunction } from 'react-i18next';

type HouseDropdownProps = SelectHTMLAttributes<HTMLSelectElement> & {
  houses: {
    id: string;
    name: string;
    activated?: boolean | null;
    dogAllowed?: boolean | null;
  }[];
  t: TFunction<'translation', undefined>;
  dogAllowedRequired: boolean;
};

export const HouseDropdown = forwardRef<HTMLSelectElement, HouseDropdownProps>(
  ({ houses, t, dogAllowedRequired, ...props }: HouseDropdownProps, ref) => (
    <select {...{ ...props, ref }}>
      <option key={'default'} value={''}>
        {t('components.form.hawBookingEntry.houseDropdown.placeholder')}
      </option>
      {houses.map(house => (
        <option key={house.id} value={house.id}>
          {getOptionText({ house, t, dogAllowedRequired })}
        </option>
      ))}
    </select>
  ),
);

function getOptionText({
  house,
  dogAllowedRequired,
  t,
}: {
  house: HouseDropdownProps['houses'][0];
  dogAllowedRequired: boolean;
  t: TFunction<'translation', undefined>;
}): string {
  if (house.activated === false)
    return `${t('components.form.hawBookingEntry.noPlacesLeftMarker')} ${
      house.name
    }`;

  if (dogAllowedRequired && !house.dogAllowed)
    return `${t('components.form.hawBookingEntry.noDogsMarker')} ${house.name}`;

  return house.name;
}

HouseDropdown.displayName = 'HouseDropdown';
export default HouseDropdown;
