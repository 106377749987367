import { CenterItemsContainer } from 'components/base/CenterItemsContainer';
import { Icon } from 'components/base/Icon';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  ApplicationFor,
  EditMyApplications_GetApplicationQuery,
  PointsTable_GetPassedApplicationsQuery,
  usePointsTable_GetPassedApplicationsLazyQuery,
} from 'gql/generated/types-and-hooks';
import {
  getAssignedBooking,
  getEarliestArrivalFromBooking,
  getEarliestBooking,
} from 'helpers/applicationHelper';
import { dateMonthFormat } from 'helpers/dateFormats';
import { getPointsForApplication } from 'helpers/pointsCalculation';
import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Application = EditMyApplications_GetApplicationQuery['application'];
type PassedApplication =
  PointsTable_GetPassedApplicationsQuery['getPassedApplications']['lastYear'][0];

const getStaffNumber = (
  application?: Application | null,
): string | undefined | null =>
  application?.applicationFor === ApplicationFor.Me
    ? application?.applicant.staffNumber
    : application?.guest?.staffNumber;

const PassedApplicationRowEntry = ({
  passedApplication: {
    booked_offer_name,
    booking_changes_booking_date_arrival,
    booking_changes_booking_date_departure,
    booking_changes_summer_holidays,
    credit_points,
  },
  even,
}: {
  passedApplication: PassedApplication;
  even: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <tr className={'PassedApplicationRowEntry' + even ? 'bg-gray-100' : ''}>
      <td className="pl-8">
        <div className="flex flex-row">
          <span className="flex-1">{`${booked_offer_name}`}</span>
          <span className="flex-1">{`${dateMonthFormat.format(
            new Date(booking_changes_booking_date_arrival),
          )} - ${dateMonthFormat.format(
            new Date(booking_changes_booking_date_departure),
          )}`}</span>
          <span className="flex-1">
            {booking_changes_summer_holidays &&
              `(${t('components.form.pointsTable.summerHolidays')})`}
          </span>
        </div>
      </td>
      <td />
      <td />
      <td className="text-right">{credit_points}</td>
    </tr>
  );
};

export const PointsTable = ({
  application,
}: {
  application: Application;
}): ReactElement => {
  const { t } = useTranslation();
  const earliestBooking = getEarliestBooking(application).booking;
  const assignedBooking = getAssignedBooking(application);
  const relevantBookingDate =
    assignedBooking?.arrival || getEarliestArrivalFromBooking(earliestBooking);
  const yearOfArrival = relevantBookingDate?.getFullYear();

  /* Only calculate the table if the booking is for the current or last year  */
  const canCalculateTable = useMemo(
    () =>
      yearOfArrival != null
        ? yearOfArrival >= new Date().getFullYear() - 1
        : false,
    [yearOfArrival],
  );

  const [getPassedApplications, { loading, error, data }] =
    usePointsTable_GetPassedApplicationsLazyQuery({
      variables: {
        comparedToBookingDate: relevantBookingDate?.toISOString(),
        forStaffNumber: getStaffNumber(application),
      },
    });

  useEffect(() => {
    if (canCalculateTable) {
      getPassedApplications();
    }
  }, [canCalculateTable, getPassedApplications]);

  if (!canCalculateTable) {
    return <PointsTableNotAvailable />;
  }

  const points = getPointsForApplication(
    application,
    data?.getPassedApplications,
  );

  const { calculation, total } = points;

  return (
    <LoadingOrError loading={loading} error={error}>
      <table className="PointsTable w-full">
        <colgroup>
          <col style={{ width: '52%' }} />
          <col style={{ width: '16%' }} />
          <col style={{ width: '16%' }} />
          <col style={{ width: '16%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>{t('components.form.pointsTable.headers.criteria')}</th>
            <th className="text-center">
              {t('components.form.pointsTable.headers.count')}
            </th>
            <th className="text-center">
              {t('components.form.pointsTable.headers.points')}
            </th>
            <th className="text-center">
              {t('components.form.pointsTable.headers.pointsTotal')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('components.form.pointsTable.criterias.permanentContract')}
            </td>
            {calculation.permanentContract.map((x, i) => (
              <td key={`permanentContract_${i}`} className="text-right">
                {x}
              </td>
            ))}
            <td className="text-right">
              {calculation.permanentContract[0] *
                calculation.permanentContract[1]}
            </td>
          </tr>
          <tr className="bg-gray-100">
            <td>{t('components.form.pointsTable.criterias.singleParent')}</td>
            {calculation.singleParent.map((x, i) => (
              <td key={`singleParent_${i}`} className="text-right">
                {x}
              </td>
            ))}
            <td className="text-right">
              {calculation.singleParent[0] * calculation.singleParent[1]}
            </td>
          </tr>
          <tr>
            <td>{t('components.form.pointsTable.criterias.disability')}</td>
            {calculation.disability.map((x, i) => (
              <td key={`disability_${i}`} className="text-right">
                {x}
              </td>
            ))}
            <td className="text-right">
              {calculation.disability[0] * calculation.disability[1]}
            </td>
          </tr>
          <tr className="bg-gray-100">
            <td>{t('components.form.pointsTable.criterias.children_0_6')}</td>
            {calculation.children_0_6.map((x, i) => (
              <td key={`children_0_6_${i}`} className="text-right">
                {x}
              </td>
            ))}
            <td className="text-right">
              {calculation.children_0_6[0] * calculation.children_0_6[1]}
            </td>
          </tr>
          <tr>
            <td>{t('components.form.pointsTable.criterias.children_7_17')}</td>
            {calculation.children_7_17.map((x, i) => (
              <td key={`children_7_17_${i}`} className="text-right">
                {x}
              </td>
            ))}
            <td className="text-right">
              {calculation.children_7_17[0] * calculation.children_7_17[1]}
            </td>
          </tr>
          <tr className="bg-gray-200">
            <td>{`${t('components.form.pointsTable.vacations')} ${
              yearOfArrival
                ? yearOfArrival - 1
                : t('components.form.pointsTable.yearUnknown')
            }`}</td>
            <td />
            <td />
            <td />
          </tr>
          {calculation.lastYear.length > 0 ? (
            calculation.lastYear.map((c, index) => (
              <PassedApplicationRowEntry
                even={index % 2 === 0}
                passedApplication={c}
                key={c.id}
              />
            ))
          ) : (
            <tr>
              <td className="pl-8">
                {t('components.form.pointsTable.noVacations')}
              </td>
            </tr>
          )}
          <tr className="bg-gray-200">
            <td>{`${t('components.form.pointsTable.vacations')} ${
              yearOfArrival
                ? yearOfArrival - 2
                : t('components.form.pointsTable.yearUnknown')
            }`}</td>
            <td />
            <td />
            <td />
          </tr>
          {calculation.twoYearsAgo.length > 0 ? (
            calculation.twoYearsAgo.map((c, index) => (
              <PassedApplicationRowEntry
                even={index % 2 === 0}
                passedApplication={c}
                key={c.id}
              />
            ))
          ) : (
            <tr>
              <td className="pl-8">
                {t('components.form.pointsTable.noVacations')}
              </td>
            </tr>
          )}
          <tr className="bg-gray-200">
            <td>{`${t('components.form.pointsTable.vacations')} ${
              yearOfArrival
                ? yearOfArrival - 3
                : t('components.form.pointsTable.yearUnknown')
            }`}</td>
            <td />
            <td />
            <td />
          </tr>
          {calculation.threeYearsAgo.length > 0 ? (
            calculation.threeYearsAgo.map((c, index) => (
              <PassedApplicationRowEntry
                even={index % 2 === 0}
                passedApplication={c}
                key={c.id}
              />
            ))
          ) : (
            <tr>
              <td className="pl-8">
                {t('components.form.pointsTable.noVacations')}
              </td>
            </tr>
          )}
          <tr>
            <td>{t('components.form.pointsTable.totalPoints')}</td>
            <td />
            <td />
            <td className="text-right">{total}</td>
          </tr>
        </tbody>
      </table>
    </LoadingOrError>
  );
};

const PointsTableNotAvailable = () => {
  const { t } = useTranslation();
  return (
    <CenterItemsContainer>
      <Icon icon={'ExclamationIcon'} className="my-4 h-16 w-16 text-red-700" />
      <div>{t('components.form.pointsTable.notAvailable')}</div>
    </CenterItemsContainer>
  );
};

export default PointsTable;
