import LoadingOrError from 'components/base/LoadingOrError';
import HouseEntry from 'components/Houses/HouseEntry';
import Intro from 'components/Houses/Intro';
import { useIndexHaw_GetListQuery } from 'gql/generated/types-and-hooks';
import { getString } from 'helpers/util';
import { ReactElement } from 'react';

export const IndexHaw = (): ReactElement => {
  const { data, loading, error } = useIndexHaw_GetListQuery();
  const houses = data?.houses ? [...data.houses] : [];

  return (
    <div className="IndexHaw">
      <LoadingOrError loading={loading} error={error}>
        <Intro />
        {houses.map(house => (
          <HouseEntry
            key={house.id}
            house={{
              ...house,
              activated: house.activated ? true : false,
              isEonHome: house.isEonHome ? true : false,
              city: getString(house.city),
              region: getString(house.region),
            }}
          />
        ))}
      </LoadingOrError>
    </div>
  );
};
