import { ReactElement, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import { DeleteButton } from './IconButton';

type DeleteWithConfirmationIconButtonProps = {
  onDelete: () => void;
  title: string;
  text: string;
};

const DeleteWithConfirmationIconButton = ({
  onDelete,
  title,
  text,
}: DeleteWithConfirmationIconButtonProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const handleClickOpen = () => setOpen(true);

  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  return (
    <div className="DeleteWithConfirmation">
      <ConfirmationDialog
        onConfirm={handleDelete}
        onCancel={handleCancel}
        open={open}
        title={title}
        text={text}
      ></ConfirmationDialog>
      <DeleteButton onClick={handleClickOpen} />
    </div>
  );
};

export default DeleteWithConfirmationIconButton;
