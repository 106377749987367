import { ReactElement, useContext, useEffect, useState } from 'react';
import EditControls, { useEditMode } from '../applicationForm/EditControls';
import InputCell from '../tables/InputCell';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';
import { Boards } from 'api/mappers';
import {
  Accommodation,
  HouseCharge,
  useVacationPrices_UpdateVacationOfferChargesMutation,
  VacationOfferBoard,
  VacationOfferCharge,
} from 'gql/generated/types-and-hooks';

type Categories = keyof Omit<Accommodation, '__typename'>;

const boards: Boards[] = [
  'selfSupply',
  'overnightBreakfast',
  'halfBoard',
  'fullBoard',
];

const VacationPrices = ({
  offer,
}: {
  offer: {
    id: string;
    charges: VacationOfferCharge;
    house: {
      charges: HouseCharge;
    };
  };
}): ReactElement => {
  const { t } = useTranslation();
  const [prices, setPrices] = useState(offer.charges);
  const [updateCharges] = useVacationPrices_UpdateVacationOfferChargesMutation({
    context: {
      debounceKey: 'useVacationPrices_UpdateVacationOfferChargesMutation',
      notification: {
        success: t(
          'components.VacationOffers.VacationPrices.notifications.success',
        ),
        error: t(
          'components.VacationOffers.VacationPrices.notifications.error',
        ),
      },
    },
  });
  const { isAdmin } = useContext(UserInfoContext);
  const [, enterEditMode, leaveEditMode] = useEditMode(false);
  const [editColumn, setEditColumn] = useState(null); // 0 means none

  useEffect(() => {
    setPrices(offer.charges);
  }, [offer.charges]);

  const pricesByGuestCategory = (category: Categories) => ({
    selfSupply: prices.selfSupply.bundle
      ? (prices.selfSupply.bundle[category] as number)
      : 0,
    overnightBreakfast: prices.overnightBreakfast.bundle
      ? (prices.overnightBreakfast.bundle[category] as number)
      : 0,
    halfBoard: prices.halfBoard.bundle
      ? (prices.halfBoard.bundle[category] as number)
      : 0,
    fullBoard: prices.fullBoard.bundle
      ? (prices.fullBoard.bundle[category] as number)
      : 0,
  });

  const handleChangePrice =
    (category: Categories) =>
    (categoryPrices: ReturnType<typeof pricesByGuestCategory>) => {
      const newPrices: VacationOfferCharge = JSON.parse(JSON.stringify(prices));
      if (newPrices.selfSupply.bundle) {
        newPrices.selfSupply.bundle[category] = categoryPrices.selfSupply;
      }
      if (newPrices.overnightBreakfast.bundle) {
        newPrices.overnightBreakfast.bundle[category] =
          categoryPrices.overnightBreakfast;
      }
      if (newPrices.halfBoard.bundle) {
        newPrices.halfBoard.bundle[category] = categoryPrices.halfBoard;
      }
      if (newPrices.fullBoard.bundle) {
        newPrices.fullBoard.bundle[category] = categoryPrices.fullBoard;
      }
      setPrices(newPrices);
    };

  if (!offer.charges)
    return <div className="font-medium text-red-700">Error</div>;

  const renderRow = (category: Categories, rowTitle: string) => {
    const rowPrice = pricesByGuestCategory(category);
    const changePrice = handleChangePrice(category);
    return (
      <tr>
        <td>{rowTitle}</td>
        {(isAdmin || offer.house?.charges.selfSupply.activated) && (
          <InputCell
            value={rowPrice.selfSupply}
            disabled={!offer.house?.charges.selfSupply.activated && isAdmin}
            onChange={selfSupply => changePrice({ ...rowPrice, selfSupply })}
            edit={editColumn === 'selfSupply'}
          />
        )}
        {(isAdmin || offer.house?.charges.overnightBreakfast.activated) && (
          <InputCell
            value={rowPrice.overnightBreakfast}
            disabled={
              !offer.house?.charges.overnightBreakfast.activated && isAdmin
            }
            onChange={overnightBreakfast =>
              changePrice({ ...rowPrice, overnightBreakfast })
            }
            edit={editColumn === 'overnightBreakfast'}
          />
        )}
        {(isAdmin || offer.house?.charges.halfBoard.activated) && (
          <InputCell
            value={rowPrice.halfBoard}
            disabled={!offer.house?.charges.halfBoard.activated && isAdmin}
            onChange={halfBoard => changePrice({ ...rowPrice, halfBoard })}
            edit={editColumn === 'halfBoard'}
          />
        )}
        {(isAdmin || offer.house?.charges.fullBoard.activated) && (
          <InputCell
            value={rowPrice.fullBoard}
            disabled={!offer.house?.charges.fullBoard.activated && isAdmin}
            onChange={fullBoard => changePrice({ ...rowPrice, fullBoard })}
            edit={editColumn === 'fullBoard'}
          />
        )}
      </tr>
    );
  };

  const handleConfirm = () => {
    setEditColumn(null);
    leaveEditMode();

    const mapToApi = (boardCharges: VacationOfferBoard) => ({
      bundle: {
        adult: boardCharges.bundle?.adult || 0,
        childYoung: boardCharges.bundle?.childYoung || 0,
        childOld: boardCharges.bundle?.childOld || 0,
        retiree: boardCharges.bundle?.retiree || 0,
        executive: boardCharges.bundle?.executive || 0,
        externalAdult: boardCharges.bundle?.externalAdult || 0,
        externalChild: boardCharges.bundle?.externalChild || 0,
      },
    });

    updateCharges({
      variables: {
        vacationOfferId: offer.id,
        charges: {
          fullBoard: mapToApi(prices.fullBoard),
          halfBoard: mapToApi(prices.halfBoard),
          overnightBreakfast: mapToApi(prices.overnightBreakfast),
          selfSupply: mapToApi(prices.selfSupply),
        },
      },
    });
  };

  const handleCancel = () => {
    setPrices(offer.charges);
    setEditColumn(null);
    leaveEditMode();
  };

  const handleEdit = column => () => {
    setEditColumn(column);
    enterEditMode();
  };

  const getThStyle = (active: boolean) =>
    `text-right font-medium ${active ? '' : 'bg-gray-300 text-gray-500'}`;

  const colGroup = () => (
    <colgroup>
      <col style={{ width: '28%' }} />
      {(offer.house?.charges.selfSupply.activated || isAdmin) && (
        <col style={{ width: '18%' }} />
      )}
      {(offer.house?.charges.overnightBreakfast.activated || isAdmin) && (
        <col style={{ width: '18%' }} />
      )}
      {(offer.house?.charges.halfBoard.activated || isAdmin) && (
        <col style={{ width: '18%' }} />
      )}
      {(offer.house?.charges.fullBoard.activated || isAdmin) && (
        <col style={{ width: '18%' }} />
      )}
    </colgroup>
  );

  return (
    <table className="w-full table-fixed whitespace-nowrap">
      {colGroup()}
      <thead>
        <tr className="bg-gray-100 text-left text-primary">
          <th className="truncate font-medium">
            {t(
              'components.VacationOffers.VacationPrices.column.holidayPackageCostsPerDay',
            )}
          </th>
          <th
            className={
              getThStyle(offer.house?.charges.selfSupply.activated) +
              ' truncate'
            }
            hidden={!offer.house?.charges.selfSupply.activated && !isAdmin}
          >
            {t('components.charges.column.selfSupply')}
          </th>
          <th
            className={getThStyle(
              offer.house?.charges.overnightBreakfast.activated,
            )}
            hidden={
              !offer.house?.charges.overnightBreakfast.activated && !isAdmin
            }
          >
            {t('components.charges.column.overnightBreakfast')}
          </th>
          <th
            className={
              getThStyle(offer.house?.charges.halfBoard.activated) + ' truncate'
            }
            hidden={!offer.house?.charges.halfBoard.activated && !isAdmin}
          >
            {t('components.charges.column.halfBoard')}
          </th>
          <th
            className={
              getThStyle(offer.house?.charges.fullBoard.activated) + ' truncate'
            }
            hidden={!offer.house?.charges.fullBoard.activated && !isAdmin}
          >
            {t('components.charges.column.fullBoard')}
          </th>
        </tr>
      </thead>
      <tbody>
        {renderRow(
          'adult',
          t('components.VacationOffers.VacationPrices.adult'),
        )}
        {renderRow(
          'childYoung',
          t('components.VacationOffers.VacationPrices.childYoung'),
        )}
        {renderRow(
          'childOld',
          t('components.VacationOffers.VacationPrices.childOld'),
        )}
        {renderRow(
          'externalAdult',
          t('components.VacationOffers.VacationPrices.externalAdult'),
        )}
        {renderRow(
          'externalChild',
          t('components.VacationOffers.VacationPrices.externalChild'),
        )}
      </tbody>
      {isAdmin && (
        <tfoot>
          <tr>
            <td></td>
            {boards.map(board => (
              <td key={board}>
                {isAdmin && (
                  <div className="flex justify-end">
                    <EditControls
                      editMode={editColumn === board}
                      onEdit={handleEdit(board)}
                      onConfirm={handleConfirm}
                      onCancel={handleCancel}
                    />
                  </div>
                )}
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default VacationPrices;
