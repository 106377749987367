import Button from 'components/base/Button';
import DeleteWithConfirmationButton from 'components/base/DeleteWithConfirmationButton';
import LinkButton from 'components/base/LinkButton';
import config from 'config/config';
import { ProcessingStatus } from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const ActionButtonsForStateAsAdmin = ({
  state,
  handleSave,
  handleSubmit,
  isApplicationFinished,
}: {
  state: ProcessingStatus;
  handleSave: (sendEmail?: boolean) => void;
  handleSubmit: () => void;
  isApplicationFinished: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const actions: ReactElement[] = [];

  // Abort Button
  if (
    state === ProcessingStatus.Created ||
    state === ProcessingStatus.Submitted ||
    state === ProcessingStatus.InReview ||
    state === ProcessingStatus.Assigned ||
    state === ProcessingStatus.CancelledConfirmed ||
    state === ProcessingStatus.Confirmed
  ) {
    actions.push(
      <Button
        key="applicationFormAbortButton"
        onClick={() => {
          window.close();
        }}
        label={t('components.form.applicationForm.abortButton')}
        styleOptions={{
          inverted: true,
        }}
      />,
    );
  }

  // Close Button
  if (
    state === ProcessingStatus.Rejected ||
    state === ProcessingStatus.RejectedConfirmed ||
    state === ProcessingStatus.Cancelled ||
    isApplicationFinished
  ) {
    actions.push(
      <Button
        key="applicationFormCloseButton"
        onClick={() => {
          window.close();
        }}
        label={t('components.form.applicationForm.closeButton')}
        styleOptions={{
          inverted: true,
        }}
      />,
    );
  }

  // Save button
  if (
    state === ProcessingStatus.Submitted ||
    state === ProcessingStatus.InReview ||
    state === ProcessingStatus.Assigned ||
    state === ProcessingStatus.CancelledConfirmed ||
    state === ProcessingStatus.Confirmed
  ) {
    actions.push(
      <Button
        key="applicationFormSaveButton"
        label={t('components.form.applicationForm.saveButton')}
        onClick={() => handleSave()}
      />,
    );
  }
  // Submit Button
  if (state === ProcessingStatus.Created) {
    actions.push(
      <Button
        key="applicationFormSubmitButton"
        label={t('components.form.applicationForm.submitButton')}
        onClick={() => handleSubmit()}
      />,
    );
  }

  // Save and Send Email
  if (
    state === ProcessingStatus.Confirmed ||
    state === ProcessingStatus.CancelledConfirmed
  ) {
    actions.push(
      <Button
        key="applicationFormSaveAndSendEmailButton"
        label={t('components.form.applicationForm.saveAndSendEmailButton')}
        onClick={() => handleSave(true)}
        icon={'EmailIcon'}
      />,
    );
  }

  return (
    <div className={`grid gap-x-2 grid-cols-${actions.length}`}>{actions}</div>
  );
};

export const ActionButtonsForStateAsUser = ({
  state,
  handleSave,
  handleSubmit,
  onDelete,
  isApplicationFinished,
}: {
  state: ProcessingStatus;
  handleSave: () => void;
  handleSubmit: () => void;
  onDelete?: () => void;
  isApplicationFinished: boolean;
}): ReactElement => {
  const actions: ReactElement[] = [];
  const { t } = useTranslation();
  // Abort Button
  if (
    state === ProcessingStatus.Created ||
    state === ProcessingStatus.SentBack
  ) {
    actions.push(
      <LinkButton
        key="applicationFormAbortButton"
        to={config.localRoutes.myApplications.index}
        inverted={true}
      >
        {t('components.form.applicationForm.abortButton')}
      </LinkButton>,
    );
  }

  // Close Button
  if (
    state === ProcessingStatus.Submitted ||
    state === ProcessingStatus.InReview ||
    state === ProcessingStatus.Assigned ||
    state === ProcessingStatus.Confirmed ||
    state === ProcessingStatus.Rejected ||
    state === ProcessingStatus.RejectedConfirmed ||
    state === ProcessingStatus.Cancelled ||
    state === ProcessingStatus.CancelledConfirmed ||
    isApplicationFinished
  ) {
    actions.push(
      <LinkButton
        key="applicationFormCloseButton"
        to={config.localRoutes.myApplications.index}
        inverted={false}
      >
        {t('components.form.applicationForm.closeButton')}
      </LinkButton>,
    );
  }

  // Delete button
  if (
    onDelete &&
    (state === ProcessingStatus.Created || state === ProcessingStatus.SentBack)
  ) {
    actions.push(
      <DeleteWithConfirmationButton
        key="applicationFormDeleteWithConfirmationButton"
        label={t('components.form.applicationForm.deleteButton')}
        inverted={true}
        onDelete={onDelete}
        text={t('components.form.applicationForm.deleteDialog.text')}
        title={t('components.form.applicationForm.deleteDialog.title')}
      />,
    );
  }

  // Save/Submit button
  if (
    state === ProcessingStatus.Created ||
    state === ProcessingStatus.SentBack
  ) {
    actions.push(
      <Button
        key="applicationFormSaveButton"
        label={t('components.form.applicationForm.saveButton')}
        onClick={() => handleSave()}
      />,
    );

    actions.push(
      <Button
        key="applicationFormSubmitButton"
        label={t('components.form.applicationForm.submitButton')}
        onClick={() => handleSubmit()}
      />,
    );
  }

  return (
    <div className={`grid gap-x-2 grid-cols-${actions.length}`}>{actions}</div>
  );
};
