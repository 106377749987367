import Button from 'components/base/Button';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  BookingType,
  StatusFilter,
  useFilterEntries_GetDataQuery,
} from 'gql/generated/types-and-hooks';
import { sequence, unique } from 'helpers/util';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationFilter } from './interfaces';

type FilterEntriesProps = {
  onSetFilter: (filter: ApplicationFilter) => void;
  filter: ApplicationFilter;
};

export const defaultFilter: ApplicationFilter = {
  processingStatus: StatusFilter.Submitted,
};

const createYearFilterOptions = () => {
  const currentYear = new Date().getFullYear();
  return sequence(currentYear - 4, currentYear + 2, 1);
};

const FilterEntries = ({
  onSetFilter,
  filter,
}: FilterEntriesProps): ReactElement => {
  const SHOW_ALL = 'SHOW_ALL';
  const { t } = useTranslation();

  const handleChange = ([k, v]: [string, number | string | undefined]) => {
    onSetFilter({
      ...filter,
      [k]: v === SHOW_ALL ? undefined : v,
    });
  };

  const resetFilter = () => {
    onSetFilter(defaultFilter);
  };

  const { data, loading, error } = useFilterEntries_GetDataQuery();

  const eonAndHawHouses = useMemo(() => {
    return unique(
      data?.eonAndHawHouses.filter(house => !!house.name).map(x => x.name) ??
        [],
    );
  }, [data?.eonAndHawHouses]);

  const vacationOffers = useMemo(() => {
    return unique(
      data?.vacationOffers.filter(offer => !!offer.name).map(x => x.name) ?? [],
    );
  }, [data?.vacationOffers]);

  return (
    <div className="FilterEntries">
      <LoadingOrError error={error} loading={loading}>
        <div>{t('components.applications.filterAndExport.type')}</div>
        <select
          className="mb-2 w-full"
          name="type"
          onChange={e => handleChange([e.target.name, e.target.value])}
          value={filter.type || SHOW_ALL}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          <option value={BookingType.HawF}>
            {t('layouts.mainLayout.menu.holiday')}
          </option>
          <option value={BookingType.HawG}>
            {t('layouts.mainLayout.menu.healthDays')}
          </option>
          <option value={BookingType.Eon}>
            {t('layouts.mainLayout.menu.eon')}
          </option>
        </select>
        <div>{t('components.applications.filterAndExport.state')}</div>
        <select
          className="mb-2 w-full"
          name={'processingStatus'}
          onChange={e => handleChange([e.target.name, e.target.value])}
          value={filter.processingStatus || SHOW_ALL}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          <option value={StatusFilter.Submitted}>
            {t(
              'components.applications.filterAndExport.statusFilter.SUBMITTED',
            )}
          </option>
          <option value={StatusFilter.InReview}>
            {t(
              'components.applications.filterAndExport.statusFilter.IN_REVIEW',
            )}
          </option>
          <option value={StatusFilter.Rejected}>
            {t('components.applications.filterAndExport.statusFilter.REJECTED')}
          </option>
          <option value={StatusFilter.Assigned}>
            {t('components.applications.filterAndExport.statusFilter.ASSIGNED')}
          </option>
          <option value={StatusFilter.Cancelled}>
            {t(
              'components.applications.filterAndExport.statusFilter.CANCELLED',
            )}
          </option>
          <option value={StatusFilter.CancelledConfirmed}>
            {t(
              'components.applications.filterAndExport.statusFilter.CANCELLED_CONFIRMED',
            )}
          </option>
          <option value={StatusFilter.RejectedConfirmed}>
            {t(
              'components.applications.filterAndExport.statusFilter.REJECTED_CONFIRMED',
            )}
          </option>
          <option value={StatusFilter.Confirmed}>
            {t(
              'components.applications.filterAndExport.statusFilter.CONFIRMED',
            )}
          </option>
          <option value={StatusFilter.FinishedApplication}>
            {t(
              'components.applications.filterAndExport.statusFilter.FINISHED_APPLICATION',
            )}
          </option>
          <option value={StatusFilter.FinishedBooking}>
            {t(
              'components.applications.filterAndExport.statusFilter.FINISHED_BOOKING',
            )}
          </option>
        </select>

        <div>{t('components.applications.filterAndExport.house')}</div>
        <select
          className="mb-2 w-full"
          name="house"
          onChange={e => handleChange([e.target.name, e.target.value])}
          value={filter.house || SHOW_ALL}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          {eonAndHawHouses.map(name => (
            <option key={name} value={name}>{`${name}`}</option>
          ))}
        </select>
        <div>{t('components.applications.filterAndExport.offer')}</div>
        <select
          className="mb-2 w-full"
          name="offer"
          onChange={e => handleChange([e.target.name, e.target.value])}
          value={filter.offer || SHOW_ALL}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          {vacationOffers.map(name => (
            <option key={name} value={name ?? ''}>{`${name ?? ''}`}</option>
          ))}
        </select>

        <div>{t('components.applications.filterAndExport.year')}</div>
        <select
          className="mb-2 w-full"
          name="year"
          onChange={e =>
            handleChange([
              e.target.name,
              e.target.value !== SHOW_ALL ? parseInt(e.target.value) : SHOW_ALL,
            ])
          }
          value={filter.year == undefined ? SHOW_ALL : filter.year}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          {createYearFilterOptions().map(y => (
            <option key={y} value={y}>{`${y}`}</option>
          ))}
        </select>
        <div className="mt-2">
          {t('components.applications.filterAndExport.month')}
        </div>
        <select
          className="mb-2 w-full"
          name="month"
          onChange={e =>
            handleChange([
              e.target.name,
              e.target.value !== SHOW_ALL ? parseInt(e.target.value) : SHOW_ALL,
            ])
          }
          value={filter.month === undefined ? SHOW_ALL : filter.month}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          {sequence(1, 12, 1).map(month => (
            <option key={month} value={month}>
              {t(`components.applications.filterAndExport.months.${month}`)}
            </option>
          ))}
        </select>
        <div>{t('components.applications.filterAndExport.priority')}</div>
        <select
          className="mb-2 w-full"
          name="priority"
          onChange={e =>
            handleChange([
              e.target.name,
              e.target.value !== SHOW_ALL ? parseInt(e.target.value) : SHOW_ALL,
            ])
          }
          value={filter.priority == undefined ? SHOW_ALL : filter.priority}
        >
          <option value={SHOW_ALL}>
            {t('components.applications.filterAndExport.showAll')}
          </option>
          {sequence(1, 6, 1).map(prio => (
            <option key={prio} value={prio.toString()}>
              {prio.toString()}
            </option>
          ))}
        </select>

        <div className="flex w-full justify-center border-b border-grey pb-2">
          <Button
            onClick={resetFilter}
            disabled={Object.keys(filter).every(
              k => filter[k] === defaultFilter[k],
            )}
            label={t('components.applications.filterAndExport.resetFilter')}
            styleOptions={{
              wFull: true,
            }}
          />
        </div>
      </LoadingOrError>
    </div>
  );
};

export default FilterEntries;
