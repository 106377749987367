import { KeyFormArea } from 'context/KeyFormContext';
import { useTranslation } from 'react-i18next';
import UploadHouseFile from './UploadHouseFile';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { getString } from 'helpers/util';
import {
  File as AttachmentFile,
  FileType,
  refetchHouseForm_GetHouseQuery,
  useHouseAttachments_DeleteFileMutation,
  useHouseAttachments_UpdateDataFileMutation,
  useHouseAttachments_UpdateFileMetadataMutation,
} from 'gql/generated/types-and-hooks';
import { readFile } from 'helpers/fileHelper';
import { AttachmentTable } from 'components/AttachmentTable/AttachmentTable';

const HouseAttachments = ({
  houseId,
  files,
}: {
  houseId: string;
  files: Pick<
    AttachmentFile,
    'id' | 'description' | 'type' | 'path' | 'name'
  >[];
}): ReactElement => {
  const { isAdmin } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const refetchQueries = [refetchHouseForm_GetHouseQuery({ id: houseId })];

  const [deleteFile] = useHouseAttachments_DeleteFileMutation({
    refetchQueries,
    context: {
      debounceKey: 'useHouseAttachments_DeleteFileMutation',
      notification: {
        success: t(
          'components.attachment.notifications.deleteAttachment.success',
        ),
        error: t('components.attachment.notifications.deleteAttachment.error'),
      },
    },
  });
  const [updateAttachment] = useHouseAttachments_UpdateDataFileMutation({
    refetchQueries,
    context: {
      debounceKey: 'useHouseAttachments_UpdateDataFileMutation',
      notification: {
        success: t(
          'components.attachment.notifications.updateAttachment.success',
        ),
        error: t('components.attachment.notifications.updateAttachment.error'),
      },
    },
  });
  const [updateFileMetadata] = useHouseAttachments_UpdateFileMetadataMutation({
    refetchQueries,
    context: {
      debounceKey: 'useHouseAttachments_UpdateFileMetadataMutation',
      notification: {
        success: t(
          'components.attachment.notifications.updateFileMetadata.success',
        ),
        error: t(
          'components.attachment.notifications.updateFileMetadata.error',
        ),
      },
    },
  });
  const handleDelete = (id: string) => deleteFile({ variables: { id } });

  const handleUpload = async (id: string, file: File) => {
    const result = await readFile(file);
    updateAttachment({
      variables: {
        id,
        file: {
          name: result.name,
          content: result.content,
          type: FileType.Attachment,
        },
      },
    });
  };

  const handleDescriptionChange = (id: string, newDescription: string) => {
    updateFileMetadata({
      variables: {
        id,
        file: {
          description: newDescription,
        },
      },
    });
  };

  const attachments = files.filter(x => x.type === 'ATTACHMENT') || [];

  return (
    <div className="OfferAttachments">
      <div className="mb-2 flex items-end">
        <div className="flex-auto">
          <h2 className="font-medium">{t('components.attachment.title')}</h2>
        </div>
        <div className="">
          {isAdmin && (
            <KeyFormArea>
              <UploadHouseFile houseId={houseId} />
            </KeyFormArea>
          )}
        </div>
      </div>

      <div className="mt-8">
        {attachments.length > 0 ? (
          <AttachmentTable
            attachments={attachments.map(x => ({
              ...x,
              description: getString(x.description),
            }))}
            editable={isAdmin}
            onUpload={handleUpload}
            onDelete={handleDelete}
            onDescriptionChanged={handleDescriptionChange}
          />
        ) : (
          <div>{t('pages.common.noData')}</div>
        )}
      </div>
    </div>
  );
};

export default HouseAttachments;
