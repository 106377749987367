import { createContext, useContext } from 'react';

export type UserRole = 'BOARD_BILLING' | 'ADMIN';

type UserInfo = {
  roles: UserRole[];
  isAdmin: boolean;
  email: string;
  kid: string;
  name: string;
  accountingArea: string;
  department: string;
  staffNumber: string;
};
export const UserInfoContext = createContext<UserInfo>({
  name: '',
  roles: [],
  isAdmin: false,
  email: '',
  kid: '',
  accountingArea: '',
  department: '',
  staffNumber: '',
});

export const useUserName = (): string => {
  const { name } = useContext(UserInfoContext);
  return name;
};

export const useEmail = (): string => {
  const { email } = useContext(UserInfoContext);
  return email;
};

export const useKID = (): string => {
  const { kid } = useContext(UserInfoContext);
  return kid;
};
