import AuthLink from 'components/AttachmentTable/AuthLink';
import { SortableColumn, StatusFilter } from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnSorting } from 'types/ColumnSorting';
import { ApplicationFilter } from './interfaces';

type ExportEntriesProps = {
  filter: ApplicationFilter;
  sorting: ColumnSorting<SortableColumn>;
};

export const ExportEntries = ({
  filter,
  sorting,
}: ExportEntriesProps): ReactElement => {
  const { t } = useTranslation();
  let isDisabledPayroll = true;
  let isDisabledTaxAndEvaluation = true;
  let isDisabledEonActive = true;
  let isDisabledApplicationPayroll = true;

  if (
    filter.processingStatus &&
    (filter.processingStatus === StatusFilter.Assigned ||
      filter.processingStatus === StatusFilter.Confirmed)
  ) {
    isDisabledEonActive = false;
  }
  if (
    filter.processingStatus &&
    (filter.processingStatus === StatusFilter.Assigned ||
      filter.processingStatus === StatusFilter.Confirmed ||
      filter.processingStatus === StatusFilter.FinishedBooking)
  ) {
    isDisabledTaxAndEvaluation = false;
  }

  if (
    filter.processingStatus &&
    (filter.processingStatus === StatusFilter.Confirmed ||
      filter.processingStatus === StatusFilter.CancelledConfirmed)
  ) {
    isDisabledPayroll = false;
  }

  if (
    filter.processingStatus &&
    (filter.processingStatus === StatusFilter.RejectedConfirmed ||
      filter.processingStatus === StatusFilter.FinishedApplication)
  ) {
    isDisabledApplicationPayroll = false;
  }

  return (
    <>
      <h2>{t('components.applications.filterAndExport.export')}</h2>
      <div className="ml-6">
        <ExportLink
          name={t('components.applications.filterAndExport.payroll')}
          path={'export/entgeltabrechnung'}
          sorting={sorting}
          filter={filter}
          disabled={isDisabledPayroll}
        />
        <ExportLink
          name={t('components.applications.filterAndExport.taxAndEvaluation')}
          path={'export/kurtaxe'}
          sorting={sorting}
          filter={filter}
          disabled={isDisabledTaxAndEvaluation}
        />
        <ExportLink
          name={t('components.applications.filterAndExport.eonActive')}
          path={'export/eon-aktiv'}
          sorting={sorting}
          filter={filter}
          disabled={isDisabledEonActive}
        />
        <ExportLink
          name={t('components.applications.filterAndExport.applicationPayroll')}
          path={'export/antragsabrechnung'}
          sorting={sorting}
          filter={filter}
          disabled={isDisabledApplicationPayroll}
        />
      </div>
    </>
  );
};

const ExportLink = ({
  name,
  path,
  disabled,
  filter,
  sorting,
}: {
  name: string;
  path: string;
  disabled: boolean;
} & ExportEntriesProps): ReactElement => {
  return (
    <AuthLink
      disabled={disabled}
      attachment={{
        name,
        path,
      }}
      fetchOptions={{
        method: 'POST',
        body: JSON.stringify({
          filter: {
            filterByHouse: filter.house,
            filterByMonth: filter.month,
            filterByYear: filter.year,
            filterByOffer: filter.offer,
            filterByPriority: filter.priority,
            filterByStatus: filter.processingStatus,
            filterByType: filter.type,
          },
          sorting: sorting,
        }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }}
    />
  );
};
