import FormSection from '../FormSection';
import { useFormContext } from 'react-hook-form';
import RowLabel from '../RowLabel';
import { useTranslation } from 'react-i18next';
import { isEmpty, sequence } from 'helpers/util';
import TextInput from '../TextInput';
import { ValidationFeedback } from '../Validatable';
import isPostalCode from 'validator/lib/isPostalCode';
import isNumeric from 'validator/lib/isNumeric';
import { RadioButton } from '../RadioButton';
import {
  EditableCheckbox,
  EditableInput,
  EditableSelect,
} from '../editableInputs';
import {
  EditMyApplications_GetApplicationQuery,
  EmploymentType,
  PositionType,
} from 'gql/generated/types-and-hooks';
import { ReactElement, useState } from 'react';

const PersonalDataInput = ({
  editMode,
}: {
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<EditMyApplications_GetApplicationQuery['application']>();

  const {
    street,
    houseNumber,
    zip,
    city,
    phone,
    singleParent,
    disability,
    allergies,
    companyPosition,
  } = getValues() || {};

  const [isAllergiesFormVisible, setIsAllergiesFormVisible] = useState(
    !isEmpty(allergies),
  );

  const entranceYearOptions = [
    {
      key: 'entranceYearPlaceholder',
      value: '',
      displayValue: t('components.form.personalDataInput.placeholder'),
    },
    ...sequence(0, 75, 1).map(i => {
      const year = (currentYear - i).toString();
      return {
        key: year,
        value: year,
        displayValue: year,
      };
    }),
  ];

  const disabilityDegrees = sequence(20, 100, 10).map(i => {
    const degree = i.toString();
    return {
      key: degree,
      displayValue: degree,
      value: i,
    };
  });

  return (
    <div className="PersonalDataInput">
      <FormSection name={t('components.form.personalDataInput.sectionTitle')}>
        <div className="grid grid-cols-12 items-baseline gap-3">
          <RowLabel required={true} editMode={editMode}>
            {t('components.form.personalDataInput.streetHousenumber')}
          </RowLabel>
          {editMode ? (
            <>
              <div className="col-span-4">
                <ValidationFeedback error={errors?.street}>
                  <TextInput
                    {...register('street', {
                      validate: v =>
                        !isEmpty(v) ||
                        t<string>('pages.elements.formInputValidator.required'),
                    })}
                  />
                </ValidationFeedback>
              </div>
              <div className="col-span-2">
                <ValidationFeedback error={errors?.houseNumber}>
                  <TextInput
                    {...register('houseNumber', {
                      validate: v =>
                        !isEmpty(v) ||
                        t<string>('pages.elements.formInputValidator.required'),
                    })}
                  />
                </ValidationFeedback>
              </div>
            </>
          ) : (
            <div className="col-span-4">{`${street} ${houseNumber}`}</div>
          )}

          <RowLabel required={true} editMode={editMode}>
            {t('components.form.personalDataInput.zipCity')}
          </RowLabel>
          {editMode ? (
            <>
              <div className="col-span-2">
                <ValidationFeedback error={errors?.zip}>
                  <TextInput
                    {...register('zip', {
                      validate: v =>
                        (!isEmpty(v) && isPostalCode(v, 'DE')) ||
                        t<string>('pages.elements.formInputValidator.zip'),
                    })}
                  />
                </ValidationFeedback>
              </div>
              <div className="col-span-4">
                <ValidationFeedback error={errors?.city}>
                  <TextInput
                    {...register('city', {
                      validate: v =>
                        !isEmpty(v) ||
                        t<string>('pages.elements.formInputValidator.required'),
                    })}
                  />
                </ValidationFeedback>
              </div>
            </>
          ) : (
            <div className="col-span-4">{`${zip} ${city}`}</div>
          )}
          <RowLabel required={true} editMode={editMode}>
            {t('components.form.personalDataInput.privatePhone')}
          </RowLabel>
          <div className="col-span-3">
            <ValidationFeedback error={errors?.phone}>
              <EditableInput
                editMode={editMode}
                {...register('phone', {
                  validate: v =>
                    (!isEmpty(v) && isNumeric(v)) ||
                    t<string>('pages.elements.formInputValidator.numberOnly'),
                })}
                nonEditableValue={phone}
              />
            </ValidationFeedback>
          </div>
          <RowLabel>
            {t('components.form.personalDataInput.disability')}
          </RowLabel>

          <div className="col-span-3">
            <EditableCheckbox
              editMode={editMode}
              nonEditableValue={disability.present}
              {...register('disability.present')}
            />
          </div>
          {disability.present ? (
            <>
              <RowLabel className={'ml-8'}>
                {t('components.form.personalDataInput.disabilityDegree')}
              </RowLabel>
              <div className="col-span-3">
                <EditableSelect
                  editMode={editMode}
                  options={disabilityDegrees}
                  nonEditableValue={disability.degree?.toString() || ''}
                  {...register('disability.degree', {
                    shouldUnregister: true,
                    valueAsNumber: true,
                  })}
                />
              </div>
              <RowLabel className={'ml-8'}>
                {t('components.form.personalDataInput.walkingDisability')}
              </RowLabel>
              <div className="col-span-3">
                <EditableCheckbox
                  editMode={editMode}
                  nonEditableValue={disability.walkingDisability}
                  {...register('disability.walkingDisability', {
                    shouldUnregister: true,
                  })}
                />
              </div>
              <RowLabel className={'ml-8'}>
                {t('components.form.personalDataInput.markBOnPassport')}
              </RowLabel>
              <div className="col-span-3">
                <EditableCheckbox
                  editMode={editMode}
                  nonEditableValue={disability.markBOnPassport}
                  {...register('disability.markBOnPassport', {
                    shouldUnregister: true,
                  })}
                />
              </div>
            </>
          ) : null}

          <RowLabel>{t('components.form.personalDataInput.allergy')}</RowLabel>
          <div className="col-span-3">
            <EditableCheckbox
              editMode={editMode}
              checked={isAllergiesFormVisible}
              nonEditableValue={isAllergiesFormVisible}
              onChange={e => setIsAllergiesFormVisible(e.currentTarget.checked)}
            />
          </div>
          {isAllergiesFormVisible ? (
            <>
              <RowLabel className={'ml-8'}>
                {t('components.form.personalDataInput.allergyType')}
              </RowLabel>
              <div className="col-span-3">
                <ValidationFeedback error={errors.allergies}>
                  <EditableInput
                    editMode={editMode}
                    nonEditableValue={allergies ? allergies : ''}
                    {...register('allergies', {
                      shouldUnregister: true,
                      validate: v =>
                        !isEmpty(v) ||
                        t<string>('pages.elements.formInputValidator.required'),
                    })}
                  />
                </ValidationFeedback>
              </div>
            </>
          ) : null}
          <RowLabel>{t('components.form.personalDataInput.single')}</RowLabel>
          <EditableCheckbox
            editMode={editMode}
            nonEditableValue={singleParent}
            {...register('singleParent')}
          />

          <RowLabel>{t('components.form.personalDataInput.position')}</RowLabel>
          <div className="col-span-3">
            {editMode ? (
              <div className="flex gap-4">
                <RadioButton
                  {...register('companyPosition.position')}
                  label={t(
                    'components.form.personalDataInput.companyPosition.employee',
                  )}
                  value={PositionType.Employee}
                />
                <RadioButton
                  {...register('companyPosition.position')}
                  label={t(
                    'components.form.personalDataInput.companyPosition.executive',
                  )}
                  value={PositionType.Executive}
                />
                <RadioButton
                  {...register('companyPosition.position')}
                  label={t(
                    'components.form.personalDataInput.companyPosition.retiree',
                  )}
                  value={PositionType.Retiree}
                />
              </div>
            ) : (
              <span>
                {companyPosition.position
                  ? t(
                      `components.form.personalDataInput.companyPosition.${companyPosition.position.toLowerCase()}`,
                    )
                  : '---'}
              </span>
            )}
          </div>
          <RowLabel required={true} editMode={editMode} className={'ml-8'}>
            {t('components.form.personalDataInput.entranceYear')}
          </RowLabel>
          <div className="col-span-3">
            <ValidationFeedback error={errors?.companyPosition?.entranceYear}>
              <EditableSelect
                editMode={editMode}
                options={entranceYearOptions}
                nonEditableValue={
                  companyPosition.entranceYear?.toString() || ''
                }
                {...register('companyPosition.entranceYear', {
                  valueAsNumber: true,
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                })}
              />
            </ValidationFeedback>
          </div>
          {companyPosition.position !== PositionType.Retiree ? (
            <>
              <RowLabel className={'ml-8'}>
                {t('components.form.personalDataInput.contract')}
              </RowLabel>
              <div className="col-span-3">
                {editMode ? (
                  <ValidationFeedback
                    error={errors.companyPosition?.employment}
                  >
                    <div className="flex gap-4">
                      <RadioButton
                        {...register('companyPosition.employment', {
                          required: t<string>(
                            'pages.elements.formInputValidator.required',
                          ),
                          shouldUnregister: true,
                        })}
                        label={t(
                          'components.form.personalDataInput.contractChoices.PERMANENT',
                        )}
                        value={EmploymentType.Permanent}
                      />
                      <RadioButton
                        {...register('companyPosition.employment', {
                          required: t<string>(
                            'pages.elements.formInputValidator.required',
                          ),
                          shouldUnregister: true,
                        })}
                        label={t(
                          'components.form.personalDataInput.contractChoices.FIXED_TERM',
                        )}
                        value={EmploymentType.FixedTerm}
                      />
                    </div>
                  </ValidationFeedback>
                ) : (
                  <span>
                    {t(
                      `components.form.personalDataInput.contractChoices.${companyPosition.employment}`,
                    )}
                  </span>
                )}
              </div>
            </>
          ) : null}
        </div>
      </FormSection>
    </div>
  );
};

export default PersonalDataInput;
