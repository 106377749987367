import { useRef } from 'react';
import { useEffect, VoidFunctionComponent } from 'react';
import { Icon } from './base/Icon';

export type NotificationTypes = 'success' | 'error' | 'info';
export type NotificationContent = { type: NotificationTypes; text: string };

type NotificationProps = {
  notification?: { type: NotificationTypes; text: string };
  onDisappear?: () => void;
};

export const Notification: VoidFunctionComponent<NotificationProps> = ({
  notification,
  onDisappear,
}: NotificationProps) => {
  const notificationElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (notification) {
      notificationElement.current?.classList.add('fadeIn');
      setTimeout(() => {
        notificationElement.current?.classList.replace('fadeIn', 'fadeOut');
        setTimeout(() => {
          onDisappear && onDisappear();
          notificationElement.current?.classList.replace('fadeOut', 'fadeIn');
        }, 251);
      }, 2500);
    }
  }, [notification, onDisappear]);

  return notification ? (
    <div ref={notificationElement} className="Notification">
      <div
        className={`fadeIn flex h-16 w-96 items-center rounded px-2 ${getBackgroundColorClassName(
          notification.type,
        )}`}
      >
        <div>{getIcon(notification.type)}</div>
        <div className="truncate-2">{notification.text}</div>
      </div>
    </div>
  ) : null;
};

const getBackgroundColorClassName = (type: NotificationTypes) => {
  switch (type) {
    case 'success':
      return 'bg-notification-success';
    case 'error':
      return 'bg-notification-error';
    case 'info':
      return 'bg-notification-info';
    default:
      return '';
  }
};

const getIcon = (type: NotificationTypes) => {
  const className = 'mr-2 w-6 h-6';
  switch (type) {
    case 'success':
      return <Icon icon={'CheckCircleIcon'} className={className} />;
    case 'error':
      return <Icon icon={'ExclamationIcon'} className={className} />;
    case 'info':
      return <Icon icon={'InformationCircleIcon'} className={className} />;
    default:
      return null;
  }
};
