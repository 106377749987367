import { currencyNumberFormat } from 'helpers/util';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxInput from 'components/applicationForm/CheckboxInput';
import {
  ImportEntryWithoutSocialCreditPointData,
  SocialCreditPointsSettings,
} from './SocialCreditPointsController';

type SocialCreditPointsStepProps = {
  data?: ImportEntryWithoutSocialCreditPointData[];
  socialCreditPoints: {
    settings: SocialCreditPointsSettings;
    setCrediting: (value: {
      socialCreditPoints: boolean;
      bookingId: string;
    }) => void;
    setSummerholidays: (value: {
      summerHolidays: boolean;
      bookingId: string;
    }) => void;
  };
};

export const SocialCreditPointsStep = memo(function SocialCreditPointsStep({
  data,
  socialCreditPoints: { settings, setCrediting, setSummerholidays },
}: SocialCreditPointsStepProps) {
  const { t } = useTranslation();

  return (
    <article className="flex h-full flex-col">
      <header className="py-4">
        <h2 className="text-2xl font-bold">
          {t(
            'components.VacationOffers.import.steps.socialCreditPoints.heading',
          )}
        </h2>
        <h3 className="text-ml font-normal">
          {t(
            'components.VacationOffers.import.steps.socialCreditPoints.description',
          )}
        </h3>
      </header>
      <section className="flex flex-grow flex-col overflow-y-auto">
        <Table
          entries={data}
          socialCreditPointsSetting={settings}
          setCrediting={setCrediting}
          setSummerholidays={setSummerholidays}
        />
      </section>
    </article>
  );
});

const Table = ({
  entries,
  socialCreditPointsSetting,
  setCrediting,
  setSummerholidays,
}: {
  entries?: ImportEntryWithoutSocialCreditPointData[];
  socialCreditPointsSetting: SocialCreditPointsSettings;
  setCrediting: (value: {
    socialCreditPoints: boolean;
    bookingId: string;
  }) => void;
  setSummerholidays: (value: {
    summerHolidays: boolean;
    bookingId: string;
  }) => void;
}) => {
  const { t } = useTranslation();

  const handleChangeCrediting = useCallback(
    (bookingId: string) => (e: React.FormEvent<HTMLInputElement>) =>
      setCrediting({
        socialCreditPoints: e.currentTarget.checked,
        bookingId,
      }),
    [setCrediting],
  );

  const handleChangeSummerholidays = useCallback(
    (bookingId: string) => (e: React.FormEvent<HTMLInputElement>) =>
      setSummerholidays({
        summerHolidays: e.currentTarget.checked,
        bookingId,
      }),
    [setSummerholidays],
  );

  return (
    <table className="w-full">
      <colgroup>
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>
            {t('components.VacationOffers.import.tableHeaders.bookingId')}
          </th>
          <th>{t('components.VacationOffers.import.tableHeaders.house')}</th>
          <th>{t('components.VacationOffers.import.tableHeaders.arrival')}</th>
          <th>
            {t('components.VacationOffers.import.tableHeaders.departure')}
          </th>
          <th>{t('components.VacationOffers.import.tableHeaders.charge')}</th>
          <th>
            {t(
              'components.VacationOffers.import.tableHeaders.socialCreditPoints',
            )}
          </th>
          <th>
            {t('components.VacationOffers.import.tableHeaders.summerHolidays')}
          </th>
        </tr>
      </thead>
      <tbody>
        {entries?.map(entry => (
          <tr key={entry.bookingId}>
            <td>{entry.bookingId}</td>
            <td>{entry.house}</td>
            <td>
              {entry.arrival.toLocaleDateString('de', { dateStyle: 'medium' })}
            </td>
            <td>
              {entry.departure.toLocaleDateString('de', {
                dateStyle: 'medium',
              })}
            </td>
            <td>{currencyNumberFormat.format(entry.charge)}</td>
            <td>
              <CheckboxInput
                name="socialCreditPoints"
                checked={
                  socialCreditPointsSetting[entry.bookingId].socialCreditPoints
                }
                onChange={handleChangeCrediting(entry.bookingId)}
              />
            </td>
            <td>
              <CheckboxInput
                name="summerHolidays"
                checked={
                  socialCreditPointsSetting[entry.bookingId].summerHolidays
                }
                onChange={handleChangeSummerholidays(entry.bookingId)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
