import { FieldError } from 'react-hook-form';

function isFieldError(
  error?: FieldError | Record<string, unknown>,
): error is FieldError {
  if (error == undefined) return false;
  return error.type !== undefined;
}

export function getSpecificError(
  field: string,
  error?: FieldError | Record<string, FieldError>,
): FieldError | undefined {
  if (error && error[field]) return error[field];
  if (isFieldError(error)) return error;
  return undefined;
}
