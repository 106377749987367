import React, { HTMLProps } from 'react';

type CheckBoxProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  disabled?: boolean;
  name?: string;
};

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ disabled, label, name, ...props }, ref) => {
    const id = name;
    const input = (
      <input
        {...props}
        type="checkbox"
        ref={ref}
        name={name}
        id={id}
        className={`
         rounded border
          ${disabled ? 'bg-gray-100 text-gray-500' : ''}
        `}
      />
    );

    return label ? (
      <label className="flex items-center gap-2" htmlFor={id}>
        {input}
        {label}
      </label>
    ) : (
      input
    );
  },
);

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
