import LoadingOrError from 'components/base/LoadingOrError';
import {
  useEmailTemplates_GetHawConfirmationTemplateQuery,
  useEmailTemplates_UpdateFreeTextMutation,
  refetchEmailTemplates_GetHawConfirmationTemplateQuery,
  FreeTextId,
} from 'gql/generated/types-and-hooks';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OnSubmitTemplateForm,
  ConfirmationTemplate,
} from './BaseConfirmationTemplate';

export const HawConfirmationTemplate = (): ReactElement => {
  const { data, loading, error } =
    useEmailTemplates_GetHawConfirmationTemplateQuery();
  const { t } = useTranslation();
  const [updateEmailFreetext] = useEmailTemplates_UpdateFreeTextMutation({
    refetchQueries: [refetchEmailTemplates_GetHawConfirmationTemplateQuery()],
    context: {
      debounceKey: 'useEmailTemplates_UpdateFreeTextMutation',
      notification: {
        success: t(
          'components.settings.email.templates.notifications.update.success',
        ),
        error: t(
          'components.settings.email.templates.notifications.update.error',
        ),
      },
    },
  });
  const onSubmit: OnSubmitTemplateForm = useCallback(
    async ({ additionalFreetext, freeText, onComplete, subject }) => {
      await updateEmailFreetext({
        onCompleted: () => onComplete(),
        variables: {
          freeTexts: [
            {
              text: additionalFreetext,
              id: FreeTextId.ConfirmationHawAdditionalFreetext,
            },
            {
              text: freeText,
              id: FreeTextId.ConfirmationHawFreetext,
            },
            {
              id: FreeTextId.ConfirmationHawSubject,
              text: subject,
            },
          ],
        },
      });
    },
    [updateEmailFreetext],
  );
  return (
    <div className="HawConfirmationTemplate">
      <LoadingOrError {...{ loading, error }}>
        <ConfirmationTemplate
          onSubmit={onSubmit}
          templateData={data?.hawConfirmationEmailTemplate}
        />
      </LoadingOrError>
    </div>
  );
};
