import HouseEntry from 'components/Houses/HouseEntry';
import Intro from 'components/Eon/Intro';
import { getString } from 'helpers/util';
import LoadingOrError from 'components/base/LoadingOrError';
import { ReactElement } from 'react';
import { useIndexEon_GetListQuery } from 'gql/generated/types-and-hooks';

const IndexEon = (): ReactElement => {
  const { data, loading, error } = useIndexEon_GetListQuery();
  const houses = data?.eonHouses ? [...data.eonHouses] : [];

  return (
    <div className="IndexEon">
      <LoadingOrError loading={loading} error={error}>
        <Intro />
        {houses.map(house => (
          <HouseEntry
            key={house.id}
            house={{
              ...house,
              activated: house.activated ? true : false,
              isEonHome: house.isEonHome ? true : false,
              city: getString(house.city),
              region: getString(house.region),
            }}
          />
        ))}
      </LoadingOrError>
    </div>
  );
};

export default IndexEon;
