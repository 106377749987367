import { memo, ReactElement, ReactNode } from 'react';

/* Taken from MGStrom */

export const Show = memo(function Show({
  when,
  children,
  fallback,
}: {
  when: boolean | undefined | null;
  children?: ReactNode;
  fallback?: ReactNode;
}): ReactElement | null {
  if (when) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
});
