import { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditControls, { useEditMode } from '../applicationForm/EditControls';
import { UserInfoContext } from '../../context/UserInfoContext';
import { EditableTextArea } from 'components/applicationForm/editableInputs';
import { useForm } from 'react-hook-form';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import {
  refetchVacationOfferForm_GetVacationOfferQuery,
  useHouseDescription_UpdateVacationOfferMutation,
} from 'gql/generated/types-and-hooks';

const ServiceForm = ({
  offer,
  editable,
}: {
  offer: {
    id: string;
    name: string;
    service: string;
  };
  editable: boolean;
}): ReactElement => {
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);
  const { t } = useTranslation();
  const {
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service: offer.service,
    },
  });

  useEffect(() => {
    reset({ service: offer.service });
  }, [offer, reset]);

  const [updateVacationOffer] = useHouseDescription_UpdateVacationOfferMutation(
    {
      onCompleted: () => leaveEditMode(),
      refetchQueries: [
        refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
      ],
      context: {
        debounceKey: 'useHouseDescription_UpdateVacationOfferMutation',
        notification: {
          success: t(
            'components.VacationOffers.HouseDescription.notifications.update.success',
          ),
          error: t(
            'components.VacationOffers.HouseDescription.notifications.update.error',
          ),
        },
      },
    },
  );

  const handleEdit = () => enterEditMode();

  const handleConfirm = handleSubmit(() =>
    updateVacationOffer({
      variables: {
        id: offer.id,
        offer: {
          name: offer.name,
          service: getValues('service'),
        },
      },
    }),
  );

  const handleCancel = () => {
    leaveEditMode();
    reset();
  };

  return (
    <div className="ServiceForm grid grid-cols-3 gap-y-0.5">
      <div className="col-span-3 flex items-center font-medium">
        {t('components.description.key.holidayPacket')}
        {editable && (
          <div className="ml-3 inline-block">
            <EditControls
              editMode={editMode}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              onEdit={handleEdit}
            />
          </div>
        )}
      </div>

      <div>
        {`${t(
          'components.VacationOffers.HouseDescription.ServiceForm.extras',
        )}:`}
      </div>

      <div className="col-span-2">
        <ValidationFeedback error={errors.service}>
          <EditableTextArea
            {...register('service')}
            editMode={editable && editMode}
            nonEditableValue={offer.service || ''}
          />
        </ValidationFeedback>
      </div>
    </div>
  );
};

const HouseDescription = ({
  offer,
}: {
  offer: {
    id: string;
    name: string;
    service: string;
    house?: {
      type?: string | null;
      region?: string | null;
      streetAndHouseNo?: string | null;
      postalCode?: string | null;
      city?: string | null;
      telephone?: string | null;
      email?: string | null;
      board?: string | null;
      facilities?: string | null;
      additionalEquipment?: string | null;
      dogAllowed?: boolean | null;
    };
  };
}): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);

  return (
    <div className="HouseDescription grid grid-cols-2 gap-x-8">
      <div
        className="grid"
        style={{
          gridTemplateRows: 'repeat(3, min-content)',
        }}
      >
        <div className="grid grid-cols-3 content-start gap-y-0.5">
          <div className="col-span-3 mb-2 font-medium">
            {t('components.description.group.category')}
          </div>
          <div>{t('components.description.key.type')}:</div>
          <div className="col-span-2">{offer.house?.type || ''}</div>
          <div>{t('components.description.key.vacationArea')}:</div>
          <div className="col-span-2">{offer.house?.region || ''}</div>
        </div>

        <div className="mt-4 grid grid-cols-3 content-start gap-y-0.5">
          <div className="col-span-3 mb-2 font-medium">
            {t('components.description.group.addressContact')}
          </div>

          <div>{t('components.description.key.street')}:</div>
          <div className="col-span-2">
            {offer.house?.streetAndHouseNo || ''}
          </div>

          <div>{t('components.description.key.postalCode')}:</div>
          <div className="col-span-2">{offer.house?.postalCode || ''}</div>

          <div>{t('components.description.key.location')}:</div>
          <div className="col-span-2">{offer.house?.city || ''}</div>

          <div>{t('components.description.key.phone')}:</div>
          <div className="col-span-2">{offer.house?.telephone || ''}</div>

          <div>{t('components.description.key.email')}:</div>
          <div className="col-span-2">{offer.house?.email || ''}</div>
        </div>

        <div className="mt-4">
          <ServiceForm offer={offer} editable={isAdmin} />
        </div>
      </div>

      <div className="grid grid-cols-3 content-start gap-y-0.5">
        <p className="col-span-3 mb-2 font-medium">
          {t('components.description.group.equipment')}
        </p>
        <div>{t('components.description.key.board')}:</div>
        <div className="col-span-2 whitespace-pre-wrap">
          {offer.house?.board || ''}
        </div>
        <div>{t('components.description.key.standard')}:</div>
        <div className="col-span-2 whitespace-pre-wrap">
          {offer.house?.facilities || ''}
        </div>
        <div>{t('components.description.key.optional')}:</div>
        <div className="col-span-2 whitespace-pre-wrap">
          {offer.house?.additionalEquipment || ''}
        </div>
        <div>{t('components.description.key.dogAllowed')}:</div>
        <div className="col-span-2">
          {offer.house?.dogAllowed
            ? t('components.form.yesNoChoice.true')
            : t('components.form.yesNoChoice.false')}
        </div>
      </div>
    </div>
  );
};

export default HouseDescription;
