import { createContext } from 'react';

type IdToken = {
  accessToken: string;
  email?: string;
  preferred_username?: string;
  name?: string;
};
export const IdTokenContext = createContext<IdToken>({
  accessToken: '',
  email: '',
  preferred_username: '',
  name: '',
});
