import { getUrl } from 'config/config';
import { ReactElement, useContext, useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';
import { IdTokenContext } from 'context/IdTokenContext';
import { Icon } from 'components/base/Icon';
import useReactTooltip from 'hooks/useReactTooltip';

const buttonStyle =
  'absolute top-0 right-0 p-2 m-2 bg-white rounded-md shadow hover:bg-gray-200';

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  useReactTooltip();

  return (
    <button
      className={buttonStyle}
      onClick={onClick}
      data-tip={useTranslation().t('components.photos.tooltips.delete')}
    >
      <Icon icon={'TrashIcon'} />
    </button>
  );
};

const Photo = ({
  photo,
  editable,
  onClick,
  onDelete,
  isDragging,
}: {
  photo: { path: string };
  editable: boolean;
  onClick: (objectUrl: string) => void;
  onDelete: ({ path }: { path: string }) => void;
  isDragging: boolean;
}): ReactElement => {
  const imgRef = useRef<HTMLImageElement>(null);
  const idToken = useContext(IdTokenContext);
  const { isAdmin } = useContext(UserInfoContext);

  useEffect(() => {
    const loadPicture = async () => {
      if (imgRef.current) {
        const fullUrl = getUrl(photo.path);

        const result = await fetch(fullUrl, {
          headers: {
            Authorization: `Bearer ${idToken?.accessToken}`,
          },
        });

        const blob = await result.blob();
        imgRef.current.src = URL.createObjectURL(blob);
      }
    };

    loadPicture();
  }, [photo, idToken]);

  const handleDelete = () => onDelete(photo);

  return (
    <div className="Photo">
      <div className="relative flex h-32 w-full items-center justify-center overflow-hidden rounded-md border border-gray-200 lg:h-36">
        <img
          draggable="false"
          className="h-full w-full object-cover"
          ref={imgRef}
          alt=""
          onClick={() => {
            if (isDragging === false && imgRef.current) {
              onClick(imgRef.current.src);
            }
          }}
        />
        {editable && isAdmin && <DeleteButton onClick={handleDelete} />}
      </div>
    </div>
  );
};

export default Photo;
