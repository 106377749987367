import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from './ProgressBar';
import { Show } from './Show';
import { clamp } from 'helpers/util';
import { CloseButton } from './IconButton';
import Button from './Button';

type Step = {
  id: symbol;
  backButtonLabel: string;
  nextButtonLabel: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
};

type WizardProps = {
  header: string;
  steps: Step[];
  children: ReactElement[] | ReactElement;
  onStepChanged?: (stepId: symbol, back: boolean) => void;
  onClose: () => void;
  onFinish: () => void;
};

/* Component taken from Messgeräte Strom */

export function Wizard({
  header,
  steps,
  onStepChanged,
  onClose,
  onFinish,
  children,
}: WizardProps): ReactElement {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const handleButtonClick = useCallback(
    (type: 'next' | 'back') => {
      let newStep = step;
      if (type === 'back') {
        newStep = clamp(step - 1, 0, steps.length - 1);
      } else {
        newStep = clamp(step + 1, 0, steps.length - 1);
      }
      if (newStep !== step) {
        if (onStepChanged != undefined) {
          onStepChanged(steps[newStep].id, type === 'back');
        }
        setStep(newStep);
      } else {
        if (newStep === steps.length - 1) {
          onFinish();
        }
      }
    },
    [onFinish, onStepChanged, step, steps],
  );

  const [progress, translateX] = useMemo(
    () => [
      getProgressForStep(step + 1, steps.length),
      getTranslateXForStep(step, steps.length),
    ],
    [step, steps.length],
  );

  if (steps.length !== Object.keys(children).length) {
    return (
      <>{`Number of children (${
        Object.keys(children).length
      }) is different to number of steps (${steps.length})`}</>
    );
  }

  return (
    <article className="relative flex h-full flex-col gap-2 overflow-clip">
      <header className="px-4">
        <div className="mb-2 flex items-center justify-between">
          <h1 className="font-semibold">
            {header}
            <span className="text-texts-grey ml-2 font-light">
              {t('components.wizard.step', {
                currentStep: step + 1,
                nbrOfSteps: steps.length,
              })}
            </span>
          </h1>
          <CloseButton onClick={onClose} />
        </div>
        <ProgressBar progress={progress} />
      </header>
      <div
        className={`flex h-full justify-center overflow-hidden pt-4 transition-transform duration-300`}
        style={{
          width: `${steps.length * 100}%`,
          transform: `translateX(${translateX})`,
        }}
      >
        {Object.values(children).map((child, i) => {
          return (
            <div key={i} className="h-full w-full px-24">
              {child}
            </div>
          );
        })}
      </div>
      <footer className="border-graphic-decorative flex justify-evenly border-t py-2">
        <Show when={step > 0}>
          <Button
            label={steps[step].backButtonLabel}
            disabled={steps[step].backDisabled}
            onClick={() => handleButtonClick('back')}
          />
        </Show>
        <Button
          label={steps[step].nextButtonLabel}
          disabled={steps[step].nextDisabled}
          onClick={() => {
            handleButtonClick('next');
          }}
        />
      </footer>
    </article>
  );
}

const getTranslateXForStep = (step: number, numberOfSteps: number) => {
  return `${-(step / numberOfSteps) * 100}%`;
};

const getProgressForStep = (step: number, numberOfSteps: number) => {
  return (step / numberOfSteps) * 100;
};
