import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { BoardBillingSortableColumn } from 'gql/generated/types-and-hooks';
import { BoardBillingRow, BoardBillingRowProps } from './Row';
import { SortableHeader, TableHeader } from 'components/common/TableElements';
import { BoardBillingSorting } from 'pages/boardBilling/BoardBilling';
import { KeyFormProvider } from 'context/KeyFormContext';

type ApplicationTableProps = {
  data: BoardBillingRowProps[];
  onSort: (order: BoardBillingSorting) => void;
  sorting: BoardBillingSorting;
};
export const BoardBillingTable = ({
  data,
  onSort,
  sorting,
}: ApplicationTableProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <table className="BoardBillingTable w-full table-fixed">
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col className="w-28" />
        <col className="w-28" />
        <col className="w-28" />
        <col className="w-56" />
        <col className="w-32" />
      </colgroup>
      <thead className="whitespace-nowrap">
        <tr className="border-b border-grey">
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.house')}
            column={BoardBillingSortableColumn.HouseName}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.offer')}
            column={BoardBillingSortableColumn.OfferName}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.employee')}
            column={BoardBillingSortableColumn.Employee}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.period')}
            column={BoardBillingSortableColumn.Period}
          />
          <TableHeader>
            {t('components.form.priceTableEditable.supplements')}
          </TableHeader>
          <TableHeader>
            {t('components.form.priceTableEditable.reductions')}
          </TableHeader>
          <TableHeader>
            {t('components.form.priceTableEditable.calculatedNew')}
          </TableHeader>
          <TableHeader>
            {t('components.form.priceTableEditable.remark')}
          </TableHeader>
          <TableHeader />
        </tr>
      </thead>
      <tbody>
        <KeyFormProvider>
          {data.map(item => {
            return <BoardBillingRow key={item.applicationId} {...item} />;
          })}
        </KeyFormProvider>
      </tbody>
    </table>
  );
};
