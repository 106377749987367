import { de } from 'date-fns/locale';
import { ReactElement, useEffect, useState, FocusEvent } from 'react';
import { DateRange } from 'react-date-range';
import ConfirmationDialog from '../base/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import CheckboxInput from 'components/applicationForm/CheckboxInput';
import { convertDateFromUTC, convertDateToUTC } from 'helpers/dateFormats';
import TextInput from 'components/applicationForm/TextInput';
import NumberFormat from 'react-number-format';
import {
  CountervailingBenefit,
  CountervailingBenefitInput,
} from 'gql/generated/types-and-hooks';

const getRangeDate = (range: { startDate: Date; endDate: Date }) => ({
  ...range,
  key: 'selection',
});

type BookingModalProps = {
  booking: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    eonBookingId: string;
    countervailingBenefit: CountervailingBenefit;
  };
  open: boolean;
  showEonBookingId?: boolean;
  showCountervailingBenefit?: {
    [k in keyof Omit<CountervailingBenefit, '__typename'>]?: true;
  };
  onCancel: () => void;
  onConfirm: (changes: BookingModalProps['booking']) => void;
};

// A component with a confirmation modal dialog.
const BookingModal = ({
  booking,
  open = false,
  showEonBookingId = false,
  showCountervailingBenefit = undefined,
  onCancel,
  onConfirm,
}: BookingModalProps): ReactElement => {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState([
    {
      startDate: convertDateFromUTC(booking.arrival),
      endDate: convertDateFromUTC(booking.departure),
    },
  ]);
  const [summer, setSummer] = useState(booking.summer);
  const [points, setPoints] = useState(booking.socialPoints);
  const [eonBookingId, setEonBookingId] = useState(booking.eonBookingId);
  const [countervailingBenefit, setCountervailingBenefit] = useState(
    booking.countervailingBenefit,
  );

  useEffect(() => {
    setRanges([
      {
        startDate: convertDateFromUTC(new Date(booking.arrival)),
        endDate: convertDateFromUTC(new Date(booking.departure)),
      },
    ]);
    setSummer(booking.summer);
    setPoints(booking.socialPoints);
    setEonBookingId(booking.eonBookingId);
    setCountervailingBenefit(booking.countervailingBenefit);
  }, [
    booking.arrival,
    booking.departure,
    booking.summer,
    booking.socialPoints,
    booking.eonBookingId,
    booking.countervailingBenefit,
    open,
  ]);

  const handleChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => setRanges([{ startDate, endDate }]);

  const handleToggleSummer = () => {
    setSummer(summer => !summer);
  };

  const handleTogglePoints = () => {
    setPoints(points => !points);
  };

  const handleChangeEonBookingId = (value: string) => {
    setEonBookingId(value);
  };

  const handleChangeCountervailingBenefit =
    (field: keyof CountervailingBenefitInput) => (value: number) => {
      setCountervailingBenefit(cur => ({
        ...cur,
        [field]: value,
      }));
    };

  const handleConfirm = () => {
    onConfirm({
      arrival: convertDateToUTC(ranges[0].startDate),
      departure: convertDateToUTC(ranges[0].endDate),
      summer: summer,
      socialPoints: points,
      eonBookingId: eonBookingId,
      countervailingBenefit: countervailingBenefit,
    });
  };
  return (
    <ConfirmationDialog
      open={open}
      title={t('components.VacationOffers.BookingModal.selectDateRange')}
      confirmText={t('components.VacationOffers.BookingModal.confirm')}
      cancelText={t('components.VacationOffers.BookingModal.cancel')}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <div className="grid grid-cols-12 items-center gap-2">
        <div className="col-span-12">
          <DateRange
            className="w-full"
            locale={de}
            ranges={ranges.map(range => getRangeDate(range))}
            rangeColors={['#3d91ff']}
            weekStartsOn={1}
            dateDisplayFormat="dd.MM.yyyy"
            onChange={ranges => {
              const { startDate, endDate } = ranges['selection'];
              if (startDate && endDate) {
                handleChange({
                  startDate: startDate,
                  endDate: endDate,
                });
              }
            }}
          />
        </div>
        <div className="col-span-5">
          {t('components.VacationOffers.BookingModal.pointsCrediting')}
        </div>
        <div className="col-span-7 flex items-center">
          <CheckboxInput
            name="points"
            checked={points}
            onChange={handleTogglePoints}
          />
        </div>
        <div className="col-span-5">
          {t('components.VacationOffers.BookingModal.summerHolidays')}
        </div>
        <div className="col-span-7 flex items-center">
          <CheckboxInput
            name="summer"
            checked={summer}
            onChange={handleToggleSummer}
          />
        </div>
        {showEonBookingId && (
          <>
            <div className="col-span-5">
              {t('components.VacationOffers.BookingModal.eonBookingId')}
            </div>
            <div className="col-span-7">
              <TextInput
                name="eonBookingId"
                value={eonBookingId}
                onChange={e => handleChangeEonBookingId(e.currentTarget.value)}
              />
            </div>
          </>
        )}
        {showCountervailingBenefit &&
          Object.keys(showCountervailingBenefit).map(key => {
            return [
              <div key={`${key}-label`} className="col-span-5">
                {t(
                  `components.VacationOffers.BookingModal.countervailingBenefit.${key}`,
                )}
              </div>,
              <div key={`${key}-input`} className="col-span-7">
                <NumberFormat
                  className={`w-full rounded-md border border-grey bg-white px-3 py-1 text-right text-sm outline-none focus-within:ring-1`}
                  value={countervailingBenefit[key]}
                  decimalSeparator=","
                  thousandSeparator="."
                  allowNegative={false}
                  suffix=" €"
                  fixedDecimalScale={true}
                  allowEmptyFormatting={true}
                  defaultValue="0"
                  decimalScale={2}
                  // Fix to have the same focus as for inputs which are not in a dialog
                  onFocus={(e: FocusEvent<HTMLInputElement>) => {
                    setTimeout(() => e.target.select(), 0);
                  }}
                  onValueChange={values => {
                    if (values.floatValue != undefined) {
                      handleChangeCountervailingBenefit(key)(values.floatValue);
                    }
                  }}
                />
              </div>,
            ];
          })}
      </div>
    </ConfirmationDialog>
  );
};

export default BookingModal;
