import BaseDialog from 'components/base/BaseDialog';
import { getDaysInMonth, setDate } from 'date-fns';
import { CancellationInput } from 'gql/generated/types-and-hooks';
import { ReactElement, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { de } from 'date-fns/locale';
import CheckboxInput from 'components/applicationForm/CheckboxInput';
import Button from 'components/base/Button';

export const CancelApplicationModal = ({
  open = false,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: (parameters: CancellationInput) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const [parameters, setParameters] = useState({
    cancellationDate: new Date(),
    withCosts: true,
  });

  const handleConfirm = () =>
    onConfirm({
      withCosts: parameters.withCosts,
      cancellationDate: parameters.cancellationDate.toISOString(),
    });

  const title = t('components.applications.cancelApplicationModal.title');

  return (
    <BaseDialog text={''} open={open} title={title} onClose={onCancel}>
      <div className="w-full">
        <Calendar
          classNames={{ calendarWrapper: 'w-full' }}
          locale={de}
          dateDisplayFormat="dd.MM.yyyy"
          date={parameters.cancellationDate}
          onChange={range => {
            const newDate = range as Date;
            setParameters({ ...parameters, cancellationDate: newDate });
          }}
          onShownDateChange={changedDate => {
            const daysInMonth = getDaysInMonth(changedDate);
            const pickedDay = parameters.cancellationDate.getDate();
            const newDate = setDate(
              changedDate,
              pickedDay > daysInMonth ? daysInMonth : pickedDay,
            );
            setParameters({ ...parameters, cancellationDate: newDate });
          }}
        />
        <div className="flex items-center gap-x-2">
          {t('components.applications.cancelApplicationModal.noCostsReason')}
          <CheckboxInput
            name="points"
            checked={!parameters.withCosts}
            onChange={e => {
              const target = e.target as EventTarget & HTMLInputElement;
              setParameters({ ...parameters, withCosts: !target.checked });
            }}
          />
        </div>
        <div className="flex flex-row-reverse gap-x-2 pt-8">
          <Button
            label={t('components.applications.cancelApplicationModal.confirm')}
            onClick={handleConfirm}
            styleOptions={{
              inverted: true,
            }}
          />
          <Button
            label={t('components.applications.cancelApplicationModal.cancel')}
            onClick={onCancel}
          />
        </div>
      </div>
    </BaseDialog>
  );
};
