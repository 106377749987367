export function getFormattedDate(
  date: Date | undefined | null,
  formatter: Intl.DateTimeFormat,
): string {
  return date ? formatter.format(date) : '---';
}

export function getDate(date: string | undefined | null): Date | undefined {
  return date ? new Date(date) : undefined;
}
