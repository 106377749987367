import Button from 'components/base/Button';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement, useContext, useState } from 'react';
import BookingModal from './BookingModal';
import BookingRow from './BookingRow';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';
import {
  BookingOfferUpdateInput,
  CountervailingBenefit,
  refetchVacationOfferForm_GetVacationOfferQuery,
  useBookingDates_CreateBookingMutation,
  useBookingDates_DeleteBookingOfferMutation,
  useBookingDates_UpdateBookingOfferMutation,
} from 'gql/generated/types-and-hooks';

const defaultBookingData = () => ({
  arrival: new Date(),
  departure: new Date(),
  summer: false,
  socialPoints: true,
  eonBookingId: '',
  countervailingBenefit: {
    childOld: 0,
    childYoung: 0,
    firstAdult: 0,
    secondAdult: 0,
    perNight: 0,
  } as CountervailingBenefit,
});

const BookingDates = ({
  offer,
}: {
  offer: {
    id: string;
    bookingOffers: {
      id: string;
      active: boolean;
      isBooked: boolean;
      arrival: Date;
      departure: Date;
      socialCreditPoints: boolean;
      summerHolidays: boolean;
      countervailingBenefit: CountervailingBenefit;
    }[];
  };
}): ReactElement => {
  const { isAdmin } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const [modelOpen, setModalOpen] = useState(false);
  const [createBooking] = useBookingDates_CreateBookingMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useBookingDates_CreateBookingMutation',
      notification: {
        success: t(
          'components.VacationOffers.BookingDates.notifications.add.success',
        ),
        error: t(
          'components.VacationOffers.BookingDates.notifications.add.error',
        ),
      },
    },
  });
  const [deleteBooking] = useBookingDates_DeleteBookingOfferMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useBookingDates_DeleteBookingOfferMutation',
      notification: {
        success: t(
          'components.VacationOffers.BookingDates.notifications.delete.success',
        ),
        error: t(
          'components.VacationOffers.BookingDates.notifications.delete.error',
        ),
      },
    },
  });
  const [updateBooking] = useBookingDates_UpdateBookingOfferMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useBookingDates_UpdateBookingOfferMutation',
      notification: {
        success: t(
          'components.VacationOffers.BookingDates.notifications.update.success',
        ),
        error: t(
          'components.VacationOffers.BookingDates.notifications.update.error',
        ),
      },
    },
  });

  const handleOpenModal = () => setModalOpen(true);

  const handleCancel = () => setModalOpen(false);

  const handleConfirm = (booking: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    countervailingBenefit: CountervailingBenefit;
  }) => {
    createBooking({
      variables: {
        offerId: offer.id,
        bookingOffer: {
          active: true,
          bookingDate: {
            arrival: booking.arrival.toISOString(),
            departure: booking.departure.toISOString(),
          },
          socialCreditPoints: booking.socialPoints,
          summerHolidays: booking.summer,
          countervailingBenefit: booking.countervailingBenefit,
        },
      },
    });
    setModalOpen(false);
  };

  const handleDeleteBooking = (id: string) => () => {
    deleteBooking({
      variables: {
        id,
      },
    });
  };

  const handleUpdateBooking =
    (id: string) => (input: BookingOfferUpdateInput) => {
      updateBooking({
        variables: {
          bookingOfferId: id,
          bookingOffer: input,
        },
      });
    };

  return (
    <div className="BookingDates">
      <p className="mb-4 font-medium">
        {t('components.VacationOffers.BookingDates.tableHeader')}
      </p>
      {offer.bookingOffers.length ? (
        <table className="mb-4 w-full">
          <thead>
            <tr>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.arrival',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.departure',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.pointsCrediting',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.summerHolidays',
                )}
              </th>
              <th colSpan={5} className="text-center">
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.title',
                )}
              </th>
              <th />
            </tr>
            <tr>
              <th colSpan={4} />
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.firstAdult',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.secondAdult',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.childYoung',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.childOld',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.countervailingBenefit.perNight',
                )}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {offer.bookingOffers
              .filter(booking => isAdmin || booking.active)
              .sort((a, b) =>
                (a.arrival || '') > (b.arrival || '')
                  ? 1
                  : a.arrival === b.arrival
                  ? (a.departure || '') > (b.departure || '')
                    ? 1
                    : -1
                  : -1,
              )
              .map((booking, index) => (
                <BookingRow
                  key={index}
                  booking={{
                    ...booking,
                  }}
                  onUpdateBooking={handleUpdateBooking(booking.id)}
                  onDeleteBooking={handleDeleteBooking(booking.id)}
                />
              ))}
          </tbody>
        </table>
      ) : (
        <div className="mb-2">
          {t('components.VacationOffers.BookingDates.noDateHint')}
        </div>
      )}
      {isAdmin && (
        <KeyFormArea>
          <Button
            label={t('components.VacationOffers.BookingDates.newBookingButton')}
            onClick={handleOpenModal}
          />
          <BookingModal
            open={modelOpen}
            showCountervailingBenefit={{
              firstAdult: true,
              secondAdult: true,
              childYoung: true,
              childOld: true,
              perNight: true,
            }}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            booking={defaultBookingData()}
          />
        </KeyFormArea>
      )}
    </div>
  );
};
export default BookingDates;
