import { useTranslation } from 'react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import PickBoardDropdown from '../PickBoardDropdown';
import { mapBoardPropertynameToEnum } from 'interfaces/BoardType';
import HouseDropdown from '../HouseDropdown';
import { useFormContext } from 'react-hook-form';
import { ValidationFeedback } from '../Validatable';
import { dateMonthFormat, dateMonthYearFormat } from 'helpers/dateFormats';
import { Boards } from 'api/mappers';
import {
  EditMyApplications_GetApplicationQuery,
  ApplicationForm_GetVactionOffersHousesQuery,
  ProcessingStatus,
  BookingDate,
} from 'gql/generated/types-and-hooks';
import { getDate } from 'helpers/dateHelper';
import OfferDropdown from '../OfferDropdown';

const getHouseOptions = ({
  houses,
  withDog,
  selectedHouseId,
}: {
  houses: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'];
  withDog: boolean;
  selectedHouseId?: string;
}) => {
  const availableHouses = houses.filter(x => {
    if (x.id === selectedHouseId) return true;
    if (x.activated == false) return false;
    if (withDog && x.dogAllowed == false) return false;
    const offers = x.eonOffers?.filter(offer => offer.active);
    return offers && offers.length > 0;
  });
  return availableHouses;
};

const getTimespanTextForPeriod = (period?: BookingDate | null) => {
  if (period?.arrival == null && period?.departure == null) {
    return '';
  }
  if (period.arrival == null) {
    period.arrival = period.departure;
  } else if (period.departure == null) {
    period.departure = period.arrival;
  }

  const from = getDate(period.arrival) || new Date(0);
  const to = getDate(period.departure) || new Date(0);

  return `${dateMonthFormat.format(from)} - ${dateMonthYearFormat.format(to)}`;
};

const getTimespanTextForOffer = (
  offerId: string,
  offers: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'][0]['eonOffers'],
): string => {
  const offer = offers?.find(x => x.id === offerId);
  if (offer == null) {
    return '---';
  }

  const from = getDate(offer.bookingDate.arrival) || new Date(0);
  const to = getDate(offer.bookingDate.departure) || new Date(0);

  return `${dateMonthFormat.format(from)} - ${dateMonthYearFormat.format(to)}`;
};

const EonBookingEntry = ({
  index,
  houses,
  onChangeHouse,
  editMode,
}: {
  index: number;
  houses: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'];
  onChangeHouse: (houesid: string) => void;
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext<EditMyApplications_GetApplicationQuery['application']>();
  const [originalBooking, setOriginalBooking] = useState<
    typeof booking & { houseName: string }
  >();

  const processingStatus = getValues('processingStatus');
  const baseControlName: `eonBookings.${number}` = `eonBookings.${index}`;
  const booking = getValues<`eonBookings.${number}`>(baseControlName);
  const withDog = getValues('dog');

  useEffect(() => {
    if (booking.period && booking.bookingChoiceId == null) {
      setOriginalBooking({
        ...booking,
        houseName: houses.find(x => x.id === booking.houseId)?.name ?? '',
      });
    }
  }, [booking, houses]);

  if (!booking) return <div>{t('components.form.eonBookingEntry.error')}</div>;

  const selectedHouse = houses.find(x => x.id === booking.houseId);
  return (
    <div className="EonBookingEntry">
      {originalBooking == null ? null : (
        /* Display a hint that this is an old eon bokking */
        <>
          <div className="grid grid-cols-12 gap-x-2 py-2">
            <div className="col-span-full col-start-2 font-semibold">
              {t('components.form.eonBookingEntry.oldBooking')}
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-3">{originalBooking.houseName}</div>
            <div className="col-span-5">
              {getTimespanTextForPeriod(originalBooking.period)}
            </div>
            <div className="col-span-3">
              {t(`components.form.pickBoardDropdown.${originalBooking.board}`)}
            </div>
          </div>
        </>
      )}
      <div className="grid grid-cols-12 gap-x-2 py-2">
        <div className="col-span-1 text-center font-semibold">
          {`Rang ${booking.priority}:`}
        </div>
        <div className="col-span-3">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.eonBookings
                  ? errors?.eonBookings[index]?.houseId
                  : undefined
              }
            >
              <HouseDropdown
                className="w-full"
                {...register(`${baseControlName}.houseId`, {
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                  validate: value => {
                    if (
                      processingStatus === ProcessingStatus.Created ||
                      processingStatus === ProcessingStatus.SentBack
                    ) {
                      const selectedEntry = houses.find(x => x.id === value);
                      if (selectedEntry && !selectedEntry.activated) {
                        return t(
                          'components.form.eonBookingEntry.errorHouseFull',
                        ) as string;
                      }
                      if (
                        selectedEntry && withDog
                          ? !selectedEntry.dogAllowed
                          : false
                      ) {
                        return t(
                          'components.form.eonBookingEntry.errorNoDogsAllowed',
                        ) as string;
                      }
                    }
                  },
                  onChange: e => {
                    onChangeHouse(e.target.value);
                  },
                })}
                houses={getHouseOptions({
                  houses,
                  withDog,
                  selectedHouseId: booking.houseId,
                })}
                t={t}
                dogAllowedRequired={withDog}
              />
            </ValidationFeedback>
          ) : (
            <span>{selectedHouse?.name || '---'}</span>
          )}
        </div>
        <div className="col-span-5">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.eonBookings
                  ? errors?.eonBookings[index]?.bookingChoiceId
                  : undefined
              }
            >
              <OfferDropdown
                className="w-full"
                initValue={booking.bookingChoiceId ?? ''}
                {...register(`${baseControlName}.bookingChoiceId`, {
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                  validate: value => {
                    if (
                      processingStatus === ProcessingStatus.Created ||
                      processingStatus === ProcessingStatus.SentBack
                    ) {
                      const noPlacesLeftMessage = t(
                        'components.form.eonBookingEntry.errorNoPlacesLeft',
                      ) as string;
                      const selectedBooking = selectedHouse?.eonOffers?.find(
                        x => x.id === value,
                      );
                      if (selectedBooking && selectedBooking.active === false) {
                        return noPlacesLeftMessage;
                      }
                    }
                  },
                })}
                t={t}
                options={[
                  {
                    name: '',
                    active: true,
                    bookings:
                      selectedHouse?.eonOffers?.map(offer => {
                        return {
                          id: offer.id,
                          active: offer.active,
                          from:
                            getDate(offer.bookingDate.arrival) || new Date(0),
                          to:
                            getDate(offer.bookingDate.departure) || new Date(0),
                        };
                      }) ?? [],
                  },
                ]}
              />
            </ValidationFeedback>
          ) : (
            <span>
              {getTimespanTextForOffer(
                booking.bookingChoiceId ?? '',
                selectedHouse?.eonOffers ?? [],
              )}
            </span>
          )}
        </div>
        <div className="col-span-3">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.eonBookings
                  ? errors?.eonBookings[index]?.board
                  : undefined
              }
            >
              <PickBoardDropdown
                name={`${baseControlName}.board`}
                options={
                  selectedHouse?.charges
                    ? Object.keys(selectedHouse?.charges)
                        .filter(
                          board =>
                            board !== '__typename' &&
                            selectedHouse?.charges[board].activated,
                        )
                        .map(board =>
                          mapBoardPropertynameToEnum(board as Boards),
                        )
                    : []
                }
              />
            </ValidationFeedback>
          ) : (
            <span>
              {t(`components.form.pickBoardDropdown.${booking.board}`)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EonBookingEntry;
