import { VisibleIcon } from 'components/base/IconButton';
import config from 'config/config';
import LinkCard from 'components/base/LinkCard';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { Icon } from 'components/base/Icon';

const HouseEntry = ({
  house,
}: {
  house: {
    id: string;
    activated: boolean;
    isEonHome: boolean;
    name: string;
    city: string;
    region: string;
  };
}): ReactElement | null => {
  const { isAdmin } = useContext(UserInfoContext);

  if (house.activated == false && isAdmin == false) {
    return null;
  }

  return (
    <div className="HouseEntry">
      <LinkCard
        to={
          house.isEonHome
            ? config.localRoutes.eon.edit.replace(':id', house.id)
            : config.localRoutes.haw.edit.replace(':id', house.id)
        }
      >
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="p-2">
            <Icon icon={'ChevronDownIcon'} />
          </div>
          <div className="flex-1">{house.name}</div>
          <div className="flex-1">{house.city}</div>
          <div className="flex-1">{house.region}</div>
          <div className="p-2">
            {isAdmin && <VisibleIcon visible={house.activated} />}
          </div>
        </div>
      </LinkCard>
    </div>
  );
};

export default HouseEntry;
