import {
  ApplicationFor,
  ApplicationInput,
  BoardType,
  EditMyApplications_GetApplicationQuery,
  EonBookingInput,
  HawBookingInput,
  HouseBoardInput,
  HouseCharge,
  HouseChargesInput,
  MutationUpdateApplicationArgs,
  PartnerStatus,
  PositionType,
  VacationOfferCharge,
} from 'gql/generated/types-and-hooks';
import { isEmpty, isNotEmpty } from 'helpers/util';
import { applicantIsNotInAccountingArea } from 'helpers/applicationHelper';
import { AccountingAreasNotAllowedForEonActive } from 'interfaces/accountingAreas';

// const MINIMUM_DISABILITY_DEGREE = 20;

export type Boards =
  | 'fullBoard'
  | 'halfBoard'
  | 'overnightBreakfast'
  | 'selfSupply';
type Costs = 'accommodation' | 'furtherPosition' | 'bundle';

export type CostType =
  | 'adult'
  | 'childYoung'
  | 'childOld'
  | 'retiree'
  | 'executive'
  | 'externalAdult'
  | 'externalChild'
  | 'firstAdult'
  | 'secondAdult'
  | 'costsPerNight'
  | 'visitorsTaxSummer'
  | 'visitorsTaxWinter'
  | 'cleaning';

export type ChargesFormRow = {
  [board in Boards]: number;
};

const mapChargeToFormRow = (
  charges: HouseCharge | VacationOfferCharge,
  costs: Costs,
  type: CostType,
): ChargesFormRow => {
  return {
    fullBoard: charges.fullBoard[costs][type],
    halfBoard: charges.halfBoard[costs][type],
    overnightBreakfast: charges.overnightBreakfast[costs][type],
    selfSupply: charges.selfSupply[costs][type],
  };
};

export const mapChargesToForm = (charges: HouseCharge): FEHouseBoard => ({
  active: {
    fullBoard: charges.fullBoard.activated,
    halfBoard: charges.halfBoard.activated,
    overnightBreakfast: charges.overnightBreakfast.activated,
    selfSupply: charges.selfSupply.activated,
  },
  prices: {
    adult: mapChargeToFormRow(charges, 'accommodation', 'adult'),
    youngChild: mapChargeToFormRow(charges, 'accommodation', 'childYoung'),
    oldChild: mapChargeToFormRow(charges, 'accommodation', 'childOld'),
    retired: mapChargeToFormRow(charges, 'accommodation', 'retiree'),
    executive: mapChargeToFormRow(charges, 'accommodation', 'executive'),
    externalAdult: mapChargeToFormRow(
      charges,
      'accommodation',
      'externalAdult',
    ),
    externalChild: mapChargeToFormRow(
      charges,
      'accommodation',
      'externalChild',
    ),
    costsPerNight: mapChargeToFormRow(
      charges,
      'furtherPosition',
      'costsPerNight',
    ),
    cleaning: mapChargeToFormRow(charges, 'furtherPosition', 'cleaning'),
    summerTax: mapChargeToFormRow(
      charges,
      'furtherPosition',
      'visitorsTaxSummer',
    ),
    winterTax: mapChargeToFormRow(
      charges,
      'furtherPosition',
      'visitorsTaxWinter',
    ),
  },
});

export type FEHouseBoard = {
  active: {
    [board in Boards]: boolean;
  };
  prices: {
    adult: ChargesFormRow;
    youngChild: ChargesFormRow;
    oldChild: ChargesFormRow;
    executive: ChargesFormRow;
    retired: ChargesFormRow;
    externalAdult: ChargesFormRow;
    externalChild: ChargesFormRow;
    costsPerNight: ChargesFormRow;
    cleaning: ChargesFormRow;
    summerTax: ChargesFormRow;
    winterTax: ChargesFormRow;
  };
};

export type FEVacationOfferPackageBoard = {
  // active: {
  //   [board in Boards]: boolean;
  // };
  prices: {
    adult: ChargesFormRow;
    youngChild: ChargesFormRow;
    oldChild: ChargesFormRow;
    externalAdult: ChargesFormRow;
    externalChild: ChargesFormRow;
  };
};

export const mapBoardToApi = (
  board: Boards,
  data: FEHouseBoard,
): HouseBoardInput => {
  return {
    activated: data.active[board],
    accommodation: {
      adult: data.prices.adult[board],
      childYoung: data.prices.youngChild[board],
      childOld: data.prices.oldChild[board],
      executive: data.prices.executive[board],
      retiree: data.prices.retired[board],
      externalAdult: data.prices.externalAdult[board],
      externalChild: data.prices.externalChild[board],
    },
    furtherPosition: {
      costsPerNight: data.prices.costsPerNight[board],
      cleaning: data.prices.cleaning[board],
      visitorsTaxSummer: data.prices.summerTax[board],
      visitorsTaxWinter: data.prices.winterTax[board],
    },
  };
};

export const mapChargesToApi = (data: FEHouseBoard): HouseChargesInput => {
  return {
    fullBoard: mapBoardToApi('fullBoard', data),
    halfBoard: mapBoardToApi('halfBoard', data),
    overnightBreakfast: mapBoardToApi('overnightBreakfast', data),
    selfSupply: mapBoardToApi('selfSupply', data),
  };
};

export type FEApplicationInput = MutationUpdateApplicationArgs['application'];

export const mapApplicationToApi = (
  data: EditMyApplications_GetApplicationQuery['application'],
): ApplicationInput => ({
  processingStatus: data.processingStatus,
  applicantKID: data.applicantKID,
  applicationFor: data.applicationFor,
  applicant: {
    name: data.applicant.name || '',
    email: data.applicant.email || '',
    staffNumber: data.applicant.staffNumber || '',
    accountingArea: data.applicant.accountingArea || '',
  },
  guest:
    data.applicationFor === ApplicationFor.OtherPerson
      ? {
          name: data.guest?.name || '',
          email: data.guest?.email || '',
          staffNumber: data.guest?.staffNumber || '',
          accountingArea: data.guest?.accountingArea || '',
        }
      : undefined,
  applicationType: data.applicationType,
  street: data.street,
  houseNumber: data.houseNumber,
  zip: data.zip,
  city: data.city,
  phone: data.phone,
  disability: !data.disability.present
    ? {
        present: false,
        degree: null,
        walkingDisability: false,
        markBOnPassport: false,
      }
    : {
        present: data.disability.present,
        degree: data.disability.degree,
        walkingDisability: data.disability.walkingDisability,
        markBOnPassport: data.disability.markBOnPassport,
      },
  allergies: data.allergies,
  singleParent: data.singleParent,
  companyPosition: {
    position: data.companyPosition.position
      ? data.companyPosition.position
      : PositionType.Employee,
    entranceYear: data.companyPosition.entranceYear,
    employment: data.companyPosition.employment,
  },
  partner: {
    status: data.partner.status ? data.partner.status : PartnerStatus.Without,
    employed: data.partner.employed,
    name: data.partner.name,
    staffNumber: data.partner.staffNumber,
  },
  dog: data.dog,
  furtherGuests: {
    familyChildrenUnderage: data.furtherGuests.familyChildrenUnderage.map(
      x => ({ dateOfBirth: x.dateOfBirth }),
    ),
    familyChildrenAdult: data.furtherGuests.familyChildrenAdult,
    furtherAdults: data.furtherGuests.furtherAdults,
    nonFamilyChildrenUnderage: data.furtherGuests.nonFamilyChildrenUnderage.map(
      x => ({ dateOfBirth: x.dateOfBirth }),
    ),
  },
  comment: data.comment,
  priceChanges: data.priceChanges,
  bookingChanges: data.bookingChanges
    ? {
        summerHolidays: data.bookingChanges.summerHolidays,
        socialCreditPoints: data.bookingChanges.socialCreditPoints,
        eonBookingId: data.bookingChanges.eonBookingId,
        bookingDate: {
          arrival: data.bookingChanges.bookingDate.arrival,
          departure: data.bookingChanges.bookingDate.departure,
        },
      }
    : null,
  hawBookings: data.hawBookings
    ? data.hawBookings
        .filter(booking => isNotEmpty(booking.bookingChoiceId))
        .map(mapHawBookingToApi)
    : [],
  eonBookings:
    applicantIsNotInAccountingArea(
      data,
      AccountingAreasNotAllowedForEonActive,
    ) && data.eonBookings
      ? data.eonBookings
          .filter(
            booking =>
              isNotEmpty(booking.houseId) &&
              isNotEmpty(booking.bookingChoiceId),
          )
          .map(mapEonBookingToApi)
      : [],
});

export type FEHawBookingInput = {
  id?: string | undefined;
  priority: number;
  board?: BoardType | null;
  bookingChoiceId?: string | null;
};

export const mapHawBookingToApi = (
  data: FEHawBookingInput,
): HawBookingInput => {
  return {
    id: isEmpty(data.id) ? undefined : data.id,
    board: isEmpty(data.board) ? null : data.board,
    priority: data.priority,
    bookingChoiceId: isEmpty(data.bookingChoiceId)
      ? null
      : data.bookingChoiceId,
  };
};

export type FEEonBookingInput = {
  id?: string | null;
  priority: number;
  houseId: string;
  board?: BoardType | null;
  bookingChoiceId?: string | null;
};

export const mapEonBookingToApi = (
  data: FEEonBookingInput,
): EonBookingInput => {
  return {
    id: isEmpty(data.id) ? undefined : data.id,
    priority: data.priority,
    houseId: data.houseId,
    board: isEmpty(data.board) ? null : data.board,
    bookingChoiceId: isEmpty(data.bookingChoiceId)
      ? null
      : data.bookingChoiceId,
  };
};
