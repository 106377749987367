import IRoute from '../interfaces/route';
import config from './config';
import InformationPage from '../pages/information/information';
import IndexVacationOffers from '../pages/vacationOffers/IndexVacationOffers';
import EditVacationOffer from 'pages/vacationOffers/EditVacationOffer';
import IndexHealthOffers from 'pages/healthOffers/IndexHealthOffers';
import NewEon from 'pages/eon/NewEon';
import IndexEon from 'pages/eon/IndexEon';
import EditEon from 'pages/eon/EditEon';
import { EmployeeApplications } from 'pages/employeeApplications/EmployeeApplications';
import { EditHaw } from 'pages/haw/EditHaw';
import { IndexHaw } from 'pages/haw/IndexHaw';
import { NewHaw } from 'pages/haw/NewHaw';
import { EditMyApplications } from 'pages/myApplications/EditMyApplications';
import { IndexMyApplications } from 'pages/myApplications/IndexMyApplications';
import { NewMyApplications } from 'pages/myApplications/NewMyApplications';
import { IndexSettings } from 'pages/settings/IndexSettings';
import { BoardBilling } from 'pages/boardBilling/BoardBilling';
import { ImportEon } from 'components/Eon/import';
import { NewMyApplicationFromTemplate } from 'pages/myApplications/NewMyApplicationFromTemplate';

const routes: IRoute[] = [
  {
    path: config.localRoutes.information,
    component: InformationPage,
    exact: true,
  },

  // Applications
  {
    path: config.localRoutes.myApplications.index,
    component: IndexMyApplications,
    exact: true,
  },
  {
    path: config.localRoutes.employeeApplications.index,
    component: EmployeeApplications,
    exact: true,
    requiresRole: 'ADMIN',
  },
  {
    path: config.localRoutes.myApplications.edit,
    component: EditMyApplications,
    exact: true,
  },
  {
    path: config.localRoutes.employeeApplications.edit,
    component: EditMyApplications,
    exact: true,
    requiresRole: 'ADMIN',
  },
  {
    path: config.localRoutes.employeeApplications.newFromTemplate,
    component: NewMyApplicationFromTemplate,
    exact: true,
    requiresRole: 'ADMIN',
  },
  {
    path: config.localRoutes.myApplications.new,
    component: NewMyApplications,
    exact: true,
  },
  {
    path: config.localRoutes.boardBilling,
    component: BoardBilling,
    exact: true,
    requiresRole: 'BOARD_BILLING',
  },

  // Haw

  {
    path: config.localRoutes.haw.new,
    component: NewHaw,
    exact: true,
  },
  {
    path: config.localRoutes.haw.index,
    component: IndexHaw,
    exact: true,
  },
  {
    path: config.localRoutes.haw.edit,
    component: EditHaw,
    exact: true,
  },

  // Eon

  {
    path: config.localRoutes.eon.new,
    component: NewEon,
    exact: true,
    isEonActiveRoute: true,
  },
  {
    path: config.localRoutes.eon.index,
    component: IndexEon,
    exact: true,
    isEonActiveRoute: true,
  },
  {
    path: config.localRoutes.eon.edit,
    component: EditEon,
    exact: true,
    isEonActiveRoute: true,
  },
  {
    path: config.localRoutes.eon.import,
    component: ImportEon,
    isEonActiveRoute: true,
    requiresRole: 'ADMIN',
    exact: true,
  },

  // Vacation offers

  {
    path: config.localRoutes.vacationOffers.index,
    component: IndexVacationOffers,
    exact: true,
  },
  {
    path: config.localRoutes.vacationOffers.edit,
    component: EditVacationOffer,
    exact: true,
  },

  // Health offers

  {
    path: config.localRoutes.events,
    component: IndexHealthOffers,
    exact: true,
  },

  // Settings

  {
    path: config.localRoutes.settings.index,
    exact: false,
    requiresRole: 'ADMIN',
    subRoutes: [
      {
        path: config.localRoutes.settings.email.templates.index,
        exact: true,
        requiresRole: 'ADMIN',
        component: IndexSettings,
        subRoutes: [
          {
            path: config.localRoutes.settings.email.templates.confirmation.haw,
            component: IndexSettings,
            exact: true,
            requiresRole: 'ADMIN',
          },
          {
            path: config.localRoutes.settings.email.templates.confirmation.eon,
            component: IndexSettings,
            exact: true,
            requiresRole: 'ADMIN',
          },
          {
            path: config.localRoutes.settings.email.templates.rejected,
            component: IndexSettings,
            exact: true,
            requiresRole: 'ADMIN',
          },
          {
            path: config.localRoutes.settings.email.templates.signature,
            component: IndexSettings,
            exact: true,
            requiresRole: 'ADMIN',
          },
        ],
      },
      {
        path: config.localRoutes.settings.email.recipients,
        component: IndexSettings,
        exact: true,
        requiresRole: 'ADMIN',
      },
      {
        path: config.localRoutes.settings.maintenance,
        component: IndexSettings,
        exact: true,
        requiresRole: 'ADMIN',
      },
    ],
  },
];

export default routes;
