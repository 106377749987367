import Card from 'components/base/Card';
import { CheckButton, CloseButton } from 'components/base/IconButton';
import TextInput from 'components/applicationForm/TextInput';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import config from 'config/config';
import { useNewHouseEntry_CreateHouseMutation } from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type NewHouseEntryProps = {
  isEonHome: boolean;
};

const NewHouseEntry = ({ isEonHome }: NewHouseEntryProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { name: '' },
  });
  const history = useHistory();
  const getIndexUrl = (isEonHome: boolean): string =>
    config.localRoutes[isEonHome ? 'eon' : 'haw'].index;

  const onCompleted = response =>
    history.push(`${getIndexUrl(isEonHome)}/${response.createHouse.id}/edit`);

  const [createHouse, { called, reset }] = useNewHouseEntry_CreateHouseMutation(
    {
      onCompleted,
      onError: () => {
        reset();
      },
      context: {
        debounceKey: 'useNewHouseEntry_CreateHouseMutation',
        notification: {
          error: t('components.houses.NewHouseEntry.notifications.error'),
          success: t('components.houses.NewHouseEntry.notifications.success'),
        },
      },
    },
  );

  const handleConfirm = (name: string, isEonHome: boolean) => {
    if (called === false) {
      createHouse({ variables: { house: { name, isEonHome } } });
    }
  };

  const handleCancel = () => history.push(getIndexUrl(isEonHome));
  const errorMsg = t('pages.elements.formInputValidator.required');
  return (
    <div className="NewHouseEntry">
      <Card>
        <div className="flex items-center">
          <div className="w-3/12">
            <ValidationFeedback error={errors.name}>
              <TextInput
                {...register('name', {
                  validate: v => !isEmpty(v) || errorMsg,
                })}
              />
            </ValidationFeedback>
          </div>
          <div className="ml-4 flex w-6/12">
            <div className="flex">
              <CheckButton
                onClick={() => handleConfirm(getValues('name'), isEonHome)}
              />
              <CloseButton onClick={handleCancel} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewHouseEntry;
