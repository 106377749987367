import LoadingOrError from 'components/base/LoadingOrError';
import UploadButton from 'components/base/UploadButton';
import { Files } from 'components/information/Files';
import { News } from 'components/information/News';
import { KeyFormProvider, KeyFormArea } from 'context/KeyFormContext';
import {
  FileType,
  refetchInformation_GetAttachmentsQuery,
  useInformation_AddAttachmentMutation,
  useInformation_DeleteAttachmentMutation,
  useInformation_GetAttachmentsQuery,
  useInformation_UpdateAttachmentMutation,
  useInformation_UpdateFileMetadataMutation,
} from 'gql/generated/types-and-hooks';
import { readFile, readFiles } from 'helpers/fileHelper';
import { getString } from 'helpers/util';
import { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import titlePhoto from '../../assets/images/strandkoerbe.jpg';
import { UserInfoContext } from '../../context/UserInfoContext';

const refetchQueries = [refetchInformation_GetAttachmentsQuery()];

const InformationPage = (): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);

  const { data, loading, error } = useInformation_GetAttachmentsQuery({
    context: {
      debounceKey: 'useInformation_GetAttachmentsQuery',
    },
  });
  const [deleteAttachment] = useInformation_DeleteAttachmentMutation({
    refetchQueries,
    context: {
      debounceKey: 'useInformation_DeleteAttachmentMutation',
      notification: {
        success: t(
          'components.attachment.notifications.deleteAttachment.success',
        ),
        error: t('components.attachment.notifications.deleteAttachment.error'),
      },
    },
  });
  const [updateAttachment] = useInformation_UpdateAttachmentMutation({
    refetchQueries,
    context: {
      debounceKey: 'useInformation_UpdateAttachmentMutation',
      notification: {
        success: t(
          'components.attachment.notifications.updateAttachment.success',
        ),
        error: t('components.attachment.notifications.updateAttachment.error'),
      },
    },
  });
  const [addAttachment] = useInformation_AddAttachmentMutation({
    refetchQueries,
    context: {
      debounceKey: 'useInformation_AddAttachmentMutation',
      notification: {
        success: t('components.attachment.notifications.addAttachment.success'),
        error: t('components.attachment.notifications.addAttachment.error'),
      },
    },
  });
  const [updateFileMetadata] = useInformation_UpdateFileMetadataMutation({
    refetchQueries,
    context: {
      debounceKey: 'useInformation_UpdateFileMetadataMutation',
      notification: {
        success: t(
          'components.attachment.notifications.updateFileMetadata.success',
        ),
        error: t(
          'components.attachment.notifications.updateFileMetadata.error',
        ),
      },
    },
  });

  const handleAdd = useCallback(
    async (files: FileList) => {
      for (const file of await readFiles(files)) {
        await addAttachment({
          variables: {
            file: {
              name: file.name,
              content: file.content,
              type: FileType.Attachment,
            },
          },
        });
      }
    },
    [addAttachment],
  );

  const handleUpload = useCallback(
    async (id: string, file: File) => {
      const result = await readFile(file);
      updateAttachment({
        variables: {
          id,
          file: {
            name: result.name,
            content: result.content,
            type: FileType.Attachment,
          },
        },
      });
    },
    [updateAttachment],
  );

  const handleDelete = useCallback(
    (id: string) => {
      deleteAttachment({
        variables: {
          id,
        },
      });
    },
    [deleteAttachment],
  );

  const handleDescriptionChange = useCallback(
    (id: string, newDescription: string) => {
      updateFileMetadata({
        variables: {
          id,
          file: {
            description: newDescription,
          },
        },
      });
    },
    [updateFileMetadata],
  );

  return (
    <div className="InformationPage container mx-auto">
      <KeyFormProvider>
        <News />
        <div className="mt-4 flex gap-x-4">
          <div className="hidden w-1/3 md:block">
            <img src={titlePhoto} alt="" width="768" />
          </div>
          <div className="flex w-full flex-col gap-y-4 md:w-2/3">
            <LoadingOrError
              loading={loading}
              error={error}
              data={data?.informationFiles}
              render={informationFiles => {
                return (
                  <>
                    {informationFiles.length === 0 ? (
                      <div>{t('pages.common.noData')}</div>
                    ) : (
                      <Files
                        attachments={informationFiles.map(x => ({
                          ...x,
                          description: getString(x.description),
                        }))}
                        onUpload={handleUpload}
                        onDelete={handleDelete}
                        onDescriptionChanged={handleDescriptionChange}
                      />
                    )}
                    <div className="flex w-full justify-end">
                      {isAdmin && (
                        <KeyFormArea>
                          <UploadButton
                            label={t('components.attachment.buttons.add')}
                            onChange={handleAdd}
                          />
                        </KeyFormArea>
                      )}
                    </div>
                  </>
                );
              }}
            ></LoadingOrError>
          </div>
        </div>
      </KeyFormProvider>
    </div>
  );
};
export default InformationPage;
