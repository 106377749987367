import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export function NavItem({
  to,
  label,
}: {
  to: string;
  label: string;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <li>
      <NavLink
        to={to}
        className="inline-block w-full px-4 py-2 text-center text-gray-500"
        activeClassName="text-primary border-b-2 border-primary"
        activeStyle={{
          marginBottom: '-2px',
        }}
      >
        {t(label)}
      </NavLink>
    </li>
  );
}
