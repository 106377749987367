import { PropsWithChildren, ReactElement } from 'react';

export const FormGrid = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement => (
  <div className="grid grid-cols-12 gap-8">{children}</div>
);
export const FormKey = ({
  label,
  children,
}: PropsWithChildren<{ label: string }>): ReactElement => (
  <>
    <div className="col-span-2 col-start-1 font-medium">{label}</div>
    {children}
  </>
);

export const FormValue = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement => (
  <div className="col-start-3 col-end-11">{children}</div>
);
export const FormControl = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement => (
  <div className="col-start-12 col-end-13">{children}</div>
);
