import { NavLink } from 'react-router-dom';
import UserMenu from './UserMenu';
import logo from '../assets/images/shng-logo.svg';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { MainNavigation } from './MainNavigation';

const Header = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="Header">
      <hr className="h-8 border-b-2" />

      <nav className="px-12 py-8">
        <ul className="grid list-none grid-cols-3 items-center">
          <li>
            <img src={logo} alt="" width="179" height="33" />
          </li>

          <li className="justify-self-center">
            <NavLink to="/" className="text-2xl font-medium text-primary">
              {t('pages.header.name')}
            </NavLink>
          </li>

          <li className="justify-self-end">
            <UserMenu />
          </li>
        </ul>
      </nav>
      <MainNavigation />
    </div>
  );
};

export default Header;
