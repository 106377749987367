import {
  Configuration,
  EndSessionRequest,
  PopupRequest,
} from '@azure/msal-browser';
import { getEnv } from 'helpers/util';

const stsAuthority = getEnv('REACT_APP_WELL_KNOWN_ENDPOINT');
const clientId = getEnv('REACT_APP_CLIENT_ID');
const redirectUri = getEnv('REACT_APP_REDIRECT_URL');
const clientScope = getEnv('REACT_APP_AUTH_SCOPES');

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId ? clientId : '',
    authority: stsAuthority,
    redirectUri: redirectUri,
  },
  system: {
    tokenRenewalOffsetSeconds: 120,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: clientScope ? clientScope.split(' ') : [],
};

export const logoutRequest: EndSessionRequest = {
  postLogoutRedirectUri: null,
};
