import { ReactElement, useState } from 'react';
import NewVacationOfferModal from './NewVacationOfferModal';
import Button from 'components/base/Button';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'helpers/util';
import {
  refetchIndexVacationOffers_GetVacationOffersListQuery,
  useNewVacationOfferButton_CreateVacationOfferMutation,
  useNewVacationOfferButton_GetHawHousesQuery,
} from 'gql/generated/types-and-hooks';

const NewVacationOfferButton = (): ReactElement => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [createVacationOffer] =
    useNewVacationOfferButton_CreateVacationOfferMutation({
      refetchQueries: [refetchIndexVacationOffers_GetVacationOffersListQuery()],
      context: {
        debounceKey: 'useNewVacationOfferButton_CreateVacationOfferMutation',
        notification: {
          success: t(
            'components.VacationOffers.NewVacationOfferButton.notifications.success',
          ),
          error: t(
            'components.VacationOffers.NewVacationOfferButton.notifications.error',
          ),
        },
      },
    });
  const { data, loading, error } =
    useNewVacationOfferButton_GetHawHousesQuery();
  const houses = data?.houses ? [...data.houses] : [];

  const handleConfirm = (houseId: string) => {
    if (isEmpty(houseId) === false) {
      createVacationOffer({
        variables: {
          houseId: houseId,
          offer: {
            activated: true,
            name: t(
              'components.VacationOffers.NewVacationOfferButton.defaultName',
            ),
            service: '',
          },
        },
      });
      setModalOpen(false);
    }
  };

  const handleCancel = () => setModalOpen(false);
  const handleOpenModal = () => setModalOpen(true);
  const disabled = houses.length === 0;

  return (
    <div className="NewVacationOfferButton flex flex-col items-center">
      {!loading && !error && !disabled && (
        <NewVacationOfferModal
          open={modalOpen}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          houses={houses}
        />
      )}
      <Button
        disabled={disabled}
        onClick={handleOpenModal}
        label={
          loading
            ? t('pages.common.loading')
            : error
            ? t('pages.common.error')
            : houses.length === 0
            ? t('components.VacationOffers.NewVacationOfferButton.noHouse')
            : t('components.VacationOffers.NewVacationOfferButton.newOffer')
        }
      />
    </div>
  );
};
export default NewVacationOfferButton;
