import DeleteWithConfirmationIconButton from 'components/base/DeleteWithConfirmationIconButton';
import { ToggleActiveButton } from 'components/base/IconButton';
import { EditableInput } from 'components/applicationForm/editableInputs';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import config from 'config/config';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { UserInfoContext } from '../../context/UserInfoContext';
import { Icon } from 'components/base/Icon';
import {
  refetchHouseForm_GetHouseQuery,
  useHeader_DeleteHouseMutation,
  useHeader_GetIsHouseRemoveableQuery,
  useHeader_UpdateHouseMutation,
} from 'gql/generated/types-and-hooks';

const Header = ({
  house,
}: {
  house: {
    id: string;
    activated: boolean;
    isEonHome: boolean;
    name: string;
  };
}): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);
  const { isAdmin } = useContext(UserInfoContext);
  const [updateHouse] = useHeader_UpdateHouseMutation({
    refetchQueries: [
      refetchHouseForm_GetHouseQuery({
        id: house.id,
      }),
    ],
    context: {
      debounceKey: 'useHeader_UpdateHouseMutation',
      notification: {
        success: t('components.houses.header.update.notifications.success'),
        error: t('components.houses.header.update.notifications.error'),
      },
    },
  });
  const [deleteHouse] = useHeader_DeleteHouseMutation({
    onCompleted: () => {
      history.push(
        house.isEonHome
          ? config.localRoutes.eon.index
          : config.localRoutes.haw.index,
      );
    },
    context: {
      debounceKey: 'useHeader_DeleteHouseMutation',
      notification: {
        error: t('components.houses.header.delete.notifications.error'),
        success: t('components.houses.header.delete.notifications.success'),
      },
    },
  });
  const { data: isRemoveable } = useHeader_GetIsHouseRemoveableQuery({
    variables: {
      id: house.id,
    },
  });

  const {
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { name: house.name },
  });

  const onConfirm = () => {
    const name = getValues('name');
    const newHouse = {
      ...house,
      id: undefined,
      files: undefined,
      charges: undefined,
      eonOffers: undefined,
      name,
    };
    updateHouse({
      variables: {
        id: house.id,
        house: newHouse,
      },
    });
    leaveEditMode();
  };

  const onCancel = () => {
    reset({ name: house.name });
    leaveEditMode();
  };

  const handleDelete = () =>
    deleteHouse({
      variables: {
        id: house.id,
      },
    });

  const handleToggleHouse = () => {
    const newHouse = {
      ...house,
      id: undefined,
      files: undefined,
      charges: undefined,
      eonOffers: undefined,
      activated: !house.activated,
    };
    updateHouse({
      variables: { id: house.id, house: newHouse },
    });
  };

  return (
    <div className="Header mb-8">
      <div className="flex items-center gap-2">
        <Link
          className="p-2"
          to={
            house.isEonHome
              ? config.localRoutes.eon.index
              : config.localRoutes.haw.index
          }
        >
          <Icon icon={'ChevronUpIcon'} />
        </Link>
        <div className="HouseNameForm flex flex-1 items-center">
          <div className={`${editMode ? 'w-1/3' : ''}`}>
            <ValidationFeedback error={errors.name}>
              <EditableInput
                nonEditableValue={house.name}
                editMode={editMode}
                {...register('name', {
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                })}
              />
            </ValidationFeedback>
          </div>
          <div className="ml-2">
            {isAdmin && (
              <EditControls
                editMode={editMode}
                onEdit={enterEditMode}
                onConfirm={handleSubmit(() => onConfirm())}
                onCancel={() => {
                  onCancel();
                }}
              />
            )}
          </div>
        </div>

        <KeyFormArea>
          <div className="flex">
            {isRemoveable && isAdmin && (
              <DeleteWithConfirmationIconButton
                onDelete={handleDelete}
                text={t('pages.houses.dialog.text', { name: house.name })}
                title={t('pages.houses.dialog.title')}
              />
            )}
            {isAdmin && (
              <ToggleActiveButton
                onClick={handleToggleHouse}
                active={house.activated}
              />
            )}
          </div>
        </KeyFormArea>
      </div>
    </div>
  );
};

export default Header;
