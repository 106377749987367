import { useTranslation } from 'react-i18next';
import { Icon } from 'components/base/Icon';
import useReactTooltip from 'hooks/useReactTooltip';
import { ReactElement } from 'react';

const AddButton = ({
  onChange,
}: {
  onChange: (files: FileList) => void;
}): ReactElement => {
  useReactTooltip();
  const uploadPhotoInputId = `uploadPhotoInput_${(Math.random() * 1000).toFixed(
    0,
  )}`;

  return (
    <label htmlFor={uploadPhotoInputId} className="flex-none">
      <div className="relative flex h-32 w-full cursor-pointer items-center justify-center rounded-md border border-gray-200 lg:h-36">
        <div className="group relative flex-none">
          <div
            className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-300"
            data-tip={useTranslation().t('components.photos.tooltips.upload')}
          >
            <Icon icon={'PlusIcon'} />
          </div>
          <input
            id={uploadPhotoInputId}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            className="hidden"
            multiple
            onChange={e => {
              if (e.target.files) {
                onChange(e.target.files);
              }
              // Clear value to accept new file selection
              e.target.value = '';
            }}
          />
        </div>
      </div>
    </label>
  );
};

export default AddButton;
