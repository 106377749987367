export interface Event<T = EventTarget> {
  /* https://github.com/microsoft/TypeScript/issues/31816#issuecomment-636671463 */
  target: T;
}

export const getEnv = (varName: string): string | undefined => {
  if (process.env[varName]) {
    return process.env[varName];
  } else {
    throw new Error(`ENV '${varName}' was not set`);
  }
};

export const currencyNumberFormat = Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export function deepCopy<T>(source: T): T {
  return Array.isArray(source)
    ? source.map(item => deepCopy(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
        o[prop] = deepCopy(source[prop]);
        return o;
      }, Object.create(Object.getPrototypeOf(source)))
    : (source as T);
}
export function getKid(preferred_username?: string): string {
  const [kid] = preferred_username?.split('@') || ['no-kid'];
  return kid;
}

export function isEmpty(str?: string | null): boolean {
  return !str || 0 === str.length;
}

export function isNotEmpty(str?: string | null): str is string {
  return !isEmpty(str);
}

/**
 * A function that removed all duplicated values from an array
 */
export function unique<T>(arr: T[]): T[] {
  return arr.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
}

/**
 * Returns an array incl. start and if step size matches incl. end.
 */
export const sequence = (
  start: number,
  end: number,
  step: number,
): number[] => {
  const result: number[] = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
};

const alphanumericCollator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'case',
});

export function getAlphanumericSort(): (a: string, b: string) => number {
  return (a: string, b: string) => {
    return alphanumericCollator.compare(a, b);
  };
}
export function getAlphanumericSortByKey<T extends Record<keyof T, string>>(
  key: keyof T,
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    return alphanumericCollator.compare(a[key], b[key]);
  };
}

export const intersection = <T>(
  sets: T[][],
  comparator = (a: T, b: T) => a === b,
): T[] =>
  sets.reduce(
    (result, currentItems, idx) =>
      idx === 0
        ? unique(currentItems)
        : result.filter(
            candidate => !!currentItems.find(i => comparator(i, candidate)),
          ),
    [],
  );

export const uuid = (): string => Math.random().toString(36).substring(2, 9);

export function getInitials(name: string): string {
  const names = !isEmpty(name) ? name.trim().split(' ') : '?';
  let initials = names[0].substring(0, 1).toUpperCase();
  initials +=
    names.length > 1
      ? names[names.length - 1].substring(0, 1).toUpperCase()
      : '';
  return initials;
}

export function getString(s: string | undefined | null): string {
  return s || '';
}

export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);
