import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import i18n from 'i18n';
import BaseDialog from './BaseDialog';
import Button from './Button';

type ConfirmationDialogProps = PropsWithChildren<{
  open: boolean;
  title: string;
  text?: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  showCancel?: boolean;
  children?: ReactNode;
}>;

const ConfirmationDialog = ({
  open,
  text,
  title,
  onConfirm,
  onCancel,
  confirmText = i18n.t('components.confirmationDialog.button.confirm'),
  cancelText = i18n.t('components.confirmationDialog.button.cancel'),
  showCancel = true,
  children,
}: ConfirmationDialogProps): ReactElement => {
  return (
    <BaseDialog open={open} title={title} text={text} onClose={onCancel}>
      <p className="break-normal text-sm">{text}</p>
      {children}
      <div className="pt-6 sm:flex sm:flex-row-reverse">
        <Button
          onClick={onConfirm}
          label={
            confirmText === undefined
              ? i18n.t('components.confirmationDialog.button.confirm')
              : confirmText
          }
        ></Button>
        {showCancel && (
          <Button
            onClick={onCancel}
            label={
              cancelText === undefined
                ? i18n.t('components.confirmationDialog.button.confirm')
                : cancelText
            }
            styleOptions={{
              inverted: true,
            }}
          ></Button>
        )}
      </div>
    </BaseDialog>
  );
};

export default ConfirmationDialog;
