import { useTranslation } from 'react-i18next';
import PickBoardDropdown from '../PickBoardDropdown';
import { mapBoardPropertynameToEnum } from 'interfaces/BoardType';
import HouseDropdown from '../HouseDropdown';
import { useFormContext } from 'react-hook-form';
import OfferDropdown from '../OfferDropdown';
import { ValidationFeedback } from '../Validatable';
import { Boards } from 'api/mappers';
import { dateMonthFormat, dateMonthYearFormat } from 'helpers/dateFormats';
import {
  EditMyApplications_GetApplicationQuery,
  ProcessingStatus,
} from 'gql/generated/types-and-hooks';
import {
  HawCatalogueData,
  HawCatalogueEntry,
} from 'interfaces/HawCatalogueData';
import { ReactElement } from 'react';
import { getOfferForBooking } from 'helpers/applicationHelper';

const getHouseOptions = ({
  catalogue,
  withDog,
  selectedHouseId,
}: {
  catalogue: HawCatalogueData;
  withDog: boolean;
  selectedHouseId?: string;
}) => {
  const catalogueWithBookings = catalogue.filter(x => {
    if (x.id === selectedHouseId) return true;
    if (x.activated == false) return false;
    if (withDog && x.dogAllowed == false) return false;
    const offers = x.offers.filter(offer => {
      if (!offer.active) return false;

      const bookings = offer.bookings.filter(booking => booking.active);
      return bookings.length > 0;
    });
    return offers.length > 0;
  });
  return catalogueWithBookings;
};

const getBookingFromOffers = (
  bookingId: string,
  offers?: HawCatalogueData[0]['offers'],
): { active: boolean } | undefined => {
  if (offers == null) {
    return undefined;
  }
  const selectedBookings = offers.flatMap(
    offer => offer.bookings.find(booking => booking.id === bookingId) || [],
  );
  return selectedBookings[0];
};

const getBookingTimespanWithOfferNameText = (
  offers: {
    name: string;
    bookings: { id: string; from: Date; to: Date }[];
  }[],
  bookingId: string,
): string => {
  if (offers) {
    for (const offer of offers) {
      const bookingIndex = offer.bookings.findIndex(
        booking => booking.id === bookingId,
      );
      if (bookingIndex > -1) {
        const booking = offer.bookings[bookingIndex];
        return `${dateMonthFormat.format(
          booking.from,
        )} - ${dateMonthYearFormat.format(booking.to)} ${offer.name}`;
      }
    }
  }
  return '---';
};

const filterOffersWithBookings = (
  offers: HawCatalogueEntry['offers'] | undefined,
) => {
  if (offers == null) {
    return [];
  }
  return offers.filter(offer => offer.bookings.length > 0);
};

const getCatalogueEntryForBooking = (
  booking: {
    bookingChoiceId?: string | null;
    houseId?: string;
  },
  catalogue: HawCatalogueData,
) => {
  if (booking.houseId) {
    return catalogue.find(x => x.id === booking.houseId) || undefined;
  }
  return (
    catalogue.find(x =>
      x.offers.some(x =>
        x.bookings.some(y => y.id === booking.bookingChoiceId),
      ),
    ) || undefined
  );
};

const HawBookingEntry = ({
  index,
  catalogue,
  onChangeHouse,
  editMode,
}: {
  index: number;
  catalogue: HawCatalogueData;
  onChangeHouse: (id: string) => void;
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext<
    EditMyApplications_GetApplicationQuery['application'] & {
      hawBookings: (EditMyApplications_GetApplicationQuery['application']['hawBookings'][0] & {
        houseId?: string;
      })[];
    }
  >();

  const processingStatus = getValues('processingStatus');
  const baseControlName: `hawBookings.${number}` = `hawBookings.${index}`;
  const booking = getValues(baseControlName);
  const withDog = getValues('dog');

  const catalogueEntry = getCatalogueEntryForBooking(booking, catalogue);
  const house = catalogueEntry?.offers[0].house;

  return (
    <div className="HawBookingEntry">
      <div className="grid grid-cols-12 gap-x-2 py-2">
        <div className="col-span-1 text-center font-semibold">
          {`Rang ${booking.priority}:`}
        </div>

        <div className="col-span-3">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.hawBookings
                  ? errors?.hawBookings[index]?.houseId
                  : undefined
              }
            >
              <HouseDropdown
                className="w-full"
                {...register(`${baseControlName}.houseId`, {
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                  validate: value => {
                    if (
                      processingStatus === ProcessingStatus.Created ||
                      processingStatus === ProcessingStatus.SentBack
                    ) {
                      const selectedEntry = catalogue.find(x => x.id === value);
                      if (selectedEntry && !selectedEntry.activated) {
                        return t(
                          'components.form.hawBookingEntry.errorHouseFull',
                        ) as string;
                      }
                      if (
                        selectedEntry && withDog
                          ? !selectedEntry.dogAllowed
                          : false
                      ) {
                        return t(
                          'components.form.hawBookingEntry.errorNoDogsAllowed',
                        ) as string;
                      }
                    }
                  },
                  value: house?.id,
                  onChange: e => {
                    onChangeHouse(e.target.value);
                  },
                })}
                houses={getHouseOptions({
                  catalogue,
                  withDog: withDog,
                  selectedHouseId: house?.id,
                })}
                t={t}
                dogAllowedRequired={withDog}
              />
            </ValidationFeedback>
          ) : (
            <span>{catalogueEntry?.name || '---'}</span>
          )}
        </div>
        <div className="col-span-5">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.hawBookings
                  ? errors?.hawBookings[index]?.bookingChoiceId
                  : undefined
              }
            >
              <OfferDropdown
                className="w-full"
                initValue={booking.bookingChoiceId ?? ''}
                {...register(`${baseControlName}.bookingChoiceId`, {
                  required: t<string>(
                    'pages.elements.formInputValidator.required',
                  ),
                  validate: value => {
                    if (
                      processingStatus === ProcessingStatus.Created ||
                      processingStatus === ProcessingStatus.SentBack
                    ) {
                      const noPlacesLeftMessage = t(
                        'components.form.hawBookingEntry.errorNoPlacesLeft',
                      ) as string;
                      const selectedBooking = getBookingFromOffers(
                        value ?? '',
                        catalogueEntry?.offers,
                      );
                      if (selectedBooking && !selectedBooking.active) {
                        return noPlacesLeftMessage;
                      }

                      const offer = getOfferForBooking(catalogue, value ?? '');
                      if (offer && !offer.active) {
                        return noPlacesLeftMessage;
                      }
                    }
                  },
                })}
                t={t}
                options={filterOffersWithBookings(catalogueEntry?.offers)}
              />
            </ValidationFeedback>
          ) : (
            <span>
              {catalogueEntry
                ? getBookingTimespanWithOfferNameText(
                    catalogueEntry.offers,
                    booking.bookingChoiceId ?? '',
                  )
                : ''}
            </span>
          )}
        </div>
        <div className="col-span-3">
          {editMode ? (
            <ValidationFeedback
              error={
                errors?.hawBookings
                  ? errors?.hawBookings[index]?.board
                  : undefined
              }
            >
              <PickBoardDropdown
                name={`${baseControlName}.board`}
                options={
                  house?.charges
                    ? Object.keys(house?.charges)
                        .filter(
                          board =>
                            board !== '__typename' &&
                            house?.charges[board].activated,
                        )
                        .map(board =>
                          mapBoardPropertynameToEnum(board as Boards),
                        )
                    : []
                }
              />
            </ValidationFeedback>
          ) : (
            <span>
              {t(`components.form.pickBoardDropdown.${booking.board}`)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HawBookingEntry;
