import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { getEnv, getKid, isEmpty, isNotEmpty } from './helpers/util';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { acquireToken } from 'index';
import {
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LoadingOrError from 'components/base/LoadingOrError';
import { UserInfoContext, UserRole } from 'context/UserInfoContext';
import jwt_decode from 'jwt-decode';
import { IdTokenContext } from 'context/IdTokenContext';
import { AuthenticationResult } from '@azure/msal-common';
import { useShowHideLoadingOverlay } from 'context/LoadingOverlayContext';
import { OperationDefinitionNode } from 'graphql';
import DebounceLink from 'apollo-link-debounce';
import { useShowNotification } from 'context/NotificationContext';
import { NotificationTypes } from 'components/Notification';
import { logEvent } from 'AppInsights';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    // fetchPolicy: 'cache-and-network',
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    pollInterval: 30000,
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
    awaitRefetchQueries: true,
  },
} as const;

const httpBatchLink = new BatchHttpLink({
  uri: getEnv('REACT_APP_BACKEND_BASE_URL') + '/graphql',
});

function omitTypename(key: string, value: unknown) {
  return key === '__typename' ? undefined : value;
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }

    return forward(operation);
  });
}

const createErrorLink = ({
  hideOverlay,
}: ReturnType<typeof useShowHideLoadingOverlay>) =>
  onError(({ graphQLErrors, networkError }) => {
    hideOverlay();

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        logEvent(`[GraphQL error]: ${message}`, { message, locations, path }),
      );
    if (networkError)
      logEvent(`[Network error]: ${networkError.message}`, { networkError });
  });

function createShowOverlayLink({
  showOverlay,
  hideOverlay,
}: ReturnType<typeof useShowHideLoadingOverlay>): ApolloLink[] {
  const getIsMutation = (operation: Operation) => {
    // Called before operation is sent to server
    const operationDefinition = operation.query
      .definitions[0] as OperationDefinitionNode;
    return operationDefinition.operation === 'mutation';
  };

  const onCompletedLink = new ApolloLink((operation, forward) => {
    const isMutation = getIsMutation(operation);
    if (isMutation) {
      showOverlay();
    }

    return forward(operation).map(c => {
      if (isMutation) {
        hideOverlay();
      }
      return c;
    });
  });

  return [onCompletedLink];
}

function createNotificationLink(
  showNotification: ReturnType<typeof useShowNotification>,
): ApolloLink[] {
  const getNotificationTextFromContext = (
    context: Record<
      'notification',
      | {
          error?: string;
          success?: string;
          info?: string;
        }
      | undefined
    >,
    type: NotificationTypes,
  ) => {
    return context.notification ? context.notification[type] : undefined;
  };

  const onCompletedLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();

    return forward(operation).map(c => {
      if (c.errors) {
        const errorText = getNotificationTextFromContext(context, 'error');
        if (errorText) {
          showNotification({
            type: 'error',
            text: errorText,
          });
        }
      } else {
        const successText = getNotificationTextFromContext(context, 'success');
        if (successText) {
          showNotification({
            type: 'success',
            text: successText,
          });
        } else {
          const infoText = getNotificationTextFromContext(context, 'info');
          if (infoText) {
            showNotification({
              type: 'info',
              text: infoText,
            });
          }
        }
      }
      return c;
    });
  });

  return [onCompletedLink];
}

const client = new ApolloClient<NormalizedCacheObject>({
  cache: getApolloClientCache(),
  connectToDevTools: process.env['NODE_ENV'] === 'development',
  defaultOptions,
});

export const GET_TOKEN = gql(`#graphql
  query GetToken {
    getToken
  }
`);

const AuthorizedApolloClientProvider = ({
  children,
  onAuthChanged,
}: PropsWithChildren<{
  onAuthChanged: (authResult: AuthenticationResult) => void;
}>): ReactElement => {
  const {
    email,
    preferred_username: preferredUsername,
    name,
  } = useContext(IdTokenContext);
  const [ferienwerkToken, setFerienwerkToken] = useState<
    string | null | undefined
  >(undefined);
  const [error, setError] = useState<ApolloError>();
  const { showOverlay, hideOverlay } = useShowHideLoadingOverlay();
  const showNotification = useShowNotification();

  const kid = getKid(preferredUsername);

  client.setLink(
    ApolloLink.from([
      getAuthLink(onAuthChanged, ferienwerkToken),
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      createErrorLink({ showOverlay, hideOverlay }),
      ...createNotificationLink(showNotification),
      ...createShowOverlayLink({ showOverlay, hideOverlay }),
      createOmitTypenameLink(),
      httpBatchLink,
    ]),
  );

  const decodedFerienwerkToken = useMemo(() => {
    return decodeFerienwerkToken(ferienwerkToken);
  }, [ferienwerkToken]);

  useEffect(() => {
    client
      .query({
        query: GET_TOKEN,
      })
      .then(result => {
        setFerienwerkToken(result.data.getToken);
      })
      .catch(setError);
  }, []);

  if (error) {
    throw new Error(error.message);
  }

  return (
    <LoadingOrError
      loading={isEmpty(ferienwerkToken)}
      error={undefined} // should be handleed above in the component tree
      fullscreen={true}
    >
      <UserInfoContext.Provider
        value={{
          ...decodedFerienwerkToken,
          isAdmin: decodedFerienwerkToken.roles.includes('ADMIN'),
          email: email || '',
          name: name || '',
          kid,
        }}
      >
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </UserInfoContext.Provider>
    </LoadingOrError>
  );
};

function getAuthLink(
  onAuthChanged: (authResult: AuthenticationResult) => void,
  ferienwerkToken?: string | null,
) {
  return setContext(async () => {
    const authResult = await acquireToken();
    if (authResult && !authResult.fromCache) {
      onAuthChanged(authResult);
    }
    const token = authResult?.accessToken;
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...(ferienwerkToken && {
          'x-authorization-ferienwerk': ferienwerkToken,
        }),
      },
    };
  });
}

function getApolloClientCache() {
  return new InMemoryCache({
    typePolicies: {
      House: {
        fields: {
          files: {
            // Disable merge, always replace exiting with incoming data.
            // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
            merge: false,
          },
        },
      },
    },
  });
}

function decodeFerienwerkToken(token: string | null | undefined) {
  let ferienwerkTokenData = {
    roles: [] as UserRole[],
    staffNumber: '',
    accountingArea: '',
    department: '',
  };
  if (isNotEmpty(token)) {
    const decodedFerienwerkToken = jwt_decode<{
      roles: UserRole[];
      staffNumber: string;
      accountingArea: string;
      department: string;
    }>(token);
    if (decodedFerienwerkToken) {
      ferienwerkTokenData = { ...decodedFerienwerkToken };
    }
  }
  return ferienwerkTokenData;
}

export default AuthorizedApolloClientProvider;
