import { EditButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement } from 'react';

const EditKeyFormButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => (
  <KeyFormArea>
    <EditButton onClick={onClick} />
  </KeyFormArea>
);

export default EditKeyFormButton;
