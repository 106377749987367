const MAX_FILENAME = 80;

type ReadFileResult = {
  name: string;
  content: string;
};

export async function readFiles(
  files: FileList | File[],
): Promise<ReadFileResult[]> {
  const reads: Promise<ReadFileResult>[] = [];
  for (const file of Object.values(files)) {
    reads.push(readFile(file));
  }
  return Promise.all(reads);
}

export async function readFile(file: File): Promise<ReadFileResult> {
  return new Promise<ReadFileResult>(resolve => {
    const reader = new FileReader();
    const filename = sanitizeFilename(MAX_FILENAME, file.name);
    reader.onload = () => {
      const result =
        typeof reader.result === 'string'
          ? reader.result
          : Buffer.from(reader.result as ArrayBuffer).toString('utf8');

      resolve({
        name: filename,
        content: result.split(',')[1],
      });
    };
    reader.readAsDataURL(file);
  });
}

export const sanitizeFilename = (size: number, filename: string): string => {
  const split = filename.split('.');
  const extension = split.length > 1 ? split.pop()?.trim() : '';
  const name = split.join('.');
  // + 1 for dot
  const sizeWithOutExtension =
    size - (extension?.length ? extension?.length + 1 : 0);
  return `${
    name.length > size ? truncateFilename(name, sizeWithOutExtension) : name
  }${extension ? '.' + extension : ''}`;
};

export const truncateFilename = (fileName: string, maxSize = 40): string => {
  const truncatedSize = maxSize - 5 > 0 ? maxSize - 5 : 0;
  return fileName.length > maxSize
    ? `${fileName.substring(0, truncatedSize)}[...]`
    : fileName;
};
