import { getEnv } from 'helpers/util';

const BASE_URL = getEnv('REACT_APP_BACKEND_BASE_URL');
const config = {
  defaults: {
    namespace: 'Application',
  },
  localRoutes: {
    haw: {
      new: '/haw/new',
      index: '/haw',
      edit: '/haw/:id/edit',
    },
    eon: {
      new: '/eon/new',
      index: '/eon',
      edit: '/eon/:id/edit',
      import: '/eon/import',
    },
    vacationOffers: {
      new: '/haw-ferienangebote/new',
      index: '/haw-ferienangebote',
      edit: '/haw-ferienangebote/:id/edit',
    },
    myApplications: {
      new: '/meine-antraege/new',
      index: '/meine-antraege',
      edit: '/meine-antraege/:id/edit',
      adminEdit: '/meine-antraege/',
    },
    events: '/gesundheitstage',
    information: '/information',
    employeeApplications: {
      index: '/mitarbeiter-antraege',
      edit: '/mitarbeiter-antraege/:id/edit',
      newFromTemplate: '/mitarbeiter-antraege/:id/newFromTemplate',
    },
    boardBilling: '/verpflegungsabrechnung',
    settings: {
      index: '/verwaltung',
      email: {
        templates: {
          index: '/verwaltung/email-vorlagen',
          confirmation: {
            haw: '/verwaltung/email-vorlagen/haw-bestaetigung',
            eon: '/verwaltung/email-vorlagen/eon-bestaetigung',
          },
          rejected: '/verwaltung/email-vorlagen/antrag-abgelehnt',
          signature: '/verwaltung/email-vorlagen/signatur',
        },
        recipients: '/verwaltung/email-empfaenger',
      },
      maintenance: '/verwaltung/datenpflege',
    },
  },
};

export default config;

export const getUrl = (path: string): string => buildUrl(path);

function buildUrl(path: string): string {
  const url = new URL(path, BASE_URL);
  return url.href;
}
