import { getAlphanumericSort } from 'helpers/util';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import AttachmentRow from './AttachmentRow';

const sort = getAlphanumericSort();

type AttachmentTableProps = {
  attachments: {
    id: string;
    description: string;
    path: string;
    name: string;
  }[];
  editable: boolean;
  onUpload?: (id: string, file: File) => void;
  onDelete?: (id: string) => void;
  onDescriptionChanged?: (id: string, description: string) => void;
};

export const AttachmentTable = ({
  attachments,
  editable,
  onUpload,
  onDelete,
  onDescriptionChanged,
}: AttachmentTableProps): ReactElement => {
  const { t } = useTranslation();

  const handleUpload = (id: string) => (file: File) =>
    onUpload && onUpload(id, file);

  const handleDelete = (id: string) => () => onDelete && onDelete(id);

  const handleDescriptionChanged = (id: string) => (description: string) =>
    onDescriptionChanged && onDescriptionChanged(id, description);

  return (
    <table className="w-full table-fixed">
      <colgroup>
        <col className="w-6/12" />
        <col className="w-4/12" />
        <col className="w-2/12" />
      </colgroup>
      <thead>
        <tr>
          <th>{t('components.attachment.description')}</th>
          <th>{t('components.attachment.fileName')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {attachments
          .sort((a, b) => sort(a.description, b.description))
          .map(attachment => (
            <AttachmentRow
              key={attachment.id}
              attachment={attachment}
              editable={editable}
              onUploadFile={handleUpload(attachment.id)}
              onDelete={handleDelete(attachment.id)}
              onDescriptionChanged={handleDescriptionChanged(attachment.id)}
            />
          ))}
      </tbody>
    </table>
  );
};
