import LinkCard from 'components/base/LinkCard';
import config from 'config/config';
import { VisibleButton } from 'components/base/IconButton';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { Icon } from 'components/base/Icon';
import { VacationOfferForIndex } from 'gql/generated/types-and-hooks';

const VacationOfferEntry = ({
  offer,
}: {
  offer: VacationOfferForIndex;
}): ReactElement | null => {
  const { isAdmin } = useContext(UserInfoContext);

  if (offer.activated || isAdmin) {
    return (
      <div className="VacationOfferEntry">
        <LinkCard
          to={config.localRoutes.vacationOffers.edit.replace(':id', offer.id)}
        >
          <div className="flex flex-wrap items-center justify-between gap-2">
            <div className="p-2">
              <Icon icon={'ChevronDownIcon'} />
            </div>
            <div className="flex-1">{offer.house?.name || '---'}</div>
            <div className="flex-1">{offer.name}</div>
            <div className="flex-1">{offer.house?.city || '---'}</div>
            <div className="flex-1">{offer.house?.region || '---'}</div>
            <div>{isAdmin && <VisibleButton visible={offer.activated} />}</div>
          </div>
        </LinkCard>
      </div>
    );
  } else {
    return null;
  }
};

export default VacationOfferEntry;
