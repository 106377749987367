import {
  EditableInput,
  EditableTextArea,
} from 'components/applicationForm/editableInputs';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import { AvailableCharacters } from 'components/base/AvailableCharacters';
import {
  FormGrid,
  FormKey,
  FormValue,
  FormControl,
} from 'components/base/FormLayout';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  NonEditableText,
  NonEditableTextArea,
} from 'components/base/NonEditableText';
import {
  FreeTextId,
  refetchEmailTemplates_GetRejectionTemplateQuery,
  useEmailTemplates_GetRejectionTemplateQuery,
  useEmailTemplates_UpdateFreeTextMutation,
} from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AVAILABLE_TEXT_AREA_CHARACTERS,
  AVAILABLE_TEXT_INPUT_CHARACTERS,
} from './common';

export const RejectionTemplate = (): ReactElement => {
  const { t } = useTranslation();

  const [updateEmailFreetext] = useEmailTemplates_UpdateFreeTextMutation({
    refetchQueries: [refetchEmailTemplates_GetRejectionTemplateQuery()],
    context: {
      debounceKey: 'useEmailTemplates_UpdateFreeTextMutation',
      notification: {
        success: t(
          'components.settings.email.templates.notifications.update.success',
        ),
        error: t(
          'components.settings.email.templates.notifications.update.error',
        ),
      },
    },
  });
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);

  const { loading, error, data } =
    useEmailTemplates_GetRejectionTemplateQuery();

  const {
    freeText = '',
    salutation = '',
    signature = { __typename: 'Signature', managementBoard: '', text: '' },
    subject = '',
    url = '',
  } = data?.rejectionEmailTemplate || {};

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors: validationErrors },
    watch,
  } = useForm({
    defaultValues: {
      subject,
      freeText,
    },
  });

  const { freeText: currentFreeText, subject: currentSubject } = watch();

  useEffect(() => {
    reset({ freeText, subject });
  }, [data, freeText, reset, subject]);

  const onCancel = useCallback(() => {
    leaveEditMode();
    reset({ subject, freeText });
  }, [freeText, leaveEditMode, reset, subject]);

  const onConfirm = handleSubmit(async ({ freeText, subject }) => {
    await updateEmailFreetext({
      onCompleted: () => leaveEditMode(),
      variables: {
        freeTexts: [
          {
            text: freeText,
            id: FreeTextId.RejectionFreetext,
          },
          {
            id: FreeTextId.RejectionSubject,
            text: subject,
          },
        ],
      },
    });
  });

  return (
    <div className="RejectionTemplate">
      <LoadingOrError {...{ loading, error }}>
        <FormGrid>
          <FormKey
            label={t('components.settings.email.templates.subject') + ':'}
          >
            <FormValue>
              <ValidationFeedback error={validationErrors.subject}>
                <EditableInput
                  editMode={editMode}
                  nonEditableValue={subject}
                  maxLength={AVAILABLE_TEXT_INPUT_CHARACTERS}
                  {...register('subject', {
                    validate: v =>
                      !isEmpty(v) ||
                      (t(
                        'components.settings.email.templates.subjectValidationHint',
                      ) as string),
                  })}
                />
                <AvailableCharacters
                  current={currentSubject.length}
                  maxLength={AVAILABLE_TEXT_INPUT_CHARACTERS}
                  visible={editMode}
                />
              </ValidationFeedback>
            </FormValue>
          </FormKey>
          <FormControl>
            <EditControls
              editMode={editMode}
              onEdit={enterEditMode}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          </FormControl>
          <FormKey
            label={t('components.settings.email.templates.content') + ':'}
          >
            <FormValue>
              <NonEditableText>{salutation}</NonEditableText>
            </FormValue>
            <FormValue>
              <EditableTextArea
                editMode={editMode}
                nonEditableValue={freeText}
                maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                {...register('freeText')}
              />
              <AvailableCharacters
                current={currentFreeText.length}
                maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                visible={editMode}
              />
            </FormValue>
            <FormValue>
              <NonEditableText>{url}</NonEditableText>
            </FormValue>
            <FormValue>
              <NonEditableTextArea>{signature.text}</NonEditableTextArea>
            </FormValue>
            <FormValue>
              <NonEditableTextArea>
                {signature.managementBoard}
              </NonEditableTextArea>
            </FormValue>
          </FormKey>
        </FormGrid>
      </LoadingOrError>
    </div>
  );
};
