import { UserRole } from 'context/UserInfoContext';
import {
  AccountingAreasNotAllowedForEonActive,
  TaccountingAreas,
} from 'interfaces/accountingAreas';
import { isEmpty } from './util';

export function isEonActiveAllowed({
  isAdmin,
  accountingArea,
}: {
  isAdmin: boolean;
  accountingArea: TaccountingAreas;
}): boolean {
  if (isAdmin || isEmpty(accountingArea)) return true;
  return !AccountingAreasNotAllowedForEonActive.includes(accountingArea);
}

export function hasRole(
  currentRoles: UserRole[],
  expectedRole: UserRole,
): boolean {
  if (currentRoles.includes('ADMIN')) return true;
  return currentRoles.includes(expectedRole);
}
