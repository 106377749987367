import PersonalDataInput from 'components/applicationForm/sections/PersonalDataInput';
import OwnerInput from 'components/applicationForm/sections/OwnerInput';
import TravelPartnersInput from 'components/applicationForm/sections/TravelPartnerInput';
import HawCatalogue from 'components/applicationForm/sections/HawCatalogue';
import PrivacyWarning from 'components/applicationForm/sections/PrivacyWarning';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { deepCopy, isEmpty } from 'helpers/util';
import EonCatalogue from './sections/EonCatalogue';
import { getProcessingStatusToDisplay } from 'interfaces/ProcessingStatus';
import PointsTable from './sections/PointsTable';
import FormSection from './FormSection';
import AssignedBookingEntry from './sections/AssignedBookingEntry';
import {
  applicantAccountingArea,
  applicantIsNotInAccountingArea,
  getAssignedBooking,
  getAssignedOffer,
  getHawCatalogue,
  hasBookings,
} from 'helpers/applicationHelper';
import { AccountingAreasNotAllowedForEonActive } from 'interfaces/accountingAreas';
import {
  ActionButtonsForStateAsAdmin,
  ActionButtonsForStateAsUser,
} from './actionButtons';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import {
  ApplicationInput,
  EditMyApplications_GetApplicationQuery,
  ProcessingStatus,
  useApplicationForm_GetVactionOffersHousesQuery,
} from 'gql/generated/types-and-hooks';
import { mapApplicationToApi } from 'api/mappers';
import { ReactElement } from 'react';
import { getFormattedDate } from 'helpers/dateHelper';
import LoadingOrError from 'components/base/LoadingOrError';

export const ApplicationForm = ({
  initialData,
  adminView,
  onSave,
  onDelete,
  onSubmit,
}: {
  initialData?: EditMyApplications_GetApplicationQuery['application'];
  adminView: boolean;
  onSave: (application: ApplicationInput, sendEmail?: boolean) => void;
  onSubmit: (application: ApplicationInput) => void;
  onDelete?: () => void;
}): ReactElement => {
  const { t } = useTranslation();

  const { loading, error, data } =
    useApplicationForm_GetVactionOffersHousesQuery();

  const useFormReturn = useForm<
    EditMyApplications_GetApplicationQuery['application'] & {
      nbrOfHawBookings: number;
      nbrOfEonBookings: number;
    }
  >({
    mode: 'onTouched',
    defaultValues: deepCopy(initialData),
  });

  if (initialData == null)
    return <div>{t('components.form.applicationForm.noData')}</div>;

  const {
    formState: { errors, isValid },
    trigger,
    setError,
    setValue,
    watch,
  } = useFormReturn;

  const application: EditMyApplications_GetApplicationQuery['application'] =
    watch();

  const isApplicationFinished =
    application.processingStatus === ProcessingStatus.Finished ||
    application.processingStatus === ProcessingStatus.FinishedCancelled ||
    application.processingStatus ===
      ProcessingStatus.FinishedRejectedConfirmed ||
    application.processingStatus ===
      ProcessingStatus.FinishedCancelledConfirmed;

  const isApplicationCancelled =
    application.processingStatus === ProcessingStatus.Cancelled ||
    application.processingStatus === ProcessingStatus.CancelledConfirmed;

  const isEditable = adminView
    ? application.processingStatus !== ProcessingStatus.Rejected &&
      application.processingStatus !== ProcessingStatus.RejectedConfirmed &&
      application.processingStatus !== ProcessingStatus.Cancelled &&
      isApplicationFinished === false
    : application.processingStatus === ProcessingStatus.Created ||
      application.processingStatus === ProcessingStatus.SentBack;

  const handleSave = async (sendEmail?: boolean) => {
    // Allow to save application without bookings
    const isValidFromTrigger = await trigger();

    if (
      application.processingStatus !== ProcessingStatus.SentBack &&
      application.processingStatus !== ProcessingStatus.Created &&
      !hasBookings(application)
    ) {
      setError('nbrOfHawBookings', {
        message: t(
          'components.form.applicationForm.errorNoHawBookingsSelected',
        ),
      });
      setError('nbrOfEonBookings', {
        message: t(
          'components.form.applicationForm.errorNoEonBookingsSelected',
        ),
      });
    }

    const validForSaving =
      isValidFromTrigger && Object.keys(errors).length === 0;

    if (validForSaving) {
      onSave(mapApplicationToApi(application), sendEmail);
    } else {
      trigger();
    }
  };
  const handleSubmit = async () => {
    const hasBooking = hasBookings(application);
    if (!hasBooking) {
      setError('nbrOfHawBookings', {
        message: t(
          'components.form.applicationForm.errorNoHawBookingsSelected',
        ),
      });
      setError('nbrOfEonBookings', {
        message: t(
          'components.form.applicationForm.errorNoEonBookingsSelected',
        ),
      });
    }
    const isValidFromTrigger = await trigger();
    const validForSubmit = hasBooking && isValidFromTrigger;
    if (validForSubmit) {
      onSubmit(mapApplicationToApi(application));
    }
  };

  const canBookEonActive =
    !isEmpty(applicantAccountingArea(application)) &&
    applicantIsNotInAccountingArea(
      application,
      AccountingAreasNotAllowedForEonActive,
    );

  if (
    !canBookEonActive &&
    application.eonBookings &&
    application.eonBookings.length > 0
  ) {
    setValue('eonBookings', []);
  }

  const assignedBooking = getAssignedBooking(application);

  return (
    <div className="ApplicationForm">
      <LoadingOrError
        loading={loading}
        error={error}
        data={data}
        render={data => {
          const catalogue = getHawCatalogue(data.houses, data.vacationOffers);

          const eonHouses = data.eonHouses;

          const assignedOffer:
            | {
                name: string;
                house: { name: string };
              }
            | undefined = getAssignedOffer(
            assignedBooking,
            data.eonHouses,
            catalogue,
          );

          return (
            <>
              <div className="flex w-full justify-between px-2 pb-2">
                <div>
                  {application.createdAt
                    ? `${t(
                        'components.form.applicationForm.applicationFrom',
                      )}: ${getFormattedDate(
                        new Date(application.createdAt),
                        dateMonthYearFormat,
                      )}`
                    : ''}
                </div>
                <div>
                  {`${t('components.form.applicationForm.state')}: ${t(
                    `pages.myApplications.processingStatus.${getProcessingStatusToDisplay(
                      {
                        processingStatus: application.processingStatus,
                        isAdmin: adminView,
                      },
                    )}`,
                  )}`}
                </div>
              </div>
              {isEditable && (
                <div className="px-2 pb-2 text-sm text-gray-500">
                  {t('components.form.applicationForm.hintRequiredFields')}
                </div>
              )}
              <FormProvider {...useFormReturn}>
                <OwnerInput editMode={isEditable} />
                <PersonalDataInput editMode={isEditable} />
                <TravelPartnersInput editMode={isEditable} />
                {(adminView ||
                  application.processingStatus === ProcessingStatus.Confirmed ||
                  isApplicationCancelled ||
                  isApplicationFinished) &&
                assignedBooking &&
                assignedOffer ? (
                  <AssignedBookingEntry
                    isEditable={isEditable}
                    isEonBooking={assignedBooking.isEon}
                    application={application}
                    assignedBooking={assignedBooking}
                    assignedOffer={assignedOffer}
                    onChangeBookingOffer={bookingChanges => {
                      setValue('bookingChanges', {
                        ...bookingChanges,
                        bookingDate: {
                          arrival:
                            bookingChanges.bookingDate.arrival.toISOString(),
                          departure:
                            bookingChanges.bookingDate.departure.toISOString(),
                        },
                      });
                    }}
                    onChangePrices={priceChanges =>
                      setValue('priceChanges', priceChanges)
                    }
                  />
                ) : (
                  <>
                    <HawCatalogue catalogue={catalogue} editMode={isEditable} />
                    {canBookEonActive && (
                      <EonCatalogue
                        houses={eonHouses || []}
                        editMode={isEditable}
                      />
                    )}
                  </>
                )}
              </FormProvider>

              {(adminView &&
                application.processingStatus !== ProcessingStatus.Created) ||
              application.processingStatus === ProcessingStatus.Confirmed ||
              isApplicationFinished ? (
                <FormSection
                  name={t('components.form.applicationForm.sectionTitlePoints')}
                >
                  <PointsTable application={application} />
                </FormSection>
              ) : null}

              <PrivacyWarning />
              <div className="flex flex-col items-end pt-4">
                {Object.keys(errors).length > 0 && !isValid ? (
                  <div className="mb-2 font-semibold text-red-700">
                    {t('components.form.applicationForm.checkInputs')}
                  </div>
                ) : null}
                {adminView ? (
                  <ActionButtonsForStateAsAdmin
                    state={application.processingStatus}
                    {...{ handleSave, isApplicationFinished, handleSubmit }}
                  />
                ) : (
                  <ActionButtonsForStateAsUser
                    state={application.processingStatus}
                    {...{
                      handleSave,
                      handleSubmit,
                      onDelete,
                      isApplicationFinished,
                    }}
                  />
                )}
              </div>
            </>
          );
        }}
      ></LoadingOrError>
    </div>
  );
};
