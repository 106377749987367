import { createContext, FunctionComponent, useContext } from 'react';
import { Notification, NotificationContent } from 'components/Notification';
import { useState } from 'react';

type NotificationContextType = {
  show: (notification: NotificationContent) => void;
  notification?: NotificationContent;
};

const NotificationContext = createContext<NotificationContextType>({
  notification: undefined,
  show: () => null,
});

export const NotificationProvider: FunctionComponent = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationContent[]>([]);

  return (
    <NotificationContext.Provider
      value={{
        show: (notification: NotificationContent) => {
          setNotifications(cur => {
            return [...cur, notification];
          });
        },
      }}
    >
      <>
        {children}
        <div className="fixed top-16 flex w-full flex-col items-center gap-2">
          <Notification
            notification={
              notifications.length > 0 ? notifications[0] : undefined
            }
            onDisappear={() => {
              setNotifications(cur => {
                return cur.slice(1);
              });
            }}
          />
        </div>
      </>
    </NotificationContext.Provider>
  );
};

export const useShowNotification = (): NotificationContextType['show'] => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useShowNotification must be used within a NotificationProvider',
    );
  }
  return context.show;
};
