import { intersection, isEmpty, unique } from 'helpers/util';
import {
  ApplicationFor,
  CancellationInput,
  ConfirmBookingInput,
  ProcessingStatus,
  useActionHandler_AssignBookingMutation,
  useActionHandler_CancelApplicationMutation,
  useActionHandler_UpdateApplicationStatusMutation,
  useActionHandler_FinishApplicationMutation,
} from 'gql/generated/types-and-hooks';
import {
  ApplicationActions,
  BookingAndApplication,
  ValidStatusActions,
} from './interfaces';
import { useTranslation } from 'react-i18next';

export const useCreateAssign = (
  items: ConfirmBookingInput[],
): (() => Promise<void>) => {
  const { t } = useTranslation();
  const [assignBooking] = useActionHandler_AssignBookingMutation();

  return async () => {
    await Promise.all(
      items.map((item, index) => {
        const isLast = index === items.length - 1;
        return assignBooking({
          variables: { assignBookingInput: item },
          context: {
            notification: isLast
              ? {
                  success: t(
                    'components.applications.notifications.assign.success',
                  ),
                  error: t(
                    'components.applications.notifications.assign.error',
                  ),
                }
              : undefined,
          },
        });
      }),
    );
  };
};

export const useCreateUpdateApplicationStatus = (
  items: BookingAndApplication[],
  to: ProcessingStatus,
): (() => Promise<void>) => {
  const { t } = useTranslation();
  const [updateApplicationStatus] =
    useActionHandler_UpdateApplicationStatusMutation();

  return async () => {
    await Promise.all(
      items.map(({ applicationId }, i) => {
        const isLast = i === items.length - 1;
        return updateApplicationStatus({
          variables: {
            applicationID: applicationId,
            applicationStatus: {
              processingStatus: to,
            },
          },
          context: {
            notification: isLast
              ? {
                  success: t(
                    'components.applications.notifications.updateStatus.success',
                  ),
                  error: t(
                    'components.applications.notifications.updateStatus.error',
                  ),
                }
              : undefined,
          },
        });
      }),
    );
  };
};

export const useFinishApplication = (
  items: BookingAndApplication[],
): (() => Promise<void>) => {
  const { t } = useTranslation();
  const [finish] = useActionHandler_FinishApplicationMutation();

  return async () => {
    await Promise.all(
      items.map(({ applicationId }, i) => {
        const isLast = i === items.length - 1;
        return finish({
          variables: {
            applicationID: applicationId,
          },
          context: {
            notification: isLast
              ? {
                  success: t(
                    'components.applications.notifications.updateStatus.success',
                  ),
                  error: t(
                    'components.applications.notifications.updateStatus.error',
                  ),
                }
              : undefined,
          },
        });
      }),
    );
  };
};

export const useCreateMailTo = (
  items: BookingAndApplication[],
): (() => Promise<void>) => {
  const mailAddresses = items
    .map(
      ({
        applicationApplicationFor,
        applicationApplicantEmail,
        applicationGuestEmail,
      }) => {
        return applicationApplicationFor === ApplicationFor.Me
          ? applicationApplicantEmail
          : applicationGuestEmail;
      },
    )
    .filter<string>((mail): mail is string => isEmpty(mail) === false);
  const uniqueAddreses = unique(mailAddresses);
  const link = document.createElement('a');
  link.href = `mailto:${uniqueAddreses.join(';')}`;
  return async () => {
    link.click();
    link.remove();
  };
};

export const useCreateCancelApplication = (
  items: BookingAndApplication[],
): ((input: CancellationInput) => Promise<void>) => {
  const { t } = useTranslation();
  const [cancelApplication] = useActionHandler_CancelApplicationMutation();

  return async (cancelInput: CancellationInput) => {
    await Promise.all(
      items.map(({ applicationId }, i) => {
        const isLast = i === items.length - 1;
        return cancelApplication({
          variables: {
            applicationID: applicationId,
            cancelInput,
          },
          context: {
            notification: isLast
              ? {
                  success: t(
                    'components.applications.notifications.cancel.success',
                  ),
                  error: t(
                    'components.applications.notifications.cancel.error',
                  ),
                }
              : undefined,
          },
        });
      }),
    );
  };
};

export const isActionAllowed = (
  allowedActions: ApplicationActions[],
  action: ApplicationActions,
): boolean => allowedActions.includes(action);

export const collectAllowedActions = (
  processingStatusList: ProcessingStatus[],
): ApplicationActions[] => {
  const possibleSuccessiveStates = processingStatusList.map(processingStatus =>
    getValidActions(processingStatus),
  );
  return intersection(possibleSuccessiveStates);
};

const getValidActions = (current: ProcessingStatus): ApplicationActions[] =>
  VALID_STATUS_ACTIONS[current] || [];

const VALID_STATUS_ACTIONS: ValidStatusActions = {
  CREATED: [],
  IN_REVIEW: ['Assigned', 'Cancelled', 'Rejected', 'SentBack'],
  SUBMITTED: ['SentBack', 'Cancelled', 'InReview'],
  ASSIGNED: ['Cancelled', 'Confirmed', 'InReview'],
  CONFIRMED: ['Cancelled', 'Finished'],
  REJECTED: ['InReview', 'RejectedConfirmed'],
  REJECTED_CONFIRMED: ['Finished'],
  SENT_BACK: [],
  CANCELLED: ['Finished'],
  CANCELLED_CONFIRMED: ['Finished'],
  FINISHED_CANCELLED: [],
  FINISHED_CANCELLED_CONFIRMED: [],
  FINISHED_REJECTED_CONFIRMED: [],
  FINISHED: [],
};
