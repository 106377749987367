import { currencyNumberFormat } from '../../helpers/util';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { ReactElement } from 'react';

const getCellStyle = (disabled: boolean) =>
  `text-right ${disabled ? 'bg-gray-300' : ''}`;

const InputCell = ({
  value,
  disabled,
  className = '',
  edit = false,
  max = undefined,
  onChange,
}: {
  value: number;
  disabled: boolean;
  className?: string;
  edit: boolean;
  max?: number;
  onChange: (value: number) => void;
}): ReactElement => {
  const handleChange = (newValue: number) => {
    onChange(newValue);
  };

  const withValueLimit = ({ floatValue }: NumberFormatValues) =>
    max == null || floatValue == null ? true : floatValue <= max;

  return edit ? (
    <td className={`${getCellStyle(disabled)} ${className}`}>
      <NumberFormat
        className={`w-full rounded-md border border-grey bg-white px-1 py-0.5 text-right text-sm outline-none focus-within:ring-1`}
        value={value}
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        isAllowed={withValueLimit}
        suffix=" €"
        fixedDecimalScale={true}
        allowEmptyFormatting={true}
        defaultValue="0"
        decimalScale={2}
        onValueChange={values => {
          if (values.floatValue != undefined) {
            handleChange(values.floatValue);
          }
        }}
      />
    </td>
  ) : (
    <td className={getCellStyle(disabled)}>
      {`${currencyNumberFormat.format(value)}`}
    </td>
  );
};

export default InputCell;
