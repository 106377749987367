import { useKeyForm } from 'context/KeyFormContext';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../base/ConfirmationDialog';
import {
  refetchVacationOfferForm_GetVacationOfferQuery,
  useHouseChoiceModal_GetHawHousesQuery,
  useHouseChoiceModal_UpdateVacationOfferMutation,
} from 'gql/generated/types-and-hooks';
import LoadingOrError from 'components/base/LoadingOrError';

const HouseChoiceModal = ({
  offer,
  open,
  onClose,
}: {
  offer: {
    id: string;
    name: string;
    service: string;
    activated: boolean;
    house: {
      id: string;
    };
  };
  open: boolean;
  onClose: () => void;
}): ReactElement => {
  const { t } = useTranslation();
  const [selectedHouse, setSelectedHouse] = useState<string>(
    offer.house.id || '',
  );
  const { clearKey } = useKeyForm();
  const { loading, error, data } = useHouseChoiceModal_GetHawHousesQuery();
  const houses = data?.houses ? [...data.houses] : [];

  const closeModal = () => {
    clearKey();
    onClose();
  };
  const [updateVacationOffer] = useHouseChoiceModal_UpdateVacationOfferMutation(
    {
      onCompleted: () => closeModal(),
      refetchQueries: [
        refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
      ],
      context: {
        debounceKey: 'useHouseChoiceModal_UpdateVacationOfferMutation',
        notification: {
          success: t(
            'components.VacationOffers.HouseChoiceModal.notifications.update.success',
          ),
          error: t(
            'components.VacationOffers.HouseChoiceModal.notifications.update.error',
          ),
        },
      },
    },
  );

  useEffect(() => {
    setSelectedHouse(offer.house.id || '');
  }, [offer.house.id]);

  const handleSelectHouse = e => setSelectedHouse(e.target.value);

  const handleConfirm = () => {
    updateVacationOffer({
      variables: {
        id: offer.id,
        offer: {
          activated: offer.activated,
          name: offer.name,
          service: offer.service,
          houseId: selectedHouse,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      title={t('components.VacationOffers.HouseChoiceModal.title')}
      confirmText={t('components.VacationOffers.HouseChoiceModal.confirm')}
      cancelText={t('components.VacationOffers.HouseChoiceModal.cancel')}
      onConfirm={handleConfirm}
      onCancel={closeModal}
      open={open}
    >
      <div className="mb-4">
        {t('components.VacationOffers.HouseChoiceModal.message')}
      </div>
      <LoadingOrError loading={loading} error={error}>
        <select
          className="form-select block w-full rounded-md border border-grey bg-white outline-none focus-within:ring-2 focus-within:ring-primary"
          value={selectedHouse}
          onChange={handleSelectHouse}
        >
          {houses.map(houseOption => (
            <option key={houseOption.id} value={houseOption.id}>
              {houseOption.name}
            </option>
          ))}
        </select>
      </LoadingOrError>
    </ConfirmationDialog>
  );
};

export default HouseChoiceModal;
