import AttachmentDescriptionCell from './AttachmentDescriptionCell';
import AuthLink from './AuthLink';
import AttachmentActions from './AttachmentActions';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

const AttachmentRow = ({
  attachment,
  editable,
  onUploadFile,
  onDelete,
  onDescriptionChanged,
}: {
  attachment: {
    path: string;
    name: string;
    description: string;
  };
  editable: boolean;
  onUploadFile?: (file: File) => void;
  onDelete?: () => void;
  onDescriptionChanged?: (newDescription: string) => void;
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <tr className="AttachmentRow">
      <td className="w-6/12" style={{ overflowWrap: 'anywhere' }}>
        <AttachmentDescriptionCell
          file={attachment}
          editable={editable}
          onDescriptionChanged={onDescriptionChanged}
        />
      </td>
      <td className="w-4/12">
        {attachment.path ? (
          <AuthLink attachment={attachment} />
        ) : (
          <div style={{ color: 'red' }}>
            {t('components.attachment.notFound')}
          </div>
        )}
      </td>
      <td className="w-2/12 text-right">
        {editable && onUploadFile && onDelete ? (
          <AttachmentActions onDelete={onDelete} onUpload={onUploadFile} />
        ) : null}
      </td>
    </tr>
  );
};

export default AttachmentRow;
