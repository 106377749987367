import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from 'application';
import './i18n';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionRequiredAuthError,
  SilentRequest,
  EndSessionRequest,
} from '@azure/msal-browser';
import { loginRequest, msalConfig } from 'authConfig';
import { reactPlugin } from 'AppInsights';
import { useTranslation } from 'react-i18next';
import LoadingOrError from 'components/base/LoadingOrError';
import { PublicClientApplicationMock } from 'helpers/authMock';

const msalInstance =
  process.env['REACT_APP_SKIP_AUTH'] === 'true'
    ? new PublicClientApplicationMock()
    : new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  const preferredAccount =
    accounts.find(a => {
      const preferredUsername = a.idTokenClaims
        ? a.idTokenClaims['preferred_username']
        : undefined;
      if (preferredUsername) {
        return preferredUsername.includes('@eon.com');
      }
      return false;
    }) || accounts[0];
  msalInstance.setActiveAccount(preferredAccount);
}
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const request: SilentRequest = {
  ...loginRequest,
};
export async function acquireToken(): Promise<
  AuthenticationResult | undefined
> {
  try {
    return await msalInstance.acquireTokenSilent(request);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      await msalInstance.acquireTokenRedirect(request);
    } else {
      console.error(error);
      throw error;
    }
  }
  return undefined;
}

export async function logOut(logoutRequest: EndSessionRequest): Promise<void> {
  await msalInstance.logoutRedirect(logoutRequest);
}

const Loading = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className="loading-icon"></div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    {/* "React suspense" in translation is set "true" so this component waits for the translation file before it can be rendered */}
    <Suspense fallback={<Loading />}>
      <AppInsightsErrorBoundary
        onError={() => (
          <LoadingOrError
            error={true}
            loading={false}
            fullscreen={true}
            customErrorMessage={useTranslation().t('pages.main.error')}
          />
        )}
        appInsights={reactPlugin}
      >
        <Application pca={msalInstance} />
      </AppInsightsErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
