import HouseEntry from 'components/Houses/HouseEntry';
import Intro from 'components/Eon/Intro';
import HouseForm from 'components/Houses/HouseForm';
import { useParams } from 'react-router-dom';
import { getString } from 'helpers/util';
import LoadingOrError from 'components/base/LoadingOrError';
import { ReactElement } from 'react';
import { useEditEon_GetListQuery } from 'gql/generated/types-and-hooks';

type EditEonParams = {
  id: string;
};

const EditEon = (): ReactElement => {
  const { data, loading, error } = useEditEon_GetListQuery();
  const { id } = useParams<EditEonParams>();
  const houses = data?.eonHouses ? [...data.eonHouses] : [];

  return (
    <div className="EditEon">
      <LoadingOrError loading={loading} error={error}>
        <Intro />

        {houses.map(house =>
          id === house.id ? (
            <HouseForm key={house.id} houseId={house.id} />
          ) : (
            <HouseEntry
              key={house.id}
              house={{
                ...house,
                activated: house.activated ? true : false,
                isEonHome: house.isEonHome ? true : false,
                city: getString(house.city),
                region: getString(house.region),
              }}
            />
          ),
        )}
      </LoadingOrError>
    </div>
  );
};

export default EditEon;
