import {
  ConfirmBookingInput,
  BookingType,
} from 'gql/generated/types-and-hooks';
import { BookingAndApplication, SelectedItem } from './interfaces';

export const isHawBooking = (booking: BookingAndApplication): boolean => {
  return booking.bookingtype === BookingType.HawF;
};

export const pickSelectedOffers = (
  items: BookingAndApplication[] = [],
  selection: SelectedItem[] = [],
): ConfirmBookingInput[] =>
  selection.reduce((result, { id }) => {
    const booking = items.find(({ bookingId }) => bookingId === id);
    if (booking) {
      return [
        ...result,
        {
          id,
          bookingType: booking.bookingtype,
        },
      ] as ConfirmBookingInput[];
    }
    return result;
  }, [] as ConfirmBookingInput[]);

export const pickSelectedItems = (
  items: BookingAndApplication[] = [],
  selection: SelectedItem[] = [],
): BookingAndApplication[] =>
  items.filter(i => !!selection.find(si => si.id === i.bookingId));
