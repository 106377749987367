import { useTranslation } from 'react-i18next';
import { currencyNumberFormat } from 'helpers/util';
import InputCell from 'components/tables/InputCell';
import TextInput from '../TextInput';
import {
  PartnerStatus,
  PositionType,
  PriceChanges,
} from 'gql/generated/types-and-hooks';
import { Charges } from 'moin-ferienwerk-shared/dist/types/priceCalculation';
import {
  calculateCancellationCosts,
  calculatePrice,
} from 'moin-ferienwerk-shared';
import { PartnerStatus as PartnerStatusShared } from 'moin-ferienwerk-shared/dist/types/priceCalculation';
import { getPositionType } from 'helpers/priceCalculation';
import { ReactElement } from 'react';

const getPriceOrDefault = (price: number) =>
  isNaN(price) ? '---' : `${currencyNumberFormat.format(price)}`;

export const PriceTableEditable = ({
  isEditable,
  position,
  markBOnPassport,
  calculateVacationPackage,
  timespan,
  charges,
  travelers,
  changes,
  wasConfirmedAndIsCanceled,
  cancellationDate,
  isEonBooking,
  onChangesChanged,
}: {
  isEditable: boolean;
  position: PositionType;
  markBOnPassport: boolean;
  calculateVacationPackage: boolean;
  timespan: {
    arrival: Date;
    departure: Date;
  };
  charges?: Charges;
  travelers: {
    minorChildren: { dateOfBirth: number }[];
    otherMinors: { dateOfBirth: number }[];
    relationshipStatus: PartnerStatus;
  };
  changes?: PriceChanges;
  wasConfirmedAndIsCanceled: boolean;
  cancellationDate?: Date;
  isEonBooking: boolean;
  onChangesChanged: (changes: PriceChanges) => void;
}): ReactElement => {
  const { t } = useTranslation();

  if (changes == null) {
    return <div>Changes missing</div>;
  }
  if (charges == null) {
    return <div>Charges missing</div>;
  }

  const updateChange = (value: PriceChanges) => {
    onChangesChanged(value);
  };

  const result = calculatePrice({
    calculateVacationPackage: calculateVacationPackage,
    position: getPositionType(position),
    markBOnPassport: markBOnPassport,
    timespan: {
      arrival: timespan.arrival,
      departure: timespan.departure,
    },
    travelers: {
      ...travelers,
      relationshipStatus:
        travelers.relationshipStatus === PartnerStatus.Married
          ? PartnerStatusShared.MARRIED
          : PartnerStatusShared.WITHOUT,
    },
    charges: charges,
  });
  const costs = result;

  const subsistenceExpenses_extraCosts =
    costs.subsistenceExpenses + (costs.extraCosts ? costs.extraCosts : 0);

  let cancellationCosts = 0;

  if (isEonBooking === false && wasConfirmedAndIsCanceled && cancellationDate) {
    cancellationCosts = calculateCancellationCosts({
      arrivalDate: timespan.arrival,
      cancellationDate: cancellationDate,
      subsistenceExpenses:
        subsistenceExpenses_extraCosts +
        changes.subsistence.increment -
        changes.subsistence.decrement,
    });
  }

  const cellStyleRight = `text-right`;
  return (
    <div className="PriceTableEditable grid grid-cols-12">
      <table className="col-span-12 col-start-1">
        <colgroup>
          <col style={{ width: '12%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>{t('components.form.priceTableEditable.costOverview')}</th>
            <th />
            <th className={cellStyleRight}>
              {t('components.form.priceTableEditable.calculated')}
            </th>
            <th className={cellStyleRight}>
              {t('components.form.priceTableEditable.supplements')}
            </th>
            <th className={cellStyleRight}>
              {t('components.form.priceTableEditable.reductions')}
            </th>
            <th className={cellStyleRight}>
              {t('components.form.priceTableEditable.calculatedNew')}
            </th>
            <th>{t('components.form.priceTableEditable.remark')}</th>
          </tr>
        </thead>
        <tbody>
          {wasConfirmedAndIsCanceled ? (
            <tr key="cancellationCosts">
              <td>
                {t('components.form.priceTableEditable.cancellationCosts')}
              </td>
              <td>{t('components.form.priceTableEditable.netDeduction')}</td>
              <td className={cellStyleRight}>
                {getPriceOrDefault(cancellationCosts)}
              </td>
              <InputCell
                edit={isEditable}
                value={changes.cancellationCosts.increment}
                disabled={false}
                onChange={value => {
                  updateChange({
                    ...changes,
                    cancellationCosts: {
                      ...changes.cancellationCosts,
                      increment: value,
                    },
                  });
                }}
              />
              <InputCell
                edit={isEditable}
                value={changes.cancellationCosts.decrement}
                disabled={false}
                max={cancellationCosts}
                onChange={value => {
                  updateChange({
                    ...changes,
                    cancellationCosts: {
                      ...changes.cancellationCosts,
                      decrement: value,
                    },
                  });
                }}
              />
              <td className={cellStyleRight}>
                {getPriceOrDefault(
                  cancellationCosts +
                    changes.cancellationCosts.increment -
                    changes.cancellationCosts.decrement,
                )}
              </td>
              <td>
                {isEditable ? (
                  <TextInput
                    value={changes.cancellationCosts.remark}
                    onChange={e => {
                      updateChange({
                        ...changes,
                        cancellationCosts: {
                          ...changes.cancellationCosts,
                          remark: e.currentTarget.value,
                        },
                      });
                    }}
                  />
                ) : (
                  changes.cancellationCosts.remark
                )}
              </td>
            </tr>
          ) : (
            [
              <tr key="subsistenceExpenses">
                <td>
                  {t('components.form.priceTableEditable.subsistenceExpenses')}
                </td>
                <td>{t('components.form.priceTableEditable.netDeduction')}</td>
                <td className={cellStyleRight}>
                  {getPriceOrDefault(subsistenceExpenses_extraCosts)}
                </td>
                <InputCell
                  edit={isEditable}
                  value={changes.subsistence.increment}
                  disabled={false}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      subsistence: {
                        ...changes.subsistence,
                        increment: value,
                      },
                    });
                  }}
                />
                <InputCell
                  edit={isEditable}
                  value={changes.subsistence.decrement}
                  disabled={false}
                  max={subsistenceExpenses_extraCosts}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      subsistence: {
                        ...changes.subsistence,
                        decrement: value,
                      },
                    });
                  }}
                />
                <td className={cellStyleRight}>
                  {getPriceOrDefault(
                    subsistenceExpenses_extraCosts +
                      changes.subsistence.increment -
                      changes.subsistence.decrement,
                  )}
                </td>
                <td>
                  {isEditable ? (
                    <TextInput
                      value={changes.subsistence.remark}
                      onChange={e => {
                        updateChange({
                          ...changes,
                          subsistence: {
                            ...changes.subsistence,
                            remark: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    changes.subsistence.remark
                  )}
                </td>
              </tr>,
              <tr key="tax" className="bg-gray-100">
                <td>{t('components.form.priceTableEditable.tax')}</td>
                <td>{t('components.form.priceTableEditable.netDeduction')}</td>
                <td className={cellStyleRight}>
                  {getPriceOrDefault(costs.tax)}
                </td>
                <InputCell
                  edit={isEditable}
                  value={changes.tax.increment}
                  disabled={false}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      tax: {
                        ...changes.tax,
                        increment: value,
                      },
                    });
                  }}
                />
                <InputCell
                  edit={isEditable}
                  value={changes.tax.decrement}
                  disabled={false}
                  max={costs.tax}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      tax: {
                        ...changes.tax,
                        decrement: value,
                      },
                    });
                  }}
                />
                <td className={cellStyleRight}>
                  {getPriceOrDefault(
                    costs.tax + changes.tax.increment - changes.tax.decrement,
                  )}
                </td>
                <td>
                  {isEditable ? (
                    <TextInput
                      value={changes.tax.remark}
                      onChange={e => {
                        updateChange({
                          ...changes,
                          tax: {
                            ...changes.tax,
                            remark: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    changes.tax.remark
                  )}
                </td>
              </tr>,
              <tr key="boardOnSite">
                <td>{t('components.form.priceTableEditable.boardOnSite')}</td>
                <td>{t('components.form.priceTableEditable.netDeduction')}</td>
                <td />
                <InputCell
                  edit={isEditable}
                  value={changes.board.increment}
                  disabled={false}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      board: {
                        ...changes.board,
                        increment: value,
                      },
                    });
                  }}
                />
                <InputCell
                  edit={isEditable}
                  value={changes.board.decrement}
                  disabled={false}
                  max={changes.board.increment}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      board: {
                        ...changes.board,
                        decrement: value,
                      },
                    });
                  }}
                />
                <td className={cellStyleRight}>
                  {getPriceOrDefault(
                    changes.board.increment - changes.board.decrement,
                  )}
                </td>
                <td>
                  {isEditable ? (
                    <TextInput
                      value={changes.board.remark}
                      onChange={e => {
                        updateChange({
                          ...changes,
                          board: {
                            ...changes.board,
                            remark: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    changes.board.remark
                  )}
                </td>
              </tr>,
              <tr key="pecuniaryAdvantage" className="bg-gray-100">
                <td>
                  {t('components.form.priceTableEditable.pecuniaryAdvantage')}
                </td>
                <td>{t('components.form.priceTableEditable.taxSocialTax')}</td>
                <td className={cellStyleRight}>
                  {getPriceOrDefault(costs.pecuniaryAdvantage)}
                </td>
                <InputCell
                  edit={isEditable}
                  value={changes.pecuniaryAdvantage.increment}
                  disabled={false}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      pecuniaryAdvantage: {
                        ...changes.pecuniaryAdvantage,
                        increment: value,
                      },
                    });
                  }}
                />
                <InputCell
                  edit={isEditable}
                  value={changes.pecuniaryAdvantage.decrement}
                  disabled={false}
                  max={costs.pecuniaryAdvantage}
                  onChange={value => {
                    updateChange({
                      ...changes,
                      pecuniaryAdvantage: {
                        ...changes.pecuniaryAdvantage,
                        decrement: value,
                      },
                    });
                  }}
                />
                <td className={cellStyleRight}>
                  {getPriceOrDefault(
                    costs.pecuniaryAdvantage +
                      changes.pecuniaryAdvantage.increment -
                      changes.pecuniaryAdvantage.decrement,
                  )}
                </td>
                <td>
                  {isEditable ? (
                    <TextInput
                      value={changes.pecuniaryAdvantage.remark}
                      onChange={e => {
                        updateChange({
                          ...changes,
                          pecuniaryAdvantage: {
                            ...changes.pecuniaryAdvantage,
                            remark: e.currentTarget.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    changes.pecuniaryAdvantage.remark
                  )}
                </td>
              </tr>,
            ]
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PriceTableEditable;
