import TextInput from 'components/applicationForm/TextInput';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import Button from 'components/base/Button';
import { DeleteButton } from 'components/base/IconButton';
import LoadingOrError from 'components/base/LoadingOrError';
import NoData from 'components/base/NoData';
import {
  refetchRecipient_GetRecipientsQuery,
  useRecipients_AddRecipientMutation,
  useRecipients_DeleteRecipientMutation,
  useRecipient_GetRecipientsQuery,
} from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';

export function EmailRecipients(): ReactElement {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newRecipient: '',
    },
  });

  const [createEmailRecipient] = useRecipients_AddRecipientMutation({
    refetchQueries: [refetchRecipient_GetRecipientsQuery()],
    context: {
      debounceKey: 'useRecipients_AddRecipientMutation',
      notification: {
        success: t(
          'components.settings.email.recipients.notifications.add.success',
        ),
        error: t(
          'components.settings.email.recipients.notifications.add.error',
        ),
      },
    },
  });

  const [deleteEmailRecipient] = useRecipients_DeleteRecipientMutation({
    refetchQueries: [refetchRecipient_GetRecipientsQuery()],
    context: {
      debounceKey: 'useRecipients_DeleteRecipientMutation',
      notification: {
        success: t(
          'components.settings.email.recipients.notifications.delete.success',
        ),
        error: t(
          'components.settings.email.recipients.notifications.delete.error',
        ),
      },
    },
  });

  const onConfirm = handleSubmit(({ newRecipient }) => {
    createEmailRecipient({
      variables: { emailRecipient: { email: newRecipient } },
      onCompleted: () => {
        reset();
      },
    });
  });

  const onDelete = async (email: string) => {
    await deleteEmailRecipient({
      variables: { emailRecipient: { email } },
    });
  };

  const validationHint = t(
    'components.settings.email.recipients.validationHint',
  );
  const { data, error, loading } = useRecipient_GetRecipientsQuery();
  const recipients = data?.emailRecipients.map(r => r.email) || [];
  return (
    <>
      <div className="mb-4 flex items-baseline gap-x-2">
        <div className="w-80">
          <ValidationFeedback error={errors.newRecipient}>
            <TextInput
              {...register('newRecipient', {
                validate: v => isEmpty(v) || isEmail(v) || validationHint,
              })}
            />
          </ValidationFeedback>
        </div>
        <Button label="Hinzufügen" onClick={onConfirm} />
      </div>
      <LoadingOrError loading={loading} error={error}>
        <RecipientsTable recipients={recipients} onDelete={onDelete} />
      </LoadingOrError>
    </>
  );
}

type RecipientsTableProps = {
  recipients: string[];
  onDelete: (email: string) => Promise<void>;
};
const RecipientsTable = ({ recipients, onDelete }: RecipientsTableProps) => {
  const { t } = useTranslation();

  return !recipients.length ? (
    <NoData label={t('components.settings.email.recipients.noData')} />
  ) : (
    <table className="w-full table-fixed">
      <thead>
        <tr>
          <th>{t('components.settings.email.recipients.thAddress')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {recipients.map(email => (
          <tr key={email}>
            <td>{email}</td>
            <td className="flex justify-end">
              <DeleteButton onClick={() => onDelete(email)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
