import { PropsWithChildren, ReactElement } from 'react';

const FormSection = ({
  children,
  name,
}: PropsWithChildren<{ name: string }>): ReactElement => (
  <div className="FormSection">
    <div className="header mb-4 border bg-gray-100 p-4 text-primary">
      {name}
    </div>
    <div className="mb-4 px-20">{children}</div>
  </div>
);

export default FormSection;
