import LinkButton from 'components/base/LinkButton';
import { isEmpty } from 'helpers/util';
import { useTranslation } from 'react-i18next';
import config from '../../config/config';
import LinkCard from 'components/base/LinkCard';
import { getProcessingStatusToDisplay } from 'interfaces/ProcessingStatus';
import LoadingOrError from 'components/base/LoadingOrError';
import { Icon } from 'components/base/Icon';
import {
  IndexMyApplications_GetMyApplicationsQuery,
  ProcessingStatus,
  useIndexMyApplications_GetMyApplicationsQuery,
} from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { useKID } from 'context/UserInfoContext';

const CLOSED_STATES = [
  ProcessingStatus.Finished,
  ProcessingStatus.FinishedCancelled,
  ProcessingStatus.FinishedRejectedConfirmed,
  ProcessingStatus.FinishedCancelledConfirmed,
];

const ApplicationEntry = ({
  data,
}: {
  data: IndexMyApplications_GetMyApplicationsQuery['myApplications']['items'][0];
}) => {
  const { t } = useTranslation();

  return (
    <div className="ApplicationEntry">
      <LinkCard
        to={config.localRoutes.myApplications.edit.replace(':id', data.id)}
      >
        <div className="flex flex-nowrap items-center justify-between gap-2">
          <Icon icon={'ChevronDownIcon'} className="mr-2" />
          <div className="w-1/5">{new Date(data.createdAt).getFullYear()}</div>
          <div className="flex-grow">
            {isEmpty(data.applicant.name)
              ? data.applicantKID
              : data.applicant.name}
          </div>
          <div className="p-2 text-right">
            {t(
              `pages.myApplications.processingStatus.${getProcessingStatusToDisplay(
                {
                  processingStatus: data.processingStatus,
                  isAdmin: false,
                },
              )}`,
            )}
          </div>
        </div>
      </LinkCard>
    </div>
  );
};

export const IndexMyApplications = (): ReactElement => {
  const kid = useKID();
  const { data, error, loading } =
    useIndexMyApplications_GetMyApplicationsQuery({
      variables: {
        myApplicationsApplicantKid: kid,
      },
    });
  const { t } = useTranslation();
  const myApplications = data?.myApplications.items || [];

  const openApplications = myApplications
    .filter(
      application =>
        CLOSED_STATES.includes(application.processingStatus) === false,
    )
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  const closedApplications = myApplications
    .filter(application => CLOSED_STATES.includes(application.processingStatus))
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return (
    <div className="IndexMyApplications">
      <LoadingOrError loading={loading} error={error}>
        <>
          <div className="mb-8 flex items-center justify-between">
            <p>
              {openApplications.length > 0
                ? t('pages.myApplications.desc')
                : ''}
            </p>
            <LinkButton to={config.localRoutes.myApplications.new}>
              {t('pages.myApplications.button.newApplication')}
            </LinkButton>
          </div>
          <div>
            {openApplications.length > 0 ? (
              openApplications.map(item => (
                <ApplicationEntry key={item.id} data={item} />
              ))
            ) : (
              <div className="w-full text-center text-gray-500">
                {t('pages.myApplications.noOpenApplications')}
              </div>
            )}
          </div>
          {closedApplications.length > 0 ? (
            <>
              <hr className="mb-8 mt-16" />
              <p>{`${t('pages.myApplications.pastApplications')}:`}</p>
              {closedApplications.map(item => (
                <ApplicationEntry key={item.id} data={item} />
              ))}
            </>
          ) : null}
        </>
      </LoadingOrError>
    </div>
  );
};
