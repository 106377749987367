import Button from 'components/base/Button';
import {
  ChevronLeftButton,
  ChevronRightButton,
} from 'components/base/IconButton';
import LoadingOrError from 'components/base/LoadingOrError';
import { useBoardBilling_Filter_GetDataQuery } from 'gql/generated/types-and-hooks';
import { sequence, unique } from 'helpers/util';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type BoardBillingFilter = {
  house?: string;
  offer?: string;
  month?: number;
  year?: number;
};

const SHOW_ALL = 'SHOW_ALL';

export const defaultFilter: BoardBillingFilter = {};

const createYearFilterOptions = () => {
  const currentYear = new Date().getFullYear();
  return sequence(currentYear - 4, currentYear + 2, 1);
};

type FilterProps = {
  onSetFilter: (filter: BoardBillingFilter) => void;
  filter: BoardBillingFilter;
};

export const Filter = ({ onSetFilter, filter }: FilterProps): ReactElement => {
  const { t } = useTranslation();
  const [isCollpased, setIsCollpased] = useState(false);
  const { data, loading, error } = useBoardBilling_Filter_GetDataQuery();

  const houses = useMemo(() => {
    return unique(
      data?.houses?.filter(house => !!house.name).map(x => x.name) ?? [],
    );
  }, [data?.houses]);

  const vacationOffers = useMemo(() => {
    return unique(
      data?.vacationOffers.filter(offer => !!offer.name).map(x => x.name) ?? [],
    );
  }, [data?.vacationOffers]);

  const handleChange = useCallback(
    ([k, v]: [string, number | string | undefined]) => {
      onSetFilter({
        ...filter,
        [k]: v === SHOW_ALL ? undefined : v,
      });
    },
    [onSetFilter, filter],
  );

  const resetFilter = useCallback(() => {
    onSetFilter(defaultFilter);
  }, [onSetFilter]);

  return (
    <div className="FilterAndExport mr-4 h-full bg-gray-200">
      {isCollpased ? (
        <div className="my-4 flex h-full w-10 flex-col items-center">
          <ChevronRightButton
            onClick={() => setIsCollpased(prev => !prev)}
            disabled={false}
          />
          <div className="w-1 break-all">
            {t('components.applications.filterAndExport.title')}
          </div>
        </div>
      ) : (
        <div className="m-4 w-72">
          <div className="mb-2 flex flex-row items-center justify-center border-b border-grey pb-2 text-lg">
            <div className="absolute left-4">
              <ChevronLeftButton
                onClick={() => setIsCollpased(prev => !prev)}
                disabled={false}
              />
            </div>
            {t('components.applications.filterAndExport.title')}
          </div>
          <div className="FilterEntries">
            <LoadingOrError error={error} loading={loading}>
              <div>{t('components.applications.filterAndExport.house')}</div>
              <select
                className="mb-2 w-full"
                name="house"
                onChange={e => handleChange([e.target.name, e.target.value])}
                value={filter.house || SHOW_ALL}
              >
                <option value={SHOW_ALL}>
                  {t('components.applications.filterAndExport.showAll')}
                </option>
                {houses.map(name => (
                  <option key={name} value={name}>{`${name}`}</option>
                ))}
              </select>
              <div>{t('components.applications.filterAndExport.offer')}</div>
              <select
                className="mb-2 w-full"
                name="offer"
                onChange={e => handleChange([e.target.name, e.target.value])}
                value={filter.offer || SHOW_ALL}
              >
                <option value={SHOW_ALL}>
                  {t('components.applications.filterAndExport.showAll')}
                </option>
                {vacationOffers.map(name => (
                  <option key={name} value={name ?? ''}>{`${
                    name ?? ''
                  }`}</option>
                ))}
              </select>

              <div>{t('components.applications.filterAndExport.year')}</div>
              <select
                className="mb-2 w-full"
                name="year"
                onChange={e =>
                  handleChange([
                    e.target.name,
                    e.target.value !== SHOW_ALL
                      ? parseInt(e.target.value)
                      : SHOW_ALL,
                  ])
                }
                value={filter.year == undefined ? SHOW_ALL : filter.year}
              >
                <option value={SHOW_ALL}>
                  {t('components.applications.filterAndExport.showAll')}
                </option>
                {createYearFilterOptions().map(y => (
                  <option key={y} value={y}>{`${y}`}</option>
                ))}
              </select>
              <div className="mt-2">
                {t('components.applications.filterAndExport.month')}
              </div>
              <select
                className="mb-2 w-full"
                name="month"
                onChange={e =>
                  handleChange([
                    e.target.name,
                    e.target.value !== SHOW_ALL
                      ? parseInt(e.target.value)
                      : SHOW_ALL,
                  ])
                }
                value={filter.month === undefined ? SHOW_ALL : filter.month}
              >
                <option value={SHOW_ALL}>
                  {t('components.applications.filterAndExport.showAll')}
                </option>
                {sequence(1, 12, 1).map(month => (
                  <option key={month} value={month}>
                    {t(
                      `components.applications.filterAndExport.months.${month}`,
                    )}
                  </option>
                ))}
              </select>

              <div className="flex w-full justify-center">
                <Button
                  onClick={resetFilter}
                  disabled={Object.keys(filter).every(
                    k => filter[k] === defaultFilter[k],
                  )}
                  label={t(
                    'components.applications.filterAndExport.resetFilter',
                  )}
                  styleOptions={{
                    wFull: true,
                  }}
                />
              </div>
            </LoadingOrError>
          </div>
        </div>
      )}
    </div>
  );
};
