import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import config from 'config/config';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  ApplicationInput,
  EditMyApplications_DeleteApplicationMutation,
  EditMyApplications_UpdateApplicationMutation,
  ProcessingStatus,
  useEditMyApplications_DeleteApplicationMutation,
  useEditMyApplications_GetApplicationQuery,
  useEditMyApplications_UpdateApplicationMutation,
} from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { ApplicationForm } from 'components/applicationForm/ApplicationForm';
import { useTranslation } from 'react-i18next';

const useAdminView = (id: string) => {
  const { pathname } = useLocation();
  return (
    pathname === config.localRoutes.employeeApplications.edit.replace(':id', id)
  );
};

export const EditMyApplications = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const adminView = useAdminView(id);
  const { data, error, loading } = useEditMyApplications_GetApplicationQuery({
    variables: { id },
  });
  const history = useHistory();
  const onCompleted = (
    data:
      | EditMyApplications_DeleteApplicationMutation
      | EditMyApplications_UpdateApplicationMutation,
  ) => {
    if (data) {
      if (adminView) {
        window.close();
      } else {
        history.goBack();
      }
    }
  };
  const [deleteApplication, deleteResult] =
    useEditMyApplications_DeleteApplicationMutation({
      onCompleted,
      onError: () => {
        deleteResult.reset();
      },
      context: {
        debounceKey: 'useEditMyApplications_DeleteApplicationMutation',
        notification: {
          success: t('pages.myApplications.notifications.delete.success'),
          error: t('pages.myApplications.notifications.delete.error'),
        },
      },
    });
  const [updateApplication, updateResult] =
    useEditMyApplications_UpdateApplicationMutation({
      onCompleted,
      onError: () => {
        updateResult.reset();
      },
      context: {
        debounceKey: 'useEditMyApplications_UpdateApplicationMutation',
        notification: {
          success: t('pages.myApplications.notifications.update.success'),
          error: t('pages.myApplications.notifications.update.error'),
        },
      },
    });

  const handleSubmit = (data: ApplicationInput) => {
    if (updateResult.called === false) {
      updateApplication({
        variables: {
          applicationID: id,
          updateApplication: {
            ...data,
            processingStatus: ProcessingStatus.Submitted,
          },
        },
      });
    }
  };
  const handleSave = (data: ApplicationInput, sendEmail?: boolean) => {
    if (updateResult.called === false) {
      updateApplication({
        variables: { applicationID: id, updateApplication: data, sendEmail },
      });
    }
  };

  const handleDelete = () => {
    if (deleteResult.called === false) {
      deleteApplication({ variables: { id } });
    }
  };

  return (
    <div className="EditMyApplication">
      <LoadingOrError
        loading={loading}
        error={error}
        data={data?.application}
        render={application => (
          <ApplicationForm
            adminView={adminView}
            initialData={application}
            onSave={handleSave}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        )}
      ></LoadingOrError>
    </div>
  );
};
