import LinkButton from 'components/base/LinkButton';
import config from 'config/config';
import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import FreePlaceListUrl from './FreePlaceListUrl';

const Intro = ({
  showButton = true,
}: {
  showButton?: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);

  return (
    <div className="Intro">
      <div className="mb-8 flex items-center justify-between">
        <div className="w-2/5">
          <FreePlaceListUrl />
        </div>
        {showButton && isAdmin && (
          <span className="grid grid-cols-2 gap-x-2">
            <LinkButton inverted={true} to={config.localRoutes.eon.import}>
              {t('pages.houses.button.importOffers')}
            </LinkButton>
            <LinkButton to={config.localRoutes.eon.new}>
              {t('pages.houses.button.newHouse')}
            </LinkButton>
          </span>
        )}
      </div>
      <div className="mb-8 flex items-center justify-between">
        <p>{t('pages.houses.desc')}</p>
      </div>
    </div>
  );
};
export default Intro;
