import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import isPostalCode from 'validator/lib/isPostalCode';
import isURL from 'validator/lib/isURL';
import {
  EditableInput,
  EditableCheckbox,
  EditableTextArea,
} from 'components/applicationForm/editableInputs';
import { deepCopy, isEmpty } from 'helpers/util';
import { UserInfoContext } from '../../context/UserInfoContext';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import {
  refetchHouseForm_GetHouseQuery,
  useDescription_UpdateHouseMutation,
} from 'gql/generated/types-and-hooks';

const Description = ({
  house,
}: {
  house: {
    id: string;
    name: string;
    type: string;
    region: string;
    streetAndHouseNo: string;
    postalCode: string;
    city: string;
    telephone: string;
    email: string;
    board: string;
    facilities: string;
    additionalEquipment: string;
    dogAllowed: boolean;
    furtherInformation: string;
    isEonHome: boolean;
  };
}): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);
  const [updateHouse] = useDescription_UpdateHouseMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: house.id })],
    onCompleted: () => {
      leaveEditMode();
    },
    context: {
      debounceKey: 'useDescription_UpdateHouseMutation',
      notification: {
        success: t('components.description.notifications.success'),
        error: t('components.description.notifications.error'),
      },
    },
  });
  const {
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...deepCopy(house),
    },
  });

  const handleCancel = () => {
    reset({ ...deepCopy(house) });
    leaveEditMode();
  };

  return (
    <div className="Description grid grid-cols-2 gap-x-8">
      <div
        className="grid"
        style={{
          gridTemplateRows: 'repeat(2, min-content)',
        }}
      >
        <div className="grid grid-cols-3 content-start gap-y-0.5">
          <div className="col-span-3 mb-2 font-medium">
            {t('components.description.group.category')}
          </div>

          <div>{t('components.description.key.type')}:</div>
          <div className="col-span-2">
            <EditableInput
              editMode={editMode}
              nonEditableValue={house.type}
              {...register('type')}
            />
          </div>

          <div>{t('components.description.key.vacationArea')}:</div>
          <div className="col-span-2">
            <EditableInput
              editMode={editMode}
              nonEditableValue={house.region}
              {...register('region')}
            />
          </div>
        </div>

        <div className="mt-4 grid grid-cols-3 content-start gap-y-0.5">
          <div className="col-span-3 mb-2 font-medium">
            {t('components.description.group.addressContact')}
          </div>

          <div>{t('components.description.key.street')}:</div>
          <div className="col-span-2">
            <EditableInput
              nonEditableValue={house.streetAndHouseNo}
              editMode={editMode}
              {...register('streetAndHouseNo')}
            />
          </div>

          <div>{t('components.description.key.postalCode')}:</div>
          <div className="col-span-2">
            <ValidationFeedback error={errors.postalCode}>
              <EditableInput
                nonEditableValue={house.postalCode}
                editMode={editMode}
                {...register('postalCode', {
                  validate: v =>
                    isEmpty(v) ||
                    isPostalCode(v as string, 'DE') ||
                    (t('pages.elements.formInputValidator.zip') as string),
                })}
              />
            </ValidationFeedback>
          </div>

          <div>{t('components.description.key.location')}:</div>
          <div className="col-span-2">
            <EditableInput
              nonEditableValue={house.city}
              editMode={editMode}
              {...register('city')}
            />
          </div>

          <div>{t('components.description.key.phone')}:</div>
          <div className="col-span-2">
            <ValidationFeedback error={errors.telephone}>
              <EditableInput
                nonEditableValue={house.telephone}
                editMode={editMode}
                {...register('telephone', {
                  validate: v =>
                    isEmpty(v) ||
                    isNumeric(v as string) ||
                    (t(
                      'pages.elements.formInputValidator.numberOnly',
                    ) as string),
                })}
              />
            </ValidationFeedback>
          </div>

          <div>{t('components.description.key.email')}:</div>
          <div className="col-span-2">
            <ValidationFeedback error={errors.email}>
              <EditableInput
                nonEditableValue={house.email}
                editMode={editMode}
                {...register('email', {
                  validate: v =>
                    isEmpty(v) ||
                    isEmail(v as string) ||
                    (t(
                      'pages.elements.formInputValidator.emailOnly',
                    ) as string),
                })}
              />
            </ValidationFeedback>
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-3 content-start gap-y-0.5">
          <div className="col-span-3 mb-2 font-medium">
            {t('components.description.group.equipment')}
          </div>

          <div>{t('components.description.key.board')}:</div>
          <div className="col-span-2 break-words">
            <EditableTextArea
              editMode={editMode}
              nonEditableValue={house.board}
              {...register('board')}
            />
          </div>

          <div>{t('components.description.key.standard')}:</div>
          <div className="col-span-2 break-words">
            <EditableTextArea
              editMode={editMode}
              nonEditableValue={house.facilities}
              {...register('facilities')}
            />
          </div>

          <div>{t('components.description.key.optional')}:</div>
          <div className="col-span-2 break-words">
            <EditableTextArea
              editMode={editMode}
              nonEditableValue={house.additionalEquipment}
              {...register('additionalEquipment')}
            />
          </div>

          <div>{t('components.description.key.dogAllowed')}:</div>
          <div className="col-span-2">
            <EditableCheckbox
              editMode={editMode}
              {...register('dogAllowed')}
              nonEditableValue={house.dogAllowed}
            />
          </div>

          {house.isEonHome && (
            <>
              <div>{t('components.description.key.additionalInfo')}:</div>
              <div className="col-span-2">
                <ValidationFeedback error={errors.furtherInformation}>
                  <EditableInput
                    nonEditableValue={house.furtherInformation}
                    type="url"
                    editMode={editMode}
                    {...register('furtherInformation', {
                      validate: v =>
                        isEmpty(v) ||
                        isURL(v as string) ||
                        (t('pages.elements.formInputValidator.url') as string),
                    })}
                  />
                </ValidationFeedback>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-span-2 mt-2">
        <div className="float-right">
          {isAdmin && (
            <EditControls
              editMode={editMode}
              onEdit={enterEditMode}
              onCancel={handleCancel}
              onConfirm={handleSubmit(() => {
                const values = getValues();
                const newHouse = {
                  ...house,
                  ...values,
                  name: house.name,
                  id: undefined,
                  files: undefined,
                  charges: undefined,
                  eonOffers: undefined,
                };
                updateHouse({
                  variables: { id: house.id, house: newHouse },
                });
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Description;
