import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {onError: () => {}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: string;
};

export type Accommodation = {
  __typename?: 'Accommodation';
  adult: Scalars['Float'];
  childOld: Scalars['Float'];
  childYoung: Scalars['Float'];
  executive: Scalars['Float'];
  externalAdult: Scalars['Float'];
  externalChild: Scalars['Float'];
  retiree: Scalars['Float'];
};

export type AccommodationInput = {
  adult: Scalars['Float'];
  childOld: Scalars['Float'];
  childYoung: Scalars['Float'];
  executive: Scalars['Float'];
  externalAdult: Scalars['Float'];
  externalChild: Scalars['Float'];
  retiree: Scalars['Float'];
};

export enum ActionType {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Delete = 'DELETE',
  Finished = 'FINISHED',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  RejectedConfirmed = 'REJECTED_CONFIRMED',
  SentBack = 'SENT_BACK',
  Submitted = 'SUBMITTED',
  Update = 'UPDATE',
}

export type Applicant = {
  __typename?: 'Applicant';
  accountingArea?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  staffNumber?: Maybe<Scalars['String']>;
};

export type ApplicantInput = {
  accountingArea?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  staffNumber?: InputMaybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  allergies?: Maybe<Scalars['String']>;
  applicant: Applicant;
  applicantKID: Scalars['String'];
  applicationFor: ApplicationFor;
  applicationHistory: Array<ApplicationHistory>;
  applicationType: ApplicationType;
  bookedOfferName: Scalars['String'];
  bookingChanges?: Maybe<BookingChanges>;
  cancellationDate?: Maybe<Scalars['DateTimeISO']>;
  city: Scalars['String'];
  comment: Scalars['String'];
  companyPosition: Position;
  createdAt: Scalars['DateTimeISO'];
  disability: Disability;
  dog: Scalars['Boolean'];
  eonBookings: Array<EonBooking>;
  fixedCharges?: Maybe<FixedCharges>;
  furtherGuests: FurtherGuests;
  guest?: Maybe<Guest>;
  hawBookings: Array<HawBooking>;
  houseNumber: Scalars['String'];
  id: Scalars['ID'];
  partner: Partner;
  passedApplications: PassedApplications;
  phone: Scalars['String'];
  priceChanges?: Maybe<PriceChanges>;
  processingStatus: ProcessingStatus;
  singleParent: Scalars['Boolean'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type ApplicationCount = {
  __typename?: 'ApplicationCount';
  numberOfApplications: Scalars['Float'];
};

export enum ApplicationFor {
  Me = 'ME',
  OtherPerson = 'OTHER_PERSON',
}

export type ApplicationHistory = {
  __typename?: 'ApplicationHistory';
  actionType: ActionType;
  applicantKID: Scalars['String'];
  applicationId: Scalars['ID'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['Float'];
};

export type ApplicationInput = {
  allergies?: InputMaybe<Scalars['String']>;
  applicant: ApplicantInput;
  applicantKID: Scalars['String'];
  applicationFor: ApplicationFor;
  applicationType: ApplicationType;
  bookingChanges?: InputMaybe<BookingChangesInput>;
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  companyPosition: PositionInput;
  disability: DisabilityInput;
  dog: Scalars['Boolean'];
  eonBookings?: InputMaybe<Array<EonBookingInput>>;
  furtherGuests: FurtherGuestsInput;
  guest?: InputMaybe<GuestInput>;
  hawBookings?: InputMaybe<Array<HawBookingInput>>;
  houseNumber?: InputMaybe<Scalars['String']>;
  partner: PartnerInput;
  phone?: InputMaybe<Scalars['String']>;
  priceChanges?: InputMaybe<PriceChangesInput>;
  processingStatus?: InputMaybe<ProcessingStatus>;
  singleParent: Scalars['Boolean'];
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ApplicationStatusInput = {
  processingStatus: ProcessingStatus;
};

export enum ApplicationType {
  HealthDays = 'HEALTH_DAYS',
  VacationOffer = 'VACATION_OFFER',
}

export type BinaryUpdateInput = {
  content: Scalars['String'];
  name: Scalars['String'];
  type: FileType;
};

export type BoardBilling = {
  __typename?: 'BoardBilling';
  applicationId: Scalars['String'];
  arrival: Scalars['DateTimeISO'];
  departure: Scalars['DateTimeISO'];
  employeeName: Scalars['String'];
  houseName: Scalars['String'];
  priceChange: PriceChange;
  vacationOfferName: Scalars['String'];
};

export enum BoardBillingSortableColumn {
  Employee = 'EMPLOYEE',
  HouseName = 'HOUSE_NAME',
  OfferName = 'OFFER_NAME',
  Period = 'PERIOD',
}

export enum BoardType {
  FullBoard = 'FULL_BOARD',
  HalfBoard = 'HALF_BOARD',
  OvernightBreakfast = 'OVERNIGHT_BREAKFAST',
  SelfSupply = 'SELF_SUPPLY',
}

export type BookingAndApplication = {
  __typename?: 'BookingAndApplication';
  applicationApplicantEmail: Scalars['String'];
  applicationApplicantName: Scalars['String'];
  applicationApplicantStaffNumber: Scalars['String'];
  applicationApplicationFor: ApplicationFor;
  applicationBookingChangesArrival?: Maybe<Scalars['DateTimeISO']>;
  applicationBookingChangesDeparture?: Maybe<Scalars['DateTimeISO']>;
  applicationCreatedAt: Scalars['DateTimeISO'];
  applicationGuestEmail?: Maybe<Scalars['String']>;
  applicationGuestName?: Maybe<Scalars['String']>;
  applicationGuestStaffNumber?: Maybe<Scalars['String']>;
  applicationId: Scalars['ID'];
  applicationPartnerStaffNumber?: Maybe<Scalars['String']>;
  applicationProcessingStatus: ProcessingStatus;
  board: Scalars['String'];
  bookingArrival: Scalars['DateTimeISO'];
  bookingChoiceId: Scalars['ID'];
  bookingDeparture: Scalars['DateTimeISO'];
  bookingId: Scalars['ID'];
  bookingPriority: Scalars['Float'];
  bookingStatus: BookingConfirmationStatus;
  bookingtype: BookingType;
  creditPoints: Scalars['Float'];
  houseChargeId: Scalars['ID'];
  houseId: Scalars['ID'];
  houseName: Scalars['String'];
  houseType: Scalars['String'];
  vacationOfferChargeId?: Maybe<Scalars['ID']>;
  vacationOfferName?: Maybe<Scalars['String']>;
};

export type BookingChanges = {
  __typename?: 'BookingChanges';
  bookingDate: BookingDate;
  eonBookingId: Scalars['String'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export type BookingChangesInput = {
  bookingDate: BookingDateUpdateInput;
  eonBookingId: Scalars['String'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export enum BookingConfirmationStatus {
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type BookingDate = {
  __typename?: 'BookingDate';
  arrival?: Maybe<Scalars['DateTimeISO']>;
  departure?: Maybe<Scalars['DateTimeISO']>;
};

export type BookingDateInput = {
  arrival: Scalars['DateTimeISO'];
  departure: Scalars['DateTimeISO'];
};

export type BookingDateUpdateInput = {
  arrival?: InputMaybe<Scalars['DateTimeISO']>;
  departure?: InputMaybe<Scalars['DateTimeISO']>;
};

export type BookingOffer = {
  __typename?: 'BookingOffer';
  active: Scalars['Boolean'];
  bookingDate: BookingDate;
  countervailingBenefit?: Maybe<CountervailingBenefit>;
  createdAt: Scalars['DateTimeISO'];
  hawPrimaryBookings?: Maybe<Array<HawBooking>>;
  id: Scalars['ID'];
  isBooked: Scalars['Boolean'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
  vacationOfferId?: Maybe<Scalars['ID']>;
};

export type BookingOfferInput = {
  active: Scalars['Boolean'];
  bookingDate: BookingDateInput;
  countervailingBenefit: CountervailingBenefitInput;
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export type BookingOfferUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bookingDate?: InputMaybe<BookingDateUpdateInput>;
  countervailingBenefit?: InputMaybe<CountervailingBenefitInput>;
  socialCreditPoints?: InputMaybe<Scalars['Boolean']>;
  summerHolidays?: InputMaybe<Scalars['Boolean']>;
};

export enum BookingType {
  Eon = 'EON',
  HawF = 'HAW_F',
  HawG = 'HAW_G',
}

export type CancellationInput = {
  cancellationDate: Scalars['DateTimeISO'];
  withCosts: Scalars['Boolean'];
};

export type ConfirmBookingInput = {
  bookingType: BookingType;
  id: Scalars['ID'];
};

export type ConfirmationEmailTemplate = {
  __typename?: 'ConfirmationEmailTemplate';
  additionalFreetext: Scalars['String'];
  bookingDetails: Scalars['String'];
  freeText: Scalars['String'];
  salutation: Scalars['String'];
  signature: Signature;
  subject: Scalars['String'];
  url: Scalars['String'];
};

export type CountervailingBenefit = {
  __typename?: 'CountervailingBenefit';
  childOld: Scalars['Float'];
  childYoung: Scalars['Float'];
  firstAdult: Scalars['Float'];
  perNight: Scalars['Float'];
  secondAdult: Scalars['Float'];
};

export type CountervailingBenefitInput = {
  childOld: Scalars['Float'];
  childYoung: Scalars['Float'];
  firstAdult: Scalars['Float'];
  perNight: Scalars['Float'];
  secondAdult: Scalars['Float'];
};

export type DateOfBirth = {
  __typename?: 'DateOfBirth';
  dateOfBirth: Scalars['Float'];
};

export type DateOfBirthInput = {
  dateOfBirth: Scalars['Float'];
};

export type Disability = {
  __typename?: 'Disability';
  degree?: Maybe<Scalars['Int']>;
  markBOnPassport: Scalars['Boolean'];
  present: Scalars['Boolean'];
  walkingDisability: Scalars['Boolean'];
};

export type DisabilityInput = {
  degree?: InputMaybe<Scalars['Int']>;
  markBOnPassport: Scalars['Boolean'];
  present: Scalars['Boolean'];
  walkingDisability: Scalars['Boolean'];
};

export type EmailRecipientInput = {
  email: Scalars['String'];
};

export enum EmploymentType {
  FixedTerm = 'FIXED_TERM',
  Permanent = 'PERMANENT',
}

export type EonBooking = {
  __typename?: 'EonBooking';
  applicationId: Scalars['ID'];
  board?: Maybe<BoardType>;
  bookingChoice?: Maybe<EonOffer>;
  bookingChoiceId?: Maybe<Scalars['ID']>;
  bookingStatus: BookingConfirmationStatus;
  createdAt: Scalars['DateTimeISO'];
  creditPoints: Scalars['Float'];
  house?: Maybe<House>;
  houseId: Scalars['ID'];
  id: Scalars['ID'];
  /** @deprecated use bookingChoice */
  period?: Maybe<BookingDate>;
  priority: Scalars['Int'];
};

export type EonBookingInput = {
  board?: InputMaybe<BoardType>;
  bookingChoiceId?: InputMaybe<Scalars['ID']>;
  houseId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  priority: Scalars['Int'];
};

export type EonOffer = {
  __typename?: 'EonOffer';
  active: Scalars['Boolean'];
  bookingDate: BookingDate;
  countervailingBenefit?: Maybe<CountervailingBenefit>;
  createdAt: Scalars['DateTimeISO'];
  eonBookingId: Scalars['String'];
  eonPrimaryBookings?: Maybe<Array<EonBooking>>;
  houseId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isBooked: Scalars['Boolean'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export type EonOfferInput = {
  active: Scalars['Boolean'];
  bookingDate: BookingDateInput;
  countervailingBenefit: CountervailingBenefitInput;
  eonBookingId?: Scalars['String'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export type EonOfferUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  bookingDate?: InputMaybe<BookingDateUpdateInput>;
  countervailingBenefit?: InputMaybe<CountervailingBenefitInput>;
  eonBookingId?: InputMaybe<Scalars['String']>;
  socialCreditPoints?: InputMaybe<Scalars['Boolean']>;
  summerHolidays?: InputMaybe<Scalars['Boolean']>;
};

export type EonOfferWithHouseIdInput = {
  active: Scalars['Boolean'];
  bookingDate: BookingDateInput;
  countervailingBenefit: CountervailingBenefitInput;
  eonBookingId?: Scalars['String'];
  houseId: Scalars['String'];
  socialCreditPoints: Scalars['Boolean'];
  summerHolidays: Scalars['Boolean'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  type: FileType;
};

export type FileInput = {
  content: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  type: FileType;
};

export enum FileType {
  Attachment = 'ATTACHMENT',
  Photo = 'PHOTO',
}

export type FileUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type FixedCharge = {
  __typename?: 'FixedCharge';
  accommodation: Accommodation;
  activated: Scalars['Boolean'];
  countervailingBenefit: CountervailingBenefit;
  furtherPosition: FurtherPosition;
};

export type FixedCharges = {
  __typename?: 'FixedCharges';
  house: FixedHouseCharge;
  vacationPackage?: Maybe<FixedVacationOfferCharge>;
};

export type FixedHouseCharge = {
  __typename?: 'FixedHouseCharge';
  fullBoard: FixedCharge;
  halfBoard: FixedCharge;
  overnightBreakfast: FixedCharge;
  selfSupply: FixedCharge;
};

export type FixedVacationOfferCharge = {
  __typename?: 'FixedVacationOfferCharge';
  fullBoard: Accommodation;
  halfBoard: Accommodation;
  overnightBreakfast: Accommodation;
  selfSupply: Accommodation;
};

export type FreeText = {
  __typename?: 'FreeText';
  id: Scalars['String'];
  text: Scalars['String'];
};

export enum FreeTextId {
  ConfirmationEonAdditionalFreetext = 'CONFIRMATION_EON_ADDITIONAL_FREETEXT',
  ConfirmationEonFreetext = 'CONFIRMATION_EON_FREETEXT',
  ConfirmationEonSubject = 'CONFIRMATION_EON_SUBJECT',
  ConfirmationHawAdditionalFreetext = 'CONFIRMATION_HAW_ADDITIONAL_FREETEXT',
  ConfirmationHawFreetext = 'CONFIRMATION_HAW_FREETEXT',
  ConfirmationHawSubject = 'CONFIRMATION_HAW_SUBJECT',
  RejectionFreetext = 'REJECTION_FREETEXT',
  RejectionSubject = 'REJECTION_SUBJECT',
  SignatureFreetext = 'SIGNATURE_FREETEXT',
  SignatureManagementBoard = 'SIGNATURE_MANAGEMENT_BOARD',
}

export type FreeTextInput = {
  id: FreeTextId;
  text: Scalars['String'];
};

export type FurtherGuests = {
  __typename?: 'FurtherGuests';
  familyChildrenAdult: Scalars['Int'];
  familyChildrenUnderage: Array<DateOfBirth>;
  furtherAdults: Scalars['Int'];
  nonFamilyChildrenUnderage: Array<DateOfBirth>;
};

export type FurtherGuestsInput = {
  familyChildrenAdult?: Scalars['Int'];
  familyChildrenUnderage: Array<DateOfBirthInput>;
  furtherAdults?: Scalars['Int'];
  nonFamilyChildrenUnderage: Array<DateOfBirthInput>;
};

export type FurtherPosition = {
  __typename?: 'FurtherPosition';
  cleaning: Scalars['Float'];
  costsPerNight: Scalars['Float'];
  visitorsTaxSummer: Scalars['Float'];
  visitorsTaxWinter: Scalars['Float'];
};

export type FurtherPositionInput = {
  cleaning: Scalars['Float'];
  costsPerNight: Scalars['Float'];
  visitorsTaxSummer: Scalars['Float'];
  visitorsTaxWinter: Scalars['Float'];
};

export type Guest = {
  __typename?: 'Guest';
  accountingArea?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  staffNumber?: Maybe<Scalars['String']>;
};

export type GuestInput = {
  accountingArea?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  staffNumber?: InputMaybe<Scalars['String']>;
};

export type HawBooking = {
  __typename?: 'HawBooking';
  applicationId: Scalars['ID'];
  board?: Maybe<BoardType>;
  bookingChoice?: Maybe<BookingOffer>;
  bookingChoiceId?: Maybe<Scalars['ID']>;
  bookingStatus: BookingConfirmationStatus;
  createdAt: Scalars['DateTimeISO'];
  creditPoints: Scalars['Float'];
  id: Scalars['ID'];
  priority: Scalars['Int'];
};

export type HawBookingInput = {
  board?: InputMaybe<BoardType>;
  bookingChoiceId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  priority: Scalars['Int'];
};

export type House = {
  __typename?: 'House';
  activated?: Maybe<Scalars['Boolean']>;
  additionalEquipment?: Maybe<Scalars['String']>;
  board?: Maybe<Scalars['String']>;
  charges: HouseCharge;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  dogAllowed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  eonBookings?: Maybe<Array<EonBooking>>;
  eonOffers?: Maybe<Array<EonOffer>>;
  eonUrl?: Maybe<Scalars['String']>;
  facilities?: Maybe<Scalars['String']>;
  files?: Maybe<Array<File>>;
  furtherInformation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEonHome?: Maybe<Scalars['Boolean']>;
  isRemoveable: Scalars['Boolean'];
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  streetAndHouseNo?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vacationOffers?: Maybe<Array<VacationOffer>>;
};

export type HouseBoard = {
  __typename?: 'HouseBoard';
  accommodation: Accommodation;
  activated: Scalars['Boolean'];
  furtherPosition: FurtherPosition;
};

export type HouseBoardInput = {
  accommodation: AccommodationInput;
  activated: Scalars['Boolean'];
  furtherPosition: FurtherPositionInput;
};

export type HouseCharge = {
  __typename?: 'HouseCharge';
  fullBoard: HouseBoard;
  halfBoard: HouseBoard;
  overnightBreakfast: HouseBoard;
  selfSupply: HouseBoard;
};

export type HouseChargesInput = {
  fullBoard: HouseBoardInput;
  halfBoard: HouseBoardInput;
  overnightBreakfast: HouseBoardInput;
  selfSupply: HouseBoardInput;
};

export type HouseForIndex = {
  __typename?: 'HouseForIndex';
  city?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type HouseInput = {
  isEonHome?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type HouseUpdateInput = {
  activated?: InputMaybe<Scalars['Boolean']>;
  additionalEquipment?: InputMaybe<Scalars['String']>;
  board?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dogAllowed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  facilities?: InputMaybe<Scalars['String']>;
  furtherInformation?: InputMaybe<Scalars['String']>;
  isEonHome?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  streetAndHouseNo?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MapKidToPersonData = {
  __typename?: 'MapKidToPersonData';
  accountingArea?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  kid?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  staffNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEmailRecipient: User;
  addHouseFile: File;
  addInformationFile: File;
  addVacationOfferFile: File;
  assignBooking: Application;
  cancelApplication: Application;
  createApplication: Application;
  createBookingOffer: BookingOffer;
  createEonOffer: EonOffer;
  createEonOffers: Array<EonOffer>;
  createHouse: House;
  createVacationOffer: VacationOffer;
  deleteApplication: Scalars['Boolean'];
  deleteApplicationsOlderThanFourYears?: Maybe<Scalars['Int']>;
  deleteBookingOffer: Scalars['Boolean'];
  deleteEmailRecipient: User;
  deleteEonOffer: Scalars['Boolean'];
  deleteHouse: Scalars['Boolean'];
  deleteVacationOffer: Scalars['Boolean'];
  finishApplication: Application;
  removeFile: Scalars['Boolean'];
  updateApplication: Application;
  updateApplicationStatus: Application;
  updateBinary: File;
  updateBoardBilling: PriceChange;
  updateBookingOffer: BookingOffer;
  updateEonOffer: EonOffer;
  updateFile: Scalars['ID'];
  updateFreeText: Array<FreeText>;
  updateHouse: Scalars['Boolean'];
  updateHouseCharges: Scalars['Boolean'];
  updateSettings: Settings;
  updateVacationOffer: Scalars['Boolean'];
  updateVacationOfferCharges: Scalars['Boolean'];
};

export type MutationAddEmailRecipientArgs = {
  emailRecipient: EmailRecipientInput;
};

export type MutationAddHouseFileArgs = {
  file: FileInput;
  houseId: Scalars['ID'];
};

export type MutationAddInformationFileArgs = {
  file: FileInput;
};

export type MutationAddVacationOfferFileArgs = {
  file: FileInput;
  vacationOfferId: Scalars['ID'];
};

export type MutationAssignBookingArgs = {
  input: ConfirmBookingInput;
};

export type MutationCancelApplicationArgs = {
  id: Scalars['ID'];
  input: CancellationInput;
};

export type MutationCreateApplicationArgs = {
  application: ApplicationInput;
};

export type MutationCreateBookingOfferArgs = {
  bookingOffer: BookingOfferInput;
  offerId: Scalars['String'];
};

export type MutationCreateEonOfferArgs = {
  houseId: Scalars['String'];
  offer: EonOfferInput;
};

export type MutationCreateEonOffersArgs = {
  offers: Array<EonOfferWithHouseIdInput>;
};

export type MutationCreateHouseArgs = {
  house: HouseInput;
};

export type MutationCreateVacationOfferArgs = {
  houseId: Scalars['String'];
  offer: VacationOfferInput;
};

export type MutationDeleteApplicationArgs = {
  id: Scalars['String'];
};

export type MutationDeleteBookingOfferArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEmailRecipientArgs = {
  emailRecipient: EmailRecipientInput;
};

export type MutationDeleteEonOfferArgs = {
  id: Scalars['String'];
};

export type MutationDeleteHouseArgs = {
  id: Scalars['String'];
};

export type MutationDeleteVacationOfferArgs = {
  id: Scalars['String'];
};

export type MutationFinishApplicationArgs = {
  applicationID: Scalars['ID'];
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateApplicationArgs = {
  application: ApplicationInput;
  applicationID: Scalars['ID'];
  sendEmail?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateApplicationStatusArgs = {
  applicationID: Scalars['ID'];
  applicationStatus: ApplicationStatusInput;
};

export type MutationUpdateBinaryArgs = {
  file: BinaryUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateBoardBillingArgs = {
  applicationID: Scalars['ID'];
  change: PriceChangeInput;
};

export type MutationUpdateBookingOfferArgs = {
  bookingOffer: BookingOfferUpdateInput;
  bookingOfferId: Scalars['String'];
};

export type MutationUpdateEonOfferArgs = {
  offer: EonOfferUpdateInput;
  offerId: Scalars['String'];
};

export type MutationUpdateFileArgs = {
  file: FileUpdateInput;
  id: Scalars['ID'];
};

export type MutationUpdateFreeTextArgs = {
  freeTexts: Array<FreeTextInput>;
};

export type MutationUpdateHouseArgs = {
  house: HouseUpdateInput;
  id: Scalars['String'];
};

export type MutationUpdateHouseChargesArgs = {
  charges: HouseChargesInput;
  houseId: Scalars['String'];
};

export type MutationUpdateSettingsArgs = {
  settings: SettingsInput;
};

export type MutationUpdateVacationOfferArgs = {
  id: Scalars['String'];
  offer: VacationOfferUpdateInput;
};

export type MutationUpdateVacationOfferChargesArgs = {
  charges: VacationOfferChargesInput;
  vacationOfferId: Scalars['String'];
};

export type PaginatedApplicationResponse = {
  __typename?: 'PaginatedApplicationResponse';
  items: Array<Application>;
  total: Scalars['Int'];
};

export type PaginatedBoardBilling = {
  __typename?: 'PaginatedBoardBilling';
  items: Array<BoardBilling>;
  total: Scalars['Int'];
};

export type PaginatedBookingAndApplicationResponse = {
  __typename?: 'PaginatedBookingAndApplicationResponse';
  items: Array<BookingAndApplication>;
  total: Scalars['Int'];
};

export type Partner = {
  __typename?: 'Partner';
  employed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  staffNumber?: Maybe<Scalars['String']>;
  status?: Maybe<PartnerStatus>;
};

export type PartnerInput = {
  employed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  staffNumber?: InputMaybe<Scalars['String']>;
  status: PartnerStatus;
};

export enum PartnerStatus {
  Married = 'MARRIED',
  Without = 'WITHOUT',
}

export type PassedApplication = {
  __typename?: 'PassedApplication';
  booked_offer_name: Scalars['String'];
  booking_changes_booking_date_arrival: Scalars['DateTimeISO'];
  booking_changes_booking_date_departure: Scalars['DateTimeISO'];
  booking_changes_summer_holidays: Scalars['Boolean'];
  credit_points: Scalars['Int'];
  id: Scalars['ID'];
};

export type PassedApplications = {
  __typename?: 'PassedApplications';
  lastYear: Array<PassedApplication>;
  threeYearsAgo: Array<PassedApplication>;
  twoYearsAgo: Array<PassedApplication>;
};

export type Position = {
  __typename?: 'Position';
  employment?: Maybe<EmploymentType>;
  entranceYear?: Maybe<Scalars['Int']>;
  position?: Maybe<PositionType>;
};

export type PositionInput = {
  employment?: InputMaybe<EmploymentType>;
  entranceYear?: InputMaybe<Scalars['Int']>;
  position: PositionType;
};

export enum PositionType {
  Employee = 'EMPLOYEE',
  Executive = 'EXECUTIVE',
  Retiree = 'RETIREE',
}

export type PriceChange = {
  __typename?: 'PriceChange';
  decrement: Scalars['Float'];
  increment: Scalars['Float'];
  remark: Scalars['String'];
};

export type PriceChangeInput = {
  decrement: Scalars['Float'];
  increment: Scalars['Float'];
  remark: Scalars['String'];
};

export type PriceChanges = {
  __typename?: 'PriceChanges';
  board: PriceChange;
  cancellationCosts: PriceChange;
  pecuniaryAdvantage: PriceChange;
  subsistence: PriceChange;
  tax: PriceChange;
};

export type PriceChangesInput = {
  board: PriceChangeInput;
  cancellationCosts: PriceChangeInput;
  pecuniaryAdvantage: PriceChangeInput;
  subsistence: PriceChangeInput;
  tax: PriceChangeInput;
};

export enum ProcessingStatus {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  CancelledConfirmed = 'CANCELLED_CONFIRMED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Finished = 'FINISHED',
  FinishedCancelled = 'FINISHED_CANCELLED',
  FinishedCancelledConfirmed = 'FINISHED_CANCELLED_CONFIRMED',
  FinishedRejectedConfirmed = 'FINISHED_REJECTED_CONFIRMED',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  RejectedConfirmed = 'REJECTED_CONFIRMED',
  SentBack = 'SENT_BACK',
  Submitted = 'SUBMITTED',
}

export type Query = {
  __typename?: 'Query';
  application: Application;
  applicationStatistics: Statistics;
  applications: PaginatedBookingAndApplicationResponse;
  applicationsForBoardBilling: PaginatedBoardBilling;
  emailRecipients: Array<User>;
  emailSignature: Signature;
  eonAndHawHouses: Array<House>;
  eonConfirmationEmailTemplate: ConfirmationEmailTemplate;
  eonHouses: Array<House>;
  file?: Maybe<File>;
  filterAndCountApplications: ApplicationCount;
  getPassedApplications: PassedApplications;
  getPersondata?: Maybe<MapKidToPersonData>;
  getSettings: Settings;
  getToken?: Maybe<Scalars['String']>;
  hawConfirmationEmailTemplate: ConfirmationEmailTemplate;
  house?: Maybe<House>;
  houseFiles?: Maybe<Array<File>>;
  houses: Array<House>;
  informationFiles?: Maybe<Array<File>>;
  myApplications: PaginatedApplicationResponse;
  rejectionEmailTemplate: RejectionEmailTemplate;
  vacationOffer?: Maybe<VacationOffer>;
  vacationOfferFiles?: Maybe<Array<File>>;
  vacationOffers: Array<VacationOffer>;
  vacationOffersForIndex: Array<VacationOfferForIndex>;
};

export type QueryApplicationArgs = {
  id: Scalars['ID'];
};

export type QueryApplicationsArgs = {
  column?: InputMaybe<SortableColumn>;
  filterByHouse?: InputMaybe<Scalars['String']>;
  filterByMonth?: InputMaybe<Scalars['Int']>;
  filterByOffer?: InputMaybe<Scalars['String']>;
  filterByPriority?: InputMaybe<Scalars['Int']>;
  filterByStatus?: InputMaybe<StatusFilter>;
  filterByType?: InputMaybe<BookingType>;
  filterByYear?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortingOrder>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryApplicationsForBoardBillingArgs = {
  column?: InputMaybe<BoardBillingSortableColumn>;
  filterByHouse?: InputMaybe<Scalars['String']>;
  filterByMonth?: InputMaybe<Scalars['Int']>;
  filterByOffer?: InputMaybe<Scalars['String']>;
  filterByYear?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortingOrder>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryFileArgs = {
  id: Scalars['String'];
};

export type QueryFilterAndCountApplicationsArgs = {
  filterByHouse?: InputMaybe<Scalars['String']>;
  filterByMonth?: InputMaybe<Scalars['Int']>;
  filterByOffer?: InputMaybe<Scalars['String']>;
  filterByPriority?: InputMaybe<Scalars['Int']>;
  filterByStatus?: InputMaybe<StatusFilter>;
  filterByType?: InputMaybe<BookingType>;
  filterByYear?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPassedApplicationsArgs = {
  comparedToBookingDate?: InputMaybe<Scalars['DateTimeISO']>;
  forStaffNumber?: InputMaybe<Scalars['String']>;
};

export type QueryGetPersondataArgs = {
  email?: InputMaybe<Scalars['String']>;
  kid?: InputMaybe<Scalars['String']>;
};

export type QueryHouseArgs = {
  id: Scalars['String'];
};

export type QueryHouseFilesArgs = {
  houseId: Scalars['ID'];
};

export type QueryMyApplicationsArgs = {
  applicantKID: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryVacationOfferArgs = {
  id: Scalars['String'];
};

export type QueryVacationOfferFilesArgs = {
  vacationOfferId: Scalars['ID'];
};

export type RejectionEmailTemplate = {
  __typename?: 'RejectionEmailTemplate';
  freeText: Scalars['String'];
  salutation: Scalars['String'];
  signature: Signature;
  subject: Scalars['String'];
  url: Scalars['String'];
};

export type Settings = {
  __typename?: 'Settings';
  adminGroups: Array<Scalars['String']>;
  boardBillingGroups: Array<Scalars['String']>;
  eonHousesFreePlacesList?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  news?: Maybe<Scalars['String']>;
};

export type SettingsInput = {
  adminGroups?: InputMaybe<Array<Scalars['String']>>;
  eonHousesFreePlacesList?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  news?: InputMaybe<Scalars['String']>;
};

export type Signature = {
  __typename?: 'Signature';
  managementBoard: Scalars['String'];
  text: Scalars['String'];
};

export enum SortableColumn {
  BookingType = 'BOOKING_TYPE',
  CreatedAt = 'CREATED_AT',
  Employee = 'EMPLOYEE',
  HouseName = 'HOUSE_NAME',
  OfferName = 'OFFER_NAME',
  Period = 'PERIOD',
  Priority = 'PRIORITY',
  ProcessingStatus = 'PROCESSING_STATUS',
  SocialCreditPoints = 'SOCIAL_CREDIT_POINTS',
}

export enum SortingOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type Statistics = {
  __typename?: 'Statistics';
  ASSIGNED: StatisticsField;
  CANCELLED: StatisticsField;
  CANCELLED_CONFIRMED: StatisticsField;
  CONFIRMED: StatisticsField;
  CREATED: StatisticsField;
  FINISHED: StatisticsField;
  FINISHED_CANCELLED: StatisticsField;
  FINISHED_CANCELLED_CONFIRMED: StatisticsField;
  FINISHED_REJECTED_CONFIRMED: StatisticsField;
  IN_REVIEW: StatisticsField;
  REJECTED: StatisticsField;
  REJECTED_CONFIRMED: StatisticsField;
  SENT_BACK: StatisticsField;
  SUBMITTED: StatisticsField;
  currentYear: Scalars['Float'];
};

export type StatisticsField = {
  __typename?: 'StatisticsField';
  CURRENT_YEAR: Scalars['Float'];
  FOURTH_LAST_YEAR: Scalars['Float'];
  LAST_YEAR: Scalars['Float'];
  OLDER_THAN_FOURTH_LAST_YEAR: Scalars['Float'];
  SECOND_LAST_YEAR: Scalars['Float'];
  THIRD_LAST_YEAR: Scalars['Float'];
};

export enum StatusFilter {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  CancelledConfirmed = 'CANCELLED_CONFIRMED',
  Confirmed = 'CONFIRMED',
  FinishedApplication = 'FINISHED_APPLICATION',
  FinishedBooking = 'FINISHED_BOOKING',
  InReview = 'IN_REVIEW',
  Rejected = 'REJECTED',
  RejectedConfirmed = 'REJECTED_CONFIRMED',
  Submitted = 'SUBMITTED',
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
};

export type VacationOffer = {
  __typename?: 'VacationOffer';
  activated: Scalars['Boolean'];
  bookingOffers?: Maybe<Array<BookingOffer>>;
  charges: VacationOfferCharge;
  createdAt: Scalars['DateTimeISO'];
  files?: Maybe<Array<File>>;
  house?: Maybe<House>;
  house_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
};

export type VacationOfferBoard = {
  __typename?: 'VacationOfferBoard';
  bundle: Accommodation;
};

export type VacationOfferBoardInput = {
  bundle: AccommodationInput;
};

export type VacationOfferCharge = {
  __typename?: 'VacationOfferCharge';
  fullBoard: VacationOfferBoard;
  halfBoard: VacationOfferBoard;
  overnightBreakfast: VacationOfferBoard;
  selfSupply: VacationOfferBoard;
};

export type VacationOfferChargesInput = {
  fullBoard: VacationOfferBoardInput;
  halfBoard: VacationOfferBoardInput;
  overnightBreakfast: VacationOfferBoardInput;
  selfSupply: VacationOfferBoardInput;
};

export type VacationOfferForIndex = {
  __typename?: 'VacationOfferForIndex';
  activated: Scalars['Boolean'];
  house?: Maybe<HouseForIndex>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type VacationOfferInput = {
  activated: Scalars['Boolean'];
  name: Scalars['String'];
  service?: InputMaybe<Scalars['String']>;
};

export type VacationOfferUpdateInput = {
  activated?: InputMaybe<Scalars['Boolean']>;
  houseId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
};

export type GetTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetTokenQuery = { __typename?: 'Query'; getToken?: string | null };

export type FreePlaceListUrl_UpdateSettingsMutationVariables = Exact<{
  settings: SettingsInput;
}>;

export type FreePlaceListUrl_UpdateSettingsMutation = {
  __typename?: 'Mutation';
  updateSettings: {
    __typename?: 'Settings';
    name: string;
    eonHousesFreePlacesList?: string | null;
  };
};

export type FreePlaceListUrl_GetSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FreePlaceListUrl_GetSettingsQuery = {
  __typename?: 'Query';
  getSettings: {
    __typename?: 'Settings';
    name: string;
    eonHousesFreePlacesList?: string | null;
  };
};

export type GetEonHousesAndOffersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEonHousesAndOffersQuery = {
  __typename?: 'Query';
  eonHouses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    region?: string | null;
    eonOffers?: Array<{
      __typename?: 'EonOffer';
      eonBookingId: string;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
      countervailingBenefit?: {
        __typename?: 'CountervailingBenefit';
        childOld: number;
        childYoung: number;
        firstAdult: number;
        perNight: number;
        secondAdult: number;
      } | null;
    }> | null;
  }>;
};

export type CreateEonOffersMutationVariables = Exact<{
  offers: Array<EonOfferWithHouseIdInput> | EonOfferWithHouseIdInput;
}>;

export type CreateEonOffersMutation = {
  __typename?: 'Mutation';
  createEonOffers: Array<{ __typename?: 'EonOffer'; id: string }>;
};

export type EonOffer_CreateMutationVariables = Exact<{
  houseId: Scalars['String'];
  offer: EonOfferInput;
}>;

export type EonOffer_CreateMutation = {
  __typename?: 'Mutation';
  createEonOffer: { __typename?: 'EonOffer'; id: string };
};

export type EonOffer_DeleteMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type EonOffer_DeleteMutation = {
  __typename?: 'Mutation';
  deleteEonOffer: boolean;
};

export type EonOffer_UpdateMutationVariables = Exact<{
  offer: EonOfferUpdateInput;
  offerId: Scalars['String'];
}>;

export type EonOffer_UpdateMutation = {
  __typename?: 'Mutation';
  updateEonOffer: { __typename?: 'EonOffer'; id: string };
};

export type Charges_UpdateHouseChargesMutationVariables = Exact<{
  charges: HouseChargesInput;
  houseId: Scalars['String'];
}>;

export type Charges_UpdateHouseChargesMutation = {
  __typename?: 'Mutation';
  updateHouseCharges: boolean;
};

export type Description_UpdateHouseMutationVariables = Exact<{
  house: HouseUpdateInput;
  id: Scalars['String'];
}>;

export type Description_UpdateHouseMutation = {
  __typename?: 'Mutation';
  updateHouse: boolean;
};

export type Header_DeleteHouseMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type Header_DeleteHouseMutation = {
  __typename?: 'Mutation';
  deleteHouse: boolean;
};

export type Header_UpdateHouseMutationVariables = Exact<{
  house: HouseUpdateInput;
  id: Scalars['String'];
}>;

export type Header_UpdateHouseMutation = {
  __typename?: 'Mutation';
  updateHouse: boolean;
};

export type Header_GetIsHouseRemoveableQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type Header_GetIsHouseRemoveableQuery = {
  __typename?: 'Query';
  house?: { __typename?: 'House'; isRemoveable: boolean } | null;
};

export type HouseAttachments_DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type HouseAttachments_DeleteFileMutation = {
  __typename?: 'Mutation';
  removeFile: boolean;
};

export type HouseAttachments_UpdateDataFileMutationVariables = Exact<{
  id: Scalars['ID'];
  file: BinaryUpdateInput;
}>;

export type HouseAttachments_UpdateDataFileMutation = {
  __typename?: 'Mutation';
  updateBinary: { __typename?: 'File'; id: string };
};

export type HouseAttachments_UpdateFileMetadataMutationVariables = Exact<{
  id: Scalars['ID'];
  file: FileUpdateInput;
}>;

export type HouseAttachments_UpdateFileMetadataMutation = {
  __typename?: 'Mutation';
  updateFile: string;
};

export type HouseForm_GetHouseQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type HouseForm_GetHouseQuery = {
  __typename?: 'Query';
  house?: {
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    type?: string | null;
    region?: string | null;
    streetAndHouseNo?: string | null;
    postalCode?: string | null;
    city?: string | null;
    telephone?: string | null;
    email?: string | null;
    board?: string | null;
    facilities?: string | null;
    additionalEquipment?: string | null;
    dogAllowed?: boolean | null;
    furtherInformation?: string | null;
    charges: {
      __typename?: 'HouseCharge';
      fullBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      halfBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      overnightBreakfast: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      selfSupply: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
    };
    files?: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      description?: string | null;
      order?: number | null;
      path: string;
      type: FileType;
    }> | null;
    eonOffers?: Array<{
      __typename?: 'EonOffer';
      id: string;
      active: boolean;
      isBooked: boolean;
      socialCreditPoints: boolean;
      summerHolidays: boolean;
      eonBookingId: string;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
      countervailingBenefit?: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      } | null;
    }> | null;
  } | null;
};

export type NewHouseEntry_CreateHouseMutationVariables = Exact<{
  house: HouseInput;
}>;

export type NewHouseEntry_CreateHouseMutation = {
  __typename?: 'Mutation';
  createHouse: { __typename?: 'House'; id: string; name: string };
};

export type Photos_AddHouseFileMutationVariables = Exact<{
  houseId: Scalars['ID'];
  file: FileInput;
}>;

export type Photos_AddHouseFileMutation = {
  __typename?: 'Mutation';
  addHouseFile: {
    __typename?: 'File';
    id: string;
    name: string;
    type: FileType;
    order?: number | null;
    description?: string | null;
    path: string;
    createdAt: string;
  };
};

export type Photos_DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type Photos_DeleteFileMutation = {
  __typename?: 'Mutation';
  removeFile: boolean;
};

export type Photos_UpdateFileMetadataMutationVariables = Exact<{
  id: Scalars['ID'];
  file: FileUpdateInput;
}>;

export type Photos_UpdateFileMetadataMutation = {
  __typename?: 'Mutation';
  updateFile: string;
};

export type UploadHouseFile_AddHouseFileMutationVariables = Exact<{
  houseId: Scalars['ID'];
  file: FileInput;
}>;

export type UploadHouseFile_AddHouseFileMutation = {
  __typename?: 'Mutation';
  addHouseFile: {
    __typename?: 'File';
    id: string;
    name: string;
    type: FileType;
    order?: number | null;
    description?: string | null;
    path: string;
    createdAt: string;
  };
};

export type BookingDates_CreateBookingMutationVariables = Exact<{
  offerId: Scalars['String'];
  bookingOffer: BookingOfferInput;
}>;

export type BookingDates_CreateBookingMutation = {
  __typename?: 'Mutation';
  createBookingOffer: { __typename?: 'BookingOffer'; id: string };
};

export type BookingDates_DeleteBookingOfferMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type BookingDates_DeleteBookingOfferMutation = {
  __typename?: 'Mutation';
  deleteBookingOffer: boolean;
};

export type BookingDates_UpdateBookingOfferMutationVariables = Exact<{
  bookingOffer: BookingOfferUpdateInput;
  bookingOfferId: Scalars['String'];
}>;

export type BookingDates_UpdateBookingOfferMutation = {
  __typename?: 'Mutation';
  updateBookingOffer: { __typename?: 'BookingOffer'; id: string };
};

export type Header_UpdateVacationOfferMutationVariables = Exact<{
  offer: VacationOfferUpdateInput;
  id: Scalars['String'];
}>;

export type Header_UpdateVacationOfferMutation = {
  __typename?: 'Mutation';
  updateVacationOffer: boolean;
};

export type Header_DeleteVacationOfferMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type Header_DeleteVacationOfferMutation = {
  __typename?: 'Mutation';
  deleteVacationOffer: boolean;
};

export type HouseChoiceModal_UpdateVacationOfferMutationVariables = Exact<{
  offer: VacationOfferUpdateInput;
  id: Scalars['String'];
}>;

export type HouseChoiceModal_UpdateVacationOfferMutation = {
  __typename?: 'Mutation';
  updateVacationOffer: boolean;
};

export type HouseChoiceModal_GetHawHousesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type HouseChoiceModal_GetHawHousesQuery = {
  __typename?: 'Query';
  houses: Array<{ __typename?: 'House'; id: string; name: string }>;
};

export type HouseDescription_UpdateVacationOfferMutationVariables = Exact<{
  offer: VacationOfferUpdateInput;
  id: Scalars['String'];
}>;

export type HouseDescription_UpdateVacationOfferMutation = {
  __typename?: 'Mutation';
  updateVacationOffer: boolean;
};

export type NewVacationOfferButton_GetHawHousesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NewVacationOfferButton_GetHawHousesQuery = {
  __typename?: 'Query';
  houses: Array<{ __typename?: 'House'; id: string; name: string }>;
};

export type NewVacationOfferButton_CreateVacationOfferMutationVariables =
  Exact<{
    houseId: Scalars['String'];
    offer: VacationOfferInput;
  }>;

export type NewVacationOfferButton_CreateVacationOfferMutation = {
  __typename?: 'Mutation';
  createVacationOffer: { __typename?: 'VacationOffer'; id: string };
};

export type UploadVacationOfferFile_AddVacationOfferFileMutationVariables =
  Exact<{
    vacationOfferId: Scalars['ID'];
    file: FileInput;
  }>;

export type UploadVacationOfferFile_AddVacationOfferFileMutation = {
  __typename?: 'Mutation';
  addVacationOfferFile: {
    __typename?: 'File';
    id: string;
    name: string;
    type: FileType;
    order?: number | null;
    description?: string | null;
    path: string;
    createdAt: string;
  };
};

export type VacationNameForm_UpdateVacationOfferMutationVariables = Exact<{
  offer: VacationOfferUpdateInput;
  id: Scalars['String'];
}>;

export type VacationNameForm_UpdateVacationOfferMutation = {
  __typename?: 'Mutation';
  updateVacationOffer: boolean;
};

export type VacationOfferAttachments_DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VacationOfferAttachments_DeleteFileMutation = {
  __typename?: 'Mutation';
  removeFile: boolean;
};

export type VacationOfferAttachments_UpdateDataFileMutationVariables = Exact<{
  id: Scalars['ID'];
  file: BinaryUpdateInput;
}>;

export type VacationOfferAttachments_UpdateDataFileMutation = {
  __typename?: 'Mutation';
  updateBinary: { __typename?: 'File'; id: string };
};

export type VacationOfferAttachments_UpdateFileMetadataMutationVariables =
  Exact<{
    id: Scalars['ID'];
    file: FileUpdateInput;
  }>;

export type VacationOfferAttachments_UpdateFileMetadataMutation = {
  __typename?: 'Mutation';
  updateFile: string;
};

export type VacationOfferForm_GetVacationOfferQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type VacationOfferForm_GetVacationOfferQuery = {
  __typename?: 'Query';
  vacationOffer?: {
    __typename?: 'VacationOffer';
    id: string;
    activated: boolean;
    name?: string | null;
    service?: string | null;
    createdAt: string;
    files?: Array<{
      __typename?: 'File';
      id: string;
      name: string;
      description?: string | null;
      path: string;
      order?: number | null;
      type: FileType;
    }> | null;
    charges: {
      __typename?: 'VacationOfferCharge';
      fullBoard: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      halfBoard: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      overnightBreakfast: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      selfSupply: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
    };
    bookingOffers?: Array<{
      __typename?: 'BookingOffer';
      id: string;
      summerHolidays: boolean;
      socialCreditPoints: boolean;
      active: boolean;
      isBooked: boolean;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
      countervailingBenefit?: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      } | null;
    }> | null;
    house?: {
      __typename?: 'House';
      id: string;
      name: string;
      type?: string | null;
      region?: string | null;
      city?: string | null;
      postalCode?: string | null;
      facilities?: string | null;
      streetAndHouseNo?: string | null;
      telephone?: string | null;
      board?: string | null;
      email?: string | null;
      additionalEquipment?: string | null;
      dogAllowed?: boolean | null;
      files?: Array<{
        __typename?: 'File';
        id: string;
        name: string;
        description?: string | null;
        path: string;
        order?: number | null;
        type: FileType;
      }> | null;
      charges: {
        __typename?: 'HouseCharge';
        fullBoard: {
          __typename?: 'HouseBoard';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
        };
        halfBoard: {
          __typename?: 'HouseBoard';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
        };
        overnightBreakfast: {
          __typename?: 'HouseBoard';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
        };
        selfSupply: {
          __typename?: 'HouseBoard';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
        };
      };
    } | null;
  } | null;
};

export type VacationPrices_UpdateVacationOfferChargesMutationVariables = Exact<{
  charges: VacationOfferChargesInput;
  vacationOfferId: Scalars['String'];
}>;

export type VacationPrices_UpdateVacationOfferChargesMutation = {
  __typename?: 'Mutation';
  updateVacationOfferCharges: boolean;
};

export type FilterEntries_GetDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FilterEntries_GetDataQuery = {
  __typename?: 'Query';
  eonAndHawHouses: Array<{ __typename?: 'House'; id: string; name: string }>;
  vacationOffers: Array<{
    __typename?: 'VacationOffer';
    id: string;
    name?: string | null;
  }>;
};

export type ActionHandler_UpdateApplicationStatusMutationVariables = Exact<{
  applicationID: Scalars['ID'];
  applicationStatus: ApplicationStatusInput;
}>;

export type ActionHandler_UpdateApplicationStatusMutation = {
  __typename?: 'Mutation';
  updateApplicationStatus: {
    __typename?: 'Application';
    id: string;
    processingStatus: ProcessingStatus;
  };
};

export type ActionHandler_CancelApplicationMutationVariables = Exact<{
  applicationID: Scalars['ID'];
  cancelInput: CancellationInput;
}>;

export type ActionHandler_CancelApplicationMutation = {
  __typename?: 'Mutation';
  cancelApplication: {
    __typename?: 'Application';
    id: string;
    processingStatus: ProcessingStatus;
  };
};

export type ActionHandler_AssignBookingMutationVariables = Exact<{
  assignBookingInput: ConfirmBookingInput;
}>;

export type ActionHandler_AssignBookingMutation = {
  __typename?: 'Mutation';
  assignBooking: {
    __typename?: 'Application';
    id: string;
    processingStatus: ProcessingStatus;
    hawBookings: Array<{
      __typename?: 'HawBooking';
      bookingStatus: BookingConfirmationStatus;
    }>;
    eonBookings: Array<{
      __typename?: 'EonBooking';
      bookingStatus: BookingConfirmationStatus;
    }>;
  };
};

export type ActionHandler_FinishApplicationMutationVariables = Exact<{
  applicationID: Scalars['ID'];
}>;

export type ActionHandler_FinishApplicationMutation = {
  __typename?: 'Mutation';
  finishApplication: {
    __typename?: 'Application';
    id: string;
    processingStatus: ProcessingStatus;
  };
};

export type ApplicationForm_GetVactionOffersHousesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicationForm_GetVactionOffersHousesQuery = {
  __typename?: 'Query';
  vacationOffers: Array<{
    __typename?: 'VacationOffer';
    id: string;
    activated: boolean;
    name?: string | null;
    house_id?: string | null;
    bookingOffers?: Array<{
      __typename?: 'BookingOffer';
      id: string;
      active: boolean;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
      countervailingBenefit?: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      } | null;
    }> | null;
    charges: {
      __typename?: 'VacationOfferCharge';
      fullBoard: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      halfBoard: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      overnightBreakfast: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
      selfSupply: {
        __typename?: 'VacationOfferBoard';
        bundle: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      };
    };
  }>;
  houses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    dogAllowed?: boolean | null;
    charges: {
      __typename?: 'HouseCharge';
      fullBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      halfBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      overnightBreakfast: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      selfSupply: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
    };
  }>;
  eonHouses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    dogAllowed?: boolean | null;
    eonOffers?: Array<{
      __typename?: 'EonOffer';
      id: string;
      active: boolean;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
      countervailingBenefit?: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      } | null;
    }> | null;
    charges: {
      __typename?: 'HouseCharge';
      fullBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      halfBoard: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      overnightBreakfast: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
      selfSupply: {
        __typename?: 'HouseBoard';
        activated: boolean;
        accommodation: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        furtherPosition: {
          __typename?: 'FurtherPosition';
          cleaning: number;
          costsPerNight: number;
          visitorsTaxSummer: number;
          visitorsTaxWinter: number;
        };
      };
    };
  }>;
};

export type PointsTable_GetPassedApplicationsQueryVariables = Exact<{
  forStaffNumber?: InputMaybe<Scalars['String']>;
  comparedToBookingDate?: InputMaybe<Scalars['DateTimeISO']>;
}>;

export type PointsTable_GetPassedApplicationsQuery = {
  __typename?: 'Query';
  getPassedApplications: {
    __typename?: 'PassedApplications';
    lastYear: Array<{
      __typename?: 'PassedApplication';
      id: string;
      booking_changes_booking_date_arrival: string;
      booking_changes_booking_date_departure: string;
      booking_changes_summer_holidays: boolean;
      booked_offer_name: string;
      credit_points: number;
    }>;
    twoYearsAgo: Array<{
      __typename?: 'PassedApplication';
      id: string;
      booking_changes_booking_date_arrival: string;
      booking_changes_booking_date_departure: string;
      booking_changes_summer_holidays: boolean;
      booked_offer_name: string;
      credit_points: number;
    }>;
    threeYearsAgo: Array<{
      __typename?: 'PassedApplication';
      id: string;
      booking_changes_booking_date_arrival: string;
      booking_changes_booking_date_departure: string;
      booking_changes_summer_holidays: boolean;
      booked_offer_name: string;
      credit_points: number;
    }>;
  };
};

export type BoardBilling_Filter_GetDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BoardBilling_Filter_GetDataQuery = {
  __typename?: 'Query';
  houses: Array<{ __typename?: 'House'; id: string; name: string }>;
  vacationOffers: Array<{
    __typename?: 'VacationOffer';
    id: string;
    name?: string | null;
  }>;
};

export type News_UpdateSettingsMutationVariables = Exact<{
  settings: SettingsInput;
}>;

export type News_UpdateSettingsMutation = {
  __typename?: 'Mutation';
  updateSettings: {
    __typename?: 'Settings';
    name: string;
    news?: string | null;
  };
};

export type News_GetSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type News_GetSettingsQuery = {
  __typename?: 'Query';
  getSettings: { __typename?: 'Settings'; name: string; news?: string | null };
};

export type Recipients_AddRecipientMutationVariables = Exact<{
  emailRecipient: EmailRecipientInput;
}>;

export type Recipients_AddRecipientMutation = {
  __typename?: 'Mutation';
  addEmailRecipient: { __typename?: 'User'; email: string };
};

export type Recipients_DeleteRecipientMutationVariables = Exact<{
  emailRecipient: EmailRecipientInput;
}>;

export type Recipients_DeleteRecipientMutation = {
  __typename?: 'Mutation';
  deleteEmailRecipient: { __typename?: 'User'; email: string };
};

export type Recipient_GetRecipientsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Recipient_GetRecipientsQuery = {
  __typename?: 'Query';
  emailRecipients: Array<{ __typename?: 'User'; email: string }>;
};

export type EmailTemplates_GetHawConfirmationTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailTemplates_GetHawConfirmationTemplateQuery = {
  __typename?: 'Query';
  hawConfirmationEmailTemplate: {
    __typename?: 'ConfirmationEmailTemplate';
    subject: string;
    salutation: string;
    freeText: string;
    bookingDetails: string;
    additionalFreetext: string;
    url: string;
    signature: {
      __typename?: 'Signature';
      text: string;
      managementBoard: string;
    };
  };
};

export type EmailTemplates_GetEonConfirmationTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailTemplates_GetEonConfirmationTemplateQuery = {
  __typename?: 'Query';
  eonConfirmationEmailTemplate: {
    __typename?: 'ConfirmationEmailTemplate';
    subject: string;
    salutation: string;
    freeText: string;
    bookingDetails: string;
    additionalFreetext: string;
    url: string;
    signature: {
      __typename?: 'Signature';
      text: string;
      managementBoard: string;
    };
  };
};

export type EmailTemplates_GetRejectionTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailTemplates_GetRejectionTemplateQuery = {
  __typename?: 'Query';
  rejectionEmailTemplate: {
    __typename?: 'RejectionEmailTemplate';
    subject: string;
    salutation: string;
    freeText: string;
    url: string;
    signature: {
      __typename?: 'Signature';
      text: string;
      managementBoard: string;
    };
  };
};

export type EmailTemplates_GetSignatureTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailTemplates_GetSignatureTemplateQuery = {
  __typename?: 'Query';
  emailSignature: {
    __typename?: 'Signature';
    managementBoard: string;
    text: string;
  };
};

export type EmailTemplates_UpdateFreeTextMutationVariables = Exact<{
  freeTexts: Array<FreeTextInput> | FreeTextInput;
}>;

export type EmailTemplates_UpdateFreeTextMutation = {
  __typename?: 'Mutation';
  updateFreeText: Array<{ __typename?: 'FreeText'; id: string; text: string }>;
};

export type AllStatisticFieldsFragment = {
  __typename?: 'StatisticsField';
  CURRENT_YEAR: number;
  LAST_YEAR: number;
  SECOND_LAST_YEAR: number;
  THIRD_LAST_YEAR: number;
  FOURTH_LAST_YEAR: number;
  OLDER_THAN_FOURTH_LAST_YEAR: number;
};

export type ApplicationStatisticsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicationStatisticsQuery = {
  __typename?: 'Query';
  applicationStatistics: {
    __typename?: 'Statistics';
    currentYear: number;
    CREATED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    SENT_BACK: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    SUBMITTED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    IN_REVIEW: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    CONFIRMED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    CANCELLED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    REJECTED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    ASSIGNED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    CANCELLED_CONFIRMED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    REJECTED_CONFIRMED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    FINISHED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    FINISHED_CANCELLED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    FINISHED_REJECTED_CONFIRMED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
    FINISHED_CANCELLED_CONFIRMED: {
      __typename?: 'StatisticsField';
      CURRENT_YEAR: number;
      LAST_YEAR: number;
      SECOND_LAST_YEAR: number;
      THIRD_LAST_YEAR: number;
      FOURTH_LAST_YEAR: number;
      OLDER_THAN_FOURTH_LAST_YEAR: number;
    };
  };
};

export type DeleteApplicationsOlderThanFourYearsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteApplicationsOlderThanFourYearsMutation = {
  __typename?: 'Mutation';
  deleteApplicationsOlderThanFourYears?: number | null;
};

export type VacationPackageChargeFieldsFragment = {
  __typename?: 'VacationOfferCharge';
  fullBoard: {
    __typename?: 'VacationOfferBoard';
    bundle: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
  };
  halfBoard: {
    __typename?: 'VacationOfferBoard';
    bundle: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
  };
  overnightBreakfast: {
    __typename?: 'VacationOfferBoard';
    bundle: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
  };
  selfSupply: {
    __typename?: 'VacationOfferBoard';
    bundle: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
  };
};

export type HouseChargeFieldsFragment = {
  __typename?: 'HouseCharge';
  fullBoard: {
    __typename?: 'HouseBoard';
    activated: boolean;
    accommodation: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    furtherPosition: {
      __typename?: 'FurtherPosition';
      cleaning: number;
      costsPerNight: number;
      visitorsTaxSummer: number;
      visitorsTaxWinter: number;
    };
  };
  halfBoard: {
    __typename?: 'HouseBoard';
    activated: boolean;
    accommodation: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    furtherPosition: {
      __typename?: 'FurtherPosition';
      cleaning: number;
      costsPerNight: number;
      visitorsTaxSummer: number;
      visitorsTaxWinter: number;
    };
  };
  overnightBreakfast: {
    __typename?: 'HouseBoard';
    activated: boolean;
    accommodation: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    furtherPosition: {
      __typename?: 'FurtherPosition';
      cleaning: number;
      costsPerNight: number;
      visitorsTaxSummer: number;
      visitorsTaxWinter: number;
    };
  };
  selfSupply: {
    __typename?: 'HouseBoard';
    activated: boolean;
    accommodation: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    furtherPosition: {
      __typename?: 'FurtherPosition';
      cleaning: number;
      costsPerNight: number;
      visitorsTaxSummer: number;
      visitorsTaxWinter: number;
    };
  };
};

export type HouseBoardFieldsFragment = {
  __typename?: 'HouseBoard';
  activated: boolean;
  accommodation: {
    __typename?: 'Accommodation';
    adult: number;
    childYoung: number;
    childOld: number;
    retiree: number;
    executive: number;
    externalAdult: number;
    externalChild: number;
  };
  furtherPosition: {
    __typename?: 'FurtherPosition';
    cleaning: number;
    costsPerNight: number;
    visitorsTaxSummer: number;
    visitorsTaxWinter: number;
  };
};

export type FurtherPositionFieldsFragment = {
  __typename?: 'FurtherPosition';
  cleaning: number;
  costsPerNight: number;
  visitorsTaxSummer: number;
  visitorsTaxWinter: number;
};

export type CountervailingBenefitFieldsFragment = {
  __typename?: 'CountervailingBenefit';
  firstAdult: number;
  secondAdult: number;
  childYoung: number;
  childOld: number;
  perNight: number;
};

export type AccommodationFieldsFragment = {
  __typename?: 'Accommodation';
  adult: number;
  childYoung: number;
  childOld: number;
  retiree: number;
  executive: number;
  externalAdult: number;
  externalChild: number;
};

export type FixedChargeFieldsFragment = {
  __typename?: 'FixedCharge';
  activated: boolean;
  accommodation: {
    __typename?: 'Accommodation';
    adult: number;
    childYoung: number;
    childOld: number;
    retiree: number;
    executive: number;
    externalAdult: number;
    externalChild: number;
  };
  furtherPosition: {
    __typename?: 'FurtherPosition';
    cleaning: number;
    costsPerNight: number;
    visitorsTaxSummer: number;
    visitorsTaxWinter: number;
  };
  countervailingBenefit: {
    __typename?: 'CountervailingBenefit';
    firstAdult: number;
    secondAdult: number;
    childYoung: number;
    childOld: number;
    perNight: number;
  };
};

export type FixedChargesFieldsFragment = {
  __typename?: 'FixedCharges';
  house: {
    __typename?: 'FixedHouseCharge';
    fullBoard: {
      __typename?: 'FixedCharge';
      activated: boolean;
      accommodation: {
        __typename?: 'Accommodation';
        adult: number;
        childYoung: number;
        childOld: number;
        retiree: number;
        executive: number;
        externalAdult: number;
        externalChild: number;
      };
      furtherPosition: {
        __typename?: 'FurtherPosition';
        cleaning: number;
        costsPerNight: number;
        visitorsTaxSummer: number;
        visitorsTaxWinter: number;
      };
      countervailingBenefit: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      };
    };
    halfBoard: {
      __typename?: 'FixedCharge';
      activated: boolean;
      accommodation: {
        __typename?: 'Accommodation';
        adult: number;
        childYoung: number;
        childOld: number;
        retiree: number;
        executive: number;
        externalAdult: number;
        externalChild: number;
      };
      furtherPosition: {
        __typename?: 'FurtherPosition';
        cleaning: number;
        costsPerNight: number;
        visitorsTaxSummer: number;
        visitorsTaxWinter: number;
      };
      countervailingBenefit: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      };
    };
    overnightBreakfast: {
      __typename?: 'FixedCharge';
      activated: boolean;
      accommodation: {
        __typename?: 'Accommodation';
        adult: number;
        childYoung: number;
        childOld: number;
        retiree: number;
        executive: number;
        externalAdult: number;
        externalChild: number;
      };
      furtherPosition: {
        __typename?: 'FurtherPosition';
        cleaning: number;
        costsPerNight: number;
        visitorsTaxSummer: number;
        visitorsTaxWinter: number;
      };
      countervailingBenefit: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      };
    };
    selfSupply: {
      __typename?: 'FixedCharge';
      activated: boolean;
      accommodation: {
        __typename?: 'Accommodation';
        adult: number;
        childYoung: number;
        childOld: number;
        retiree: number;
        executive: number;
        externalAdult: number;
        externalChild: number;
      };
      furtherPosition: {
        __typename?: 'FurtherPosition';
        cleaning: number;
        costsPerNight: number;
        visitorsTaxSummer: number;
        visitorsTaxWinter: number;
      };
      countervailingBenefit: {
        __typename?: 'CountervailingBenefit';
        firstAdult: number;
        secondAdult: number;
        childYoung: number;
        childOld: number;
        perNight: number;
      };
    };
  };
  vacationPackage?: {
    __typename?: 'FixedVacationOfferCharge';
    fullBoard: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    halfBoard: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    overnightBreakfast: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
    selfSupply: {
      __typename?: 'Accommodation';
      adult: number;
      childYoung: number;
      childOld: number;
      retiree: number;
      executive: number;
      externalAdult: number;
      externalChild: number;
    };
  } | null;
};

export type BoardBilling_GetApplicationsVactionOffersHousesQueryVariables =
  Exact<{
    column?: InputMaybe<BoardBillingSortableColumn>;
    order?: InputMaybe<SortingOrder>;
    filterByYear?: InputMaybe<Scalars['Int']>;
    filterByHouse?: InputMaybe<Scalars['String']>;
    filterByOffer?: InputMaybe<Scalars['String']>;
    filterByMonth?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
  }>;

export type BoardBilling_GetApplicationsVactionOffersHousesQuery = {
  __typename?: 'Query';
  applicationsForBoardBilling: {
    __typename?: 'PaginatedBoardBilling';
    total: number;
    items: Array<{
      __typename: 'BoardBilling';
      applicationId: string;
      employeeName: string;
      arrival: string;
      departure: string;
      houseName: string;
      vacationOfferName: string;
      priceChange: {
        __typename: 'PriceChange';
        remark: string;
        increment: number;
        decrement: number;
      };
    }>;
  };
};

export type BoardBilling_UpdateMutationVariables = Exact<{
  applicationID: Scalars['ID'];
  change: PriceChangeInput;
}>;

export type BoardBilling_UpdateMutation = {
  __typename?: 'Mutation';
  updateBoardBilling: {
    __typename: 'PriceChange';
    remark: string;
    increment: number;
    decrement: number;
  };
};

export type EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables =
  Exact<{
    column?: InputMaybe<SortableColumn>;
    order?: InputMaybe<SortingOrder>;
    filterByType?: InputMaybe<BookingType>;
    filterByStatus?: InputMaybe<StatusFilter>;
    filterByYear?: InputMaybe<Scalars['Int']>;
    filterByHouse?: InputMaybe<Scalars['String']>;
    filterByOffer?: InputMaybe<Scalars['String']>;
    filterByMonth?: InputMaybe<Scalars['Int']>;
    filterByPriority?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    take?: InputMaybe<Scalars['Int']>;
  }>;

export type EmployeeApplications_GetApplicationsVactionOffersHousesQuery = {
  __typename?: 'Query';
  applications: {
    __typename?: 'PaginatedBookingAndApplicationResponse';
    total: number;
    items: Array<{
      __typename: 'BookingAndApplication';
      applicationId: string;
      bookingId: string;
      bookingArrival: string;
      bookingDeparture: string;
      bookingPriority: number;
      bookingtype: BookingType;
      bookingStatus: BookingConfirmationStatus;
      houseName: string;
      vacationOfferName?: string | null;
      applicationProcessingStatus: ProcessingStatus;
      applicationApplicationFor: ApplicationFor;
      applicationApplicantStaffNumber: string;
      applicationApplicantName: string;
      applicationApplicantEmail: string;
      applicationGuestEmail?: string | null;
      applicationGuestName?: string | null;
      applicationGuestStaffNumber?: string | null;
      creditPoints: number;
      applicationCreatedAt: string;
      applicationBookingChangesArrival?: string | null;
      applicationBookingChangesDeparture?: string | null;
    }>;
  };
  filterAndCountApplications: {
    __typename?: 'ApplicationCount';
    numberOfApplications: number;
  };
};

export type EditEon_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type EditEon_GetListQuery = {
  __typename?: 'Query';
  eonHouses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type IndexEon_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type IndexEon_GetListQuery = {
  __typename?: 'Query';
  eonHouses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type NewEon_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type NewEon_GetListQuery = {
  __typename?: 'Query';
  eonHouses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type EditHaw_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type EditHaw_GetListQuery = {
  __typename?: 'Query';
  houses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type IndexHaw_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type IndexHaw_GetListQuery = {
  __typename?: 'Query';
  houses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type NewHaw_GetListQueryVariables = Exact<{ [key: string]: never }>;

export type NewHaw_GetListQuery = {
  __typename?: 'Query';
  houses: Array<{
    __typename?: 'House';
    id: string;
    name: string;
    activated?: boolean | null;
    isEonHome?: boolean | null;
    city?: string | null;
    region?: string | null;
  }>;
};

export type Information_GetAttachmentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type Information_GetAttachmentsQuery = {
  __typename?: 'Query';
  informationFiles?: Array<{
    __typename?: 'File';
    id: string;
    description?: string | null;
    path: string;
    name: string;
  }> | null;
};

export type Information_DeleteAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type Information_DeleteAttachmentMutation = {
  __typename?: 'Mutation';
  removeFile: boolean;
};

export type Information_UpdateAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
  file: BinaryUpdateInput;
}>;

export type Information_UpdateAttachmentMutation = {
  __typename?: 'Mutation';
  updateBinary: { __typename?: 'File'; id: string };
};

export type Information_AddAttachmentMutationVariables = Exact<{
  file: FileInput;
}>;

export type Information_AddAttachmentMutation = {
  __typename?: 'Mutation';
  addInformationFile: {
    __typename?: 'File';
    id: string;
    name: string;
    type: FileType;
    order?: number | null;
    description?: string | null;
    path: string;
    createdAt: string;
  };
};

export type Information_UpdateFileMetadataMutationVariables = Exact<{
  id: Scalars['ID'];
  file: FileUpdateInput;
}>;

export type Information_UpdateFileMetadataMutation = {
  __typename?: 'Mutation';
  updateFile: string;
};

export type PriceChangeFieldsFragment = {
  __typename?: 'PriceChange';
  increment: number;
  decrement: number;
  remark: string;
};

export type EditMyApplications_GetApplicationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EditMyApplications_GetApplicationQuery = {
  __typename?: 'Query';
  application: {
    __typename?: 'Application';
    id: string;
    bookedOfferName: string;
    processingStatus: ProcessingStatus;
    cancellationDate?: string | null;
    applicantKID: string;
    applicationFor: ApplicationFor;
    createdAt: string;
    applicationType: ApplicationType;
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    phone: string;
    allergies?: string | null;
    singleParent: boolean;
    dog: boolean;
    comment: string;
    applicant: {
      __typename?: 'Applicant';
      name?: string | null;
      email?: string | null;
      staffNumber?: string | null;
      accountingArea?: string | null;
    };
    guest?: {
      __typename?: 'Guest';
      name?: string | null;
      email?: string | null;
      staffNumber?: string | null;
      accountingArea?: string | null;
    } | null;
    disability: {
      __typename?: 'Disability';
      present: boolean;
      degree?: number | null;
      walkingDisability: boolean;
      markBOnPassport: boolean;
    };
    partner: {
      __typename?: 'Partner';
      status?: PartnerStatus | null;
      employed?: boolean | null;
      name?: string | null;
      staffNumber?: string | null;
    };
    furtherGuests: {
      __typename?: 'FurtherGuests';
      familyChildrenAdult: number;
      furtherAdults: number;
      nonFamilyChildrenUnderage: Array<{
        __typename?: 'DateOfBirth';
        dateOfBirth: number;
      }>;
      familyChildrenUnderage: Array<{
        __typename?: 'DateOfBirth';
        dateOfBirth: number;
      }>;
    };
    companyPosition: {
      __typename?: 'Position';
      position?: PositionType | null;
      entranceYear?: number | null;
      employment?: EmploymentType | null;
    };
    hawBookings: Array<{
      __typename?: 'HawBooking';
      id: string;
      applicationId: string;
      priority: number;
      bookingStatus: BookingConfirmationStatus;
      bookingChoiceId?: string | null;
      board?: BoardType | null;
      createdAt: string;
      bookingChoice?: {
        __typename?: 'BookingOffer';
        id: string;
        summerHolidays: boolean;
        socialCreditPoints: boolean;
        active: boolean;
        createdAt: string;
        countervailingBenefit?: {
          __typename?: 'CountervailingBenefit';
          firstAdult: number;
          secondAdult: number;
          childYoung: number;
          childOld: number;
          perNight: number;
        } | null;
        bookingDate: {
          __typename?: 'BookingDate';
          arrival?: string | null;
          departure?: string | null;
        };
      } | null;
    }>;
    eonBookings: Array<{
      __typename?: 'EonBooking';
      id: string;
      priority: number;
      applicationId: string;
      houseId: string;
      bookingStatus: BookingConfirmationStatus;
      bookingChoiceId?: string | null;
      board?: BoardType | null;
      createdAt: string;
      bookingChoice?: {
        __typename?: 'EonOffer';
        id: string;
        summerHolidays: boolean;
        socialCreditPoints: boolean;
        active: boolean;
        createdAt: string;
        countervailingBenefit?: {
          __typename?: 'CountervailingBenefit';
          firstAdult: number;
          secondAdult: number;
          childYoung: number;
          childOld: number;
          perNight: number;
        } | null;
        bookingDate: {
          __typename?: 'BookingDate';
          arrival?: string | null;
          departure?: string | null;
        };
      } | null;
      period?: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      } | null;
    }>;
    priceChanges?: {
      __typename?: 'PriceChanges';
      subsistence: {
        __typename?: 'PriceChange';
        increment: number;
        decrement: number;
        remark: string;
      };
      tax: {
        __typename?: 'PriceChange';
        increment: number;
        decrement: number;
        remark: string;
      };
      board: {
        __typename?: 'PriceChange';
        increment: number;
        decrement: number;
        remark: string;
      };
      pecuniaryAdvantage: {
        __typename?: 'PriceChange';
        increment: number;
        decrement: number;
        remark: string;
      };
      cancellationCosts: {
        __typename?: 'PriceChange';
        increment: number;
        decrement: number;
        remark: string;
      };
    } | null;
    bookingChanges?: {
      __typename?: 'BookingChanges';
      summerHolidays: boolean;
      socialCreditPoints: boolean;
      eonBookingId: string;
      bookingDate: {
        __typename?: 'BookingDate';
        arrival?: string | null;
        departure?: string | null;
      };
    } | null;
    fixedCharges?: {
      __typename?: 'FixedCharges';
      house: {
        __typename?: 'FixedHouseCharge';
        fullBoard: {
          __typename?: 'FixedCharge';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
          countervailingBenefit: {
            __typename?: 'CountervailingBenefit';
            firstAdult: number;
            secondAdult: number;
            childYoung: number;
            childOld: number;
            perNight: number;
          };
        };
        halfBoard: {
          __typename?: 'FixedCharge';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
          countervailingBenefit: {
            __typename?: 'CountervailingBenefit';
            firstAdult: number;
            secondAdult: number;
            childYoung: number;
            childOld: number;
            perNight: number;
          };
        };
        overnightBreakfast: {
          __typename?: 'FixedCharge';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
          countervailingBenefit: {
            __typename?: 'CountervailingBenefit';
            firstAdult: number;
            secondAdult: number;
            childYoung: number;
            childOld: number;
            perNight: number;
          };
        };
        selfSupply: {
          __typename?: 'FixedCharge';
          activated: boolean;
          accommodation: {
            __typename?: 'Accommodation';
            adult: number;
            childYoung: number;
            childOld: number;
            retiree: number;
            executive: number;
            externalAdult: number;
            externalChild: number;
          };
          furtherPosition: {
            __typename?: 'FurtherPosition';
            cleaning: number;
            costsPerNight: number;
            visitorsTaxSummer: number;
            visitorsTaxWinter: number;
          };
          countervailingBenefit: {
            __typename?: 'CountervailingBenefit';
            firstAdult: number;
            secondAdult: number;
            childYoung: number;
            childOld: number;
            perNight: number;
          };
        };
      };
      vacationPackage?: {
        __typename?: 'FixedVacationOfferCharge';
        fullBoard: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        halfBoard: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        overnightBreakfast: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
        selfSupply: {
          __typename?: 'Accommodation';
          adult: number;
          childYoung: number;
          childOld: number;
          retiree: number;
          executive: number;
          externalAdult: number;
          externalChild: number;
        };
      } | null;
    } | null;
  };
};

export type EditMyApplications_DeleteApplicationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type EditMyApplications_DeleteApplicationMutation = {
  __typename?: 'Mutation';
  deleteApplication: boolean;
};

export type EditMyApplications_UpdateApplicationMutationVariables = Exact<{
  applicationID: Scalars['ID'];
  updateApplication: ApplicationInput;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
}>;

export type EditMyApplications_UpdateApplicationMutation = {
  __typename?: 'Mutation';
  updateApplication: { __typename?: 'Application'; id: string };
};

export type IndexMyApplications_GetMyApplicationsQueryVariables = Exact<{
  myApplicationsApplicantKid: Scalars['String'];
}>;

export type IndexMyApplications_GetMyApplicationsQuery = {
  __typename?: 'Query';
  myApplications: {
    __typename?: 'PaginatedApplicationResponse';
    total: number;
    items: Array<{
      __typename?: 'Application';
      id: string;
      processingStatus: ProcessingStatus;
      createdAt: string;
      applicantKID: string;
      applicant: { __typename?: 'Applicant'; name?: string | null };
    }>;
  };
};

export type NewMyApplicationFromTemplate_GetApplicationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type NewMyApplicationFromTemplate_GetApplicationQuery = {
  __typename?: 'Query';
  application: {
    __typename?: 'Application';
    applicantKID: string;
    applicationFor: ApplicationFor;
    applicationType: ApplicationType;
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    phone: string;
    allergies?: string | null;
    singleParent: boolean;
    dog: boolean;
    comment: string;
    applicant: {
      __typename?: 'Applicant';
      name?: string | null;
      email?: string | null;
      staffNumber?: string | null;
      accountingArea?: string | null;
    };
    guest?: {
      __typename?: 'Guest';
      name?: string | null;
      email?: string | null;
      staffNumber?: string | null;
      accountingArea?: string | null;
    } | null;
    disability: {
      __typename?: 'Disability';
      present: boolean;
      degree?: number | null;
      walkingDisability: boolean;
      markBOnPassport: boolean;
    };
    partner: {
      __typename?: 'Partner';
      status?: PartnerStatus | null;
      employed?: boolean | null;
      name?: string | null;
      staffNumber?: string | null;
    };
    furtherGuests: {
      __typename?: 'FurtherGuests';
      familyChildrenAdult: number;
      furtherAdults: number;
      nonFamilyChildrenUnderage: Array<{
        __typename?: 'DateOfBirth';
        dateOfBirth: number;
      }>;
      familyChildrenUnderage: Array<{
        __typename?: 'DateOfBirth';
        dateOfBirth: number;
      }>;
    };
    companyPosition: {
      __typename?: 'Position';
      position?: PositionType | null;
      entranceYear?: number | null;
      employment?: EmploymentType | null;
    };
  };
};

export type NewMyApplications_CreateApplicationMutationVariables = Exact<{
  createApplication: ApplicationInput;
}>;

export type NewMyApplications_CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication: { __typename?: 'Application'; id: string };
};

export type NewMyApplications_GetPersondataQueryVariables = Exact<{
  kid: Scalars['String'];
}>;

export type NewMyApplications_GetPersondataQuery = {
  __typename?: 'Query';
  getPersondata?: {
    __typename?: 'MapKidToPersonData';
    staffNumber?: string | null;
    accountingArea?: string | null;
  } | null;
};

export type EditVacationOffer_GetVacationOffersListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EditVacationOffer_GetVacationOffersListQuery = {
  __typename?: 'Query';
  vacationOffersForIndex: Array<{
    __typename?: 'VacationOfferForIndex';
    id: string;
    activated: boolean;
    name?: string | null;
    house?: {
      __typename?: 'HouseForIndex';
      name: string;
      city?: string | null;
      region?: string | null;
    } | null;
  }>;
};

export type IndexVacationOffers_GetVacationOffersListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IndexVacationOffers_GetVacationOffersListQuery = {
  __typename?: 'Query';
  vacationOffersForIndex: Array<{
    __typename?: 'VacationOfferForIndex';
    id: string;
    activated: boolean;
    name?: string | null;
    house?: {
      __typename?: 'HouseForIndex';
      name: string;
      city?: string | null;
      region?: string | null;
    } | null;
  }>;
};

export const AllStatisticFieldsFragmentDoc = gql`
  fragment AllStatisticFields on StatisticsField {
    CURRENT_YEAR
    LAST_YEAR
    SECOND_LAST_YEAR
    THIRD_LAST_YEAR
    FOURTH_LAST_YEAR
    OLDER_THAN_FOURTH_LAST_YEAR
  }
`;
export const AccommodationFieldsFragmentDoc = gql`
  fragment AccommodationFields on Accommodation {
    adult
    childYoung
    childOld
    retiree
    executive
    externalAdult
    externalChild
  }
`;
export const VacationPackageChargeFieldsFragmentDoc = gql`
  fragment VacationPackageChargeFields on VacationOfferCharge {
    fullBoard {
      bundle {
        ...AccommodationFields
      }
    }
    halfBoard {
      bundle {
        ...AccommodationFields
      }
    }
    overnightBreakfast {
      bundle {
        ...AccommodationFields
      }
    }
    selfSupply {
      bundle {
        ...AccommodationFields
      }
    }
  }
`;
export const FurtherPositionFieldsFragmentDoc = gql`
  fragment FurtherPositionFields on FurtherPosition {
    cleaning
    costsPerNight
    visitorsTaxSummer
    visitorsTaxWinter
  }
`;
export const HouseBoardFieldsFragmentDoc = gql`
  fragment HouseBoardFields on HouseBoard {
    activated
    accommodation {
      ...AccommodationFields
    }
    furtherPosition {
      ...FurtherPositionFields
    }
  }
`;
export const HouseChargeFieldsFragmentDoc = gql`
  fragment HouseChargeFields on HouseCharge {
    fullBoard {
      ...HouseBoardFields
    }
    halfBoard {
      ...HouseBoardFields
    }
    overnightBreakfast {
      ...HouseBoardFields
    }
    selfSupply {
      ...HouseBoardFields
    }
  }
`;
export const CountervailingBenefitFieldsFragmentDoc = gql`
  fragment CountervailingBenefitFields on CountervailingBenefit {
    firstAdult
    secondAdult
    childYoung
    childOld
    perNight
  }
`;
export const FixedChargeFieldsFragmentDoc = gql`
  fragment FixedChargeFields on FixedCharge {
    activated
    accommodation {
      ...AccommodationFields
    }
    furtherPosition {
      ...FurtherPositionFields
    }
    countervailingBenefit {
      ...CountervailingBenefitFields
    }
  }
`;
export const FixedChargesFieldsFragmentDoc = gql`
  fragment FixedChargesFields on FixedCharges {
    house {
      fullBoard {
        ...FixedChargeFields
      }
      halfBoard {
        ...FixedChargeFields
      }
      overnightBreakfast {
        ...FixedChargeFields
      }
      selfSupply {
        ...FixedChargeFields
      }
    }
    vacationPackage {
      fullBoard {
        ...AccommodationFields
      }
      halfBoard {
        ...AccommodationFields
      }
      overnightBreakfast {
        ...AccommodationFields
      }
      selfSupply {
        ...AccommodationFields
      }
    }
  }
`;
export const PriceChangeFieldsFragmentDoc = gql`
  fragment PriceChangeFields on PriceChange {
    increment
    decrement
    remark
  }
`;
export const GetTokenDocument = gql`
  query GetToken {
    getToken
  }
`;

/**
 * __useGetTokenQuery__
 *
 * To run a query within a React component, call `useGetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTokenQuery, GetTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTokenQuery, GetTokenQueryVariables>(
    GetTokenDocument,
    options,
  );
}
export function useGetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTokenQuery,
    GetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTokenQuery, GetTokenQueryVariables>(
    GetTokenDocument,
    options,
  );
}
export type GetTokenQueryHookResult = ReturnType<typeof useGetTokenQuery>;
export type GetTokenLazyQueryHookResult = ReturnType<
  typeof useGetTokenLazyQuery
>;
export type GetTokenQueryResult = Apollo.QueryResult<
  GetTokenQuery,
  GetTokenQueryVariables
>;
export function refetchGetTokenQuery(variables?: GetTokenQueryVariables) {
  return { query: GetTokenDocument, variables: variables };
}
export const FreePlaceListUrl_UpdateSettingsDocument = gql`
  mutation FreePlaceListUrl_UpdateSettings($settings: SettingsInput!) {
    updateSettings(settings: $settings) {
      name
      eonHousesFreePlacesList
    }
  }
`;
export type FreePlaceListUrl_UpdateSettingsMutationFn = Apollo.MutationFunction<
  FreePlaceListUrl_UpdateSettingsMutation,
  FreePlaceListUrl_UpdateSettingsMutationVariables
>;

/**
 * __useFreePlaceListUrl_UpdateSettingsMutation__
 *
 * To run a mutation, you first call `useFreePlaceListUrl_UpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreePlaceListUrl_UpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freePlaceListUrlUpdateSettingsMutation, { data, loading, error }] = useFreePlaceListUrl_UpdateSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useFreePlaceListUrl_UpdateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FreePlaceListUrl_UpdateSettingsMutation,
    FreePlaceListUrl_UpdateSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FreePlaceListUrl_UpdateSettingsMutation,
    FreePlaceListUrl_UpdateSettingsMutationVariables
  >(FreePlaceListUrl_UpdateSettingsDocument, options);
}
export type FreePlaceListUrl_UpdateSettingsMutationHookResult = ReturnType<
  typeof useFreePlaceListUrl_UpdateSettingsMutation
>;
export type FreePlaceListUrl_UpdateSettingsMutationResult =
  Apollo.MutationResult<FreePlaceListUrl_UpdateSettingsMutation>;
export type FreePlaceListUrl_UpdateSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    FreePlaceListUrl_UpdateSettingsMutation,
    FreePlaceListUrl_UpdateSettingsMutationVariables
  >;
export const FreePlaceListUrl_GetSettingsDocument = gql`
  query FreePlaceListUrl_GetSettings {
    getSettings {
      name
      eonHousesFreePlacesList
    }
  }
`;

/**
 * __useFreePlaceListUrl_GetSettingsQuery__
 *
 * To run a query within a React component, call `useFreePlaceListUrl_GetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreePlaceListUrl_GetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreePlaceListUrl_GetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFreePlaceListUrl_GetSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FreePlaceListUrl_GetSettingsQuery,
    FreePlaceListUrl_GetSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FreePlaceListUrl_GetSettingsQuery,
    FreePlaceListUrl_GetSettingsQueryVariables
  >(FreePlaceListUrl_GetSettingsDocument, options);
}
export function useFreePlaceListUrl_GetSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FreePlaceListUrl_GetSettingsQuery,
    FreePlaceListUrl_GetSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FreePlaceListUrl_GetSettingsQuery,
    FreePlaceListUrl_GetSettingsQueryVariables
  >(FreePlaceListUrl_GetSettingsDocument, options);
}
export type FreePlaceListUrl_GetSettingsQueryHookResult = ReturnType<
  typeof useFreePlaceListUrl_GetSettingsQuery
>;
export type FreePlaceListUrl_GetSettingsLazyQueryHookResult = ReturnType<
  typeof useFreePlaceListUrl_GetSettingsLazyQuery
>;
export type FreePlaceListUrl_GetSettingsQueryResult = Apollo.QueryResult<
  FreePlaceListUrl_GetSettingsQuery,
  FreePlaceListUrl_GetSettingsQueryVariables
>;
export function refetchFreePlaceListUrl_GetSettingsQuery(
  variables?: FreePlaceListUrl_GetSettingsQueryVariables,
) {
  return { query: FreePlaceListUrl_GetSettingsDocument, variables: variables };
}
export const GetEonHousesAndOffersDocument = gql`
  query GetEonHousesAndOffers {
    eonHouses {
      id
      eonOffers {
        bookingDate {
          arrival
          departure
        }
        countervailingBenefit {
          childOld
          childYoung
          firstAdult
          perNight
          secondAdult
        }
        eonBookingId
      }
      name
      region
    }
  }
`;

/**
 * __useGetEonHousesAndOffersQuery__
 *
 * To run a query within a React component, call `useGetEonHousesAndOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEonHousesAndOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEonHousesAndOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEonHousesAndOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEonHousesAndOffersQuery,
    GetEonHousesAndOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEonHousesAndOffersQuery,
    GetEonHousesAndOffersQueryVariables
  >(GetEonHousesAndOffersDocument, options);
}
export function useGetEonHousesAndOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEonHousesAndOffersQuery,
    GetEonHousesAndOffersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEonHousesAndOffersQuery,
    GetEonHousesAndOffersQueryVariables
  >(GetEonHousesAndOffersDocument, options);
}
export type GetEonHousesAndOffersQueryHookResult = ReturnType<
  typeof useGetEonHousesAndOffersQuery
>;
export type GetEonHousesAndOffersLazyQueryHookResult = ReturnType<
  typeof useGetEonHousesAndOffersLazyQuery
>;
export type GetEonHousesAndOffersQueryResult = Apollo.QueryResult<
  GetEonHousesAndOffersQuery,
  GetEonHousesAndOffersQueryVariables
>;
export function refetchGetEonHousesAndOffersQuery(
  variables?: GetEonHousesAndOffersQueryVariables,
) {
  return { query: GetEonHousesAndOffersDocument, variables: variables };
}
export const CreateEonOffersDocument = gql`
  mutation CreateEonOffers($offers: [EonOfferWithHouseIdInput!]!) {
    createEonOffers(offers: $offers) {
      id
    }
  }
`;
export type CreateEonOffersMutationFn = Apollo.MutationFunction<
  CreateEonOffersMutation,
  CreateEonOffersMutationVariables
>;

/**
 * __useCreateEonOffersMutation__
 *
 * To run a mutation, you first call `useCreateEonOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEonOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEonOffersMutation, { data, loading, error }] = useCreateEonOffersMutation({
 *   variables: {
 *      offers: // value for 'offers'
 *   },
 * });
 */
export function useCreateEonOffersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEonOffersMutation,
    CreateEonOffersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEonOffersMutation,
    CreateEonOffersMutationVariables
  >(CreateEonOffersDocument, options);
}
export type CreateEonOffersMutationHookResult = ReturnType<
  typeof useCreateEonOffersMutation
>;
export type CreateEonOffersMutationResult =
  Apollo.MutationResult<CreateEonOffersMutation>;
export type CreateEonOffersMutationOptions = Apollo.BaseMutationOptions<
  CreateEonOffersMutation,
  CreateEonOffersMutationVariables
>;
export const EonOffer_CreateDocument = gql`
  mutation EonOffer_Create($houseId: String!, $offer: EonOfferInput!) {
    createEonOffer(houseId: $houseId, offer: $offer) {
      id
    }
  }
`;
export type EonOffer_CreateMutationFn = Apollo.MutationFunction<
  EonOffer_CreateMutation,
  EonOffer_CreateMutationVariables
>;

/**
 * __useEonOffer_CreateMutation__
 *
 * To run a mutation, you first call `useEonOffer_CreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEonOffer_CreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eonOfferCreateMutation, { data, loading, error }] = useEonOffer_CreateMutation({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      offer: // value for 'offer'
 *   },
 * });
 */
export function useEonOffer_CreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EonOffer_CreateMutation,
    EonOffer_CreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EonOffer_CreateMutation,
    EonOffer_CreateMutationVariables
  >(EonOffer_CreateDocument, options);
}
export type EonOffer_CreateMutationHookResult = ReturnType<
  typeof useEonOffer_CreateMutation
>;
export type EonOffer_CreateMutationResult =
  Apollo.MutationResult<EonOffer_CreateMutation>;
export type EonOffer_CreateMutationOptions = Apollo.BaseMutationOptions<
  EonOffer_CreateMutation,
  EonOffer_CreateMutationVariables
>;
export const EonOffer_DeleteDocument = gql`
  mutation EonOffer_Delete($id: String!) {
    deleteEonOffer(id: $id)
  }
`;
export type EonOffer_DeleteMutationFn = Apollo.MutationFunction<
  EonOffer_DeleteMutation,
  EonOffer_DeleteMutationVariables
>;

/**
 * __useEonOffer_DeleteMutation__
 *
 * To run a mutation, you first call `useEonOffer_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEonOffer_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eonOfferDeleteMutation, { data, loading, error }] = useEonOffer_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEonOffer_DeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EonOffer_DeleteMutation,
    EonOffer_DeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EonOffer_DeleteMutation,
    EonOffer_DeleteMutationVariables
  >(EonOffer_DeleteDocument, options);
}
export type EonOffer_DeleteMutationHookResult = ReturnType<
  typeof useEonOffer_DeleteMutation
>;
export type EonOffer_DeleteMutationResult =
  Apollo.MutationResult<EonOffer_DeleteMutation>;
export type EonOffer_DeleteMutationOptions = Apollo.BaseMutationOptions<
  EonOffer_DeleteMutation,
  EonOffer_DeleteMutationVariables
>;
export const EonOffer_UpdateDocument = gql`
  mutation EonOffer_Update($offer: EonOfferUpdateInput!, $offerId: String!) {
    updateEonOffer(offer: $offer, offerId: $offerId) {
      id
    }
  }
`;
export type EonOffer_UpdateMutationFn = Apollo.MutationFunction<
  EonOffer_UpdateMutation,
  EonOffer_UpdateMutationVariables
>;

/**
 * __useEonOffer_UpdateMutation__
 *
 * To run a mutation, you first call `useEonOffer_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEonOffer_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eonOfferUpdateMutation, { data, loading, error }] = useEonOffer_UpdateMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useEonOffer_UpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EonOffer_UpdateMutation,
    EonOffer_UpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EonOffer_UpdateMutation,
    EonOffer_UpdateMutationVariables
  >(EonOffer_UpdateDocument, options);
}
export type EonOffer_UpdateMutationHookResult = ReturnType<
  typeof useEonOffer_UpdateMutation
>;
export type EonOffer_UpdateMutationResult =
  Apollo.MutationResult<EonOffer_UpdateMutation>;
export type EonOffer_UpdateMutationOptions = Apollo.BaseMutationOptions<
  EonOffer_UpdateMutation,
  EonOffer_UpdateMutationVariables
>;
export const Charges_UpdateHouseChargesDocument = gql`
  mutation Charges_UpdateHouseCharges(
    $charges: HouseChargesInput!
    $houseId: String!
  ) {
    updateHouseCharges(charges: $charges, houseId: $houseId)
  }
`;
export type Charges_UpdateHouseChargesMutationFn = Apollo.MutationFunction<
  Charges_UpdateHouseChargesMutation,
  Charges_UpdateHouseChargesMutationVariables
>;

/**
 * __useCharges_UpdateHouseChargesMutation__
 *
 * To run a mutation, you first call `useCharges_UpdateHouseChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCharges_UpdateHouseChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargesUpdateHouseChargesMutation, { data, loading, error }] = useCharges_UpdateHouseChargesMutation({
 *   variables: {
 *      charges: // value for 'charges'
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useCharges_UpdateHouseChargesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Charges_UpdateHouseChargesMutation,
    Charges_UpdateHouseChargesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Charges_UpdateHouseChargesMutation,
    Charges_UpdateHouseChargesMutationVariables
  >(Charges_UpdateHouseChargesDocument, options);
}
export type Charges_UpdateHouseChargesMutationHookResult = ReturnType<
  typeof useCharges_UpdateHouseChargesMutation
>;
export type Charges_UpdateHouseChargesMutationResult =
  Apollo.MutationResult<Charges_UpdateHouseChargesMutation>;
export type Charges_UpdateHouseChargesMutationOptions =
  Apollo.BaseMutationOptions<
    Charges_UpdateHouseChargesMutation,
    Charges_UpdateHouseChargesMutationVariables
  >;
export const Description_UpdateHouseDocument = gql`
  mutation Description_UpdateHouse($house: HouseUpdateInput!, $id: String!) {
    updateHouse(house: $house, id: $id)
  }
`;
export type Description_UpdateHouseMutationFn = Apollo.MutationFunction<
  Description_UpdateHouseMutation,
  Description_UpdateHouseMutationVariables
>;

/**
 * __useDescription_UpdateHouseMutation__
 *
 * To run a mutation, you first call `useDescription_UpdateHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDescription_UpdateHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [descriptionUpdateHouseMutation, { data, loading, error }] = useDescription_UpdateHouseMutation({
 *   variables: {
 *      house: // value for 'house'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDescription_UpdateHouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Description_UpdateHouseMutation,
    Description_UpdateHouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Description_UpdateHouseMutation,
    Description_UpdateHouseMutationVariables
  >(Description_UpdateHouseDocument, options);
}
export type Description_UpdateHouseMutationHookResult = ReturnType<
  typeof useDescription_UpdateHouseMutation
>;
export type Description_UpdateHouseMutationResult =
  Apollo.MutationResult<Description_UpdateHouseMutation>;
export type Description_UpdateHouseMutationOptions = Apollo.BaseMutationOptions<
  Description_UpdateHouseMutation,
  Description_UpdateHouseMutationVariables
>;
export const Header_DeleteHouseDocument = gql`
  mutation Header_DeleteHouse($id: String!) {
    deleteHouse(id: $id)
  }
`;
export type Header_DeleteHouseMutationFn = Apollo.MutationFunction<
  Header_DeleteHouseMutation,
  Header_DeleteHouseMutationVariables
>;

/**
 * __useHeader_DeleteHouseMutation__
 *
 * To run a mutation, you first call `useHeader_DeleteHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeader_DeleteHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerDeleteHouseMutation, { data, loading, error }] = useHeader_DeleteHouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeader_DeleteHouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Header_DeleteHouseMutation,
    Header_DeleteHouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Header_DeleteHouseMutation,
    Header_DeleteHouseMutationVariables
  >(Header_DeleteHouseDocument, options);
}
export type Header_DeleteHouseMutationHookResult = ReturnType<
  typeof useHeader_DeleteHouseMutation
>;
export type Header_DeleteHouseMutationResult =
  Apollo.MutationResult<Header_DeleteHouseMutation>;
export type Header_DeleteHouseMutationOptions = Apollo.BaseMutationOptions<
  Header_DeleteHouseMutation,
  Header_DeleteHouseMutationVariables
>;
export const Header_UpdateHouseDocument = gql`
  mutation Header_UpdateHouse($house: HouseUpdateInput!, $id: String!) {
    updateHouse(house: $house, id: $id)
  }
`;
export type Header_UpdateHouseMutationFn = Apollo.MutationFunction<
  Header_UpdateHouseMutation,
  Header_UpdateHouseMutationVariables
>;

/**
 * __useHeader_UpdateHouseMutation__
 *
 * To run a mutation, you first call `useHeader_UpdateHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeader_UpdateHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerUpdateHouseMutation, { data, loading, error }] = useHeader_UpdateHouseMutation({
 *   variables: {
 *      house: // value for 'house'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeader_UpdateHouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Header_UpdateHouseMutation,
    Header_UpdateHouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Header_UpdateHouseMutation,
    Header_UpdateHouseMutationVariables
  >(Header_UpdateHouseDocument, options);
}
export type Header_UpdateHouseMutationHookResult = ReturnType<
  typeof useHeader_UpdateHouseMutation
>;
export type Header_UpdateHouseMutationResult =
  Apollo.MutationResult<Header_UpdateHouseMutation>;
export type Header_UpdateHouseMutationOptions = Apollo.BaseMutationOptions<
  Header_UpdateHouseMutation,
  Header_UpdateHouseMutationVariables
>;
export const Header_GetIsHouseRemoveableDocument = gql`
  query Header_GetIsHouseRemoveable($id: String!) {
    house(id: $id) {
      isRemoveable
    }
  }
`;

/**
 * __useHeader_GetIsHouseRemoveableQuery__
 *
 * To run a query within a React component, call `useHeader_GetIsHouseRemoveableQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeader_GetIsHouseRemoveableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeader_GetIsHouseRemoveableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeader_GetIsHouseRemoveableQuery(
  baseOptions: Apollo.QueryHookOptions<
    Header_GetIsHouseRemoveableQuery,
    Header_GetIsHouseRemoveableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Header_GetIsHouseRemoveableQuery,
    Header_GetIsHouseRemoveableQueryVariables
  >(Header_GetIsHouseRemoveableDocument, options);
}
export function useHeader_GetIsHouseRemoveableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Header_GetIsHouseRemoveableQuery,
    Header_GetIsHouseRemoveableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Header_GetIsHouseRemoveableQuery,
    Header_GetIsHouseRemoveableQueryVariables
  >(Header_GetIsHouseRemoveableDocument, options);
}
export type Header_GetIsHouseRemoveableQueryHookResult = ReturnType<
  typeof useHeader_GetIsHouseRemoveableQuery
>;
export type Header_GetIsHouseRemoveableLazyQueryHookResult = ReturnType<
  typeof useHeader_GetIsHouseRemoveableLazyQuery
>;
export type Header_GetIsHouseRemoveableQueryResult = Apollo.QueryResult<
  Header_GetIsHouseRemoveableQuery,
  Header_GetIsHouseRemoveableQueryVariables
>;
export function refetchHeader_GetIsHouseRemoveableQuery(
  variables: Header_GetIsHouseRemoveableQueryVariables,
) {
  return { query: Header_GetIsHouseRemoveableDocument, variables: variables };
}
export const HouseAttachments_DeleteFileDocument = gql`
  mutation HouseAttachments_DeleteFile($id: ID!) {
    removeFile(id: $id)
  }
`;
export type HouseAttachments_DeleteFileMutationFn = Apollo.MutationFunction<
  HouseAttachments_DeleteFileMutation,
  HouseAttachments_DeleteFileMutationVariables
>;

/**
 * __useHouseAttachments_DeleteFileMutation__
 *
 * To run a mutation, you first call `useHouseAttachments_DeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseAttachments_DeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseAttachmentsDeleteFileMutation, { data, loading, error }] = useHouseAttachments_DeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseAttachments_DeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HouseAttachments_DeleteFileMutation,
    HouseAttachments_DeleteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HouseAttachments_DeleteFileMutation,
    HouseAttachments_DeleteFileMutationVariables
  >(HouseAttachments_DeleteFileDocument, options);
}
export type HouseAttachments_DeleteFileMutationHookResult = ReturnType<
  typeof useHouseAttachments_DeleteFileMutation
>;
export type HouseAttachments_DeleteFileMutationResult =
  Apollo.MutationResult<HouseAttachments_DeleteFileMutation>;
export type HouseAttachments_DeleteFileMutationOptions =
  Apollo.BaseMutationOptions<
    HouseAttachments_DeleteFileMutation,
    HouseAttachments_DeleteFileMutationVariables
  >;
export const HouseAttachments_UpdateDataFileDocument = gql`
  mutation HouseAttachments_UpdateDataFile(
    $id: ID!
    $file: BinaryUpdateInput!
  ) {
    updateBinary(id: $id, file: $file) {
      id
    }
  }
`;
export type HouseAttachments_UpdateDataFileMutationFn = Apollo.MutationFunction<
  HouseAttachments_UpdateDataFileMutation,
  HouseAttachments_UpdateDataFileMutationVariables
>;

/**
 * __useHouseAttachments_UpdateDataFileMutation__
 *
 * To run a mutation, you first call `useHouseAttachments_UpdateDataFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseAttachments_UpdateDataFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseAttachmentsUpdateDataFileMutation, { data, loading, error }] = useHouseAttachments_UpdateDataFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useHouseAttachments_UpdateDataFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HouseAttachments_UpdateDataFileMutation,
    HouseAttachments_UpdateDataFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HouseAttachments_UpdateDataFileMutation,
    HouseAttachments_UpdateDataFileMutationVariables
  >(HouseAttachments_UpdateDataFileDocument, options);
}
export type HouseAttachments_UpdateDataFileMutationHookResult = ReturnType<
  typeof useHouseAttachments_UpdateDataFileMutation
>;
export type HouseAttachments_UpdateDataFileMutationResult =
  Apollo.MutationResult<HouseAttachments_UpdateDataFileMutation>;
export type HouseAttachments_UpdateDataFileMutationOptions =
  Apollo.BaseMutationOptions<
    HouseAttachments_UpdateDataFileMutation,
    HouseAttachments_UpdateDataFileMutationVariables
  >;
export const HouseAttachments_UpdateFileMetadataDocument = gql`
  mutation HouseAttachments_UpdateFileMetadata(
    $id: ID!
    $file: FileUpdateInput!
  ) {
    updateFile(id: $id, file: $file)
  }
`;
export type HouseAttachments_UpdateFileMetadataMutationFn =
  Apollo.MutationFunction<
    HouseAttachments_UpdateFileMetadataMutation,
    HouseAttachments_UpdateFileMetadataMutationVariables
  >;

/**
 * __useHouseAttachments_UpdateFileMetadataMutation__
 *
 * To run a mutation, you first call `useHouseAttachments_UpdateFileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseAttachments_UpdateFileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseAttachmentsUpdateFileMetadataMutation, { data, loading, error }] = useHouseAttachments_UpdateFileMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useHouseAttachments_UpdateFileMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HouseAttachments_UpdateFileMetadataMutation,
    HouseAttachments_UpdateFileMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HouseAttachments_UpdateFileMetadataMutation,
    HouseAttachments_UpdateFileMetadataMutationVariables
  >(HouseAttachments_UpdateFileMetadataDocument, options);
}
export type HouseAttachments_UpdateFileMetadataMutationHookResult = ReturnType<
  typeof useHouseAttachments_UpdateFileMetadataMutation
>;
export type HouseAttachments_UpdateFileMetadataMutationResult =
  Apollo.MutationResult<HouseAttachments_UpdateFileMetadataMutation>;
export type HouseAttachments_UpdateFileMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    HouseAttachments_UpdateFileMetadataMutation,
    HouseAttachments_UpdateFileMetadataMutationVariables
  >;
export const HouseForm_GetHouseDocument = gql`
  query HouseForm_GetHouse($id: String!) {
    house(id: $id) {
      id
      name
      activated
      isEonHome
      type
      region
      streetAndHouseNo
      postalCode
      city
      telephone
      email
      board
      facilities
      additionalEquipment
      dogAllowed
      furtherInformation
      charges {
        ...HouseChargeFields
      }
      files {
        id
        name
        description
        order
        path
        type
      }
      eonOffers {
        id
        active
        isBooked
        bookingDate {
          arrival
          departure
        }
        socialCreditPoints
        summerHolidays
        eonBookingId
        countervailingBenefit {
          ...CountervailingBenefitFields
        }
      }
    }
  }
  ${HouseChargeFieldsFragmentDoc}
  ${HouseBoardFieldsFragmentDoc}
  ${AccommodationFieldsFragmentDoc}
  ${FurtherPositionFieldsFragmentDoc}
  ${CountervailingBenefitFieldsFragmentDoc}
`;

/**
 * __useHouseForm_GetHouseQuery__
 *
 * To run a query within a React component, call `useHouseForm_GetHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseForm_GetHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseForm_GetHouseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseForm_GetHouseQuery(
  baseOptions: Apollo.QueryHookOptions<
    HouseForm_GetHouseQuery,
    HouseForm_GetHouseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HouseForm_GetHouseQuery,
    HouseForm_GetHouseQueryVariables
  >(HouseForm_GetHouseDocument, options);
}
export function useHouseForm_GetHouseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HouseForm_GetHouseQuery,
    HouseForm_GetHouseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HouseForm_GetHouseQuery,
    HouseForm_GetHouseQueryVariables
  >(HouseForm_GetHouseDocument, options);
}
export type HouseForm_GetHouseQueryHookResult = ReturnType<
  typeof useHouseForm_GetHouseQuery
>;
export type HouseForm_GetHouseLazyQueryHookResult = ReturnType<
  typeof useHouseForm_GetHouseLazyQuery
>;
export type HouseForm_GetHouseQueryResult = Apollo.QueryResult<
  HouseForm_GetHouseQuery,
  HouseForm_GetHouseQueryVariables
>;
export function refetchHouseForm_GetHouseQuery(
  variables: HouseForm_GetHouseQueryVariables,
) {
  return { query: HouseForm_GetHouseDocument, variables: variables };
}
export const NewHouseEntry_CreateHouseDocument = gql`
  mutation NewHouseEntry_CreateHouse($house: HouseInput!) {
    createHouse(house: $house) {
      id
      name
    }
  }
`;
export type NewHouseEntry_CreateHouseMutationFn = Apollo.MutationFunction<
  NewHouseEntry_CreateHouseMutation,
  NewHouseEntry_CreateHouseMutationVariables
>;

/**
 * __useNewHouseEntry_CreateHouseMutation__
 *
 * To run a mutation, you first call `useNewHouseEntry_CreateHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewHouseEntry_CreateHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newHouseEntryCreateHouseMutation, { data, loading, error }] = useNewHouseEntry_CreateHouseMutation({
 *   variables: {
 *      house: // value for 'house'
 *   },
 * });
 */
export function useNewHouseEntry_CreateHouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewHouseEntry_CreateHouseMutation,
    NewHouseEntry_CreateHouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NewHouseEntry_CreateHouseMutation,
    NewHouseEntry_CreateHouseMutationVariables
  >(NewHouseEntry_CreateHouseDocument, options);
}
export type NewHouseEntry_CreateHouseMutationHookResult = ReturnType<
  typeof useNewHouseEntry_CreateHouseMutation
>;
export type NewHouseEntry_CreateHouseMutationResult =
  Apollo.MutationResult<NewHouseEntry_CreateHouseMutation>;
export type NewHouseEntry_CreateHouseMutationOptions =
  Apollo.BaseMutationOptions<
    NewHouseEntry_CreateHouseMutation,
    NewHouseEntry_CreateHouseMutationVariables
  >;
export const Photos_AddHouseFileDocument = gql`
  mutation Photos_AddHouseFile($houseId: ID!, $file: FileInput!) {
    addHouseFile(houseId: $houseId, file: $file) {
      id
      name
      type
      order
      description
      path
      createdAt
    }
  }
`;
export type Photos_AddHouseFileMutationFn = Apollo.MutationFunction<
  Photos_AddHouseFileMutation,
  Photos_AddHouseFileMutationVariables
>;

/**
 * __usePhotos_AddHouseFileMutation__
 *
 * To run a mutation, you first call `usePhotos_AddHouseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotos_AddHouseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photosAddHouseFileMutation, { data, loading, error }] = usePhotos_AddHouseFileMutation({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function usePhotos_AddHouseFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Photos_AddHouseFileMutation,
    Photos_AddHouseFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Photos_AddHouseFileMutation,
    Photos_AddHouseFileMutationVariables
  >(Photos_AddHouseFileDocument, options);
}
export type Photos_AddHouseFileMutationHookResult = ReturnType<
  typeof usePhotos_AddHouseFileMutation
>;
export type Photos_AddHouseFileMutationResult =
  Apollo.MutationResult<Photos_AddHouseFileMutation>;
export type Photos_AddHouseFileMutationOptions = Apollo.BaseMutationOptions<
  Photos_AddHouseFileMutation,
  Photos_AddHouseFileMutationVariables
>;
export const Photos_DeleteFileDocument = gql`
  mutation Photos_DeleteFile($id: ID!) {
    removeFile(id: $id)
  }
`;
export type Photos_DeleteFileMutationFn = Apollo.MutationFunction<
  Photos_DeleteFileMutation,
  Photos_DeleteFileMutationVariables
>;

/**
 * __usePhotos_DeleteFileMutation__
 *
 * To run a mutation, you first call `usePhotos_DeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotos_DeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photosDeleteFileMutation, { data, loading, error }] = usePhotos_DeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhotos_DeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Photos_DeleteFileMutation,
    Photos_DeleteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Photos_DeleteFileMutation,
    Photos_DeleteFileMutationVariables
  >(Photos_DeleteFileDocument, options);
}
export type Photos_DeleteFileMutationHookResult = ReturnType<
  typeof usePhotos_DeleteFileMutation
>;
export type Photos_DeleteFileMutationResult =
  Apollo.MutationResult<Photos_DeleteFileMutation>;
export type Photos_DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  Photos_DeleteFileMutation,
  Photos_DeleteFileMutationVariables
>;
export const Photos_UpdateFileMetadataDocument = gql`
  mutation Photos_UpdateFileMetadata($id: ID!, $file: FileUpdateInput!) {
    updateFile(id: $id, file: $file)
  }
`;
export type Photos_UpdateFileMetadataMutationFn = Apollo.MutationFunction<
  Photos_UpdateFileMetadataMutation,
  Photos_UpdateFileMetadataMutationVariables
>;

/**
 * __usePhotos_UpdateFileMetadataMutation__
 *
 * To run a mutation, you first call `usePhotos_UpdateFileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotos_UpdateFileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photosUpdateFileMetadataMutation, { data, loading, error }] = usePhotos_UpdateFileMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function usePhotos_UpdateFileMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Photos_UpdateFileMetadataMutation,
    Photos_UpdateFileMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Photos_UpdateFileMetadataMutation,
    Photos_UpdateFileMetadataMutationVariables
  >(Photos_UpdateFileMetadataDocument, options);
}
export type Photos_UpdateFileMetadataMutationHookResult = ReturnType<
  typeof usePhotos_UpdateFileMetadataMutation
>;
export type Photos_UpdateFileMetadataMutationResult =
  Apollo.MutationResult<Photos_UpdateFileMetadataMutation>;
export type Photos_UpdateFileMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    Photos_UpdateFileMetadataMutation,
    Photos_UpdateFileMetadataMutationVariables
  >;
export const UploadHouseFile_AddHouseFileDocument = gql`
  mutation UploadHouseFile_AddHouseFile($houseId: ID!, $file: FileInput!) {
    addHouseFile(houseId: $houseId, file: $file) {
      id
      name
      type
      order
      description
      path
      createdAt
    }
  }
`;
export type UploadHouseFile_AddHouseFileMutationFn = Apollo.MutationFunction<
  UploadHouseFile_AddHouseFileMutation,
  UploadHouseFile_AddHouseFileMutationVariables
>;

/**
 * __useUploadHouseFile_AddHouseFileMutation__
 *
 * To run a mutation, you first call `useUploadHouseFile_AddHouseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadHouseFile_AddHouseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadHouseFileAddHouseFileMutation, { data, loading, error }] = useUploadHouseFile_AddHouseFileMutation({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadHouseFile_AddHouseFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadHouseFile_AddHouseFileMutation,
    UploadHouseFile_AddHouseFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadHouseFile_AddHouseFileMutation,
    UploadHouseFile_AddHouseFileMutationVariables
  >(UploadHouseFile_AddHouseFileDocument, options);
}
export type UploadHouseFile_AddHouseFileMutationHookResult = ReturnType<
  typeof useUploadHouseFile_AddHouseFileMutation
>;
export type UploadHouseFile_AddHouseFileMutationResult =
  Apollo.MutationResult<UploadHouseFile_AddHouseFileMutation>;
export type UploadHouseFile_AddHouseFileMutationOptions =
  Apollo.BaseMutationOptions<
    UploadHouseFile_AddHouseFileMutation,
    UploadHouseFile_AddHouseFileMutationVariables
  >;
export const BookingDates_CreateBookingDocument = gql`
  mutation BookingDates_CreateBooking(
    $offerId: String!
    $bookingOffer: BookingOfferInput!
  ) {
    createBookingOffer(offerId: $offerId, bookingOffer: $bookingOffer) {
      id
    }
  }
`;
export type BookingDates_CreateBookingMutationFn = Apollo.MutationFunction<
  BookingDates_CreateBookingMutation,
  BookingDates_CreateBookingMutationVariables
>;

/**
 * __useBookingDates_CreateBookingMutation__
 *
 * To run a mutation, you first call `useBookingDates_CreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingDates_CreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingDatesCreateBookingMutation, { data, loading, error }] = useBookingDates_CreateBookingMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      bookingOffer: // value for 'bookingOffer'
 *   },
 * });
 */
export function useBookingDates_CreateBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookingDates_CreateBookingMutation,
    BookingDates_CreateBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookingDates_CreateBookingMutation,
    BookingDates_CreateBookingMutationVariables
  >(BookingDates_CreateBookingDocument, options);
}
export type BookingDates_CreateBookingMutationHookResult = ReturnType<
  typeof useBookingDates_CreateBookingMutation
>;
export type BookingDates_CreateBookingMutationResult =
  Apollo.MutationResult<BookingDates_CreateBookingMutation>;
export type BookingDates_CreateBookingMutationOptions =
  Apollo.BaseMutationOptions<
    BookingDates_CreateBookingMutation,
    BookingDates_CreateBookingMutationVariables
  >;
export const BookingDates_DeleteBookingOfferDocument = gql`
  mutation BookingDates_DeleteBookingOffer($id: String!) {
    deleteBookingOffer(id: $id)
  }
`;
export type BookingDates_DeleteBookingOfferMutationFn = Apollo.MutationFunction<
  BookingDates_DeleteBookingOfferMutation,
  BookingDates_DeleteBookingOfferMutationVariables
>;

/**
 * __useBookingDates_DeleteBookingOfferMutation__
 *
 * To run a mutation, you first call `useBookingDates_DeleteBookingOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingDates_DeleteBookingOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingDatesDeleteBookingOfferMutation, { data, loading, error }] = useBookingDates_DeleteBookingOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingDates_DeleteBookingOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookingDates_DeleteBookingOfferMutation,
    BookingDates_DeleteBookingOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookingDates_DeleteBookingOfferMutation,
    BookingDates_DeleteBookingOfferMutationVariables
  >(BookingDates_DeleteBookingOfferDocument, options);
}
export type BookingDates_DeleteBookingOfferMutationHookResult = ReturnType<
  typeof useBookingDates_DeleteBookingOfferMutation
>;
export type BookingDates_DeleteBookingOfferMutationResult =
  Apollo.MutationResult<BookingDates_DeleteBookingOfferMutation>;
export type BookingDates_DeleteBookingOfferMutationOptions =
  Apollo.BaseMutationOptions<
    BookingDates_DeleteBookingOfferMutation,
    BookingDates_DeleteBookingOfferMutationVariables
  >;
export const BookingDates_UpdateBookingOfferDocument = gql`
  mutation BookingDates_UpdateBookingOffer(
    $bookingOffer: BookingOfferUpdateInput!
    $bookingOfferId: String!
  ) {
    updateBookingOffer(
      bookingOffer: $bookingOffer
      bookingOfferId: $bookingOfferId
    ) {
      id
    }
  }
`;
export type BookingDates_UpdateBookingOfferMutationFn = Apollo.MutationFunction<
  BookingDates_UpdateBookingOfferMutation,
  BookingDates_UpdateBookingOfferMutationVariables
>;

/**
 * __useBookingDates_UpdateBookingOfferMutation__
 *
 * To run a mutation, you first call `useBookingDates_UpdateBookingOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingDates_UpdateBookingOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingDatesUpdateBookingOfferMutation, { data, loading, error }] = useBookingDates_UpdateBookingOfferMutation({
 *   variables: {
 *      bookingOffer: // value for 'bookingOffer'
 *      bookingOfferId: // value for 'bookingOfferId'
 *   },
 * });
 */
export function useBookingDates_UpdateBookingOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookingDates_UpdateBookingOfferMutation,
    BookingDates_UpdateBookingOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BookingDates_UpdateBookingOfferMutation,
    BookingDates_UpdateBookingOfferMutationVariables
  >(BookingDates_UpdateBookingOfferDocument, options);
}
export type BookingDates_UpdateBookingOfferMutationHookResult = ReturnType<
  typeof useBookingDates_UpdateBookingOfferMutation
>;
export type BookingDates_UpdateBookingOfferMutationResult =
  Apollo.MutationResult<BookingDates_UpdateBookingOfferMutation>;
export type BookingDates_UpdateBookingOfferMutationOptions =
  Apollo.BaseMutationOptions<
    BookingDates_UpdateBookingOfferMutation,
    BookingDates_UpdateBookingOfferMutationVariables
  >;
export const Header_UpdateVacationOfferDocument = gql`
  mutation Header_UpdateVacationOffer(
    $offer: VacationOfferUpdateInput!
    $id: String!
  ) {
    updateVacationOffer(offer: $offer, id: $id)
  }
`;
export type Header_UpdateVacationOfferMutationFn = Apollo.MutationFunction<
  Header_UpdateVacationOfferMutation,
  Header_UpdateVacationOfferMutationVariables
>;

/**
 * __useHeader_UpdateVacationOfferMutation__
 *
 * To run a mutation, you first call `useHeader_UpdateVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeader_UpdateVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerUpdateVacationOfferMutation, { data, loading, error }] = useHeader_UpdateVacationOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeader_UpdateVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Header_UpdateVacationOfferMutation,
    Header_UpdateVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Header_UpdateVacationOfferMutation,
    Header_UpdateVacationOfferMutationVariables
  >(Header_UpdateVacationOfferDocument, options);
}
export type Header_UpdateVacationOfferMutationHookResult = ReturnType<
  typeof useHeader_UpdateVacationOfferMutation
>;
export type Header_UpdateVacationOfferMutationResult =
  Apollo.MutationResult<Header_UpdateVacationOfferMutation>;
export type Header_UpdateVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    Header_UpdateVacationOfferMutation,
    Header_UpdateVacationOfferMutationVariables
  >;
export const Header_DeleteVacationOfferDocument = gql`
  mutation Header_DeleteVacationOffer($id: String!) {
    deleteVacationOffer(id: $id)
  }
`;
export type Header_DeleteVacationOfferMutationFn = Apollo.MutationFunction<
  Header_DeleteVacationOfferMutation,
  Header_DeleteVacationOfferMutationVariables
>;

/**
 * __useHeader_DeleteVacationOfferMutation__
 *
 * To run a mutation, you first call `useHeader_DeleteVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHeader_DeleteVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [headerDeleteVacationOfferMutation, { data, loading, error }] = useHeader_DeleteVacationOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeader_DeleteVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Header_DeleteVacationOfferMutation,
    Header_DeleteVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Header_DeleteVacationOfferMutation,
    Header_DeleteVacationOfferMutationVariables
  >(Header_DeleteVacationOfferDocument, options);
}
export type Header_DeleteVacationOfferMutationHookResult = ReturnType<
  typeof useHeader_DeleteVacationOfferMutation
>;
export type Header_DeleteVacationOfferMutationResult =
  Apollo.MutationResult<Header_DeleteVacationOfferMutation>;
export type Header_DeleteVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    Header_DeleteVacationOfferMutation,
    Header_DeleteVacationOfferMutationVariables
  >;
export const HouseChoiceModal_UpdateVacationOfferDocument = gql`
  mutation HouseChoiceModal_UpdateVacationOffer(
    $offer: VacationOfferUpdateInput!
    $id: String!
  ) {
    updateVacationOffer(offer: $offer, id: $id)
  }
`;
export type HouseChoiceModal_UpdateVacationOfferMutationFn =
  Apollo.MutationFunction<
    HouseChoiceModal_UpdateVacationOfferMutation,
    HouseChoiceModal_UpdateVacationOfferMutationVariables
  >;

/**
 * __useHouseChoiceModal_UpdateVacationOfferMutation__
 *
 * To run a mutation, you first call `useHouseChoiceModal_UpdateVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseChoiceModal_UpdateVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseChoiceModalUpdateVacationOfferMutation, { data, loading, error }] = useHouseChoiceModal_UpdateVacationOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseChoiceModal_UpdateVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HouseChoiceModal_UpdateVacationOfferMutation,
    HouseChoiceModal_UpdateVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HouseChoiceModal_UpdateVacationOfferMutation,
    HouseChoiceModal_UpdateVacationOfferMutationVariables
  >(HouseChoiceModal_UpdateVacationOfferDocument, options);
}
export type HouseChoiceModal_UpdateVacationOfferMutationHookResult = ReturnType<
  typeof useHouseChoiceModal_UpdateVacationOfferMutation
>;
export type HouseChoiceModal_UpdateVacationOfferMutationResult =
  Apollo.MutationResult<HouseChoiceModal_UpdateVacationOfferMutation>;
export type HouseChoiceModal_UpdateVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    HouseChoiceModal_UpdateVacationOfferMutation,
    HouseChoiceModal_UpdateVacationOfferMutationVariables
  >;
export const HouseChoiceModal_GetHawHousesDocument = gql`
  query HouseChoiceModal_GetHawHouses {
    houses {
      id
      name
    }
  }
`;

/**
 * __useHouseChoiceModal_GetHawHousesQuery__
 *
 * To run a query within a React component, call `useHouseChoiceModal_GetHawHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseChoiceModal_GetHawHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseChoiceModal_GetHawHousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHouseChoiceModal_GetHawHousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HouseChoiceModal_GetHawHousesQuery,
    HouseChoiceModal_GetHawHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HouseChoiceModal_GetHawHousesQuery,
    HouseChoiceModal_GetHawHousesQueryVariables
  >(HouseChoiceModal_GetHawHousesDocument, options);
}
export function useHouseChoiceModal_GetHawHousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HouseChoiceModal_GetHawHousesQuery,
    HouseChoiceModal_GetHawHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HouseChoiceModal_GetHawHousesQuery,
    HouseChoiceModal_GetHawHousesQueryVariables
  >(HouseChoiceModal_GetHawHousesDocument, options);
}
export type HouseChoiceModal_GetHawHousesQueryHookResult = ReturnType<
  typeof useHouseChoiceModal_GetHawHousesQuery
>;
export type HouseChoiceModal_GetHawHousesLazyQueryHookResult = ReturnType<
  typeof useHouseChoiceModal_GetHawHousesLazyQuery
>;
export type HouseChoiceModal_GetHawHousesQueryResult = Apollo.QueryResult<
  HouseChoiceModal_GetHawHousesQuery,
  HouseChoiceModal_GetHawHousesQueryVariables
>;
export function refetchHouseChoiceModal_GetHawHousesQuery(
  variables?: HouseChoiceModal_GetHawHousesQueryVariables,
) {
  return { query: HouseChoiceModal_GetHawHousesDocument, variables: variables };
}
export const HouseDescription_UpdateVacationOfferDocument = gql`
  mutation HouseDescription_UpdateVacationOffer(
    $offer: VacationOfferUpdateInput!
    $id: String!
  ) {
    updateVacationOffer(offer: $offer, id: $id)
  }
`;
export type HouseDescription_UpdateVacationOfferMutationFn =
  Apollo.MutationFunction<
    HouseDescription_UpdateVacationOfferMutation,
    HouseDescription_UpdateVacationOfferMutationVariables
  >;

/**
 * __useHouseDescription_UpdateVacationOfferMutation__
 *
 * To run a mutation, you first call `useHouseDescription_UpdateVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseDescription_UpdateVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseDescriptionUpdateVacationOfferMutation, { data, loading, error }] = useHouseDescription_UpdateVacationOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseDescription_UpdateVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    HouseDescription_UpdateVacationOfferMutation,
    HouseDescription_UpdateVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    HouseDescription_UpdateVacationOfferMutation,
    HouseDescription_UpdateVacationOfferMutationVariables
  >(HouseDescription_UpdateVacationOfferDocument, options);
}
export type HouseDescription_UpdateVacationOfferMutationHookResult = ReturnType<
  typeof useHouseDescription_UpdateVacationOfferMutation
>;
export type HouseDescription_UpdateVacationOfferMutationResult =
  Apollo.MutationResult<HouseDescription_UpdateVacationOfferMutation>;
export type HouseDescription_UpdateVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    HouseDescription_UpdateVacationOfferMutation,
    HouseDescription_UpdateVacationOfferMutationVariables
  >;
export const NewVacationOfferButton_GetHawHousesDocument = gql`
  query NewVacationOfferButton_GetHawHouses {
    houses {
      id
      name
    }
  }
`;

/**
 * __useNewVacationOfferButton_GetHawHousesQuery__
 *
 * To run a query within a React component, call `useNewVacationOfferButton_GetHawHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewVacationOfferButton_GetHawHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewVacationOfferButton_GetHawHousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewVacationOfferButton_GetHawHousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewVacationOfferButton_GetHawHousesQuery,
    NewVacationOfferButton_GetHawHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewVacationOfferButton_GetHawHousesQuery,
    NewVacationOfferButton_GetHawHousesQueryVariables
  >(NewVacationOfferButton_GetHawHousesDocument, options);
}
export function useNewVacationOfferButton_GetHawHousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewVacationOfferButton_GetHawHousesQuery,
    NewVacationOfferButton_GetHawHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewVacationOfferButton_GetHawHousesQuery,
    NewVacationOfferButton_GetHawHousesQueryVariables
  >(NewVacationOfferButton_GetHawHousesDocument, options);
}
export type NewVacationOfferButton_GetHawHousesQueryHookResult = ReturnType<
  typeof useNewVacationOfferButton_GetHawHousesQuery
>;
export type NewVacationOfferButton_GetHawHousesLazyQueryHookResult = ReturnType<
  typeof useNewVacationOfferButton_GetHawHousesLazyQuery
>;
export type NewVacationOfferButton_GetHawHousesQueryResult = Apollo.QueryResult<
  NewVacationOfferButton_GetHawHousesQuery,
  NewVacationOfferButton_GetHawHousesQueryVariables
>;
export function refetchNewVacationOfferButton_GetHawHousesQuery(
  variables?: NewVacationOfferButton_GetHawHousesQueryVariables,
) {
  return {
    query: NewVacationOfferButton_GetHawHousesDocument,
    variables: variables,
  };
}
export const NewVacationOfferButton_CreateVacationOfferDocument = gql`
  mutation NewVacationOfferButton_CreateVacationOffer(
    $houseId: String!
    $offer: VacationOfferInput!
  ) {
    createVacationOffer(houseId: $houseId, offer: $offer) {
      id
    }
  }
`;
export type NewVacationOfferButton_CreateVacationOfferMutationFn =
  Apollo.MutationFunction<
    NewVacationOfferButton_CreateVacationOfferMutation,
    NewVacationOfferButton_CreateVacationOfferMutationVariables
  >;

/**
 * __useNewVacationOfferButton_CreateVacationOfferMutation__
 *
 * To run a mutation, you first call `useNewVacationOfferButton_CreateVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewVacationOfferButton_CreateVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newVacationOfferButtonCreateVacationOfferMutation, { data, loading, error }] = useNewVacationOfferButton_CreateVacationOfferMutation({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      offer: // value for 'offer'
 *   },
 * });
 */
export function useNewVacationOfferButton_CreateVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewVacationOfferButton_CreateVacationOfferMutation,
    NewVacationOfferButton_CreateVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NewVacationOfferButton_CreateVacationOfferMutation,
    NewVacationOfferButton_CreateVacationOfferMutationVariables
  >(NewVacationOfferButton_CreateVacationOfferDocument, options);
}
export type NewVacationOfferButton_CreateVacationOfferMutationHookResult =
  ReturnType<typeof useNewVacationOfferButton_CreateVacationOfferMutation>;
export type NewVacationOfferButton_CreateVacationOfferMutationResult =
  Apollo.MutationResult<NewVacationOfferButton_CreateVacationOfferMutation>;
export type NewVacationOfferButton_CreateVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    NewVacationOfferButton_CreateVacationOfferMutation,
    NewVacationOfferButton_CreateVacationOfferMutationVariables
  >;
export const UploadVacationOfferFile_AddVacationOfferFileDocument = gql`
  mutation UploadVacationOfferFile_AddVacationOfferFile(
    $vacationOfferId: ID!
    $file: FileInput!
  ) {
    addVacationOfferFile(vacationOfferId: $vacationOfferId, file: $file) {
      id
      name
      type
      order
      description
      path
      createdAt
    }
  }
`;
export type UploadVacationOfferFile_AddVacationOfferFileMutationFn =
  Apollo.MutationFunction<
    UploadVacationOfferFile_AddVacationOfferFileMutation,
    UploadVacationOfferFile_AddVacationOfferFileMutationVariables
  >;

/**
 * __useUploadVacationOfferFile_AddVacationOfferFileMutation__
 *
 * To run a mutation, you first call `useUploadVacationOfferFile_AddVacationOfferFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVacationOfferFile_AddVacationOfferFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVacationOfferFileAddVacationOfferFileMutation, { data, loading, error }] = useUploadVacationOfferFile_AddVacationOfferFileMutation({
 *   variables: {
 *      vacationOfferId: // value for 'vacationOfferId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadVacationOfferFile_AddVacationOfferFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadVacationOfferFile_AddVacationOfferFileMutation,
    UploadVacationOfferFile_AddVacationOfferFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadVacationOfferFile_AddVacationOfferFileMutation,
    UploadVacationOfferFile_AddVacationOfferFileMutationVariables
  >(UploadVacationOfferFile_AddVacationOfferFileDocument, options);
}
export type UploadVacationOfferFile_AddVacationOfferFileMutationHookResult =
  ReturnType<typeof useUploadVacationOfferFile_AddVacationOfferFileMutation>;
export type UploadVacationOfferFile_AddVacationOfferFileMutationResult =
  Apollo.MutationResult<UploadVacationOfferFile_AddVacationOfferFileMutation>;
export type UploadVacationOfferFile_AddVacationOfferFileMutationOptions =
  Apollo.BaseMutationOptions<
    UploadVacationOfferFile_AddVacationOfferFileMutation,
    UploadVacationOfferFile_AddVacationOfferFileMutationVariables
  >;
export const VacationNameForm_UpdateVacationOfferDocument = gql`
  mutation VacationNameForm_UpdateVacationOffer(
    $offer: VacationOfferUpdateInput!
    $id: String!
  ) {
    updateVacationOffer(offer: $offer, id: $id)
  }
`;
export type VacationNameForm_UpdateVacationOfferMutationFn =
  Apollo.MutationFunction<
    VacationNameForm_UpdateVacationOfferMutation,
    VacationNameForm_UpdateVacationOfferMutationVariables
  >;

/**
 * __useVacationNameForm_UpdateVacationOfferMutation__
 *
 * To run a mutation, you first call `useVacationNameForm_UpdateVacationOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVacationNameForm_UpdateVacationOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vacationNameFormUpdateVacationOfferMutation, { data, loading, error }] = useVacationNameForm_UpdateVacationOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacationNameForm_UpdateVacationOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VacationNameForm_UpdateVacationOfferMutation,
    VacationNameForm_UpdateVacationOfferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VacationNameForm_UpdateVacationOfferMutation,
    VacationNameForm_UpdateVacationOfferMutationVariables
  >(VacationNameForm_UpdateVacationOfferDocument, options);
}
export type VacationNameForm_UpdateVacationOfferMutationHookResult = ReturnType<
  typeof useVacationNameForm_UpdateVacationOfferMutation
>;
export type VacationNameForm_UpdateVacationOfferMutationResult =
  Apollo.MutationResult<VacationNameForm_UpdateVacationOfferMutation>;
export type VacationNameForm_UpdateVacationOfferMutationOptions =
  Apollo.BaseMutationOptions<
    VacationNameForm_UpdateVacationOfferMutation,
    VacationNameForm_UpdateVacationOfferMutationVariables
  >;
export const VacationOfferAttachments_DeleteFileDocument = gql`
  mutation VacationOfferAttachments_DeleteFile($id: ID!) {
    removeFile(id: $id)
  }
`;
export type VacationOfferAttachments_DeleteFileMutationFn =
  Apollo.MutationFunction<
    VacationOfferAttachments_DeleteFileMutation,
    VacationOfferAttachments_DeleteFileMutationVariables
  >;

/**
 * __useVacationOfferAttachments_DeleteFileMutation__
 *
 * To run a mutation, you first call `useVacationOfferAttachments_DeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVacationOfferAttachments_DeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vacationOfferAttachmentsDeleteFileMutation, { data, loading, error }] = useVacationOfferAttachments_DeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacationOfferAttachments_DeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VacationOfferAttachments_DeleteFileMutation,
    VacationOfferAttachments_DeleteFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VacationOfferAttachments_DeleteFileMutation,
    VacationOfferAttachments_DeleteFileMutationVariables
  >(VacationOfferAttachments_DeleteFileDocument, options);
}
export type VacationOfferAttachments_DeleteFileMutationHookResult = ReturnType<
  typeof useVacationOfferAttachments_DeleteFileMutation
>;
export type VacationOfferAttachments_DeleteFileMutationResult =
  Apollo.MutationResult<VacationOfferAttachments_DeleteFileMutation>;
export type VacationOfferAttachments_DeleteFileMutationOptions =
  Apollo.BaseMutationOptions<
    VacationOfferAttachments_DeleteFileMutation,
    VacationOfferAttachments_DeleteFileMutationVariables
  >;
export const VacationOfferAttachments_UpdateDataFileDocument = gql`
  mutation VacationOfferAttachments_UpdateDataFile(
    $id: ID!
    $file: BinaryUpdateInput!
  ) {
    updateBinary(id: $id, file: $file) {
      id
    }
  }
`;
export type VacationOfferAttachments_UpdateDataFileMutationFn =
  Apollo.MutationFunction<
    VacationOfferAttachments_UpdateDataFileMutation,
    VacationOfferAttachments_UpdateDataFileMutationVariables
  >;

/**
 * __useVacationOfferAttachments_UpdateDataFileMutation__
 *
 * To run a mutation, you first call `useVacationOfferAttachments_UpdateDataFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVacationOfferAttachments_UpdateDataFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vacationOfferAttachmentsUpdateDataFileMutation, { data, loading, error }] = useVacationOfferAttachments_UpdateDataFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useVacationOfferAttachments_UpdateDataFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VacationOfferAttachments_UpdateDataFileMutation,
    VacationOfferAttachments_UpdateDataFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VacationOfferAttachments_UpdateDataFileMutation,
    VacationOfferAttachments_UpdateDataFileMutationVariables
  >(VacationOfferAttachments_UpdateDataFileDocument, options);
}
export type VacationOfferAttachments_UpdateDataFileMutationHookResult =
  ReturnType<typeof useVacationOfferAttachments_UpdateDataFileMutation>;
export type VacationOfferAttachments_UpdateDataFileMutationResult =
  Apollo.MutationResult<VacationOfferAttachments_UpdateDataFileMutation>;
export type VacationOfferAttachments_UpdateDataFileMutationOptions =
  Apollo.BaseMutationOptions<
    VacationOfferAttachments_UpdateDataFileMutation,
    VacationOfferAttachments_UpdateDataFileMutationVariables
  >;
export const VacationOfferAttachments_UpdateFileMetadataDocument = gql`
  mutation VacationOfferAttachments_UpdateFileMetadata(
    $id: ID!
    $file: FileUpdateInput!
  ) {
    updateFile(id: $id, file: $file)
  }
`;
export type VacationOfferAttachments_UpdateFileMetadataMutationFn =
  Apollo.MutationFunction<
    VacationOfferAttachments_UpdateFileMetadataMutation,
    VacationOfferAttachments_UpdateFileMetadataMutationVariables
  >;

/**
 * __useVacationOfferAttachments_UpdateFileMetadataMutation__
 *
 * To run a mutation, you first call `useVacationOfferAttachments_UpdateFileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVacationOfferAttachments_UpdateFileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vacationOfferAttachmentsUpdateFileMetadataMutation, { data, loading, error }] = useVacationOfferAttachments_UpdateFileMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useVacationOfferAttachments_UpdateFileMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VacationOfferAttachments_UpdateFileMetadataMutation,
    VacationOfferAttachments_UpdateFileMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VacationOfferAttachments_UpdateFileMetadataMutation,
    VacationOfferAttachments_UpdateFileMetadataMutationVariables
  >(VacationOfferAttachments_UpdateFileMetadataDocument, options);
}
export type VacationOfferAttachments_UpdateFileMetadataMutationHookResult =
  ReturnType<typeof useVacationOfferAttachments_UpdateFileMetadataMutation>;
export type VacationOfferAttachments_UpdateFileMetadataMutationResult =
  Apollo.MutationResult<VacationOfferAttachments_UpdateFileMetadataMutation>;
export type VacationOfferAttachments_UpdateFileMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    VacationOfferAttachments_UpdateFileMetadataMutation,
    VacationOfferAttachments_UpdateFileMetadataMutationVariables
  >;
export const VacationOfferForm_GetVacationOfferDocument = gql`
  query VacationOfferForm_GetVacationOffer($id: String!) {
    vacationOffer(id: $id) {
      id
      activated
      name
      service
      files {
        id
        name
        description
        path
        order
        type
      }
      charges {
        ...VacationPackageChargeFields
      }
      bookingOffers {
        id
        summerHolidays
        socialCreditPoints
        active
        isBooked
        bookingDate {
          arrival
          departure
        }
        countervailingBenefit {
          ...CountervailingBenefitFields
        }
      }
      createdAt
      house {
        id
        name
        type
        region
        city
        postalCode
        facilities
        streetAndHouseNo
        telephone
        board
        email
        additionalEquipment
        dogAllowed
        files {
          id
          name
          description
          path
          order
          type
        }
        charges {
          ...HouseChargeFields
        }
      }
    }
  }
  ${VacationPackageChargeFieldsFragmentDoc}
  ${AccommodationFieldsFragmentDoc}
  ${CountervailingBenefitFieldsFragmentDoc}
  ${HouseChargeFieldsFragmentDoc}
  ${HouseBoardFieldsFragmentDoc}
  ${FurtherPositionFieldsFragmentDoc}
`;

/**
 * __useVacationOfferForm_GetVacationOfferQuery__
 *
 * To run a query within a React component, call `useVacationOfferForm_GetVacationOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacationOfferForm_GetVacationOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacationOfferForm_GetVacationOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacationOfferForm_GetVacationOfferQuery(
  baseOptions: Apollo.QueryHookOptions<
    VacationOfferForm_GetVacationOfferQuery,
    VacationOfferForm_GetVacationOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VacationOfferForm_GetVacationOfferQuery,
    VacationOfferForm_GetVacationOfferQueryVariables
  >(VacationOfferForm_GetVacationOfferDocument, options);
}
export function useVacationOfferForm_GetVacationOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VacationOfferForm_GetVacationOfferQuery,
    VacationOfferForm_GetVacationOfferQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VacationOfferForm_GetVacationOfferQuery,
    VacationOfferForm_GetVacationOfferQueryVariables
  >(VacationOfferForm_GetVacationOfferDocument, options);
}
export type VacationOfferForm_GetVacationOfferQueryHookResult = ReturnType<
  typeof useVacationOfferForm_GetVacationOfferQuery
>;
export type VacationOfferForm_GetVacationOfferLazyQueryHookResult = ReturnType<
  typeof useVacationOfferForm_GetVacationOfferLazyQuery
>;
export type VacationOfferForm_GetVacationOfferQueryResult = Apollo.QueryResult<
  VacationOfferForm_GetVacationOfferQuery,
  VacationOfferForm_GetVacationOfferQueryVariables
>;
export function refetchVacationOfferForm_GetVacationOfferQuery(
  variables: VacationOfferForm_GetVacationOfferQueryVariables,
) {
  return {
    query: VacationOfferForm_GetVacationOfferDocument,
    variables: variables,
  };
}
export const VacationPrices_UpdateVacationOfferChargesDocument = gql`
  mutation VacationPrices_UpdateVacationOfferCharges(
    $charges: VacationOfferChargesInput!
    $vacationOfferId: String!
  ) {
    updateVacationOfferCharges(
      charges: $charges
      vacationOfferId: $vacationOfferId
    )
  }
`;
export type VacationPrices_UpdateVacationOfferChargesMutationFn =
  Apollo.MutationFunction<
    VacationPrices_UpdateVacationOfferChargesMutation,
    VacationPrices_UpdateVacationOfferChargesMutationVariables
  >;

/**
 * __useVacationPrices_UpdateVacationOfferChargesMutation__
 *
 * To run a mutation, you first call `useVacationPrices_UpdateVacationOfferChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVacationPrices_UpdateVacationOfferChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vacationPricesUpdateVacationOfferChargesMutation, { data, loading, error }] = useVacationPrices_UpdateVacationOfferChargesMutation({
 *   variables: {
 *      charges: // value for 'charges'
 *      vacationOfferId: // value for 'vacationOfferId'
 *   },
 * });
 */
export function useVacationPrices_UpdateVacationOfferChargesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VacationPrices_UpdateVacationOfferChargesMutation,
    VacationPrices_UpdateVacationOfferChargesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VacationPrices_UpdateVacationOfferChargesMutation,
    VacationPrices_UpdateVacationOfferChargesMutationVariables
  >(VacationPrices_UpdateVacationOfferChargesDocument, options);
}
export type VacationPrices_UpdateVacationOfferChargesMutationHookResult =
  ReturnType<typeof useVacationPrices_UpdateVacationOfferChargesMutation>;
export type VacationPrices_UpdateVacationOfferChargesMutationResult =
  Apollo.MutationResult<VacationPrices_UpdateVacationOfferChargesMutation>;
export type VacationPrices_UpdateVacationOfferChargesMutationOptions =
  Apollo.BaseMutationOptions<
    VacationPrices_UpdateVacationOfferChargesMutation,
    VacationPrices_UpdateVacationOfferChargesMutationVariables
  >;
export const FilterEntries_GetDataDocument = gql`
  query FilterEntries_GetData {
    eonAndHawHouses {
      id
      name
    }
    vacationOffers {
      id
      name
    }
  }
`;

/**
 * __useFilterEntries_GetDataQuery__
 *
 * To run a query within a React component, call `useFilterEntries_GetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterEntries_GetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterEntries_GetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilterEntries_GetDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilterEntries_GetDataQuery,
    FilterEntries_GetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilterEntries_GetDataQuery,
    FilterEntries_GetDataQueryVariables
  >(FilterEntries_GetDataDocument, options);
}
export function useFilterEntries_GetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilterEntries_GetDataQuery,
    FilterEntries_GetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilterEntries_GetDataQuery,
    FilterEntries_GetDataQueryVariables
  >(FilterEntries_GetDataDocument, options);
}
export type FilterEntries_GetDataQueryHookResult = ReturnType<
  typeof useFilterEntries_GetDataQuery
>;
export type FilterEntries_GetDataLazyQueryHookResult = ReturnType<
  typeof useFilterEntries_GetDataLazyQuery
>;
export type FilterEntries_GetDataQueryResult = Apollo.QueryResult<
  FilterEntries_GetDataQuery,
  FilterEntries_GetDataQueryVariables
>;
export function refetchFilterEntries_GetDataQuery(
  variables?: FilterEntries_GetDataQueryVariables,
) {
  return { query: FilterEntries_GetDataDocument, variables: variables };
}
export const ActionHandler_UpdateApplicationStatusDocument = gql`
  mutation ActionHandler_UpdateApplicationStatus(
    $applicationID: ID!
    $applicationStatus: ApplicationStatusInput!
  ) {
    updateApplicationStatus(
      applicationID: $applicationID
      applicationStatus: $applicationStatus
    ) {
      id
      processingStatus
    }
  }
`;
export type ActionHandler_UpdateApplicationStatusMutationFn =
  Apollo.MutationFunction<
    ActionHandler_UpdateApplicationStatusMutation,
    ActionHandler_UpdateApplicationStatusMutationVariables
  >;

/**
 * __useActionHandler_UpdateApplicationStatusMutation__
 *
 * To run a mutation, you first call `useActionHandler_UpdateApplicationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionHandler_UpdateApplicationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionHandlerUpdateApplicationStatusMutation, { data, loading, error }] = useActionHandler_UpdateApplicationStatusMutation({
 *   variables: {
 *      applicationID: // value for 'applicationID'
 *      applicationStatus: // value for 'applicationStatus'
 *   },
 * });
 */
export function useActionHandler_UpdateApplicationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionHandler_UpdateApplicationStatusMutation,
    ActionHandler_UpdateApplicationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionHandler_UpdateApplicationStatusMutation,
    ActionHandler_UpdateApplicationStatusMutationVariables
  >(ActionHandler_UpdateApplicationStatusDocument, options);
}
export type ActionHandler_UpdateApplicationStatusMutationHookResult =
  ReturnType<typeof useActionHandler_UpdateApplicationStatusMutation>;
export type ActionHandler_UpdateApplicationStatusMutationResult =
  Apollo.MutationResult<ActionHandler_UpdateApplicationStatusMutation>;
export type ActionHandler_UpdateApplicationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ActionHandler_UpdateApplicationStatusMutation,
    ActionHandler_UpdateApplicationStatusMutationVariables
  >;
export const ActionHandler_CancelApplicationDocument = gql`
  mutation ActionHandler_CancelApplication(
    $applicationID: ID!
    $cancelInput: CancellationInput!
  ) {
    cancelApplication(id: $applicationID, input: $cancelInput) {
      id
      processingStatus
    }
  }
`;
export type ActionHandler_CancelApplicationMutationFn = Apollo.MutationFunction<
  ActionHandler_CancelApplicationMutation,
  ActionHandler_CancelApplicationMutationVariables
>;

/**
 * __useActionHandler_CancelApplicationMutation__
 *
 * To run a mutation, you first call `useActionHandler_CancelApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionHandler_CancelApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionHandlerCancelApplicationMutation, { data, loading, error }] = useActionHandler_CancelApplicationMutation({
 *   variables: {
 *      applicationID: // value for 'applicationID'
 *      cancelInput: // value for 'cancelInput'
 *   },
 * });
 */
export function useActionHandler_CancelApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionHandler_CancelApplicationMutation,
    ActionHandler_CancelApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionHandler_CancelApplicationMutation,
    ActionHandler_CancelApplicationMutationVariables
  >(ActionHandler_CancelApplicationDocument, options);
}
export type ActionHandler_CancelApplicationMutationHookResult = ReturnType<
  typeof useActionHandler_CancelApplicationMutation
>;
export type ActionHandler_CancelApplicationMutationResult =
  Apollo.MutationResult<ActionHandler_CancelApplicationMutation>;
export type ActionHandler_CancelApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    ActionHandler_CancelApplicationMutation,
    ActionHandler_CancelApplicationMutationVariables
  >;
export const ActionHandler_AssignBookingDocument = gql`
  mutation ActionHandler_AssignBooking(
    $assignBookingInput: ConfirmBookingInput!
  ) {
    assignBooking(input: $assignBookingInput) {
      id
      processingStatus
      hawBookings {
        bookingStatus
      }
      eonBookings {
        bookingStatus
      }
    }
  }
`;
export type ActionHandler_AssignBookingMutationFn = Apollo.MutationFunction<
  ActionHandler_AssignBookingMutation,
  ActionHandler_AssignBookingMutationVariables
>;

/**
 * __useActionHandler_AssignBookingMutation__
 *
 * To run a mutation, you first call `useActionHandler_AssignBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionHandler_AssignBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionHandlerAssignBookingMutation, { data, loading, error }] = useActionHandler_AssignBookingMutation({
 *   variables: {
 *      assignBookingInput: // value for 'assignBookingInput'
 *   },
 * });
 */
export function useActionHandler_AssignBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionHandler_AssignBookingMutation,
    ActionHandler_AssignBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionHandler_AssignBookingMutation,
    ActionHandler_AssignBookingMutationVariables
  >(ActionHandler_AssignBookingDocument, options);
}
export type ActionHandler_AssignBookingMutationHookResult = ReturnType<
  typeof useActionHandler_AssignBookingMutation
>;
export type ActionHandler_AssignBookingMutationResult =
  Apollo.MutationResult<ActionHandler_AssignBookingMutation>;
export type ActionHandler_AssignBookingMutationOptions =
  Apollo.BaseMutationOptions<
    ActionHandler_AssignBookingMutation,
    ActionHandler_AssignBookingMutationVariables
  >;
export const ActionHandler_FinishApplicationDocument = gql`
  mutation ActionHandler_FinishApplication($applicationID: ID!) {
    finishApplication(applicationID: $applicationID) {
      id
      processingStatus
    }
  }
`;
export type ActionHandler_FinishApplicationMutationFn = Apollo.MutationFunction<
  ActionHandler_FinishApplicationMutation,
  ActionHandler_FinishApplicationMutationVariables
>;

/**
 * __useActionHandler_FinishApplicationMutation__
 *
 * To run a mutation, you first call `useActionHandler_FinishApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionHandler_FinishApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionHandlerFinishApplicationMutation, { data, loading, error }] = useActionHandler_FinishApplicationMutation({
 *   variables: {
 *      applicationID: // value for 'applicationID'
 *   },
 * });
 */
export function useActionHandler_FinishApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionHandler_FinishApplicationMutation,
    ActionHandler_FinishApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionHandler_FinishApplicationMutation,
    ActionHandler_FinishApplicationMutationVariables
  >(ActionHandler_FinishApplicationDocument, options);
}
export type ActionHandler_FinishApplicationMutationHookResult = ReturnType<
  typeof useActionHandler_FinishApplicationMutation
>;
export type ActionHandler_FinishApplicationMutationResult =
  Apollo.MutationResult<ActionHandler_FinishApplicationMutation>;
export type ActionHandler_FinishApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    ActionHandler_FinishApplicationMutation,
    ActionHandler_FinishApplicationMutationVariables
  >;
export const ApplicationForm_GetVactionOffersHousesDocument = gql`
  query ApplicationForm_GetVactionOffersHouses {
    vacationOffers {
      id
      activated
      name
      house_id
      bookingOffers {
        id
        active
        bookingDate {
          arrival
          departure
        }
        countervailingBenefit {
          ...CountervailingBenefitFields
        }
      }
      charges {
        ...VacationPackageChargeFields
      }
    }
    houses {
      id
      name
      activated
      dogAllowed
      charges {
        ...HouseChargeFields
      }
    }
    eonHouses {
      id
      name
      activated
      dogAllowed
      eonOffers {
        id
        active
        bookingDate {
          arrival
          departure
        }
        countervailingBenefit {
          ...CountervailingBenefitFields
        }
      }
      charges {
        ...HouseChargeFields
      }
    }
  }
  ${CountervailingBenefitFieldsFragmentDoc}
  ${VacationPackageChargeFieldsFragmentDoc}
  ${AccommodationFieldsFragmentDoc}
  ${HouseChargeFieldsFragmentDoc}
  ${HouseBoardFieldsFragmentDoc}
  ${FurtherPositionFieldsFragmentDoc}
`;

/**
 * __useApplicationForm_GetVactionOffersHousesQuery__
 *
 * To run a query within a React component, call `useApplicationForm_GetVactionOffersHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationForm_GetVactionOffersHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationForm_GetVactionOffersHousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationForm_GetVactionOffersHousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationForm_GetVactionOffersHousesQuery,
    ApplicationForm_GetVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationForm_GetVactionOffersHousesQuery,
    ApplicationForm_GetVactionOffersHousesQueryVariables
  >(ApplicationForm_GetVactionOffersHousesDocument, options);
}
export function useApplicationForm_GetVactionOffersHousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationForm_GetVactionOffersHousesQuery,
    ApplicationForm_GetVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationForm_GetVactionOffersHousesQuery,
    ApplicationForm_GetVactionOffersHousesQueryVariables
  >(ApplicationForm_GetVactionOffersHousesDocument, options);
}
export type ApplicationForm_GetVactionOffersHousesQueryHookResult = ReturnType<
  typeof useApplicationForm_GetVactionOffersHousesQuery
>;
export type ApplicationForm_GetVactionOffersHousesLazyQueryHookResult =
  ReturnType<typeof useApplicationForm_GetVactionOffersHousesLazyQuery>;
export type ApplicationForm_GetVactionOffersHousesQueryResult =
  Apollo.QueryResult<
    ApplicationForm_GetVactionOffersHousesQuery,
    ApplicationForm_GetVactionOffersHousesQueryVariables
  >;
export function refetchApplicationForm_GetVactionOffersHousesQuery(
  variables?: ApplicationForm_GetVactionOffersHousesQueryVariables,
) {
  return {
    query: ApplicationForm_GetVactionOffersHousesDocument,
    variables: variables,
  };
}
export const PointsTable_GetPassedApplicationsDocument = gql`
  query PointsTable_GetPassedApplications(
    $forStaffNumber: String
    $comparedToBookingDate: DateTimeISO
  ) {
    getPassedApplications(
      forStaffNumber: $forStaffNumber
      comparedToBookingDate: $comparedToBookingDate
    ) {
      lastYear {
        id
        booking_changes_booking_date_arrival
        booking_changes_booking_date_departure
        booking_changes_summer_holidays
        booked_offer_name
        credit_points
      }
      twoYearsAgo {
        id
        booking_changes_booking_date_arrival
        booking_changes_booking_date_departure
        booking_changes_summer_holidays
        booked_offer_name
        credit_points
      }
      threeYearsAgo {
        id
        booking_changes_booking_date_arrival
        booking_changes_booking_date_departure
        booking_changes_summer_holidays
        booked_offer_name
        credit_points
      }
    }
  }
`;

/**
 * __usePointsTable_GetPassedApplicationsQuery__
 *
 * To run a query within a React component, call `usePointsTable_GetPassedApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsTable_GetPassedApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsTable_GetPassedApplicationsQuery({
 *   variables: {
 *      forStaffNumber: // value for 'forStaffNumber'
 *      comparedToBookingDate: // value for 'comparedToBookingDate'
 *   },
 * });
 */
export function usePointsTable_GetPassedApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PointsTable_GetPassedApplicationsQuery,
    PointsTable_GetPassedApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PointsTable_GetPassedApplicationsQuery,
    PointsTable_GetPassedApplicationsQueryVariables
  >(PointsTable_GetPassedApplicationsDocument, options);
}
export function usePointsTable_GetPassedApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PointsTable_GetPassedApplicationsQuery,
    PointsTable_GetPassedApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PointsTable_GetPassedApplicationsQuery,
    PointsTable_GetPassedApplicationsQueryVariables
  >(PointsTable_GetPassedApplicationsDocument, options);
}
export type PointsTable_GetPassedApplicationsQueryHookResult = ReturnType<
  typeof usePointsTable_GetPassedApplicationsQuery
>;
export type PointsTable_GetPassedApplicationsLazyQueryHookResult = ReturnType<
  typeof usePointsTable_GetPassedApplicationsLazyQuery
>;
export type PointsTable_GetPassedApplicationsQueryResult = Apollo.QueryResult<
  PointsTable_GetPassedApplicationsQuery,
  PointsTable_GetPassedApplicationsQueryVariables
>;
export function refetchPointsTable_GetPassedApplicationsQuery(
  variables?: PointsTable_GetPassedApplicationsQueryVariables,
) {
  return {
    query: PointsTable_GetPassedApplicationsDocument,
    variables: variables,
  };
}
export const BoardBilling_Filter_GetDataDocument = gql`
  query BoardBilling_Filter_GetData {
    houses {
      id
      name
    }
    vacationOffers {
      id
      name
    }
  }
`;

/**
 * __useBoardBilling_Filter_GetDataQuery__
 *
 * To run a query within a React component, call `useBoardBilling_Filter_GetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardBilling_Filter_GetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardBilling_Filter_GetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBoardBilling_Filter_GetDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BoardBilling_Filter_GetDataQuery,
    BoardBilling_Filter_GetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardBilling_Filter_GetDataQuery,
    BoardBilling_Filter_GetDataQueryVariables
  >(BoardBilling_Filter_GetDataDocument, options);
}
export function useBoardBilling_Filter_GetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardBilling_Filter_GetDataQuery,
    BoardBilling_Filter_GetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardBilling_Filter_GetDataQuery,
    BoardBilling_Filter_GetDataQueryVariables
  >(BoardBilling_Filter_GetDataDocument, options);
}
export type BoardBilling_Filter_GetDataQueryHookResult = ReturnType<
  typeof useBoardBilling_Filter_GetDataQuery
>;
export type BoardBilling_Filter_GetDataLazyQueryHookResult = ReturnType<
  typeof useBoardBilling_Filter_GetDataLazyQuery
>;
export type BoardBilling_Filter_GetDataQueryResult = Apollo.QueryResult<
  BoardBilling_Filter_GetDataQuery,
  BoardBilling_Filter_GetDataQueryVariables
>;
export function refetchBoardBilling_Filter_GetDataQuery(
  variables?: BoardBilling_Filter_GetDataQueryVariables,
) {
  return { query: BoardBilling_Filter_GetDataDocument, variables: variables };
}
export const News_UpdateSettingsDocument = gql`
  mutation News_UpdateSettings($settings: SettingsInput!) {
    updateSettings(settings: $settings) {
      name
      news
    }
  }
`;
export type News_UpdateSettingsMutationFn = Apollo.MutationFunction<
  News_UpdateSettingsMutation,
  News_UpdateSettingsMutationVariables
>;

/**
 * __useNews_UpdateSettingsMutation__
 *
 * To run a mutation, you first call `useNews_UpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNews_UpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsUpdateSettingsMutation, { data, loading, error }] = useNews_UpdateSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useNews_UpdateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    News_UpdateSettingsMutation,
    News_UpdateSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    News_UpdateSettingsMutation,
    News_UpdateSettingsMutationVariables
  >(News_UpdateSettingsDocument, options);
}
export type News_UpdateSettingsMutationHookResult = ReturnType<
  typeof useNews_UpdateSettingsMutation
>;
export type News_UpdateSettingsMutationResult =
  Apollo.MutationResult<News_UpdateSettingsMutation>;
export type News_UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<
  News_UpdateSettingsMutation,
  News_UpdateSettingsMutationVariables
>;
export const News_GetSettingsDocument = gql`
  query News_GetSettings {
    getSettings {
      name
      news
    }
  }
`;

/**
 * __useNews_GetSettingsQuery__
 *
 * To run a query within a React component, call `useNews_GetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNews_GetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNews_GetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNews_GetSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    News_GetSettingsQuery,
    News_GetSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<News_GetSettingsQuery, News_GetSettingsQueryVariables>(
    News_GetSettingsDocument,
    options,
  );
}
export function useNews_GetSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    News_GetSettingsQuery,
    News_GetSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    News_GetSettingsQuery,
    News_GetSettingsQueryVariables
  >(News_GetSettingsDocument, options);
}
export type News_GetSettingsQueryHookResult = ReturnType<
  typeof useNews_GetSettingsQuery
>;
export type News_GetSettingsLazyQueryHookResult = ReturnType<
  typeof useNews_GetSettingsLazyQuery
>;
export type News_GetSettingsQueryResult = Apollo.QueryResult<
  News_GetSettingsQuery,
  News_GetSettingsQueryVariables
>;
export function refetchNews_GetSettingsQuery(
  variables?: News_GetSettingsQueryVariables,
) {
  return { query: News_GetSettingsDocument, variables: variables };
}
export const Recipients_AddRecipientDocument = gql`
  mutation Recipients_AddRecipient($emailRecipient: EmailRecipientInput!) {
    addEmailRecipient(emailRecipient: $emailRecipient) {
      email
    }
  }
`;
export type Recipients_AddRecipientMutationFn = Apollo.MutationFunction<
  Recipients_AddRecipientMutation,
  Recipients_AddRecipientMutationVariables
>;

/**
 * __useRecipients_AddRecipientMutation__
 *
 * To run a mutation, you first call `useRecipients_AddRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipients_AddRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientsAddRecipientMutation, { data, loading, error }] = useRecipients_AddRecipientMutation({
 *   variables: {
 *      emailRecipient: // value for 'emailRecipient'
 *   },
 * });
 */
export function useRecipients_AddRecipientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Recipients_AddRecipientMutation,
    Recipients_AddRecipientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Recipients_AddRecipientMutation,
    Recipients_AddRecipientMutationVariables
  >(Recipients_AddRecipientDocument, options);
}
export type Recipients_AddRecipientMutationHookResult = ReturnType<
  typeof useRecipients_AddRecipientMutation
>;
export type Recipients_AddRecipientMutationResult =
  Apollo.MutationResult<Recipients_AddRecipientMutation>;
export type Recipients_AddRecipientMutationOptions = Apollo.BaseMutationOptions<
  Recipients_AddRecipientMutation,
  Recipients_AddRecipientMutationVariables
>;
export const Recipients_DeleteRecipientDocument = gql`
  mutation Recipients_DeleteRecipient($emailRecipient: EmailRecipientInput!) {
    deleteEmailRecipient(emailRecipient: $emailRecipient) {
      email
    }
  }
`;
export type Recipients_DeleteRecipientMutationFn = Apollo.MutationFunction<
  Recipients_DeleteRecipientMutation,
  Recipients_DeleteRecipientMutationVariables
>;

/**
 * __useRecipients_DeleteRecipientMutation__
 *
 * To run a mutation, you first call `useRecipients_DeleteRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecipients_DeleteRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recipientsDeleteRecipientMutation, { data, loading, error }] = useRecipients_DeleteRecipientMutation({
 *   variables: {
 *      emailRecipient: // value for 'emailRecipient'
 *   },
 * });
 */
export function useRecipients_DeleteRecipientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Recipients_DeleteRecipientMutation,
    Recipients_DeleteRecipientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Recipients_DeleteRecipientMutation,
    Recipients_DeleteRecipientMutationVariables
  >(Recipients_DeleteRecipientDocument, options);
}
export type Recipients_DeleteRecipientMutationHookResult = ReturnType<
  typeof useRecipients_DeleteRecipientMutation
>;
export type Recipients_DeleteRecipientMutationResult =
  Apollo.MutationResult<Recipients_DeleteRecipientMutation>;
export type Recipients_DeleteRecipientMutationOptions =
  Apollo.BaseMutationOptions<
    Recipients_DeleteRecipientMutation,
    Recipients_DeleteRecipientMutationVariables
  >;
export const Recipient_GetRecipientsDocument = gql`
  query Recipient_GetRecipients {
    emailRecipients {
      email
    }
  }
`;

/**
 * __useRecipient_GetRecipientsQuery__
 *
 * To run a query within a React component, call `useRecipient_GetRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipient_GetRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipient_GetRecipientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecipient_GetRecipientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Recipient_GetRecipientsQuery,
    Recipient_GetRecipientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Recipient_GetRecipientsQuery,
    Recipient_GetRecipientsQueryVariables
  >(Recipient_GetRecipientsDocument, options);
}
export function useRecipient_GetRecipientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Recipient_GetRecipientsQuery,
    Recipient_GetRecipientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Recipient_GetRecipientsQuery,
    Recipient_GetRecipientsQueryVariables
  >(Recipient_GetRecipientsDocument, options);
}
export type Recipient_GetRecipientsQueryHookResult = ReturnType<
  typeof useRecipient_GetRecipientsQuery
>;
export type Recipient_GetRecipientsLazyQueryHookResult = ReturnType<
  typeof useRecipient_GetRecipientsLazyQuery
>;
export type Recipient_GetRecipientsQueryResult = Apollo.QueryResult<
  Recipient_GetRecipientsQuery,
  Recipient_GetRecipientsQueryVariables
>;
export function refetchRecipient_GetRecipientsQuery(
  variables?: Recipient_GetRecipientsQueryVariables,
) {
  return { query: Recipient_GetRecipientsDocument, variables: variables };
}
export const EmailTemplates_GetHawConfirmationTemplateDocument = gql`
  query EmailTemplates_GetHawConfirmationTemplate {
    hawConfirmationEmailTemplate {
      subject
      salutation
      freeText
      bookingDetails
      additionalFreetext
      url
      signature {
        text
        managementBoard
      }
    }
  }
`;

/**
 * __useEmailTemplates_GetHawConfirmationTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplates_GetHawConfirmationTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplates_GetHawConfirmationTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplates_GetHawConfirmationTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailTemplates_GetHawConfirmationTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailTemplates_GetHawConfirmationTemplateQuery,
    EmailTemplates_GetHawConfirmationTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailTemplates_GetHawConfirmationTemplateQuery,
    EmailTemplates_GetHawConfirmationTemplateQueryVariables
  >(EmailTemplates_GetHawConfirmationTemplateDocument, options);
}
export function useEmailTemplates_GetHawConfirmationTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplates_GetHawConfirmationTemplateQuery,
    EmailTemplates_GetHawConfirmationTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailTemplates_GetHawConfirmationTemplateQuery,
    EmailTemplates_GetHawConfirmationTemplateQueryVariables
  >(EmailTemplates_GetHawConfirmationTemplateDocument, options);
}
export type EmailTemplates_GetHawConfirmationTemplateQueryHookResult =
  ReturnType<typeof useEmailTemplates_GetHawConfirmationTemplateQuery>;
export type EmailTemplates_GetHawConfirmationTemplateLazyQueryHookResult =
  ReturnType<typeof useEmailTemplates_GetHawConfirmationTemplateLazyQuery>;
export type EmailTemplates_GetHawConfirmationTemplateQueryResult =
  Apollo.QueryResult<
    EmailTemplates_GetHawConfirmationTemplateQuery,
    EmailTemplates_GetHawConfirmationTemplateQueryVariables
  >;
export function refetchEmailTemplates_GetHawConfirmationTemplateQuery(
  variables?: EmailTemplates_GetHawConfirmationTemplateQueryVariables,
) {
  return {
    query: EmailTemplates_GetHawConfirmationTemplateDocument,
    variables: variables,
  };
}
export const EmailTemplates_GetEonConfirmationTemplateDocument = gql`
  query EmailTemplates_GetEonConfirmationTemplate {
    eonConfirmationEmailTemplate {
      subject
      salutation
      freeText
      bookingDetails
      additionalFreetext
      url
      signature {
        text
        managementBoard
      }
    }
  }
`;

/**
 * __useEmailTemplates_GetEonConfirmationTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplates_GetEonConfirmationTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplates_GetEonConfirmationTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplates_GetEonConfirmationTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailTemplates_GetEonConfirmationTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailTemplates_GetEonConfirmationTemplateQuery,
    EmailTemplates_GetEonConfirmationTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailTemplates_GetEonConfirmationTemplateQuery,
    EmailTemplates_GetEonConfirmationTemplateQueryVariables
  >(EmailTemplates_GetEonConfirmationTemplateDocument, options);
}
export function useEmailTemplates_GetEonConfirmationTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplates_GetEonConfirmationTemplateQuery,
    EmailTemplates_GetEonConfirmationTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailTemplates_GetEonConfirmationTemplateQuery,
    EmailTemplates_GetEonConfirmationTemplateQueryVariables
  >(EmailTemplates_GetEonConfirmationTemplateDocument, options);
}
export type EmailTemplates_GetEonConfirmationTemplateQueryHookResult =
  ReturnType<typeof useEmailTemplates_GetEonConfirmationTemplateQuery>;
export type EmailTemplates_GetEonConfirmationTemplateLazyQueryHookResult =
  ReturnType<typeof useEmailTemplates_GetEonConfirmationTemplateLazyQuery>;
export type EmailTemplates_GetEonConfirmationTemplateQueryResult =
  Apollo.QueryResult<
    EmailTemplates_GetEonConfirmationTemplateQuery,
    EmailTemplates_GetEonConfirmationTemplateQueryVariables
  >;
export function refetchEmailTemplates_GetEonConfirmationTemplateQuery(
  variables?: EmailTemplates_GetEonConfirmationTemplateQueryVariables,
) {
  return {
    query: EmailTemplates_GetEonConfirmationTemplateDocument,
    variables: variables,
  };
}
export const EmailTemplates_GetRejectionTemplateDocument = gql`
  query EmailTemplates_GetRejectionTemplate {
    rejectionEmailTemplate {
      subject
      salutation
      freeText
      url
      signature {
        text
        managementBoard
      }
    }
  }
`;

/**
 * __useEmailTemplates_GetRejectionTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplates_GetRejectionTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplates_GetRejectionTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplates_GetRejectionTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailTemplates_GetRejectionTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailTemplates_GetRejectionTemplateQuery,
    EmailTemplates_GetRejectionTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailTemplates_GetRejectionTemplateQuery,
    EmailTemplates_GetRejectionTemplateQueryVariables
  >(EmailTemplates_GetRejectionTemplateDocument, options);
}
export function useEmailTemplates_GetRejectionTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplates_GetRejectionTemplateQuery,
    EmailTemplates_GetRejectionTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailTemplates_GetRejectionTemplateQuery,
    EmailTemplates_GetRejectionTemplateQueryVariables
  >(EmailTemplates_GetRejectionTemplateDocument, options);
}
export type EmailTemplates_GetRejectionTemplateQueryHookResult = ReturnType<
  typeof useEmailTemplates_GetRejectionTemplateQuery
>;
export type EmailTemplates_GetRejectionTemplateLazyQueryHookResult = ReturnType<
  typeof useEmailTemplates_GetRejectionTemplateLazyQuery
>;
export type EmailTemplates_GetRejectionTemplateQueryResult = Apollo.QueryResult<
  EmailTemplates_GetRejectionTemplateQuery,
  EmailTemplates_GetRejectionTemplateQueryVariables
>;
export function refetchEmailTemplates_GetRejectionTemplateQuery(
  variables?: EmailTemplates_GetRejectionTemplateQueryVariables,
) {
  return {
    query: EmailTemplates_GetRejectionTemplateDocument,
    variables: variables,
  };
}
export const EmailTemplates_GetSignatureTemplateDocument = gql`
  query EmailTemplates_GetSignatureTemplate {
    emailSignature {
      managementBoard
      text
    }
  }
`;

/**
 * __useEmailTemplates_GetSignatureTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplates_GetSignatureTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplates_GetSignatureTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplates_GetSignatureTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailTemplates_GetSignatureTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailTemplates_GetSignatureTemplateQuery,
    EmailTemplates_GetSignatureTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmailTemplates_GetSignatureTemplateQuery,
    EmailTemplates_GetSignatureTemplateQueryVariables
  >(EmailTemplates_GetSignatureTemplateDocument, options);
}
export function useEmailTemplates_GetSignatureTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplates_GetSignatureTemplateQuery,
    EmailTemplates_GetSignatureTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailTemplates_GetSignatureTemplateQuery,
    EmailTemplates_GetSignatureTemplateQueryVariables
  >(EmailTemplates_GetSignatureTemplateDocument, options);
}
export type EmailTemplates_GetSignatureTemplateQueryHookResult = ReturnType<
  typeof useEmailTemplates_GetSignatureTemplateQuery
>;
export type EmailTemplates_GetSignatureTemplateLazyQueryHookResult = ReturnType<
  typeof useEmailTemplates_GetSignatureTemplateLazyQuery
>;
export type EmailTemplates_GetSignatureTemplateQueryResult = Apollo.QueryResult<
  EmailTemplates_GetSignatureTemplateQuery,
  EmailTemplates_GetSignatureTemplateQueryVariables
>;
export function refetchEmailTemplates_GetSignatureTemplateQuery(
  variables?: EmailTemplates_GetSignatureTemplateQueryVariables,
) {
  return {
    query: EmailTemplates_GetSignatureTemplateDocument,
    variables: variables,
  };
}
export const EmailTemplates_UpdateFreeTextDocument = gql`
  mutation EmailTemplates_updateFreeText($freeTexts: [FreeTextInput!]!) {
    updateFreeText(freeTexts: $freeTexts) {
      id
      text
    }
  }
`;
export type EmailTemplates_UpdateFreeTextMutationFn = Apollo.MutationFunction<
  EmailTemplates_UpdateFreeTextMutation,
  EmailTemplates_UpdateFreeTextMutationVariables
>;

/**
 * __useEmailTemplates_UpdateFreeTextMutation__
 *
 * To run a mutation, you first call `useEmailTemplates_UpdateFreeTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplates_UpdateFreeTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailTemplatesUpdateFreeTextMutation, { data, loading, error }] = useEmailTemplates_UpdateFreeTextMutation({
 *   variables: {
 *      freeTexts: // value for 'freeTexts'
 *   },
 * });
 */
export function useEmailTemplates_UpdateFreeTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EmailTemplates_UpdateFreeTextMutation,
    EmailTemplates_UpdateFreeTextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EmailTemplates_UpdateFreeTextMutation,
    EmailTemplates_UpdateFreeTextMutationVariables
  >(EmailTemplates_UpdateFreeTextDocument, options);
}
export type EmailTemplates_UpdateFreeTextMutationHookResult = ReturnType<
  typeof useEmailTemplates_UpdateFreeTextMutation
>;
export type EmailTemplates_UpdateFreeTextMutationResult =
  Apollo.MutationResult<EmailTemplates_UpdateFreeTextMutation>;
export type EmailTemplates_UpdateFreeTextMutationOptions =
  Apollo.BaseMutationOptions<
    EmailTemplates_UpdateFreeTextMutation,
    EmailTemplates_UpdateFreeTextMutationVariables
  >;
export const ApplicationStatisticsDocument = gql`
  query ApplicationStatistics {
    applicationStatistics {
      currentYear
      CREATED {
        ...AllStatisticFields
      }
      SENT_BACK {
        ...AllStatisticFields
      }
      SUBMITTED {
        ...AllStatisticFields
      }
      IN_REVIEW {
        ...AllStatisticFields
      }
      CONFIRMED {
        ...AllStatisticFields
      }
      CANCELLED {
        ...AllStatisticFields
      }
      REJECTED {
        ...AllStatisticFields
      }
      ASSIGNED {
        ...AllStatisticFields
      }
      CANCELLED_CONFIRMED {
        ...AllStatisticFields
      }
      REJECTED_CONFIRMED {
        ...AllStatisticFields
      }
      FINISHED {
        ...AllStatisticFields
      }
      FINISHED_CANCELLED {
        ...AllStatisticFields
      }
      FINISHED_REJECTED_CONFIRMED {
        ...AllStatisticFields
      }
      FINISHED_CANCELLED_CONFIRMED {
        ...AllStatisticFields
      }
    }
  }
  ${AllStatisticFieldsFragmentDoc}
`;

/**
 * __useApplicationStatisticsQuery__
 *
 * To run a query within a React component, call `useApplicationStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationStatisticsQuery,
    ApplicationStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationStatisticsQuery,
    ApplicationStatisticsQueryVariables
  >(ApplicationStatisticsDocument, options);
}
export function useApplicationStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationStatisticsQuery,
    ApplicationStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationStatisticsQuery,
    ApplicationStatisticsQueryVariables
  >(ApplicationStatisticsDocument, options);
}
export type ApplicationStatisticsQueryHookResult = ReturnType<
  typeof useApplicationStatisticsQuery
>;
export type ApplicationStatisticsLazyQueryHookResult = ReturnType<
  typeof useApplicationStatisticsLazyQuery
>;
export type ApplicationStatisticsQueryResult = Apollo.QueryResult<
  ApplicationStatisticsQuery,
  ApplicationStatisticsQueryVariables
>;
export function refetchApplicationStatisticsQuery(
  variables?: ApplicationStatisticsQueryVariables,
) {
  return { query: ApplicationStatisticsDocument, variables: variables };
}
export const DeleteApplicationsOlderThanFourYearsDocument = gql`
  mutation deleteApplicationsOlderThanFourYears {
    deleteApplicationsOlderThanFourYears
  }
`;
export type DeleteApplicationsOlderThanFourYearsMutationFn =
  Apollo.MutationFunction<
    DeleteApplicationsOlderThanFourYearsMutation,
    DeleteApplicationsOlderThanFourYearsMutationVariables
  >;

/**
 * __useDeleteApplicationsOlderThanFourYearsMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationsOlderThanFourYearsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationsOlderThanFourYearsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationsOlderThanFourYearsMutation, { data, loading, error }] = useDeleteApplicationsOlderThanFourYearsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteApplicationsOlderThanFourYearsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApplicationsOlderThanFourYearsMutation,
    DeleteApplicationsOlderThanFourYearsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApplicationsOlderThanFourYearsMutation,
    DeleteApplicationsOlderThanFourYearsMutationVariables
  >(DeleteApplicationsOlderThanFourYearsDocument, options);
}
export type DeleteApplicationsOlderThanFourYearsMutationHookResult = ReturnType<
  typeof useDeleteApplicationsOlderThanFourYearsMutation
>;
export type DeleteApplicationsOlderThanFourYearsMutationResult =
  Apollo.MutationResult<DeleteApplicationsOlderThanFourYearsMutation>;
export type DeleteApplicationsOlderThanFourYearsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteApplicationsOlderThanFourYearsMutation,
    DeleteApplicationsOlderThanFourYearsMutationVariables
  >;
export const BoardBilling_GetApplicationsVactionOffersHousesDocument = gql`
  query BoardBilling_GetApplicationsVactionOffersHouses(
    $column: BoardBillingSortableColumn
    $order: SortingOrder
    $filterByYear: Int
    $filterByHouse: String
    $filterByOffer: String
    $filterByMonth: Int
    $skip: Int
    $take: Int
  ) {
    applicationsForBoardBilling(
      column: $column
      order: $order
      filterByYear: $filterByYear
      filterByHouse: $filterByHouse
      filterByOffer: $filterByOffer
      filterByMonth: $filterByMonth
      skip: $skip
      take: $take
    ) {
      total
      items {
        __typename
        applicationId
        employeeName
        arrival
        departure
        houseName
        vacationOfferName
        priceChange {
          __typename
          remark
          increment
          decrement
        }
      }
    }
  }
`;

/**
 * __useBoardBilling_GetApplicationsVactionOffersHousesQuery__
 *
 * To run a query within a React component, call `useBoardBilling_GetApplicationsVactionOffersHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardBilling_GetApplicationsVactionOffersHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardBilling_GetApplicationsVactionOffersHousesQuery({
 *   variables: {
 *      column: // value for 'column'
 *      order: // value for 'order'
 *      filterByYear: // value for 'filterByYear'
 *      filterByHouse: // value for 'filterByHouse'
 *      filterByOffer: // value for 'filterByOffer'
 *      filterByMonth: // value for 'filterByMonth'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useBoardBilling_GetApplicationsVactionOffersHousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BoardBilling_GetApplicationsVactionOffersHousesQuery,
    BoardBilling_GetApplicationsVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardBilling_GetApplicationsVactionOffersHousesQuery,
    BoardBilling_GetApplicationsVactionOffersHousesQueryVariables
  >(BoardBilling_GetApplicationsVactionOffersHousesDocument, options);
}
export function useBoardBilling_GetApplicationsVactionOffersHousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardBilling_GetApplicationsVactionOffersHousesQuery,
    BoardBilling_GetApplicationsVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardBilling_GetApplicationsVactionOffersHousesQuery,
    BoardBilling_GetApplicationsVactionOffersHousesQueryVariables
  >(BoardBilling_GetApplicationsVactionOffersHousesDocument, options);
}
export type BoardBilling_GetApplicationsVactionOffersHousesQueryHookResult =
  ReturnType<typeof useBoardBilling_GetApplicationsVactionOffersHousesQuery>;
export type BoardBilling_GetApplicationsVactionOffersHousesLazyQueryHookResult =
  ReturnType<
    typeof useBoardBilling_GetApplicationsVactionOffersHousesLazyQuery
  >;
export type BoardBilling_GetApplicationsVactionOffersHousesQueryResult =
  Apollo.QueryResult<
    BoardBilling_GetApplicationsVactionOffersHousesQuery,
    BoardBilling_GetApplicationsVactionOffersHousesQueryVariables
  >;
export function refetchBoardBilling_GetApplicationsVactionOffersHousesQuery(
  variables?: BoardBilling_GetApplicationsVactionOffersHousesQueryVariables,
) {
  return {
    query: BoardBilling_GetApplicationsVactionOffersHousesDocument,
    variables: variables,
  };
}
export const BoardBilling_UpdateDocument = gql`
  mutation BoardBilling_Update(
    $applicationID: ID!
    $change: PriceChangeInput!
  ) {
    updateBoardBilling(applicationID: $applicationID, change: $change) {
      __typename
      remark
      increment
      decrement
    }
  }
`;
export type BoardBilling_UpdateMutationFn = Apollo.MutationFunction<
  BoardBilling_UpdateMutation,
  BoardBilling_UpdateMutationVariables
>;

/**
 * __useBoardBilling_UpdateMutation__
 *
 * To run a mutation, you first call `useBoardBilling_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardBilling_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardBillingUpdateMutation, { data, loading, error }] = useBoardBilling_UpdateMutation({
 *   variables: {
 *      applicationID: // value for 'applicationID'
 *      change: // value for 'change'
 *   },
 * });
 */
export function useBoardBilling_UpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BoardBilling_UpdateMutation,
    BoardBilling_UpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BoardBilling_UpdateMutation,
    BoardBilling_UpdateMutationVariables
  >(BoardBilling_UpdateDocument, options);
}
export type BoardBilling_UpdateMutationHookResult = ReturnType<
  typeof useBoardBilling_UpdateMutation
>;
export type BoardBilling_UpdateMutationResult =
  Apollo.MutationResult<BoardBilling_UpdateMutation>;
export type BoardBilling_UpdateMutationOptions = Apollo.BaseMutationOptions<
  BoardBilling_UpdateMutation,
  BoardBilling_UpdateMutationVariables
>;
export const EmployeeApplications_GetApplicationsVactionOffersHousesDocument = gql`
  query EmployeeApplications_GetApplicationsVactionOffersHouses(
    $column: SortableColumn
    $order: SortingOrder
    $filterByType: BookingType
    $filterByStatus: StatusFilter
    $filterByYear: Int
    $filterByHouse: String
    $filterByOffer: String
    $filterByMonth: Int
    $filterByPriority: Int
    $skip: Int
    $take: Int
  ) {
    applications(
      column: $column
      order: $order
      filterByType: $filterByType
      filterByStatus: $filterByStatus
      filterByYear: $filterByYear
      filterByHouse: $filterByHouse
      filterByOffer: $filterByOffer
      filterByMonth: $filterByMonth
      filterByPriority: $filterByPriority
      skip: $skip
      take: $take
    ) {
      total
      items {
        __typename
        applicationId
        bookingId
        bookingArrival
        bookingDeparture
        bookingPriority
        bookingtype
        bookingStatus
        houseName
        vacationOfferName
        applicationProcessingStatus
        applicationApplicationFor
        applicationApplicantStaffNumber
        applicationApplicantName
        applicationApplicantEmail
        applicationGuestEmail
        applicationGuestName
        applicationGuestStaffNumber
        creditPoints
        applicationCreatedAt
        applicationBookingChangesArrival
        applicationBookingChangesDeparture
      }
    }
    filterAndCountApplications(
      filterByStatus: $filterByStatus
      filterByType: $filterByType
      filterByYear: $filterByYear
      filterByHouse: $filterByHouse
      filterByOffer: $filterByOffer
      filterByMonth: $filterByMonth
      filterByPriority: $filterByPriority
    ) {
      numberOfApplications
    }
  }
`;

/**
 * __useEmployeeApplications_GetApplicationsVactionOffersHousesQuery__
 *
 * To run a query within a React component, call `useEmployeeApplications_GetApplicationsVactionOffersHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeApplications_GetApplicationsVactionOffersHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeApplications_GetApplicationsVactionOffersHousesQuery({
 *   variables: {
 *      column: // value for 'column'
 *      order: // value for 'order'
 *      filterByType: // value for 'filterByType'
 *      filterByStatus: // value for 'filterByStatus'
 *      filterByYear: // value for 'filterByYear'
 *      filterByHouse: // value for 'filterByHouse'
 *      filterByOffer: // value for 'filterByOffer'
 *      filterByMonth: // value for 'filterByMonth'
 *      filterByPriority: // value for 'filterByPriority'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useEmployeeApplications_GetApplicationsVactionOffersHousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployeeApplications_GetApplicationsVactionOffersHousesQuery,
    EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeeApplications_GetApplicationsVactionOffersHousesQuery,
    EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables
  >(EmployeeApplications_GetApplicationsVactionOffersHousesDocument, options);
}
export function useEmployeeApplications_GetApplicationsVactionOffersHousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeApplications_GetApplicationsVactionOffersHousesQuery,
    EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeeApplications_GetApplicationsVactionOffersHousesQuery,
    EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables
  >(EmployeeApplications_GetApplicationsVactionOffersHousesDocument, options);
}
export type EmployeeApplications_GetApplicationsVactionOffersHousesQueryHookResult =
  ReturnType<
    typeof useEmployeeApplications_GetApplicationsVactionOffersHousesQuery
  >;
export type EmployeeApplications_GetApplicationsVactionOffersHousesLazyQueryHookResult =
  ReturnType<
    typeof useEmployeeApplications_GetApplicationsVactionOffersHousesLazyQuery
  >;
export type EmployeeApplications_GetApplicationsVactionOffersHousesQueryResult =
  Apollo.QueryResult<
    EmployeeApplications_GetApplicationsVactionOffersHousesQuery,
    EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables
  >;
export function refetchEmployeeApplications_GetApplicationsVactionOffersHousesQuery(
  variables?: EmployeeApplications_GetApplicationsVactionOffersHousesQueryVariables,
) {
  return {
    query: EmployeeApplications_GetApplicationsVactionOffersHousesDocument,
    variables: variables,
  };
}
export const EditEon_GetListDocument = gql`
  query EditEon_GetList {
    eonHouses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useEditEon_GetListQuery__
 *
 * To run a query within a React component, call `useEditEon_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditEon_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditEon_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditEon_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditEon_GetListQuery,
    EditEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditEon_GetListQuery, EditEon_GetListQueryVariables>(
    EditEon_GetListDocument,
    options,
  );
}
export function useEditEon_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditEon_GetListQuery,
    EditEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditEon_GetListQuery,
    EditEon_GetListQueryVariables
  >(EditEon_GetListDocument, options);
}
export type EditEon_GetListQueryHookResult = ReturnType<
  typeof useEditEon_GetListQuery
>;
export type EditEon_GetListLazyQueryHookResult = ReturnType<
  typeof useEditEon_GetListLazyQuery
>;
export type EditEon_GetListQueryResult = Apollo.QueryResult<
  EditEon_GetListQuery,
  EditEon_GetListQueryVariables
>;
export function refetchEditEon_GetListQuery(
  variables?: EditEon_GetListQueryVariables,
) {
  return { query: EditEon_GetListDocument, variables: variables };
}
export const IndexEon_GetListDocument = gql`
  query IndexEon_GetList {
    eonHouses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useIndexEon_GetListQuery__
 *
 * To run a query within a React component, call `useIndexEon_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexEon_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexEon_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexEon_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndexEon_GetListQuery,
    IndexEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexEon_GetListQuery, IndexEon_GetListQueryVariables>(
    IndexEon_GetListDocument,
    options,
  );
}
export function useIndexEon_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexEon_GetListQuery,
    IndexEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexEon_GetListQuery,
    IndexEon_GetListQueryVariables
  >(IndexEon_GetListDocument, options);
}
export type IndexEon_GetListQueryHookResult = ReturnType<
  typeof useIndexEon_GetListQuery
>;
export type IndexEon_GetListLazyQueryHookResult = ReturnType<
  typeof useIndexEon_GetListLazyQuery
>;
export type IndexEon_GetListQueryResult = Apollo.QueryResult<
  IndexEon_GetListQuery,
  IndexEon_GetListQueryVariables
>;
export function refetchIndexEon_GetListQuery(
  variables?: IndexEon_GetListQueryVariables,
) {
  return { query: IndexEon_GetListDocument, variables: variables };
}
export const NewEon_GetListDocument = gql`
  query NewEon_GetList {
    eonHouses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useNewEon_GetListQuery__
 *
 * To run a query within a React component, call `useNewEon_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewEon_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEon_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewEon_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewEon_GetListQuery,
    NewEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewEon_GetListQuery, NewEon_GetListQueryVariables>(
    NewEon_GetListDocument,
    options,
  );
}
export function useNewEon_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewEon_GetListQuery,
    NewEon_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewEon_GetListQuery, NewEon_GetListQueryVariables>(
    NewEon_GetListDocument,
    options,
  );
}
export type NewEon_GetListQueryHookResult = ReturnType<
  typeof useNewEon_GetListQuery
>;
export type NewEon_GetListLazyQueryHookResult = ReturnType<
  typeof useNewEon_GetListLazyQuery
>;
export type NewEon_GetListQueryResult = Apollo.QueryResult<
  NewEon_GetListQuery,
  NewEon_GetListQueryVariables
>;
export function refetchNewEon_GetListQuery(
  variables?: NewEon_GetListQueryVariables,
) {
  return { query: NewEon_GetListDocument, variables: variables };
}
export const EditHaw_GetListDocument = gql`
  query EditHaw_GetList {
    houses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useEditHaw_GetListQuery__
 *
 * To run a query within a React component, call `useEditHaw_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditHaw_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditHaw_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditHaw_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditHaw_GetListQuery,
    EditHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditHaw_GetListQuery, EditHaw_GetListQueryVariables>(
    EditHaw_GetListDocument,
    options,
  );
}
export function useEditHaw_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditHaw_GetListQuery,
    EditHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditHaw_GetListQuery,
    EditHaw_GetListQueryVariables
  >(EditHaw_GetListDocument, options);
}
export type EditHaw_GetListQueryHookResult = ReturnType<
  typeof useEditHaw_GetListQuery
>;
export type EditHaw_GetListLazyQueryHookResult = ReturnType<
  typeof useEditHaw_GetListLazyQuery
>;
export type EditHaw_GetListQueryResult = Apollo.QueryResult<
  EditHaw_GetListQuery,
  EditHaw_GetListQueryVariables
>;
export function refetchEditHaw_GetListQuery(
  variables?: EditHaw_GetListQueryVariables,
) {
  return { query: EditHaw_GetListDocument, variables: variables };
}
export const IndexHaw_GetListDocument = gql`
  query IndexHaw_GetList {
    houses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useIndexHaw_GetListQuery__
 *
 * To run a query within a React component, call `useIndexHaw_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexHaw_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexHaw_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexHaw_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndexHaw_GetListQuery,
    IndexHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IndexHaw_GetListQuery, IndexHaw_GetListQueryVariables>(
    IndexHaw_GetListDocument,
    options,
  );
}
export function useIndexHaw_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexHaw_GetListQuery,
    IndexHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexHaw_GetListQuery,
    IndexHaw_GetListQueryVariables
  >(IndexHaw_GetListDocument, options);
}
export type IndexHaw_GetListQueryHookResult = ReturnType<
  typeof useIndexHaw_GetListQuery
>;
export type IndexHaw_GetListLazyQueryHookResult = ReturnType<
  typeof useIndexHaw_GetListLazyQuery
>;
export type IndexHaw_GetListQueryResult = Apollo.QueryResult<
  IndexHaw_GetListQuery,
  IndexHaw_GetListQueryVariables
>;
export function refetchIndexHaw_GetListQuery(
  variables?: IndexHaw_GetListQueryVariables,
) {
  return { query: IndexHaw_GetListDocument, variables: variables };
}
export const NewHaw_GetListDocument = gql`
  query NewHaw_GetList {
    houses {
      id
      name
      activated
      isEonHome
      city
      region
    }
  }
`;

/**
 * __useNewHaw_GetListQuery__
 *
 * To run a query within a React component, call `useNewHaw_GetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewHaw_GetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewHaw_GetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewHaw_GetListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewHaw_GetListQuery,
    NewHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewHaw_GetListQuery, NewHaw_GetListQueryVariables>(
    NewHaw_GetListDocument,
    options,
  );
}
export function useNewHaw_GetListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewHaw_GetListQuery,
    NewHaw_GetListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewHaw_GetListQuery, NewHaw_GetListQueryVariables>(
    NewHaw_GetListDocument,
    options,
  );
}
export type NewHaw_GetListQueryHookResult = ReturnType<
  typeof useNewHaw_GetListQuery
>;
export type NewHaw_GetListLazyQueryHookResult = ReturnType<
  typeof useNewHaw_GetListLazyQuery
>;
export type NewHaw_GetListQueryResult = Apollo.QueryResult<
  NewHaw_GetListQuery,
  NewHaw_GetListQueryVariables
>;
export function refetchNewHaw_GetListQuery(
  variables?: NewHaw_GetListQueryVariables,
) {
  return { query: NewHaw_GetListDocument, variables: variables };
}
export const Information_GetAttachmentsDocument = gql`
  query Information_GetAttachments {
    informationFiles {
      id
      description
      path
      name
    }
  }
`;

/**
 * __useInformation_GetAttachmentsQuery__
 *
 * To run a query within a React component, call `useInformation_GetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInformation_GetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInformation_GetAttachmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInformation_GetAttachmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Information_GetAttachmentsQuery,
    Information_GetAttachmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Information_GetAttachmentsQuery,
    Information_GetAttachmentsQueryVariables
  >(Information_GetAttachmentsDocument, options);
}
export function useInformation_GetAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Information_GetAttachmentsQuery,
    Information_GetAttachmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Information_GetAttachmentsQuery,
    Information_GetAttachmentsQueryVariables
  >(Information_GetAttachmentsDocument, options);
}
export type Information_GetAttachmentsQueryHookResult = ReturnType<
  typeof useInformation_GetAttachmentsQuery
>;
export type Information_GetAttachmentsLazyQueryHookResult = ReturnType<
  typeof useInformation_GetAttachmentsLazyQuery
>;
export type Information_GetAttachmentsQueryResult = Apollo.QueryResult<
  Information_GetAttachmentsQuery,
  Information_GetAttachmentsQueryVariables
>;
export function refetchInformation_GetAttachmentsQuery(
  variables?: Information_GetAttachmentsQueryVariables,
) {
  return { query: Information_GetAttachmentsDocument, variables: variables };
}
export const Information_DeleteAttachmentDocument = gql`
  mutation Information_DeleteAttachment($id: ID!) {
    removeFile(id: $id)
  }
`;
export type Information_DeleteAttachmentMutationFn = Apollo.MutationFunction<
  Information_DeleteAttachmentMutation,
  Information_DeleteAttachmentMutationVariables
>;

/**
 * __useInformation_DeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useInformation_DeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformation_DeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informationDeleteAttachmentMutation, { data, loading, error }] = useInformation_DeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInformation_DeleteAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Information_DeleteAttachmentMutation,
    Information_DeleteAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Information_DeleteAttachmentMutation,
    Information_DeleteAttachmentMutationVariables
  >(Information_DeleteAttachmentDocument, options);
}
export type Information_DeleteAttachmentMutationHookResult = ReturnType<
  typeof useInformation_DeleteAttachmentMutation
>;
export type Information_DeleteAttachmentMutationResult =
  Apollo.MutationResult<Information_DeleteAttachmentMutation>;
export type Information_DeleteAttachmentMutationOptions =
  Apollo.BaseMutationOptions<
    Information_DeleteAttachmentMutation,
    Information_DeleteAttachmentMutationVariables
  >;
export const Information_UpdateAttachmentDocument = gql`
  mutation Information_UpdateAttachment($id: ID!, $file: BinaryUpdateInput!) {
    updateBinary(id: $id, file: $file) {
      id
    }
  }
`;
export type Information_UpdateAttachmentMutationFn = Apollo.MutationFunction<
  Information_UpdateAttachmentMutation,
  Information_UpdateAttachmentMutationVariables
>;

/**
 * __useInformation_UpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useInformation_UpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformation_UpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informationUpdateAttachmentMutation, { data, loading, error }] = useInformation_UpdateAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useInformation_UpdateAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Information_UpdateAttachmentMutation,
    Information_UpdateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Information_UpdateAttachmentMutation,
    Information_UpdateAttachmentMutationVariables
  >(Information_UpdateAttachmentDocument, options);
}
export type Information_UpdateAttachmentMutationHookResult = ReturnType<
  typeof useInformation_UpdateAttachmentMutation
>;
export type Information_UpdateAttachmentMutationResult =
  Apollo.MutationResult<Information_UpdateAttachmentMutation>;
export type Information_UpdateAttachmentMutationOptions =
  Apollo.BaseMutationOptions<
    Information_UpdateAttachmentMutation,
    Information_UpdateAttachmentMutationVariables
  >;
export const Information_AddAttachmentDocument = gql`
  mutation Information_AddAttachment($file: FileInput!) {
    addInformationFile(file: $file) {
      id
      name
      type
      order
      description
      path
      createdAt
    }
  }
`;
export type Information_AddAttachmentMutationFn = Apollo.MutationFunction<
  Information_AddAttachmentMutation,
  Information_AddAttachmentMutationVariables
>;

/**
 * __useInformation_AddAttachmentMutation__
 *
 * To run a mutation, you first call `useInformation_AddAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformation_AddAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informationAddAttachmentMutation, { data, loading, error }] = useInformation_AddAttachmentMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useInformation_AddAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Information_AddAttachmentMutation,
    Information_AddAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Information_AddAttachmentMutation,
    Information_AddAttachmentMutationVariables
  >(Information_AddAttachmentDocument, options);
}
export type Information_AddAttachmentMutationHookResult = ReturnType<
  typeof useInformation_AddAttachmentMutation
>;
export type Information_AddAttachmentMutationResult =
  Apollo.MutationResult<Information_AddAttachmentMutation>;
export type Information_AddAttachmentMutationOptions =
  Apollo.BaseMutationOptions<
    Information_AddAttachmentMutation,
    Information_AddAttachmentMutationVariables
  >;
export const Information_UpdateFileMetadataDocument = gql`
  mutation Information_UpdateFileMetadata($id: ID!, $file: FileUpdateInput!) {
    updateFile(id: $id, file: $file)
  }
`;
export type Information_UpdateFileMetadataMutationFn = Apollo.MutationFunction<
  Information_UpdateFileMetadataMutation,
  Information_UpdateFileMetadataMutationVariables
>;

/**
 * __useInformation_UpdateFileMetadataMutation__
 *
 * To run a mutation, you first call `useInformation_UpdateFileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInformation_UpdateFileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [informationUpdateFileMetadataMutation, { data, loading, error }] = useInformation_UpdateFileMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useInformation_UpdateFileMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Information_UpdateFileMetadataMutation,
    Information_UpdateFileMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Information_UpdateFileMetadataMutation,
    Information_UpdateFileMetadataMutationVariables
  >(Information_UpdateFileMetadataDocument, options);
}
export type Information_UpdateFileMetadataMutationHookResult = ReturnType<
  typeof useInformation_UpdateFileMetadataMutation
>;
export type Information_UpdateFileMetadataMutationResult =
  Apollo.MutationResult<Information_UpdateFileMetadataMutation>;
export type Information_UpdateFileMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    Information_UpdateFileMetadataMutation,
    Information_UpdateFileMetadataMutationVariables
  >;
export const EditMyApplications_GetApplicationDocument = gql`
  query EditMyApplications_GetApplication($id: ID!) {
    application(id: $id) {
      id
      bookedOfferName
      processingStatus
      cancellationDate
      applicantKID
      applicationFor
      createdAt
      applicant {
        name
        email
        staffNumber
        accountingArea
      }
      guest {
        name
        email
        staffNumber
        accountingArea
      }
      applicationType
      street
      houseNumber
      zip
      city
      phone
      disability {
        present
        degree
        walkingDisability
        markBOnPassport
      }
      allergies
      singleParent
      dog
      partner {
        status
        employed
        name
        staffNumber
      }
      furtherGuests {
        nonFamilyChildrenUnderage {
          dateOfBirth
        }
        familyChildrenUnderage {
          dateOfBirth
        }
        familyChildrenAdult
        furtherAdults
      }
      comment
      companyPosition {
        position
        entranceYear
        employment
      }
      hawBookings {
        id
        applicationId
        priority
        bookingStatus
        bookingChoice {
          id
          summerHolidays
          socialCreditPoints
          active
          countervailingBenefit {
            ...CountervailingBenefitFields
          }
          bookingDate {
            arrival
            departure
          }
          createdAt
        }
        bookingChoiceId
        board
        createdAt
      }
      eonBookings {
        id
        priority
        applicationId
        houseId
        bookingStatus
        bookingChoice {
          id
          summerHolidays
          socialCreditPoints
          active
          countervailingBenefit {
            ...CountervailingBenefitFields
          }
          bookingDate {
            arrival
            departure
          }
          createdAt
        }
        bookingChoiceId
        board
        createdAt
        period {
          arrival
          departure
        }
      }
      priceChanges {
        subsistence {
          ...PriceChangeFields
        }
        tax {
          ...PriceChangeFields
        }
        board {
          ...PriceChangeFields
        }
        pecuniaryAdvantage {
          ...PriceChangeFields
        }
        cancellationCosts {
          ...PriceChangeFields
        }
      }
      bookingChanges {
        summerHolidays
        socialCreditPoints
        eonBookingId
        bookingDate {
          arrival
          departure
        }
      }
      fixedCharges {
        ...FixedChargesFields
      }
    }
  }
  ${CountervailingBenefitFieldsFragmentDoc}
  ${PriceChangeFieldsFragmentDoc}
  ${FixedChargesFieldsFragmentDoc}
  ${FixedChargeFieldsFragmentDoc}
  ${AccommodationFieldsFragmentDoc}
  ${FurtherPositionFieldsFragmentDoc}
`;

/**
 * __useEditMyApplications_GetApplicationQuery__
 *
 * To run a query within a React component, call `useEditMyApplications_GetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMyApplications_GetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMyApplications_GetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditMyApplications_GetApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    EditMyApplications_GetApplicationQuery,
    EditMyApplications_GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditMyApplications_GetApplicationQuery,
    EditMyApplications_GetApplicationQueryVariables
  >(EditMyApplications_GetApplicationDocument, options);
}
export function useEditMyApplications_GetApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditMyApplications_GetApplicationQuery,
    EditMyApplications_GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditMyApplications_GetApplicationQuery,
    EditMyApplications_GetApplicationQueryVariables
  >(EditMyApplications_GetApplicationDocument, options);
}
export type EditMyApplications_GetApplicationQueryHookResult = ReturnType<
  typeof useEditMyApplications_GetApplicationQuery
>;
export type EditMyApplications_GetApplicationLazyQueryHookResult = ReturnType<
  typeof useEditMyApplications_GetApplicationLazyQuery
>;
export type EditMyApplications_GetApplicationQueryResult = Apollo.QueryResult<
  EditMyApplications_GetApplicationQuery,
  EditMyApplications_GetApplicationQueryVariables
>;
export function refetchEditMyApplications_GetApplicationQuery(
  variables: EditMyApplications_GetApplicationQueryVariables,
) {
  return {
    query: EditMyApplications_GetApplicationDocument,
    variables: variables,
  };
}
export const EditMyApplications_DeleteApplicationDocument = gql`
  mutation EditMyApplications_DeleteApplication($id: String!) {
    deleteApplication(id: $id)
  }
`;
export type EditMyApplications_DeleteApplicationMutationFn =
  Apollo.MutationFunction<
    EditMyApplications_DeleteApplicationMutation,
    EditMyApplications_DeleteApplicationMutationVariables
  >;

/**
 * __useEditMyApplications_DeleteApplicationMutation__
 *
 * To run a mutation, you first call `useEditMyApplications_DeleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMyApplications_DeleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMyApplicationsDeleteApplicationMutation, { data, loading, error }] = useEditMyApplications_DeleteApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditMyApplications_DeleteApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditMyApplications_DeleteApplicationMutation,
    EditMyApplications_DeleteApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditMyApplications_DeleteApplicationMutation,
    EditMyApplications_DeleteApplicationMutationVariables
  >(EditMyApplications_DeleteApplicationDocument, options);
}
export type EditMyApplications_DeleteApplicationMutationHookResult = ReturnType<
  typeof useEditMyApplications_DeleteApplicationMutation
>;
export type EditMyApplications_DeleteApplicationMutationResult =
  Apollo.MutationResult<EditMyApplications_DeleteApplicationMutation>;
export type EditMyApplications_DeleteApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    EditMyApplications_DeleteApplicationMutation,
    EditMyApplications_DeleteApplicationMutationVariables
  >;
export const EditMyApplications_UpdateApplicationDocument = gql`
  mutation EditMyApplications_UpdateApplication(
    $applicationID: ID!
    $updateApplication: ApplicationInput!
    $sendEmail: Boolean
  ) {
    updateApplication(
      applicationID: $applicationID
      application: $updateApplication
      sendEmail: $sendEmail
    ) {
      id
    }
  }
`;
export type EditMyApplications_UpdateApplicationMutationFn =
  Apollo.MutationFunction<
    EditMyApplications_UpdateApplicationMutation,
    EditMyApplications_UpdateApplicationMutationVariables
  >;

/**
 * __useEditMyApplications_UpdateApplicationMutation__
 *
 * To run a mutation, you first call `useEditMyApplications_UpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMyApplications_UpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMyApplicationsUpdateApplicationMutation, { data, loading, error }] = useEditMyApplications_UpdateApplicationMutation({
 *   variables: {
 *      applicationID: // value for 'applicationID'
 *      updateApplication: // value for 'updateApplication'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useEditMyApplications_UpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditMyApplications_UpdateApplicationMutation,
    EditMyApplications_UpdateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditMyApplications_UpdateApplicationMutation,
    EditMyApplications_UpdateApplicationMutationVariables
  >(EditMyApplications_UpdateApplicationDocument, options);
}
export type EditMyApplications_UpdateApplicationMutationHookResult = ReturnType<
  typeof useEditMyApplications_UpdateApplicationMutation
>;
export type EditMyApplications_UpdateApplicationMutationResult =
  Apollo.MutationResult<EditMyApplications_UpdateApplicationMutation>;
export type EditMyApplications_UpdateApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    EditMyApplications_UpdateApplicationMutation,
    EditMyApplications_UpdateApplicationMutationVariables
  >;
export const IndexMyApplications_GetMyApplicationsDocument = gql`
  query IndexMyApplications_GetMyApplications(
    $myApplicationsApplicantKid: String!
  ) {
    myApplications(applicantKID: $myApplicationsApplicantKid) {
      total
      items {
        id
        processingStatus
        createdAt
        applicantKID
        applicant {
          name
        }
      }
    }
  }
`;

/**
 * __useIndexMyApplications_GetMyApplicationsQuery__
 *
 * To run a query within a React component, call `useIndexMyApplications_GetMyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexMyApplications_GetMyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexMyApplications_GetMyApplicationsQuery({
 *   variables: {
 *      myApplicationsApplicantKid: // value for 'myApplicationsApplicantKid'
 *   },
 * });
 */
export function useIndexMyApplications_GetMyApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndexMyApplications_GetMyApplicationsQuery,
    IndexMyApplications_GetMyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndexMyApplications_GetMyApplicationsQuery,
    IndexMyApplications_GetMyApplicationsQueryVariables
  >(IndexMyApplications_GetMyApplicationsDocument, options);
}
export function useIndexMyApplications_GetMyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexMyApplications_GetMyApplicationsQuery,
    IndexMyApplications_GetMyApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexMyApplications_GetMyApplicationsQuery,
    IndexMyApplications_GetMyApplicationsQueryVariables
  >(IndexMyApplications_GetMyApplicationsDocument, options);
}
export type IndexMyApplications_GetMyApplicationsQueryHookResult = ReturnType<
  typeof useIndexMyApplications_GetMyApplicationsQuery
>;
export type IndexMyApplications_GetMyApplicationsLazyQueryHookResult =
  ReturnType<typeof useIndexMyApplications_GetMyApplicationsLazyQuery>;
export type IndexMyApplications_GetMyApplicationsQueryResult =
  Apollo.QueryResult<
    IndexMyApplications_GetMyApplicationsQuery,
    IndexMyApplications_GetMyApplicationsQueryVariables
  >;
export function refetchIndexMyApplications_GetMyApplicationsQuery(
  variables: IndexMyApplications_GetMyApplicationsQueryVariables,
) {
  return {
    query: IndexMyApplications_GetMyApplicationsDocument,
    variables: variables,
  };
}
export const NewMyApplicationFromTemplate_GetApplicationDocument = gql`
  query NewMyApplicationFromTemplate_GetApplication($id: ID!) {
    application(id: $id) {
      applicantKID
      applicationFor
      applicant {
        name
        email
        staffNumber
        accountingArea
      }
      guest {
        name
        email
        staffNumber
        accountingArea
      }
      applicationType
      street
      houseNumber
      zip
      city
      phone
      disability {
        present
        degree
        walkingDisability
        markBOnPassport
      }
      allergies
      singleParent
      dog
      partner {
        status
        employed
        name
        staffNumber
      }
      furtherGuests {
        nonFamilyChildrenUnderage {
          dateOfBirth
        }
        familyChildrenUnderage {
          dateOfBirth
        }
        familyChildrenAdult
        furtherAdults
      }
      comment
      companyPosition {
        position
        entranceYear
        employment
      }
    }
  }
`;

/**
 * __useNewMyApplicationFromTemplate_GetApplicationQuery__
 *
 * To run a query within a React component, call `useNewMyApplicationFromTemplate_GetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMyApplicationFromTemplate_GetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMyApplicationFromTemplate_GetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewMyApplicationFromTemplate_GetApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    NewMyApplicationFromTemplate_GetApplicationQuery,
    NewMyApplicationFromTemplate_GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewMyApplicationFromTemplate_GetApplicationQuery,
    NewMyApplicationFromTemplate_GetApplicationQueryVariables
  >(NewMyApplicationFromTemplate_GetApplicationDocument, options);
}
export function useNewMyApplicationFromTemplate_GetApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewMyApplicationFromTemplate_GetApplicationQuery,
    NewMyApplicationFromTemplate_GetApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewMyApplicationFromTemplate_GetApplicationQuery,
    NewMyApplicationFromTemplate_GetApplicationQueryVariables
  >(NewMyApplicationFromTemplate_GetApplicationDocument, options);
}
export type NewMyApplicationFromTemplate_GetApplicationQueryHookResult =
  ReturnType<typeof useNewMyApplicationFromTemplate_GetApplicationQuery>;
export type NewMyApplicationFromTemplate_GetApplicationLazyQueryHookResult =
  ReturnType<typeof useNewMyApplicationFromTemplate_GetApplicationLazyQuery>;
export type NewMyApplicationFromTemplate_GetApplicationQueryResult =
  Apollo.QueryResult<
    NewMyApplicationFromTemplate_GetApplicationQuery,
    NewMyApplicationFromTemplate_GetApplicationQueryVariables
  >;
export function refetchNewMyApplicationFromTemplate_GetApplicationQuery(
  variables: NewMyApplicationFromTemplate_GetApplicationQueryVariables,
) {
  return {
    query: NewMyApplicationFromTemplate_GetApplicationDocument,
    variables: variables,
  };
}
export const NewMyApplications_CreateApplicationDocument = gql`
  mutation NewMyApplications_CreateApplication(
    $createApplication: ApplicationInput!
  ) {
    createApplication(application: $createApplication) {
      id
    }
  }
`;
export type NewMyApplications_CreateApplicationMutationFn =
  Apollo.MutationFunction<
    NewMyApplications_CreateApplicationMutation,
    NewMyApplications_CreateApplicationMutationVariables
  >;

/**
 * __useNewMyApplications_CreateApplicationMutation__
 *
 * To run a mutation, you first call `useNewMyApplications_CreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewMyApplications_CreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newMyApplicationsCreateApplicationMutation, { data, loading, error }] = useNewMyApplications_CreateApplicationMutation({
 *   variables: {
 *      createApplication: // value for 'createApplication'
 *   },
 * });
 */
export function useNewMyApplications_CreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewMyApplications_CreateApplicationMutation,
    NewMyApplications_CreateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NewMyApplications_CreateApplicationMutation,
    NewMyApplications_CreateApplicationMutationVariables
  >(NewMyApplications_CreateApplicationDocument, options);
}
export type NewMyApplications_CreateApplicationMutationHookResult = ReturnType<
  typeof useNewMyApplications_CreateApplicationMutation
>;
export type NewMyApplications_CreateApplicationMutationResult =
  Apollo.MutationResult<NewMyApplications_CreateApplicationMutation>;
export type NewMyApplications_CreateApplicationMutationOptions =
  Apollo.BaseMutationOptions<
    NewMyApplications_CreateApplicationMutation,
    NewMyApplications_CreateApplicationMutationVariables
  >;
export const NewMyApplications_GetPersondataDocument = gql`
  query NewMyApplications_GetPersondata($kid: String!) {
    getPersondata(kid: $kid) {
      staffNumber
      accountingArea
    }
  }
`;

/**
 * __useNewMyApplications_GetPersondataQuery__
 *
 * To run a query within a React component, call `useNewMyApplications_GetPersondataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMyApplications_GetPersondataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMyApplications_GetPersondataQuery({
 *   variables: {
 *      kid: // value for 'kid'
 *   },
 * });
 */
export function useNewMyApplications_GetPersondataQuery(
  baseOptions: Apollo.QueryHookOptions<
    NewMyApplications_GetPersondataQuery,
    NewMyApplications_GetPersondataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewMyApplications_GetPersondataQuery,
    NewMyApplications_GetPersondataQueryVariables
  >(NewMyApplications_GetPersondataDocument, options);
}
export function useNewMyApplications_GetPersondataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewMyApplications_GetPersondataQuery,
    NewMyApplications_GetPersondataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewMyApplications_GetPersondataQuery,
    NewMyApplications_GetPersondataQueryVariables
  >(NewMyApplications_GetPersondataDocument, options);
}
export type NewMyApplications_GetPersondataQueryHookResult = ReturnType<
  typeof useNewMyApplications_GetPersondataQuery
>;
export type NewMyApplications_GetPersondataLazyQueryHookResult = ReturnType<
  typeof useNewMyApplications_GetPersondataLazyQuery
>;
export type NewMyApplications_GetPersondataQueryResult = Apollo.QueryResult<
  NewMyApplications_GetPersondataQuery,
  NewMyApplications_GetPersondataQueryVariables
>;
export function refetchNewMyApplications_GetPersondataQuery(
  variables: NewMyApplications_GetPersondataQueryVariables,
) {
  return {
    query: NewMyApplications_GetPersondataDocument,
    variables: variables,
  };
}
export const EditVacationOffer_GetVacationOffersListDocument = gql`
  query EditVacationOffer_GetVacationOffersList {
    vacationOffersForIndex {
      id
      activated
      name
      house {
        name
        city
        region
      }
    }
  }
`;

/**
 * __useEditVacationOffer_GetVacationOffersListQuery__
 *
 * To run a query within a React component, call `useEditVacationOffer_GetVacationOffersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditVacationOffer_GetVacationOffersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditVacationOffer_GetVacationOffersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditVacationOffer_GetVacationOffersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditVacationOffer_GetVacationOffersListQuery,
    EditVacationOffer_GetVacationOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditVacationOffer_GetVacationOffersListQuery,
    EditVacationOffer_GetVacationOffersListQueryVariables
  >(EditVacationOffer_GetVacationOffersListDocument, options);
}
export function useEditVacationOffer_GetVacationOffersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditVacationOffer_GetVacationOffersListQuery,
    EditVacationOffer_GetVacationOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditVacationOffer_GetVacationOffersListQuery,
    EditVacationOffer_GetVacationOffersListQueryVariables
  >(EditVacationOffer_GetVacationOffersListDocument, options);
}
export type EditVacationOffer_GetVacationOffersListQueryHookResult = ReturnType<
  typeof useEditVacationOffer_GetVacationOffersListQuery
>;
export type EditVacationOffer_GetVacationOffersListLazyQueryHookResult =
  ReturnType<typeof useEditVacationOffer_GetVacationOffersListLazyQuery>;
export type EditVacationOffer_GetVacationOffersListQueryResult =
  Apollo.QueryResult<
    EditVacationOffer_GetVacationOffersListQuery,
    EditVacationOffer_GetVacationOffersListQueryVariables
  >;
export function refetchEditVacationOffer_GetVacationOffersListQuery(
  variables?: EditVacationOffer_GetVacationOffersListQueryVariables,
) {
  return {
    query: EditVacationOffer_GetVacationOffersListDocument,
    variables: variables,
  };
}
export const IndexVacationOffers_GetVacationOffersListDocument = gql`
  query IndexVacationOffers_GetVacationOffersList {
    vacationOffersForIndex {
      id
      activated
      name
      house {
        name
        city
        region
      }
    }
  }
`;

/**
 * __useIndexVacationOffers_GetVacationOffersListQuery__
 *
 * To run a query within a React component, call `useIndexVacationOffers_GetVacationOffersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexVacationOffers_GetVacationOffersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexVacationOffers_GetVacationOffersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexVacationOffers_GetVacationOffersListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndexVacationOffers_GetVacationOffersListQuery,
    IndexVacationOffers_GetVacationOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IndexVacationOffers_GetVacationOffersListQuery,
    IndexVacationOffers_GetVacationOffersListQueryVariables
  >(IndexVacationOffers_GetVacationOffersListDocument, options);
}
export function useIndexVacationOffers_GetVacationOffersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndexVacationOffers_GetVacationOffersListQuery,
    IndexVacationOffers_GetVacationOffersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IndexVacationOffers_GetVacationOffersListQuery,
    IndexVacationOffers_GetVacationOffersListQueryVariables
  >(IndexVacationOffers_GetVacationOffersListDocument, options);
}
export type IndexVacationOffers_GetVacationOffersListQueryHookResult =
  ReturnType<typeof useIndexVacationOffers_GetVacationOffersListQuery>;
export type IndexVacationOffers_GetVacationOffersListLazyQueryHookResult =
  ReturnType<typeof useIndexVacationOffers_GetVacationOffersListLazyQuery>;
export type IndexVacationOffers_GetVacationOffersListQueryResult =
  Apollo.QueryResult<
    IndexVacationOffers_GetVacationOffersListQuery,
    IndexVacationOffers_GetVacationOffersListQueryVariables
  >;
export function refetchIndexVacationOffers_GetVacationOffersListQuery(
  variables?: IndexVacationOffers_GetVacationOffersListQueryVariables,
) {
  return {
    query: IndexVacationOffers_GetVacationOffersListDocument,
    variables: variables,
  };
}
