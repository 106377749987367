import { useTranslation } from 'react-i18next';
import { BookingAndApplication, SelectedItem } from './interfaces';
import Row from './ApplicationTableRow';
import { ReactElement } from 'react';
import { SortableColumn } from 'gql/generated/types-and-hooks';
import { SortableHeader, TableHeader } from 'components/common/TableElements';
import { ColumnSorting } from 'types/ColumnSorting';

type Sorting = ColumnSorting<SortableColumn>;

type ApplicationTableProps = {
  rowData: BookingAndApplication[];
  selectedItems: SelectedItem[];
  onRowSelect: (items: SelectedItem[]) => void;
  onSort: (order: Sorting) => void;
  sorting: Sorting;
};
const ApplicationTable = ({
  rowData,
  onRowSelect,
  onSort,
  sorting,
  selectedItems,
}: ApplicationTableProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <table className="applicationTable w-full">
      <colgroup>
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
        <col style={{ width: '0%' }} />
      </colgroup>
      <thead className="whitespace-nowrap">
        <tr className="border-b border-grey">
          <TableHeader>
            <input
              className="block"
              type="checkbox"
              checked={selectedItems.length === rowData.length}
              onChange={event => {
                onRowSelect(
                  event.target.checked
                    ? rowData.map(row => ({ id: row.bookingId }))
                    : [],
                );
              }}
            />
          </TableHeader>
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.state')}
            column={SortableColumn.ProcessingStatus}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.points')}
            column={SortableColumn.SocialCreditPoints}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.position')}
            column={SortableColumn.Priority}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.employee')}
            column={SortableColumn.Employee}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.period')}
            column={SortableColumn.Period}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.house')}
            column={SortableColumn.HouseName}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.offer')}
            column={SortableColumn.OfferName}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.date')}
            column={SortableColumn.CreatedAt}
          />
          <SortableHeader
            sorting={sorting}
            onSort={onSort}
            columnTitle={t('components.applications.type')}
            column={SortableColumn.BookingType}
          />
          <TableHeader />
          <TableHeader />
        </tr>
      </thead>
      <tbody>
        {rowData.map(item => {
          const selected =
            selectedItems.findIndex(i => i.id === item.bookingId) > -1;
          const selectionWithoutCurrent = selectedItems.filter(
            i => i.id !== item.bookingId,
          );

          return (
            <Row
              key={item.bookingId}
              item={item}
              onSelect={selected =>
                onRowSelect(
                  selected
                    ? [
                        ...selectionWithoutCurrent,
                        {
                          id: item.bookingId as string,
                        },
                      ]
                    : selectionWithoutCurrent,
                )
              }
              selected={selected}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default ApplicationTable;
