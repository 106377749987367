import Card from 'components/base/Card';
import { Icon } from 'components/base/Icon';
import config from 'config/config';
import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { EonConfirmationTemplate } from './EonConfirmationTemplate';
import { HawConfirmationTemplate } from './HawConfirmationTemplate';
import { RejectionTemplate } from './RejectionTemplate';
import { SignatureTemplate } from './SignatureTemplate';

export function EmailTemplates(): ReactElement {
  const { t } = useTranslation();
  const { localRoutes } = config;

  return (
    <>
      <ToggleCard
        to={localRoutes.settings.email.templates.confirmation.haw}
        title={t('components.settings.email.templates.confirmationHaw')}
      >
        <HawConfirmationTemplate />
      </ToggleCard>
      <ToggleCard
        to={localRoutes.settings.email.templates.confirmation.eon}
        title={t('components.settings.email.templates.confirmationEon')}
      >
        <EonConfirmationTemplate />
      </ToggleCard>
      <ToggleCard
        to={localRoutes.settings.email.templates.rejected}
        title={t('components.settings.email.templates.rejected')}
      >
        <RejectionTemplate />
      </ToggleCard>
      <ToggleCard
        to={localRoutes.settings.email.templates.signature}
        title={t('components.settings.email.templates.signature')}
      >
        <SignatureTemplate />
      </ToggleCard>
    </>
  );
}

function ToggleCard({
  to,
  title,
  children,
}: PropsWithChildren<{
  to: string;
  title: string;
}>): ReactElement {
  const { pathname } = useLocation();
  const from = to.split('/').slice(0, -1).join('/');
  return pathname !== to ? (
    <Card>
      <Link to={to}>
        <div className="flex items-center gap-2">
          <Icon icon="ChevronDownIcon" />
          {title}
        </div>
      </Link>
    </Card>
  ) : (
    <Card>
      <Link to={from}>
        <div className="flex items-center gap-2">
          <Icon icon="ChevronUpIcon" />
          {title}
        </div>
      </Link>
      <div className="py-8">{children}</div>
    </Card>
  );
}
