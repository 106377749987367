import Button from 'components/base/Button';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../../context/UserInfoContext';
import {
  CountervailingBenefit,
  EonOfferInput,
  refetchHouseForm_GetHouseQuery,
  useEonOffer_CreateMutation,
  useEonOffer_DeleteMutation,
  useEonOffer_UpdateMutation,
} from 'gql/generated/types-and-hooks';
import { EonOfferRow } from './EonOfferRow';
import BookingModal from 'components/VacationOffers/BookingModal';

const defaultBookingData = () => ({
  arrival: new Date(),
  departure: new Date(),
  summer: false,
  socialPoints: true,
  eonBookingId: '',
  countervailingBenefit: {
    childOld: 0,
    childYoung: 0,
    firstAdult: 0,
    secondAdult: 0,
    perNight: 0,
  } as CountervailingBenefit,
});

export const EonOffers = ({
  houseId,
  offers,
}: {
  houseId: string;
  offers: {
    id: string;
    active: boolean;
    isBooked: boolean;
    arrival: Date;
    departure: Date;
    socialCreditPoints: boolean;
    summerHolidays: boolean;
    eonBookingId: string;
    countervailingBenefit: CountervailingBenefit;
  }[];
}): ReactElement => {
  const { isAdmin } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const [modelOpen, setModalOpen] = useState(false);
  const [createBooking] = useEonOffer_CreateMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: houseId })],
    context: {
      debounceKey: 'useEonOffer_CreateMutation',
      notification: {
        success: t('components.eon.offers.notifications.add.success'),
        error: t('components.eon.offers.notifications.add.error'),
      },
    },
  });
  const [deleteBooking] = useEonOffer_DeleteMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: houseId })],
    context: {
      debounceKey: 'useEonOffer_DeleteMutation',
      notification: {
        success: t('components.eon.offers.notifications.delete.success'),
        error: t('components.eon.offers.notifications.delete.error'),
      },
    },
  });
  const [updateBooking] = useEonOffer_UpdateMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: houseId })],
    context: {
      debounceKey: 'useEonOffer_UpdateMutation',
      notification: {
        success: t('components.eon.offers.notifications.update.success'),
        error: t('components.eon.offers.notifications.update.error'),
      },
    },
  });

  const handleOpenModal = () => setModalOpen(true);

  const handleCancel = () => setModalOpen(false);

  const handleConfirm = (booking: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    eonBookingId: string;
    countervailingBenefit: CountervailingBenefit;
  }) => {
    createBooking({
      variables: {
        houseId: houseId,
        offer: {
          active: true,
          bookingDate: {
            arrival: booking.arrival.toISOString(),
            departure: booking.departure.toISOString(),
          },
          socialCreditPoints: booking.socialPoints,
          summerHolidays: booking.summer,
          eonBookingId: booking.eonBookingId,
          countervailingBenefit: booking.countervailingBenefit,
        },
      },
    });
    setModalOpen(false);
  };

  const handleDeleteBooking = (id: string) => () => {
    deleteBooking({
      variables: {
        id,
      },
    });
  };

  const handleUpdateBooking = (id: string) => (input: EonOfferInput) => {
    updateBooking({
      variables: {
        offerId: id,
        offer: input,
      },
    });
  };

  return (
    <div className="EonOffers">
      <p className="mb-4 font-medium">
        {t('components.eon.offers.tableHeader')}
      </p>
      {offers.length ? (
        <table className="mb-4 w-full">
          <thead>
            <tr>
              <th>{t('components.eon.offers.TableColumnHeaders.arrival')}</th>
              <th>{t('components.eon.offers.TableColumnHeaders.departure')}</th>
              <th>
                {t('components.eon.offers.TableColumnHeaders.pointsCrediting')}
              </th>
              <th>
                {t('components.eon.offers.TableColumnHeaders.summerHolidays')}
              </th>
              <th>
                {t('components.eon.offers.TableColumnHeaders.eonBookingId')}
              </th>
              <th>
                {t(
                  'components.eon.offers.TableColumnHeaders.countervailingBenefitPerNight',
                )}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {offers
              .filter(booking => isAdmin || booking.active)
              .sort((a, b) =>
                (a.arrival || '') > (b.arrival || '')
                  ? 1
                  : a.arrival === b.arrival
                  ? (a.departure || '') > (b.departure || '')
                    ? 1
                    : -1
                  : -1,
              )
              .map((booking, index) => (
                <EonOfferRow
                  key={index}
                  offer={{
                    ...booking,
                    countervailingBenefit: booking.countervailingBenefit,
                  }}
                  onUpdate={handleUpdateBooking(booking.id)}
                  onDelete={handleDeleteBooking(booking.id)}
                />
              ))}
          </tbody>
        </table>
      ) : (
        <div className="mb-2">{t('components.eon.offers.noDateHint')}</div>
      )}
      {isAdmin && (
        <KeyFormArea>
          <Button
            label={t('components.eon.offers.newBookingButton')}
            onClick={handleOpenModal}
          />
          <BookingModal
            open={modelOpen}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            booking={defaultBookingData()}
            showEonBookingId={true}
            showCountervailingBenefit={{
              perNight: true,
            }}
          />
        </KeyFormArea>
      )}
    </div>
  );
};
