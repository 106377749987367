import { FunctionComponent } from 'react';

const Card: FunctionComponent = ({ children }) => {
  return (
    <div className="Card">
      <div className="my-4 border p-4 shadow">{children}</div>
    </div>
  );
};

export default Card;
