import { ApplicationForm } from 'components/applicationForm/ApplicationForm';
import LoadingOrError from 'components/base/LoadingOrError';
import config from 'config/config';
import { useEmail, useKID, useUserName } from 'context/UserInfoContext';
import { chooseGuest } from './util';
import {
  ApplicationInput,
  EditMyApplications_GetApplicationQuery,
  NewMyApplications_CreateApplicationMutation,
  ProcessingStatus,
  useNewMyApplicationFromTemplate_GetApplicationQuery,
  useNewMyApplications_CreateApplicationMutation,
  useNewMyApplications_GetPersondataQuery,
} from 'gql/generated/types-and-hooks';
import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

export const NewMyApplicationFromTemplate = (): ReactElement => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const {
    data: applicationTemplate,
    error,
    loading,
  } = useNewMyApplicationFromTemplate_GetApplicationQuery({
    variables: { id },
  });

  const userName = useUserName();
  const email = useEmail();

  const kid = useKID();

  const {
    data: personData,
    loading: loadingPersonData,
    error: personDataError,
  } = useNewMyApplications_GetPersondataQuery({
    variables: {
      kid: kid,
    },
  });

  const applicant = useMemo(
    () => ({
      accountingArea: personData?.getPersondata?.accountingArea ?? '',
      staffNumber: personData?.getPersondata?.staffNumber ?? '',
      email,
      name: userName,
    }),
    [personData, userName, email],
  );

  const initialData = useMemo<
    EditMyApplications_GetApplicationQuery['application'] | undefined
  >(() => {
    if (applicationTemplate != null) {
      return {
        ...applicationTemplate.application,
        id: '',
        processingStatus: ProcessingStatus.Created,
        applicantKID: kid,
        applicant,
        ...chooseGuest({
          applicant,
          applicantInTemplate: applicationTemplate.application.applicant,
          guestInTemplate: applicationTemplate.application.guest,
        }),
        createdAt: new Date().toISOString(),
        bookedOfferName: '',
        eonBookings: [],
        hawBookings: [],
      };
    }
    return undefined;
  }, [applicationTemplate, kid, applicant]);

  const onCompleted = useCallback(
    (data: NewMyApplications_CreateApplicationMutation) => {
      if (data) {
        history.replace(
          config.localRoutes.employeeApplications.edit.replace(
            ':id',
            data.createApplication.id,
          ),
        );
      }
    },
    [history],
  );

  const [createApplication, { called }] =
    useNewMyApplications_CreateApplicationMutation({
      onCompleted,
      context: {
        debounceKey: 'useNewMyApplications_CreateApplicationMutation',
        notification: {
          success: t('pages.myApplications.notifications.create.success'),
          error: t('pages.myApplications.notifications.create.error'),
        },
      },
    });

  const handleSubmit = useCallback(
    (data: ApplicationInput) => {
      if (called === false) {
        createApplication({
          variables: {
            createApplication: {
              ...data,
              processingStatus: ProcessingStatus.Submitted,
            },
          },
        });
      }
    },
    [called, createApplication],
  );

  return (
    <div className="NewMyApplicationFromTemplate">
      <LoadingOrError
        loading={loading || loadingPersonData}
        error={error || personDataError}
        data={initialData}
        render={initialData => (
          <ApplicationForm
            adminView={true}
            initialData={initialData}
            onSubmit={handleSubmit}
            onSave={() => void 0}
            onDelete={() => void 0}
          />
        )}
      />
    </div>
  );
};
