import { EditableTextArea } from 'components/applicationForm/editableInputs';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import { AvailableCharacters } from 'components/base/AvailableCharacters';
import {
  FormGrid,
  FormKey,
  FormValue,
  FormControl,
} from 'components/base/FormLayout';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  FreeTextId,
  refetchEmailTemplates_GetSignatureTemplateQuery,
  useEmailTemplates_GetSignatureTemplateQuery,
  useEmailTemplates_UpdateFreeTextMutation,
} from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_TEXT_AREA_CHARACTERS } from './common';

export const SignatureTemplate = (): ReactElement => {
  const { t } = useTranslation();

  const [updateEmailFreetext] = useEmailTemplates_UpdateFreeTextMutation({
    refetchQueries: [refetchEmailTemplates_GetSignatureTemplateQuery()],
    context: {
      debounceKey: 'useEmailTemplates_UpdateFreeTextMutation',
      notification: {
        success: t(
          'components.settings.email.templates.notifications.update.success',
        ),
        error: t(
          'components.settings.email.templates.notifications.update.error',
        ),
      },
    },
  });
  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);

  const { loading, error, data } =
    useEmailTemplates_GetSignatureTemplateQuery();

  const { managementBoard = '', text = '' } = data?.emailSignature || {};

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors: validationErrors },
    watch,
  } = useForm({
    defaultValues: {
      managementBoard,
      text,
    },
  });

  const { text: currentText, managementBoard: currentManagementBoard } =
    watch();

  useEffect(() => {
    reset({ managementBoard, text });
  }, [data, managementBoard, reset, text]);

  const onCancel = useCallback(() => {
    leaveEditMode();
    reset({ managementBoard, text });
  }, [leaveEditMode, managementBoard, text, reset]);

  const onConfirm = handleSubmit(async ({ managementBoard, text }) => {
    await updateEmailFreetext({
      onCompleted: () => leaveEditMode(),
      variables: {
        freeTexts: [
          {
            text: managementBoard,
            id: FreeTextId.SignatureManagementBoard,
          },
          {
            id: FreeTextId.SignatureFreetext,
            text: text,
          },
        ],
      },
    });
  });

  return (
    <div className="SignatureTemplate">
      <LoadingOrError {...{ loading, error }}>
        <FormGrid>
          <FormKey
            label={t('components.settings.email.templates.origin') + ':'}
          >
            <FormValue>
              <ValidationFeedback error={validationErrors.text}>
                <EditableTextArea
                  editMode={editMode}
                  nonEditableValue={text}
                  maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                  {...register('text', {
                    validate: v =>
                      !isEmpty(v) ||
                      (t(
                        'components.settings.email.templates.signatureValidationHint',
                      ) as string),
                  })}
                />
                <AvailableCharacters
                  current={currentText.length}
                  maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                  visible={editMode}
                />
              </ValidationFeedback>
            </FormValue>
          </FormKey>
          <FormControl>
            <EditControls
              editMode={editMode}
              onEdit={enterEditMode}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          </FormControl>
          <FormKey
            label={
              t('components.settings.email.templates.managementBoard') + ':'
            }
          >
            <FormValue>
              <ValidationFeedback error={validationErrors.managementBoard}>
                <EditableTextArea
                  editMode={editMode}
                  nonEditableValue={managementBoard}
                  maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                  {...register('managementBoard', {
                    validate: v =>
                      !isEmpty(v) ||
                      (t(
                        'components.settings.email.templates.signatureValidationHint',
                      ) as string),
                  })}
                />
                <AvailableCharacters
                  current={currentManagementBoard.length}
                  maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
                  visible={editMode}
                />
              </ValidationFeedback>
            </FormValue>
          </FormKey>
        </FormGrid>
      </LoadingOrError>
    </div>
  );
};
