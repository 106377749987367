import UploadButton from 'components/base/UploadButton';
import {
  FileType,
  refetchVacationOfferForm_GetVacationOfferQuery,
  useUploadVacationOfferFile_AddVacationOfferFileMutation,
} from 'gql/generated/types-and-hooks';
import { readFiles } from 'helpers/fileHelper';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UploadVacationOfferFile = ({
  offer,
}: {
  offer: {
    id: string;
  };
}): ReactElement => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [addFile] = useUploadVacationOfferFile_AddVacationOfferFileMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useUploadVacationOfferFile_AddVacationOfferFileMutation',
      notification: {
        success: t(
          'components.VacationOffers.UploadVacationOfferFile.notifications.success',
        ),
        error: t(
          'components.VacationOffers.UploadVacationOfferFile.notifications.error',
        ),
      },
    },
  });
  const handleAdd = async (files: FileList) => {
    setIsUploading(true);
    for (const file of await readFiles(files)) {
      await addFile({
        variables: {
          vacationOfferId: offer.id,
          file: {
            name: file.name,
            content: file.content,
            type: FileType.Attachment,
          },
        },
      });
    }
    setIsUploading(false);
  };

  return (
    <UploadButton
      label={t('components.attachment.buttons.add')}
      disabled={isUploading}
      onChange={handleAdd}
    />
  );
};

export default UploadVacationOfferFile;
