import FormSection from '../FormSection';
import RowLabel from '../RowLabel';
import { useTranslation } from 'react-i18next';
import BaseDialog from 'components/base/BaseDialog';
import { Calendar } from 'react-date-range';
import { de } from 'date-fns/locale';
import { ReactElement, useEffect, useState } from 'react';
import { setDate, getDaysInMonth } from 'date-fns';
import { RadioButton } from '../RadioButton';
import {
  Control,
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { ValidationFeedback } from '../Validatable';
import {
  EditableCheckbox,
  EditableInput,
  EditableTextArea,
} from 'components/applicationForm/editableInputs';
import { getString, isEmpty, sequence } from 'helpers/util';
import isNumeric from 'validator/lib/isNumeric';
import { NumberDropdown } from '../NumberDropdown';
import { Icon } from 'components/base/Icon';
import {
  convertDateFromUTC,
  convertDateToUTC,
  dateMonthYearFormat,
} from 'helpers/dateFormats';
import Button from 'components/base/Button';
import {
  EditMyApplications_GetApplicationQuery,
  FurtherGuests,
  Partner,
  PartnerStatus,
} from 'gql/generated/types-and-hooks';
import { AvailableCharacters } from 'components/base/AvailableCharacters';

const getDateButtonText = (defaultText: string, date?: Date) => {
  if (date == null || isNaN(date.valueOf())) {
    return defaultText;
  }
  return dateMonthYearFormat.format(date);
};

const getTotalNumberOfTravelers = ({
  partner,
  furtherGuests,
}: {
  partner: Partner;
  furtherGuests: FurtherGuests;
}) => {
  return (
    1 + // At least one person is alsways travelling
    (partner.status !== PartnerStatus.Without ? 1 : 0) + // Add one if not traveling alone
    furtherGuests.familyChildrenUnderage.length +
    furtherGuests.nonFamilyChildrenUnderage.length
  );
};

const SelectDateModal = ({
  title,
  date,
  min,
  max,
  open = false,
  onCancel,
  onConfirm,
}: {
  title: string;
  date: Date;
  min: Date;
  max: Date;
  open: boolean;
  onCancel: () => void;
  onConfirm: (pickedDate: Date) => void;
}) => {
  const { t } = useTranslation();
  const [pickedDate, setPickedDate] = useState(convertDateFromUTC(date));

  const handleConfirm = () => {
    if (pickedDate) {
      onConfirm(convertDateToUTC(pickedDate));
    }
  };

  useEffect(() => {
    setPickedDate(convertDateFromUTC(date));
  }, [date]);

  return (
    <BaseDialog text={''} open={open} title={title} onClose={onCancel}>
      <div className="w-full">
        <Calendar
          classNames={{ calendarWrapper: 'w-full' }}
          locale={de}
          dateDisplayFormat="dd.MM.yyyy"
          date={isNaN(pickedDate.valueOf()) ? undefined : pickedDate}
          minDate={min}
          maxDate={max}
          onChange={range => {
            const newDate = range as Date;
            setPickedDate(newDate);
          }}
          onShownDateChange={changedDate => {
            const daysInMonth = getDaysInMonth(changedDate);
            const pickedDay = pickedDate.getDate();
            const newDate = setDate(
              changedDate,
              pickedDay > daysInMonth ? daysInMonth : pickedDay,
            );
            setPickedDate(newDate);
          }}
        />
        <div className="flex flex-row-reverse gap-x-2 pt-4">
          <Button
            label={t(
              'components.form.travelPartnerInput.selectDateModal.confirm',
            )}
            onClick={handleConfirm}
            styleOptions={{
              inverted: true,
            }}
          />
          <Button
            label={t(
              'components.form.travelPartnerInput.selectDateModal.cancel',
            )}
            onClick={onCancel}
          />
        </div>
      </div>
    </BaseDialog>
  );
};

const TravelPartnersInput = ({
  editMode,
}: {
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    control,
    trigger,
  } = useFormContext<EditMyApplications_GetApplicationQuery['application']>();

  const {
    fields: familyChildren,
    append: appendFamilyChildren,
    remove: removeFamilyChildren,
  } = useFieldArray({
    name: 'furtherGuests.familyChildrenUnderage',
    shouldUnregister: true,
    control,
  });

  const {
    fields: nonFamilyTravellers,
    append: appendNonFamilyTravellers,
    remove: removeNonFamilyTravellers,
  } = useFieldArray({
    name: 'furtherGuests.nonFamilyChildrenUnderage',
    shouldUnregister: true,
    control,
  });

  const [modalState, setModalState] = useState<{
    open: boolean;
    date: Date;
    onChange: (pickedDate: Date) => void;
  }>({
    open: false,
    date: new Date(),
    onChange: () => null,
  });

  const relationshipChoices = [
    {
      label: t(
        'components.form.travelPartnerInput.relationshipChoices.married',
      ),
      value: PartnerStatus.Married,
    },
    {
      label: t('components.form.travelPartnerInput.relationshipChoices.alone'),
      value: PartnerStatus.Without,
    },
  ];

  const comment = getValues('comment');
  const dogAllowed = getValues('dog');
  const partner = getValues('partner');
  const furtherGuests = getValues('furtherGuests');

  const today = new Date();
  const inNineMonths = new Date(new Date().setMonth(today.getMonth() + 9));

  return (
    <div className="TravelPartnerInput">
      <SelectDateModal
        title={t('components.form.travelPartnerInput.selectDateModal.title')}
        open={modalState.open}
        date={modalState.date}
        min={new Date('1920-01-01')}
        max={inNineMonths}
        onCancel={() =>
          setModalState(prev => ({
            ...prev,
            open: false,
          }))
        }
        onConfirm={pickedDate => {
          modalState.onChange(pickedDate);
          setModalState(prev => ({
            ...prev,
            open: false,
          }));
          trigger('furtherGuests');
        }}
      />

      <FormSection name={t('components.form.travelPartnerInput.sectionTitle')}>
        <div className="grid grid-cols-12 items-center gap-3">
          <RowLabel>
            {t('components.form.travelPartnerInput.stayWith')}
          </RowLabel>
          <div className="col-span-8">
            {editMode ? (
              <div className="flex gap-2">
                {relationshipChoices.map(choice => (
                  <RadioButton
                    key={choice.value}
                    {...register('partner.status')}
                    label={choice.label}
                    value={choice.value}
                  />
                ))}
              </div>
            ) : (
              <span>
                {relationshipChoices.find(
                  choice => choice.value === partner.status,
                )?.label || ''}
              </span>
            )}
          </div>
          {partner.status !== PartnerStatus.Without && (
            <>
              <RowLabel className={'ml-8'}>
                {t('components.form.travelPartnerInput.partnerInCompany')}
              </RowLabel>
              <div className="col-span-5">
                <EditableCheckbox
                  editMode={editMode}
                  nonEditableValue={partner.employed ? true : false}
                  {...register('partner.employed', {
                    shouldUnregister: true,
                  })}
                />
              </div>
              {partner.employed ? (
                <>
                  <RowLabel className={'ml-16'}>
                    {t('components.form.travelPartnerInput.nameOfPartner')}
                  </RowLabel>
                  <div className="col-span-5">
                    <ValidationFeedback error={errors?.partner?.name}>
                      <EditableInput
                        editMode={editMode}
                        nonEditableValue={getString(partner.name)}
                        {...register('partner.name', {
                          shouldUnregister: true,
                          required: t<string>(
                            'pages.elements.formInputValidator.required',
                          ),
                        })}
                      />
                    </ValidationFeedback>
                  </div>
                  <RowLabel className={'ml-16'}>
                    {t(
                      'components.form.travelPartnerInput.staffNumberOfPartner',
                    )}
                  </RowLabel>
                  <div className="col-span-5">
                    <ValidationFeedback error={errors?.partner?.staffNumber}>
                      <EditableInput
                        editMode={editMode}
                        nonEditableValue={getString(partner.staffNumber)}
                        {...register('partner.staffNumber', {
                          shouldUnregister: true,
                          required: t<string>(
                            'pages.elements.formInputValidator.required',
                          ),
                          validate: v => {
                            if (!isEmpty(v) && !isNumeric(v as string))
                              return t<string>(
                                'pages.elements.formInputValidator.numberOnly',
                              );
                            return true;
                          },
                        })}
                      />
                    </ValidationFeedback>
                  </div>
                </>
              ) : null}
            </>
          )}
          <RowLabel>
            {t('components.form.travelPartnerInput.minorChildren')}
          </RowLabel>
          <div className="col-span-1">
            {editMode ? (
              <NumberDropdown
                value={familyChildren.length}
                max={5}
                onChange={e =>
                  handleChangeNumberOfTravellers({
                    append: appendFamilyChildren,
                    remove: removeFamilyChildren,
                  })(parseInt(e.target.value), familyChildren.length)
                }
              />
            ) : (
              <span>{familyChildren.length}</span>
            )}
          </div>
          {familyChildren.map((child, index) => {
            return (
              <div className="col-span-12" key={child.id}>
                <div className="grid grid-cols-12 items-center gap-3">
                  <RowLabel className="ml-8">{`${t(
                    'components.form.travelPartnerInput.child',
                  )} ${index + 1}`}</RowLabel>
                  <div className="col-span-2">
                    {editMode ? (
                      <ValidatedDateInput
                        control={control}
                        name={`furtherGuests.familyChildrenUnderage.${index}.dateOfBirth`}
                        onClick={value => {
                          setModalState({
                            open: true,
                            date: new Date(value),
                            onChange: pickedDate => {
                              setValue(
                                `furtherGuests.familyChildrenUnderage.${index}.dateOfBirth`,
                                pickedDate.valueOf(),
                              );
                            },
                          });
                        }}
                      />
                    ) : (
                      <span>
                        {getDateButtonText(
                          t('components.form.travelPartnerInput.placeholder'),
                          new Date(child.dateOfBirth),
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <RowLabel>
            {t('components.form.travelPartnerInput.otherChildren')}
          </RowLabel>
          <div className="col-span-1">
            {editMode ? (
              <NumberDropdown
                value={nonFamilyTravellers.length}
                max={5}
                onChange={e =>
                  handleChangeNumberOfTravellers({
                    append: appendNonFamilyTravellers,
                    remove: removeNonFamilyTravellers,
                  })(parseInt(e.target.value), nonFamilyTravellers.length)
                }
              />
            ) : (
              <span>{nonFamilyTravellers.length}</span>
            )}
          </div>
          {nonFamilyTravellers.map((traveller, index) => (
            <div className="col-span-12" key={traveller.id}>
              <div className="grid grid-cols-12 items-center gap-3">
                <RowLabel className="ml-8">{`${t(
                  'components.form.travelPartnerInput.person',
                )} ${index + 1}`}</RowLabel>
                <div className="col-span-2">
                  {editMode ? (
                    <ValidatedDateInput
                      control={control}
                      name={`furtherGuests.nonFamilyChildrenUnderage.${index}.dateOfBirth`}
                      onClick={value => {
                        setModalState({
                          open: true,
                          date: new Date(value),
                          onChange: pickedDate => {
                            setValue(
                              `furtherGuests.nonFamilyChildrenUnderage.${index}.dateOfBirth`,
                              pickedDate.valueOf(),
                            );
                          },
                        });
                      }}
                    />
                  ) : (
                    <span>
                      {getDateButtonText(
                        t('components.form.travelPartnerInput.placeholder'),
                        new Date(traveller.dateOfBirth),
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="col-span-12 grid grid-cols-12 bg-gray-100 p-2 font-semibold">
            <RowLabel>
              {t('components.form.travelPartnerInput.totalNumberOfTravelers')}
            </RowLabel>
            <div className="col-span-8">
              {getTotalNumberOfTravelers({
                partner,
                furtherGuests,
              })}
            </div>
          </div>
          <RowLabel>{t('components.form.travelPartnerInput.dog')}</RowLabel>
          <div className="col-span-5">
            <EditableCheckbox
              editMode={editMode}
              nonEditableValue={dogAllowed}
              {...register('dog', {
                shouldUnregister: true,
              })}
            />
          </div>
          <RowLabel>{t('components.form.travelPartnerInput.remarks')}</RowLabel>
          <div className="col-span-8">
            <EditableTextArea
              editMode={editMode}
              maxLength={500}
              nonEditableValue={comment}
              {...register('comment', {
                shouldUnregister: true,
              })}
            />
            <AvailableCharacters
              current={comment?.length || 0}
              maxLength={500}
              visible={editMode}
            />
          </div>
        </div>
      </FormSection>
    </div>
  );
};

const handleChangeNumberOfTravellers =
  ({
    append,
    remove,
  }: {
    append: (fields: { dateOfBirth }[]) => void;
    remove: (ids: number[]) => void;
  }) =>
  (newAmount: number, currentAmount: number) => {
    const difference = newAmount - currentAmount;
    if (difference === 0) return;
    if (difference > 0) {
      const additionalFields = Array(difference).fill({
        dateOfBirth: new Date(''), // Invalid Date
      });
      append(additionalFields);
    } else {
      const removeIds = sequence(newAmount, currentAmount, 1);
      remove(removeIds);
    }
  };

const ValidatedDateInput = ({
  control,
  onClick,
  name,
}: {
  control: Control<EditMyApplications_GetApplicationQuery['application']>;
  onClick: (date: number) => void;
  name: `furtherGuests.${
    | 'familyChildrenUnderage'
    | 'nonFamilyChildrenUnderage'}.${number}.dateOfBirth`;
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      rules={{
        validate: value => {
          if (!value || isNaN(value.valueOf())) {
            return t<string>('pages.elements.formInputValidator.required');
          }
        },
      }}
      shouldUnregister={true}
      name={name}
      render={({ field: { value }, fieldState }) => {
        return (
          <ValidationFeedback error={fieldState.error}>
            <button
              type="button"
              className="flex w-full cursor-pointer rounded-md border border-grey px-6 py-0.5 focus:outline-none"
              onClick={() => onClick(value)}
            >
              <div className="flex-1">
                {getDateButtonText(
                  t('components.form.travelPartnerInput.placeholder'),
                  new Date(value),
                )}
              </div>
              <div>
                <Icon icon={'CalendarIcon'} />
              </div>
            </button>
          </ValidationFeedback>
        );
      }}
    />
  );
};

export default TravelPartnersInput;
