import { useCallback, useState } from 'react';
import {
  GetEonHousesAndOffersQuery,
  useGetEonHousesAndOffersLazyQuery,
} from 'gql/generated/types-and-hooks';
import { useTranslation } from 'react-i18next';
import { ImportEntry } from './UploadController';

export type Action = 'create' | 'ignore';

export type MatchingEntry = {
  bookingId: string;
  house: string;
  houseId: string;
  arrival: Date;
  departure: Date;
  charge: number;
  matchResult: MatchResult;
  action: Action;
};

export type MatchResult = {
  bookingId: boolean;
  house: boolean;
};

export const useMatchingController = ({
  onMatchingLoaded,
}: {
  onMatchingLoaded: (matching: Record<Action, MatchingEntry[]>) => void;
}): {
  loading: boolean;
  error?: string;
  data?: Record<Action, MatchingEntry[]>;
  getMatching: (importEntries: ImportEntry[]) => void;
  reset: () => void;
} => {
  const { t } = useTranslation();

  const [getEonHousesAndOffers, { loading }] =
    useGetEonHousesAndOffersLazyQuery();

  const [result, setResult] = useState<{
    error?: string;
    data?: Record<Action, MatchingEntry[]>;
  }>({
    error: undefined,
    data: undefined,
  });

  const getMatching = useCallback(
    async (importEntries: ImportEntry[]) => {
      if (importEntries.length > 0) {
        try {
          const result = await getEonHousesAndOffers();
          if (result.data?.eonHouses) {
            const data = calculateMatching(
              importEntries,
              result.data?.eonHouses,
            );
            onMatchingLoaded(data);
            setResult({
              error: undefined,
              data,
            });
          }
        } catch (error: unknown) {
          const msg = t(
            'components.VacationOffers.import.errors.loadingOffersFailed',
          );
          setResult({
            error: msg,
            data: undefined,
          });
          onMatchingLoaded({ create: [], ignore: [] });
        }
      } else {
        setResult({
          error: undefined,
          data: undefined,
        });
      }
    },
    [getEonHousesAndOffers, t, onMatchingLoaded],
  );

  const reset = useCallback(() => {
    setResult({
      error: undefined,
      data: undefined,
    });
  }, []);

  return {
    loading,
    ...result,
    getMatching,
    reset,
  };
};

const calculateMatching = (
  importEntries: ImportEntry[],
  eonHousesAndOffers: GetEonHousesAndOffersQuery['eonHouses'],
): Record<Action, MatchingEntry[]> =>
  importEntries.reduce<Record<Action, MatchingEntry[]>>(
    (result, importEntry) => {
      const house = eonHousesAndOffers.find(
        /* !! The field region is intentionally used as a house here! */
        house => house.name === importEntry.region,
      );

      const eonOffer = house?.eonOffers?.find(
        offer => offer.eonBookingId === importEntry.bookingId,
      );

      const matchResult: MatchResult = {
        bookingId: !!eonOffer,
        house: !!house,
      };

      const action = deriveAction(matchResult);

      const newEntry = {
        houseId: house?.id ?? '',
        bookingId: importEntry.bookingId,
        /* !! The field region is intentionally used as a house here! */
        house: importEntry.region,
        arrival: importEntry.arrival,
        departure: importEntry.departure,
        charge: importEntry.charge,
        matchResult,
        action,
      };

      if (action === 'create') {
        return {
          ...result,
          create: [...result.create, newEntry],
        };
      } else {
        return {
          ...result,
          ignore: [...result.ignore, newEntry],
        };
      }
    },
    { create: [], ignore: [] },
  );

const deriveAction = (matchResult: MatchResult): Action => {
  if (matchResult.house && !matchResult.bookingId) {
    return 'create';
  }
  return 'ignore';
};
