import { getAlphanumericSort } from 'helpers/util';
import { ReactElement, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import AttachmentActions from 'components/AttachmentTable/AttachmentActions';
import TextInput from 'components/applicationForm/TextInput';
import AuthLink from 'components/AttachmentTable/AuthLink';
import { UserInfoContext } from 'context/UserInfoContext';

const sort = getAlphanumericSort();

type FilesProps = {
  attachments: {
    id: string;
    description: string;
    path: string;
    name: string;
  }[];
  onUpload?: (id: string, file: File) => void;
  onDelete?: (id: string) => void;
  onDescriptionChanged?: (id: string, description: string) => void;
};

export const Files = ({
  attachments,
  onUpload,
  onDelete,
  onDescriptionChanged,
}: FilesProps): ReactElement => {
  const { t } = useTranslation();

  const handleUpload = useCallback(
    (id: string) => (file: File) => onUpload && onUpload(id, file),
    [onUpload],
  );

  const handleDelete = useCallback(
    (id: string) => () => onDelete && onDelete(id),
    [onDelete],
  );

  const handleDescriptionChanged = useCallback(
    (id: string) => (description: string) =>
      onDescriptionChanged && onDescriptionChanged(id, description),
    [onDescriptionChanged],
  );

  return (
    <div className="Files w-full table-fixed">
      <p className="bg-gray-200 px-2 py-1 font-medium text-primary">
        {t('components.information.files.title')}
      </p>
      <ol>
        {attachments
          .sort((a, b) => sort(a.description, b.description))
          .map(attachment => (
            <Item
              key={attachment.id}
              attachment={attachment}
              onUploadFile={handleUpload(attachment.id)}
              onDelete={handleDelete(attachment.id)}
              onDescriptionChanged={handleDescriptionChanged(attachment.id)}
            />
          ))}
      </ol>
    </div>
  );
};

const Item = ({
  attachment,
  onUploadFile,
  onDelete,
  onDescriptionChanged,
}: {
  attachment: {
    path: string;
    name: string;
    description: string;
  };
  onUploadFile?: (file: File) => void;
  onDelete?: () => void;
  onDescriptionChanged?: (newDescription: string) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const [edit, enterEditMode, leaveEditmode] = useEditMode(false);
  const [description, setDescription] = useState(attachment.description);

  const handleCancel = useCallback(() => {
    leaveEditmode();
    setDescription(attachment.description);
  }, [leaveEditmode, setDescription, attachment.description]);

  const handleConfirm = useCallback(() => {
    if (onDescriptionChanged && description !== attachment.description) {
      onDescriptionChanged(description);
    }
    leaveEditmode();
  }, [
    onDescriptionChanged,
    description,
    attachment.description,
    leaveEditmode,
  ]);

  const handleEdit = useCallback(() => enterEditMode(), [enterEditMode]);

  return (
    <li className="Row flex w-full items-center justify-between gap-x-4 px-2 py-1 even:bg-gray-50">
      {edit ? (
        <TextInput
          placeholder={t('components.attachment.placeholder')}
          onChange={e => setDescription(e.currentTarget.value)}
          value={description}
        />
      ) : (
        <div>
          {attachment.path ? (
            <AuthLink
              attachment={{
                path: attachment.path,
                name: description || t('components.attachment.placeholder'),
              }}
            />
          ) : (
            <div style={{ color: 'red' }}>
              {t('components.attachment.notFound')}
            </div>
          )}
        </div>
      )}

      {isAdmin && (
        <div className="flex">
          <EditControls
            editMode={edit}
            onEdit={handleEdit}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
          {onUploadFile && onDelete ? (
            <AttachmentActions onDelete={onDelete} onUpload={onUploadFile} />
          ) : null}
        </div>
      )}
    </li>
  );
};
