import { ReactElement } from 'react';

type AvailableCharactersProps = {
  visible?: boolean;
  maxLength: number;
  current: number;
};

export const AvailableCharacters = ({
  visible = true,
  current,
  maxLength,
}: AvailableCharactersProps): ReactElement => {
  const tooManyChars = current > maxLength;
  return visible ? (
    <div
      className={`text-right text-sm ${
        tooManyChars ? 'text-red-500' : 'text-gray-500'
      }`}
    >{`${current}/${maxLength}`}</div>
  ) : (
    <></>
  );
};
