import VacationPrices from 'components/VacationOffers/VacationPrices';
import HouseDescription from 'components/VacationOffers/HouseDescription';
import Header from './Header';
import VacationOfferAttachments from './VacationOfferAttachments';
import BookingDates from './BookingDates';
import { KeyFormProvider } from 'context/KeyFormContext';
import Charges from '../Houses/Charges';
import Photos from '../Houses/Photos';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { useVacationOfferForm_GetVacationOfferQuery } from 'gql/generated/types-and-hooks';
import LoadingOrError from 'components/base/LoadingOrError';

const VacationOfferForm = ({
  vacationOfferId,
}: {
  vacationOfferId: string;
}): ReactElement => {
  const { t } = useTranslation();
  const { loading, error, data } = useVacationOfferForm_GetVacationOfferQuery({
    variables: { id: vacationOfferId },
  });

  return (
    <div className="VacationOfferForm my-4 border p-4 shadow">
      <LoadingOrError
        loading={loading}
        error={error}
        data={data?.vacationOffer}
        render={vacationOffer => {
          return (
            <KeyFormProvider>
              <Header
                offer={{
                  ...vacationOffer,
                  name: vacationOffer.name || '',
                  service: vacationOffer.service || '',
                  house: vacationOffer.house || undefined,
                  bookingOffers: vacationOffer.bookingOffers || undefined,
                }}
              />
              {vacationOffer.house ? (
                <>
                  <div className="mb-10">
                    <HouseDescription
                      offer={{
                        ...vacationOffer,
                        name: vacationOffer.name || '',
                        service: vacationOffer.service || '',
                        house: vacationOffer.house || undefined,
                      }}
                    />
                  </div>
                  <div className="mb-10">
                    <Photos
                      house={{
                        id: vacationOffer.house.id,
                        files: vacationOffer.house.files || [],
                      }}
                      editable={false}
                    />
                  </div>
                  <div className="mb-10">
                    <VacationOfferAttachments
                      offer={{
                        id: vacationOffer.id,
                        files: vacationOffer.files || [],
                        house: {
                          files: vacationOffer.house.files || [],
                        },
                      }}
                    />
                  </div>
                  {vacationOffer.house.charges ? (
                    <>
                      <Charges house={vacationOffer.house} />
                      <VacationPrices
                        offer={{
                          id: vacationOffer.id,
                          charges: vacationOffer.charges,
                          house: vacationOffer.house,
                        }}
                      />
                    </>
                  ) : null}
                  <div className="mt-8">
                    <BookingDates
                      offer={{
                        id: vacationOffer.id,
                        bookingOffers:
                          vacationOffer.bookingOffers?.map(x => {
                            return {
                              ...x,
                              countervailingBenefit:
                                x.countervailingBenefit ?? {
                                  childOld: 0,
                                  childYoung: 0,
                                  firstAdult: 0,
                                  perNight: 0,
                                  secondAdult: 0,
                                },
                              arrival: new Date(x.bookingDate.arrival || ''),
                              departure: new Date(
                                x.bookingDate.departure || '',
                              ),
                            };
                          }) || [],
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="font-semibold text-red-700">
                  {t('pages.common.error')}
                </div>
              )}
            </KeyFormProvider>
          );
        }}
      ></LoadingOrError>
    </div>
  );
};

export default VacationOfferForm;
