import React, { HTMLProps } from 'react';

const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  HTMLProps<HTMLTextAreaElement> & {
    maxLength?: number;
  }
>(({ maxLength = 500, ...props }, ref) => (
  <textarea
    {...props}
    style={{ height: 200 }}
    className="w-full resize-none border p-2"
    maxLength={maxLength}
    ref={ref}
  ></textarea>
));
TextArea.displayName = 'TextArea';

export default TextArea;
