import { Icon } from 'components/base/Icon';
import { TableCell } from 'components/common/TableElements';
import config from 'config/config';
import {
  ApplicationFor,
  BookingConfirmationStatus,
  BookingDate,
  BookingType,
} from 'gql/generated/types-and-hooks';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import { ReactElement } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isHawBooking } from './data';
import { BookingAndApplication } from './interfaces';

const formatSocialCreditPoints = (p: number): string =>
  p < 0 ? `- ${-1 * p}` : p.toString();

const bookingDateText = (bookingDate: BookingDate) => {
  const arrival = bookingDate.arrival
    ? dateMonthYearFormat.format(new Date(bookingDate.arrival))
    : '---';
  const departure = bookingDate.departure
    ? dateMonthYearFormat.format(new Date(bookingDate.departure))
    : '---';

  return `${arrival} - ${departure}`;
};

const bookingStatusText = (
  bookingStatus: BookingConfirmationStatus | undefined | null,
  t: TFunction,
) => {
  if (bookingStatus === BookingConfirmationStatus.Confirmed) {
    return `(${t('pages.employeeApplications.ASSIGNED')})`;
  } else if (bookingStatus === BookingConfirmationStatus.Rejected) {
    return `(${t(`pages.employeeApplications.REJECTED`)})`;
  } else {
    return '';
  }
};

type RowProps = {
  item: BookingAndApplication;
  onSelect: (selected: boolean) => void;
  selected: boolean;
};

const getBookingType = (type: BookingType): string => {
  switch (type) {
    case BookingType.HawF:
      return 'HF';
    case BookingType.Eon:
      return 'EA';
    // case BookingType.HawG:
    //     return 'HG';
    default:
      return '';
  }
};

const Row = ({ item: booking, selected, onSelect }: RowProps): ReactElement => {
  const { t } = useTranslation();

  const bookingDate = {
    arrival: booking.bookingArrival,
    departure: booking.bookingDeparture,
  };
  const statusText = bookingStatusText(booking.bookingStatus, t);
  let label = `${bookingDateText(bookingDate)} ${statusText}`;
  // When booking is confirmed show the bookingChanges date
  // by default it is the same but it can be changed by the admin
  if (
    booking.bookingStatus === BookingConfirmationStatus.Confirmed &&
    booking.applicationBookingChangesArrival &&
    booking.applicationBookingChangesDeparture
  ) {
    label = `${bookingDateText({
      arrival: booking.applicationBookingChangesArrival,
      departure: booking.applicationBookingChangesDeparture,
    })} ${statusText}`;
  }

  return (
    <tr key={booking.bookingId} className="border-b border-grey">
      <TableCell>
        <input
          checked={selected}
          type="checkbox"
          onChange={event => onSelect(event.target.checked)}
        />
      </TableCell>
      <TableCell>
        {t(
          'pages.myApplications.processingStatus.' +
            booking.applicationProcessingStatus,
        )}
      </TableCell>
      <TableCell>{formatSocialCreditPoints(booking.creditPoints)}</TableCell>
      <TableCell>{booking.bookingPriority}</TableCell>
      <TableCell>
        {booking.applicationApplicationFor === ApplicationFor.Me
          ? booking.applicationApplicantName || ''
          : booking.applicationGuestName || ''}
      </TableCell>
      <TableCell>
        <span>{label}</span>
      </TableCell>
      <TableCell>{booking.houseName || ''}</TableCell>
      <TableCell>
        {isHawBooking(booking) ? booking.vacationOfferName ?? '---' : '---'}
      </TableCell>
      <TableCell>{`${dateMonthYearFormat.format(
        new Date(booking.applicationCreatedAt),
      )} `}</TableCell>
      <TableCell>{getBookingType(booking.bookingtype)}</TableCell>
      <TableCell>
        <Link
          target="_blank"
          data-tip={t('pages.employeeApplications.newFromTemplate')}
          to={config.localRoutes.employeeApplications.newFromTemplate.replace(
            ':id',
            booking.applicationId,
          )}
        >
          <Icon icon={'DuplicateIcon'} />
        </Link>
      </TableCell>
      <TableCell>
        <Link
          target="_blank"
          to={config.localRoutes.employeeApplications.edit.replace(
            ':id',
            booking.applicationId,
          )}
          data-tip={t('pages.employeeApplications.edit')}
        >
          <Icon icon={'PencilIcon'} />
        </Link>
      </TableCell>
    </tr>
  );
};

export default Row;
