import {
  ApplicationInsights,
  ICustomProperties,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const instrumentationKey = process.env['REACT_APP_AZURE_INSTRUMENTATION_KEY'];
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {},
  },
});

if (instrumentationKey) {
  appInsights.loadAppInsights();
}

const logEvent = (name: string, properties: ICustomProperties): void => {
  appInsights.trackEvent({
    name,
    properties: { ...properties },
  });
};
export { reactPlugin, appInsights, logEvent };
