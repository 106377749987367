import { FunctionComponent, useContext } from 'react';
import { createContext } from 'react';
import { useReducer } from 'react';

const KeyFormContext = createContext<KeyFormContextType>({
  setKey: () => null,
  clearKey: () => null,
  key: null,
});

type KeyFormContextType = {
  setKey: (key: string) => void;
  clearKey: () => void;
  key: string | null;
};

type KeyFormAction = {
  type: 'set' | 'unset';
  key?: string;
};

type KeyFormState = {
  key: string | null;
};

const keyReducer = (
  state: KeyFormState = { key: null },
  action: KeyFormAction,
): KeyFormState => {
  switch (action.type) {
    case 'set':
      return { key: action.key || null };
    case 'unset':
      return { key: null };
    default:
      return state;
  }
};

const KeyFormProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(keyReducer, { key: null });

  const setKey = (key: string) => dispatch({ type: 'set', key });
  const clearKey = () => dispatch({ type: 'unset' });
  const { key } = state;

  return (
    <KeyFormContext.Provider value={{ setKey, clearKey, key }}>
      {children}
    </KeyFormContext.Provider>
  );
};

const useKeyForm = (): KeyFormContextType => {
  const context = useContext(KeyFormContext);
  if (context === undefined) {
    throw new Error('useKeyForm must be used within a KeyFormProvider');
  }
  return context;
};

const getKey = (): string => Math.random().toString(36).substring(2, 9);

const KeyFormArea: FunctionComponent = ({ children }) => {
  const { key } = useKeyForm();
  return (
    <div className={`KeyFormArea ${key !== null ? 'invisible' : 'visible'}`}>
      {children}
    </div>
  );
};

export { KeyFormProvider, useKeyForm, KeyFormArea, getKey };
