import LoadingOrError from 'components/base/LoadingOrError';
import HouseEntry from 'components/Houses/HouseEntry';
import Intro from 'components/Houses/Intro';
import NewHouseEntry from 'components/Houses/NewHouseEntry';
import { getString } from 'helpers/util';
import { ReactElement } from 'react';
import { useNewHaw_GetListQuery } from 'gql/generated/types-and-hooks';

export const NewHaw = (): ReactElement => {
  const { data, loading, error } = useNewHaw_GetListQuery();
  const houses = data?.houses ? [...data.houses] : [];

  return (
    <div className="NewHaw">
      <LoadingOrError loading={loading} error={error}>
        <Intro showButton={false} />
        <NewHouseEntry isEonHome={false} />
        {houses.map(house => (
          <HouseEntry
            key={house.id}
            house={{
              ...house,
              activated: house.activated ? true : false,
              isEonHome: house.isEonHome ? true : false,
              city: getString(house.city),
              region: getString(house.region),
            }}
          />
        ))}
      </LoadingOrError>
    </div>
  );
};
