import {
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { Link } from 'react-router-dom';
import { buttonStyle } from './Button';

type LinkButtonProps = {
  to: string;
  inverted?: boolean;
  target?: HTMLAttributeAnchorTarget;
};

const LinkButton = ({
  children,
  inverted = false,
  to,
  target,
}: PropsWithChildren<LinkButtonProps>): ReactElement => {
  return (
    <Link to={to} target={target} className={buttonStyle({ inverted })}>
      {children}
    </Link>
  );
};

export default LinkButton;
