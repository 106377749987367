import { useTranslation } from 'react-i18next';
import FormSection from '../FormSection';
import TextInput from '../TextInput';
import RowLabel from '../RowLabel';
import { AccountingAreas } from 'interfaces/accountingAreas';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'helpers/util';
import { RadioButton } from '../RadioButton';
import isNumeric from 'validator/lib/isNumeric';
import { ValidationFeedback } from '../Validatable';
import { EditableInput } from 'components/applicationForm/editableInputs';
import isEmail from 'validator/lib/isEmail';
import {
  ApplicationFor,
  EditMyApplications_GetApplicationQuery,
  ProcessingStatus,
} from 'gql/generated/types-and-hooks';
import { getSpecificError } from 'helpers/validationErrorHelper';
import { ReactElement } from 'react';

const OwnerInput = ({ editMode }: { editMode: boolean }): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext<EditMyApplications_GetApplicationQuery['application']>();

  const personChoices = [
    {
      label: t('components.form.ownerInput.personChoices.me'),
      value: ApplicationFor.Me,
    },
    {
      label: t('components.form.ownerInput.personChoices.anotherPerson'),
      value: ApplicationFor.OtherPerson,
    },
  ];

  // Disabled as long as "HEALTH_DAYS" are not bookable
  // const typeChoices = [
  //   {
  //     label: t('components.form.ownerInput.typeChoices.vacation_offer'),
  //     value: 'VACATION_OFFER',
  //   },
  //   {
  //     label: t('components.form.ownerInput.typeChoices.health_days'),
  //     value: 'HEALTH_DAYS',
  //   },
  // ];

  const applicationFor = getValues('applicationFor') || ApplicationFor.Me;
  const applicant = getValues('applicant') || {};
  const guest = getValues('guest');
  const processingStatus = getValues('processingStatus');
  const { SentBack, Created } = ProcessingStatus;
  const isAccountingAreaEditable = [SentBack, Created].includes(
    processingStatus,
  );
  const isApplicationForEditable = [SentBack, Created].includes(
    processingStatus,
  );

  return (
    <div className="OwnerInput">
      <FormSection name={t('components.form.ownerInput.sectionTitle')}>
        <div className="grid grid-cols-12 items-center gap-3">
          <div className={editMode ? 'col-span-4' : 'col-span-2'}>
            {t('components.form.ownerInput.applicationFor')}
          </div>
          <div className="col-span-8">
            {editMode ? (
              <div className="flex gap-2">
                {personChoices.map(choice => (
                  <RadioButton
                    key={choice.value}
                    disabled={!isApplicationForEditable}
                    {...register('applicationFor')}
                    label={choice.label}
                    value={choice.value}
                  />
                ))}
              </div>
            ) : (
              <span>
                {personChoices.find(choice => choice.value === applicationFor)
                  ?.label || ''}
              </span>
            )}
          </div>

          <div className="col-span-6">
            <div className="grid grid-cols-12 items-center gap-3">
              <RowLabel required={true} editMode={editMode}>
                {t('components.form.ownerInput.applicant')}
              </RowLabel>
              <div className="col-span-6">
                {editMode ? (
                  <TextInput value={applicant.name || ''} readOnly disabled />
                ) : (
                  <span>{applicant.name}</span>
                )}
              </div>
              <RowLabel
                required={applicationFor === ApplicationFor.Me}
                editMode={editMode}
              >
                {t('components.form.ownerInput.personNumber')}
              </RowLabel>
              <div className="col-span-6">
                <ValidationFeedback error={errors?.applicant?.staffNumber}>
                  <EditableInput
                    editMode={editMode}
                    nonEditableValue={applicant.staffNumber || ''}
                    {...register('applicant.staffNumber', {
                      required: {
                        value: applicationFor === ApplicationFor.Me,
                        message: t(
                          'pages.elements.formInputValidator.required',
                        ),
                      },
                      validate: v => {
                        if (!isEmpty(v) && !isNumeric(v as string))
                          return t<string>(
                            'pages.elements.formInputValidator.numberOnly',
                          );
                        return true;
                      },
                    })}
                  />
                </ValidationFeedback>
              </div>
              <RowLabel required={false} editMode={editMode}>
                {t('components.form.ownerInput.email')}
              </RowLabel>
              <div className="col-span-6">
                {editMode ? (
                  <TextInput value={applicant.email || ''} readOnly disabled />
                ) : (
                  <span>{applicant.email}</span>
                )}
              </div>

              <RowLabel
                required={applicationFor === ApplicationFor.Me}
                editMode={editMode}
              >
                {t('components.form.ownerInput.accountingArea')}
              </RowLabel>
              <div className="col-span-6">
                {editMode ? (
                  <ValidationFeedback error={errors?.applicant?.accountingArea}>
                    <select
                      disabled={!isAccountingAreaEditable}
                      {...register('applicant.accountingArea', {
                        required: {
                          value: applicationFor === ApplicationFor.Me,
                          message: t(
                            'pages.elements.formInputValidator.required',
                          ),
                        },
                      })}
                      className={`w-full ${
                        !isAccountingAreaEditable
                          ? ' bg-gray-100 text-gray-500'
                          : ''
                      }`}
                    >
                      <option key="ownAccountingAreaPlaceholder" value="">
                        {t('components.form.personalDataInput.placeholder')}
                      </option>
                      {AccountingAreas.map(area => (
                        <option key={area} value={area}>
                          {area}
                        </option>
                      ))}
                    </select>
                  </ValidationFeedback>
                ) : (
                  <span>{applicant.accountingArea}</span>
                )}
              </div>
            </div>
          </div>

          {applicationFor === ApplicationFor.OtherPerson ? (
            <div className="col-span-6">
              <div className="grid grid-cols-12 items-center gap-3">
                <RowLabel required={true} editMode={editMode}>
                  {t('components.form.ownerInput.applicationFor')}
                </RowLabel>
                <div className="col-span-6">
                  <ValidationFeedback
                    error={getSpecificError('name', errors?.guest)}
                  >
                    <EditableInput
                      nonEditableValue={guest?.name || ''}
                      editMode={editMode}
                      {...register('guest.name', {
                        shouldUnregister: true,
                        required: t<string>(
                          'pages.elements.formInputValidator.required',
                        ),
                      })}
                    />
                  </ValidationFeedback>
                </div>

                <RowLabel required={true} editMode={editMode}>
                  {t('components.form.ownerInput.personNumber')}
                </RowLabel>
                <div className="col-span-6">
                  <ValidationFeedback
                    error={getSpecificError('staffNumber', errors?.guest)}
                  >
                    <EditableInput
                      nonEditableValue={guest?.staffNumber || ''}
                      editMode={editMode}
                      {...register('guest.staffNumber', {
                        shouldUnregister: true,
                        required: t<string>(
                          'pages.elements.formInputValidator.required',
                        ),
                        validate: v => {
                          if (!isEmpty(v) && !isNumeric(v as string))
                            return t<string>(
                              'pages.elements.formInputValidator.numberOnly',
                            );
                          return true;
                        },
                      })}
                    />
                  </ValidationFeedback>
                </div>

                <RowLabel required={true} editMode={editMode}>
                  {t('components.form.ownerInput.email')}
                </RowLabel>
                <div className="col-span-6">
                  <ValidationFeedback
                    error={getSpecificError('email', errors?.guest)}
                  >
                    <EditableInput
                      editMode={editMode}
                      nonEditableValue={guest?.email || ''}
                      {...register('guest.email', {
                        shouldUnregister: true,
                        required: t<string>(
                          'pages.elements.formInputValidator.required',
                        ),
                        validate: v => {
                          if (!isEmpty(v) && !isEmail(v as string))
                            return t<string>(
                              'pages.elements.formInputValidator.emailOnly',
                            );
                          return true;
                        },
                      })}
                    />
                  </ValidationFeedback>
                </div>

                <RowLabel required={true} editMode={editMode}>
                  {t('components.form.ownerInput.accountingArea')}
                </RowLabel>
                <div className="col-span-6">
                  {editMode ? (
                    <ValidationFeedback
                      error={getSpecificError('accountingArea', errors?.guest)}
                    >
                      <select
                        disabled={!isAccountingAreaEditable}
                        {...register('guest.accountingArea', {
                          required: t<string>(
                            'pages.elements.formInputValidator.required',
                          ),
                          shouldUnregister: true,
                        })}
                        className={`w-full ${
                          !isAccountingAreaEditable
                            ? ' bg-gray-100 text-gray-500'
                            : ''
                        }`}
                      >
                        <option key="guestAccountingAreaPlaceholder" value="">
                          {t('components.form.personalDataInput.placeholder')}
                        </option>
                        {AccountingAreas.map(area => (
                          <option key={area} value={area}>
                            {area}
                          </option>
                        ))}
                      </select>
                    </ValidationFeedback>
                  ) : (
                    <span>{guest?.accountingArea || ''}</span>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* Disabled as long as "HEALTH_DAYS" are not bookable */}
          {/* <div className="col-span-4">
            {t('components.form.ownerInput.applicationType')}
          </div>
          <div className="col-span-8">
            <RadioChoices
              choices={typeChoices}
              name="applicationType"
              value={application.applicationType}
              onChange={onChangeApplicationType}
            />
          </div> */}
        </div>
      </FormSection>
    </div>
  );
};

export default OwnerInput;
