import React, { HTMLProps } from 'react';

const disabledColors = 'bg-gray-100 text-gray-500';

const enabledColors = 'bg-white-200';

const TextInput = React.forwardRef<
  HTMLInputElement,
  HTMLProps<HTMLInputElement> & {
    disabled?: boolean;
  }
>(({ disabled, ...props }, ref) => {
  const color = disabled ? disabledColors : enabledColors;

  return (
    <input
      {...props}
      type="text"
      ref={ref}
      className={`w-full ${color} rounded-md border border-grey bg-white px-3 py-1 text-sm`}
    />
  );
});
TextInput.displayName = 'TextInput';

export default TextInput;
