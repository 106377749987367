import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AttachmentDescriptionCell = ({
  file,
  editable,
  onDescriptionChanged,
}: {
  file: {
    description: string;
  };
  editable: boolean;
  onDescriptionChanged?: (newDescription: string) => void;
}): ReactElement => {
  const [description, setDescription] = useState(file.description);
  const [edit, enterEditMode, leaveEditmode] = useEditMode(false);
  const { t } = useTranslation();

  useEffect(() => {
    setDescription(file.description);
  }, [file.description]);

  const handleCancel = () => {
    leaveEditmode();
    setDescription(file.description);
  };

  const handleConfirm = () => {
    if (onDescriptionChanged && description !== file.description) {
      onDescriptionChanged(description);
    }
    leaveEditmode();
  };

  const handleEdit = () => enterEditMode();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="relative w-full pr-2 sm:pr-24">
        {edit ? (
          <div className="flex h-full w-full items-start py-1">
            <input
              className="h-full w-full rounded-md border border-grey bg-white px-3 py-1.5 text-sm"
              placeholder={t('components.attachment.placeholder')}
              value={description || ''}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        ) : file.description === '' ? (
          <p
            className="banner py-1 text-gray-500"
            style={{ minHeight: `32px` }}
          >
            {t('components.attachment.placeholder')}
          </p>
        ) : (
          <p className="banner py-1" style={{ minHeight: `32px` }}>
            {file.description}
          </p>
        )}
      </div>

      {editable && (
        <div className="group relative flex-none">
          <EditControls
            editMode={edit}
            onEdit={handleEdit}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentDescriptionCell;
