import Slider from 'components/Slider';
import { ReactElement, useContext, useState } from 'react';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Photo from './Photo';
import { UserInfoContext } from '../../context/UserInfoContext';
import {
  FileType,
  File as PhotoFile,
  usePhotos_DeleteFileMutation,
  usePhotos_AddHouseFileMutation,
  usePhotos_UpdateFileMetadataMutation,
  refetchHouseForm_GetHouseQuery,
} from 'gql/generated/types-and-hooks';
import { readFiles } from 'helpers/fileHelper';

const Photos = ({
  house,
  editable = false,
}: {
  house: {
    id: string;
    files?: Pick<PhotoFile, 'id' | 'order' | 'path' | 'type'>[];
  };
  editable: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const refetchQueries = [refetchHouseForm_GetHouseQuery({ id: house.id })];

  const [deleteFile] = usePhotos_DeleteFileMutation({
    refetchQueries,
    context: {
      debounceKey: 'usePhotos_DeleteFileMutation',
      notification: {
        success: t('components.photos.notifications.delete.success'),
        error: t('components.photos.notifications.delete.error'),
      },
    },
  });
  const [addPhoto] = usePhotos_AddHouseFileMutation({
    refetchQueries,
    context: {
      debounceKey: 'usePhotos_AddHouseFileMutation',
      notification: {
        success: t('components.photos.notifications.add.success'),
        error: t('components.photos.notifications.add.error'),
      },
    },
  });
  const [updateFileMetadata] = usePhotos_UpdateFileMetadataMutation({
    refetchQueries,
  });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const lightboxImg = useRef<HTMLImageElement>(null);
  const { isAdmin } = useContext(UserInfoContext);

  /*
    Using an intermediate/sub state here so that changes in the drag and drop are visible immediately
    Else the changes would become visible after the response from the backend
    And while waiting the items fly back to their original positions
  */
  const [photos, setPhotos] = useState(
    house.files
      ?.filter(x => x.type === 'PHOTO')
      .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)) || [],
  );

  useEffect(() => {
    setPhotos(
      house.files
        ?.filter(x => x.type === 'PHOTO')
        .sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)) || [],
    );
  }, [house.files]);

  const handleDelete = photo => {
    deleteFile({
      variables: { id: photo.id },
    });
    setPhotos([...photos.filter(x => x.id !== photo.id)]);
  };

  const handleChange = async (files: FileList) => {
    const fileResults = await readFiles(files);
    for (let i = 0; i < fileResults.length; i++) {
      await addPhoto({
        variables: {
          houseId: house.id,
          file: {
            name: fileResults[i].name,
            type: FileType.Photo,
            content: fileResults[i].content,
            order: i,
          },
        },
      });
    }
  };

  const handleClickPhoto = blob => {
    if (lightboxImg.current) {
      lightboxImg.current.src = blob;
      setLightboxOpen(true);
    }
  };

  const lightboxStyle =
    ' z-10 fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-75 flex flex-wrap content-center justify-center';

  const lightboxEl = (
    <div className="Lightbox z-10" onClick={() => setLightboxOpen(false)}>
      <div className={lightboxOpen ? lightboxStyle : 'hidden'}>
        <img
          style={{ maxHeight: '80%', maxWidth: '80%' }}
          alt=""
          ref={lightboxImg}
        />
      </div>
    </div>
  );

  const handleSortOrderChanged = itemsUpdated => {
    for (const file of itemsUpdated) {
      updateFileMetadata({
        variables: {
          id: file.id,
          file: {
            description: file.description,
            order: file.order,
          },
        },
      });
    }

    setPhotos(itemsUpdated);
  };

  return (
    <div className="Photos">
      <p className="mb-4 font-medium">{t('components.photos.title')}</p>
      {photos.length > 0 || (editable && isAdmin) ? (
        <div className="flex w-full items-center justify-start rounded-md border-2 border-dashed border-gray-200 p-4">
          <Slider
            editable={editable && isAdmin}
            items={photos}
            isSortable={editable && isAdmin}
            itemsToShow={4}
            onSortOrderChanged={handleSortOrderChanged}
            onChange={handleChange}
            render={({ item, isDragging }) => (
              <Photo
                photo={item}
                isDragging={isDragging}
                onDelete={handleDelete}
                onClick={src => {
                  handleClickPhoto(src);
                }}
                editable={editable}
              />
            )}
          />
        </div>
      ) : (
        <div>{t('components.photos.no-photo')}</div>
      )}
      {lightboxEl}
    </div>
  );
};

export default Photos;
