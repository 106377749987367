import { Fragment, ReactElement, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from './Icon';

export interface BaseDialogProps {
  open: boolean;
  title: string;
  text?: string;
  children?: ReactNode;
  onClose: () => void;
}

const BaseDialog = ({
  open,
  title,
  children,
  onClose,
}: BaseDialogProps): ReactElement => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform rounded-md border border-gray-200 bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:align-middle">
              <div className="rounded-md bg-white pb-4">
                <div className="text-left">
                  <Dialog.Title
                    as="h3"
                    className="border-b px-6 py-4 text-lg font-medium"
                  >
                    {title}
                    <button
                      className="focus:shadow-outline-blue float-right focus:border-blue-300 focus:outline-none"
                      onClick={onClose}
                    >
                      <Icon icon={'XIcon'} />
                    </button>
                  </Dialog.Title>
                  <div className="mt-2 w-full px-6">{children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default BaseDialog;
