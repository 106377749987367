import { createContext, FunctionComponent, useContext, useEffect } from 'react';
import { useState } from 'react';

const SHOW_LOADING_SPINNER_AFTER_MS = 400;

// Prevent scrolling while overlay is visible
// and reset scrolling position after it is hidden again
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty(
    '--scroll-y',
    `${window.scrollY}px`,
  );
});

const disableScrollingOnBody = (disabled: boolean) => {
  if (disabled) {
    const scrollY =
      document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
    body.style.paddingRight = '15px';
  } else {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    body.style.paddingRight = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
};

type LoadingOverlayContextType = {
  show: () => void;
  hide: () => void;
};

const LoadingOverlayContext = createContext<LoadingOverlayContextType>({
  show: () => null,
  hide: () => null,
});

export const LoadingOverlayContextProvider: FunctionComponent = ({
  children,
}) => {
  const [state, setState] = useState({
    isLoadingOverlayShown: false,
    isLoading: false,
  });

  useEffect(() => {
    if (state.isLoading) {
      if (state.isLoadingOverlayShown === false) {
        const newTimer = setTimeout(() => {
          disableScrollingOnBody(true);
          setState({
            isLoadingOverlayShown: true,
            isLoading: true,
          });
        }, SHOW_LOADING_SPINNER_AFTER_MS);
        return () => {
          clearTimeout(newTimer);
        };
      }
    } else {
      if (state.isLoadingOverlayShown) {
        disableScrollingOnBody(false);
      }
      setState({
        isLoadingOverlayShown: false,
        isLoading: false,
      });
    }
  }, [state.isLoadingOverlayShown, state.isLoading]);

  return (
    <LoadingOverlayContext.Provider
      value={{
        show: () => {
          setState(s => ({
            ...s,
            isLoading: true,
          }));
        },
        hide: () => {
          setState(s => ({
            ...s,
            isLoading: false,
          }));
        },
      }}
    >
      <>
        {children}
        {state.isLoadingOverlayShown ? (
          <div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black bg-opacity-40">
            <div className="flex h-32 w-32 flex-col items-center justify-center rounded-full bg-white bg-opacity-80 p-4">
              <div className="loading-icon scale-75 transform" />
            </div>
          </div>
        ) : null}
      </>
    </LoadingOverlayContext.Provider>
  );
};

export const useShowHideLoadingOverlay = (): {
  showOverlay: LoadingOverlayContextType['show'];
  hideOverlay: LoadingOverlayContextType['hide'];
} => {
  const context = useContext(LoadingOverlayContext);
  if (context == null) {
    throw new Error(
      'useShowHideLoadingOverlay must be used within a LoadingOverlayContextProvider',
    );
  }

  return { showOverlay: context.show, hideOverlay: context.hide };
};
