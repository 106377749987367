import ConfirmationDialog from 'components/base/ConfirmationDialog';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewVacationOfferButton_GetHawHousesQuery } from 'gql/generated/types-and-hooks';

const NewVacationOfferModal = ({
  open,
  onConfirm,
  onCancel,
  houses,
}: {
  houses: NewVacationOfferButton_GetHawHousesQuery['houses'];
  open: boolean;
  onConfirm: (houseId: string) => void;
  onCancel: () => void;
}): ReactElement => {
  const { t } = useTranslation();
  const [selectedHouse, setSelectedHouse] = useState(houses[0].id);

  useEffect(() => {
    setSelectedHouse(houses[0].id);
  }, [houses]);

  const handleChange = e => setSelectedHouse(e.target.value);
  const handleConfirm = () => onConfirm(selectedHouse);

  return (
    <div className="NewVacationOfferModal">
      <ConfirmationDialog
        title={t('components.VacationOffers.NewVacationOfferModal.title')}
        confirmText={t(
          'components.VacationOffers.NewVacationOfferModal.confirm',
        )}
        cancelText={t('components.VacationOffers.NewVacationOfferModal.cancel')}
        onConfirm={handleConfirm}
        {...{ open, onCancel }}
      >
        <div className="mb-4">
          {t('components.VacationOffers.NewVacationOfferModal.message')}
        </div>
        <select
          value={selectedHouse ? selectedHouse : ''}
          onChange={handleChange}
          className="form-select block w-full rounded-md border border-grey bg-white outline-none focus-within:ring-2 focus-within:ring-primary"
        >
          {houses.map(house => (
            <option key={house.id} value={house.id}>
              {house.name}
            </option>
          ))}
        </select>
      </ConfirmationDialog>
    </div>
  );
};

export default NewVacationOfferModal;
