import UploadButton from 'components/base/UploadButton';
import {
  FileType,
  refetchHouseForm_GetHouseQuery,
  useUploadHouseFile_AddHouseFileMutation,
} from 'gql/generated/types-and-hooks';
import { readFiles } from 'helpers/fileHelper';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

const UploadHouseFile = ({ houseId }: { houseId: string }): ReactElement => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [addFile] = useUploadHouseFile_AddHouseFileMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: houseId })],
    context: {
      debounceKey: 'useUploadHouseFile_AddHouseFileMutation',
      notification: {
        success: t('components.attachment.notifications.addAttachment.success'),
        error: t('components.attachment.notifications.addAttachment.error'),
      },
    },
  });
  const handleAdd = async (files: FileList) => {
    setIsUploading(true);
    for (const file of await readFiles(files)) {
      await addFile({
        variables: {
          houseId: houseId,
          file: {
            name: file.name,
            content: file.content,
            type: FileType.Attachment,
          },
        },
      });
    }
    setIsUploading(false);
  };

  return (
    <UploadButton
      label={t('components.attachment.buttons.add')}
      disabled={isUploading}
      onChange={handleAdd}
    />
  );
};

export default UploadHouseFile;
