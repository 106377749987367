import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import routes from '../config/routes';
import Header from '../components/Header';
import config from '../config/config';
import { isEonActiveAllowed } from 'helpers/permissionsHelper';
import { UserInfoContext, UserRole } from '../context/UserInfoContext';
import ReactTooltip from 'react-tooltip';
import { TaccountingAreas } from 'interfaces/accountingAreas';
import { ReactElement, useContext } from 'react';
import IRoute from 'interfaces/route';

const MainLayout = (): ReactElement => {
  const { roles, accountingArea } = useContext(UserInfoContext);
  return (
    <BrowserRouter>
      <div className="flex h-full w-full flex-col">
        <Header />
        <div className="h-full overflow-auto p-4">
          <Switch>
            {addRoutes({
              routes,
              roles,
              accountingArea,
            })}
            <Route>
              <Redirect to={config.localRoutes.information} />
            </Route>
          </Switch>
          <ReactTooltip
            effect="solid"
            type="light"
            className="custom-react-tooltip"
          />
        </div>
      </div>
    </BrowserRouter>
  );
};

function isRouteAccessible({
  route,
  roles,
  isEonActiveAllowed,
}: {
  route: IRoute;
  roles: UserRole[];
  isEonActiveAllowed: boolean;
}): boolean {
  if (roles.includes('ADMIN')) return true;
  if (route.requiresRole === 'ADMIN') return false;

  if (
    route.requiresRole === 'BOARD_BILLING' &&
    !roles.includes('BOARD_BILLING')
  )
    return false;

  if (route.isEonActiveRoute && !isEonActiveAllowed) return false;

  return true;
}

function addRoutes({
  routes,
  roles,
  accountingArea,
}: {
  routes: IRoute[];
  roles: UserRole[];
  accountingArea: string;
}): (ReactElement | undefined)[] {
  return routes
    .filter(route =>
      isRouteAccessible({
        route,
        roles,
        isEonActiveAllowed: isEonActiveAllowed({
          isAdmin: roles.includes('ADMIN'),
          accountingArea: accountingArea as TaccountingAreas,
        }),
      }),
    )
    .flatMap(route => {
      return [
        ...(route.component
          ? [
              <Route key={route.path} path={route.path} exact={route.exact}>
                <route.component />
              </Route>,
            ]
          : []),

        ...(route.subRoutes
          ? [
              ...addRoutes({
                routes: route.subRoutes,
                roles,
                accountingArea: accountingArea,
              }),
            ]
          : []),
      ];
    });
}

export default MainLayout;
