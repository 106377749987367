import Card from 'components/base/Card';
import DeleteWithConfirmationButton from 'components/base/DeleteWithConfirmationButton';
import LoadingOrError from 'components/base/LoadingOrError';
import {
  ProcessingStatus,
  refetchApplicationStatisticsQuery,
  useApplicationStatisticsQuery,
  useDeleteApplicationsOlderThanFourYearsMutation,
} from 'gql/generated/types-and-hooks';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import { getFormattedDate } from 'helpers/dateHelper';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Statistics } from './statistics';

const LIST_OF_PROCESSING_STATUS = [
  ProcessingStatus.Created,
  ProcessingStatus.SentBack,
  ProcessingStatus.FinishedCancelled,
  ProcessingStatus.FinishedRejectedConfirmed,
  ProcessingStatus.FinishedCancelledConfirmed,
  ProcessingStatus.Finished,
];

export function Maintenance(): ReactElement {
  const { t } = useTranslation();
  const { data, loading, error } = useApplicationStatisticsQuery();
  const [deleteApplications] = useDeleteApplicationsOlderThanFourYearsMutation({
    refetchQueries: [refetchApplicationStatisticsQuery()],
    awaitRefetchQueries: true,
    context: {
      notification: {
        success: t('components.settings.maintenance.notifications.success'),
        error: t('components.settings.maintenance.notifications.success'),
      },
    },
  });
  const comparisonDate = useMemo(() => {
    const currentYear =
      data?.applicationStatistics.currentYear ?? new Date().getFullYear();
    return new Date(currentYear - 4, 0, 1);
  }, [data?.applicationStatistics.currentYear]);

  const nbrOfElementsToDelete = useMemo(() => {
    if (data?.applicationStatistics == null) return 0;

    return LIST_OF_PROCESSING_STATUS.reduce((acc, status) => {
      const value = Number(
        data.applicationStatistics[status].OLDER_THAN_FOURTH_LAST_YEAR,
      );
      if (Number.isNaN(value)) return acc;
      return acc + value;
    }, 0);
  }, [data?.applicationStatistics]);

  return (
    <LoadingOrError loading={loading} error={error}>
      <div className="flex flex-col gap-8">
        <Card>
          <div className="flex items-end">
            <div className="flex-1">
              <p>
                {t('components.settings.maintenance.description', {
                  date: getFormattedDate(comparisonDate, dateMonthYearFormat),
                })}
              </p>
              <ul className="ml-8 list-disc">
                {LIST_OF_PROCESSING_STATUS.map(status => {
                  return (
                    <li key={status}>
                      {t(`pages.myApplications.processingStatus.${status}`)}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <DeleteWithConfirmationButton
                label={t('components.settings.maintenance.deleteButton', {
                  count: nbrOfElementsToDelete,
                })}
                title={t('components.settings.maintenance.deleteDialog.title')}
                text={t('components.settings.maintenance.deleteDialog.text', {
                  count: nbrOfElementsToDelete,
                })}
                inverted={true}
                onDelete={() => deleteApplications()}
              />
            </div>
          </div>
        </Card>
        {data?.applicationStatistics && (
          <Statistics statistics={data?.applicationStatistics} />
        )}
      </div>
    </LoadingOrError>
  );
}
