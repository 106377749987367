import LinkButton from 'components/base/LinkButton';
import config from 'config/config';
import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';

const Intro = ({
  showButton = true,
}: {
  showButton?: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);

  return (
    <div className="Intro">
      <div className="mb-8 flex items-center justify-between">
        <p>{t('pages.houses.desc')}</p>
        {showButton && isAdmin && (
          <LinkButton to={config.localRoutes.haw.new}>
            {t('pages.houses.button.newHouse')}
          </LinkButton>
        )}
      </div>
    </div>
  );
};
export default Intro;
