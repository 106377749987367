import { PropsWithChildren, ReactElement } from 'react';

const RowLabel = ({
  children,
  className,
  required,
  editMode,
}: PropsWithChildren<{
  className?: string;
  required?: boolean;
  editMode?: boolean;
}>): ReactElement => {
  let style = 'col-span-4 col-start-1 ';
  if (className) {
    style += className;
  }
  return (
    <div className={style}>
      {children}
      {required && editMode ? '*' : ''}
    </div>
  );
};

export default RowLabel;
