import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyWarning = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="PrivateWarning mt-10 border border-grey p-4">
      <div className="heading text-primary">Hinweis zum Datenschutz</div>
      {t('copy.privacyPolicy')}
    </div>
  );
};
export default PrivacyWarning;
