import {
  ChevronLeftButton,
  ChevronRightButton,
} from 'components/base/IconButton';
import { SortableColumn } from 'gql/generated/types-and-hooks';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnSorting } from 'types/ColumnSorting';
import { ExportEntries } from './ExportEntries';
import FilterEntries from './FilterEntries';
import { ApplicationFilter } from './interfaces';

type FilterAndExportProps = {
  onSetFilter: (filter: ApplicationFilter) => void;
  filter: ApplicationFilter;
  sorting: ColumnSorting<SortableColumn>;
};

export const FilterAndExport = ({
  onSetFilter,
  filter,
  sorting,
}: FilterAndExportProps): ReactElement => {
  const { t } = useTranslation();
  const [isCollpased, setIsCollpased] = useState(false);

  return (
    <div className="FilterAndExport mr-4 h-full bg-gray-200">
      {isCollpased ? (
        <div className="my-4 flex h-full w-10 flex-col items-center">
          <ChevronRightButton
            onClick={() => setIsCollpased(prev => !prev)}
            disabled={false}
          />
          <div className="w-1 break-all">
            {t('components.applications.filterAndExport.title')}
          </div>
        </div>
      ) : (
        <div className="m-4 w-72">
          <div className="mb-2 flex flex-row items-center border-b border-grey pb-2 text-lg">
            <ChevronLeftButton
              onClick={() => setIsCollpased(prev => !prev)}
              disabled={false}
            />

            {t('components.applications.filterAndExport.title')}
          </div>
          <FilterEntries onSetFilter={onSetFilter} filter={filter} />
          <ExportEntries filter={filter} sorting={sorting} />
        </div>
      )}
    </div>
  );
};
