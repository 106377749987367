import { DeleteButton, UploadIconButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { memo, ReactElement, useRef } from 'react';

const AttachmentActions = ({
  onUpload,
  onDelete,
}: {
  onUpload: (file: File) => void;
  onDelete: () => void;
}): ReactElement => {
  const inputFieldRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => inputFieldRef.current?.click();

  return (
    <div className="AttachmentActions">
      <KeyFormArea>
        <div className="AttachmentActions flex flex-row justify-end">
          <UploadIconButton onClick={handleSelectFile} />
          <DeleteButton onClick={onDelete} />
          <input
            ref={inputFieldRef}
            type="file"
            accept="application/pdf"
            className="hidden"
            onChange={e => {
              if (e.target.files && e.target.files.length > 0) {
                onUpload(e.target.files[0]);
              }
            }}
          />
        </div>
      </KeyFormArea>
    </div>
  );
};
export default memo(AttachmentActions);
