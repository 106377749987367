import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { KeyFormProvider } from 'context/KeyFormContext';
import { Url } from 'components/applicationForm/editableInputs';
import TextInput from 'components/applicationForm/TextInput';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'helpers/util';
import isURL from 'validator/lib/isURL';
import LoadingOrError from 'components/base/LoadingOrError';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import {
  refetchFreePlaceListUrl_GetSettingsQuery,
  useFreePlaceListUrl_GetSettingsQuery,
  useFreePlaceListUrl_UpdateSettingsMutation,
} from 'gql/generated/types-and-hooks';

const FreePlaceListUrl = (): ReactElement => {
  const { t } = useTranslation();
  const { data, loading, error } = useFreePlaceListUrl_GetSettingsQuery({
    pollInterval: 0,
  });
  const [updateSettings] = useFreePlaceListUrl_UpdateSettingsMutation({
    refetchQueries: [refetchFreePlaceListUrl_GetSettingsQuery()],
    context: {
      debounceKey: 'useFreePlaceListUrl_UpdateSettingsMutation',
      notification: {
        success: t('components.eon.FreePlaceListUrl.notifications.success'),
        error: t('components.eon.FreePlaceListUrl.notifications.error'),
      },
    },
  });

  return (
    <LoadingOrError loading={loading} error={error}>
      <UrlField
        url={data?.getSettings.eonHousesFreePlacesList || ''}
        onSubmit={url => {
          if (data) {
            updateSettings({
              variables: {
                settings: {
                  eonHousesFreePlacesList: url,
                  name: data.getSettings.name,
                },
              },
            });
          }
        }}
      />
    </LoadingOrError>
  );
};

type UrlFieldProps = {
  url: string;
  onSubmit: (url: string) => void;
};

const UrlField = ({ url, onSubmit }: UrlFieldProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const [edit, enterEditMode, leaveEditmode] = useEditMode(false);
  const {
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url,
    },
  });
  const onConfirm = handleSubmit(() => {
    onSubmit(getValues('url'));
    leaveEditmode();
  });

  const validationHint = t('pages.elements.formInputValidator.url');
  return (
    <div>
      <div className="flex items-center">
        {edit ? (
          <div className="mr-2 w-full">
            <ValidationFeedback error={errors.url}>
              <TextInput
                {...register('url', {
                  validate: v => isEmpty(v) || isURL(v) || validationHint,
                })}
              />
            </ValidationFeedback>
          </div>
        ) : url ? (
          <Url url={url} text={t('pages.eonHouses.freePlacesList')} />
        ) : (
          <div>{t('pages.eonHouses.noFreePlacesList')}</div>
        )}
        {isAdmin && (
          <KeyFormProvider>
            <EditControls
              editMode={edit}
              onEdit={() => enterEditMode()}
              onConfirm={onConfirm}
              onCancel={() => {
                leaveEditmode();
                reset({ url });
              }}
            />
          </KeyFormProvider>
        )}
      </div>
    </div>
  );
};

export default FreePlaceListUrl;
