import BookingModal from 'components/VacationOffers/BookingModal';
import { DeleteButton, EditButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../../context/UserInfoContext';
import { ToggleActiveButton } from 'components/base/IconButton';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import {
  CountervailingBenefit,
  CountervailingBenefitInput,
  EonOfferInput,
} from 'gql/generated/types-and-hooks';
import { currencyNumberFormat } from 'helpers/util';

export const EonOfferRow = ({
  offer,
  onDelete,
  onUpdate,
}: {
  offer: {
    id: string;
    active: boolean;
    isBooked: boolean;
    arrival: Date;
    departure: Date;
    socialCreditPoints: boolean;
    summerHolidays: boolean;
    eonBookingId: string;
    countervailingBenefit: CountervailingBenefit;
  };
  onDelete: () => void;
  onUpdate: (offer: EonOfferInput) => void;
}): ReactElement => {
  const { isAdmin } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const [modelOpen, setModalOpen] = useState(false);
  const handleEdit = () => setModalOpen(true);
  const handleCancel = () => setModalOpen(false);

  const handleConfirm = (data: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    eonBookingId: string;
    countervailingBenefit: CountervailingBenefitInput;
  }) => {
    onUpdate({
      active: offer.active,
      bookingDate: {
        arrival: data.arrival.toISOString(),
        departure: data.departure.toISOString(),
      },
      socialCreditPoints: data.socialPoints,
      summerHolidays: data.summer,
      eonBookingId: data.eonBookingId,
      countervailingBenefit: data.countervailingBenefit,
    });
    setModalOpen(false);
  };

  const handleDelete = onDelete;

  const handleToggleActive = () => {
    onUpdate({
      bookingDate: {
        arrival: offer.arrival.toISOString(),
        departure: offer.departure.toISOString(),
      },
      socialCreditPoints: offer.socialCreditPoints,
      summerHolidays: offer.summerHolidays,
      eonBookingId: offer.eonBookingId,
      countervailingBenefit: offer.countervailingBenefit,
      active: !offer.active,
    });
  };

  if (offer.arrival == null || offer.departure == null) {
    return (
      <tr>
        <td>{t('components.eon.offers.missingBookingDates')}</td>
      </tr>
    );
  }

  const arrival = new Date(offer.arrival);
  const departure = new Date(offer.departure);

  return (
    <tr className={!offer.active ? 'bg-gray-300 text-gray-500' : ''}>
      <td>{dateMonthYearFormat.format(arrival)}</td>
      <td>{dateMonthYearFormat.format(departure)}</td>
      <td>
        {offer.socialCreditPoints
          ? t('components.form.yesNoChoice.true')
          : t('components.form.yesNoChoice.false')}
      </td>
      <td>
        {offer.summerHolidays
          ? t('components.form.yesNoChoice.true')
          : t('components.form.yesNoChoice.false')}
      </td>
      <td>{offer.eonBookingId}</td>
      <td>
        {currencyNumberFormat.format(offer.countervailingBenefit.perNight)}
      </td>
      <td className="w-0">
        <KeyFormArea>
          {isAdmin && (
            <div className="flex gap-x-2">
              <EditButton onClick={handleEdit} />
              <ToggleActiveButton
                active={offer.active}
                onClick={handleToggleActive}
              />
              {!offer.isBooked && <DeleteButton onClick={handleDelete} />}
              <BookingModal
                open={modelOpen}
                showEonBookingId={true}
                showCountervailingBenefit={{
                  perNight: true,
                }}
                booking={{
                  socialPoints: offer.socialCreditPoints,
                  summer: offer.summerHolidays,
                  eonBookingId: offer.eonBookingId,
                  countervailingBenefit: offer.countervailingBenefit,
                  arrival: arrival,
                  departure: departure,
                }}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />
            </div>
          )}
        </KeyFormArea>
      </td>
    </tr>
  );
};
