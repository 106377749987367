import { NavItem } from 'components/NavItem';
import { EmailRecipients } from 'components/settings/email/recipients';
import { EmailTemplates } from 'components/settings/email/templates/templates';
import { Maintenance } from 'components/settings/maintenance';
import config from 'config/config';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export function IndexSettings(): ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { localRoutes } = config;

  return (
    <>
      <p>{t('pages.settings.desc')}</p>
      <nav className="navbar mt-4 border-b-2 px-12">
        <ul className="flex list-none flex-wrap items-center gap-x-8">
          <NavItem
            to={localRoutes.settings.email.templates.index}
            label="pages.settings.email.templates"
          />
          <NavItem
            to={localRoutes.settings.email.recipients}
            label="pages.settings.email.recipients"
          />
          <NavItem
            to={localRoutes.settings.maintenance}
            label="pages.settings.maintenance"
          />
        </ul>
      </nav>
      <div className="p-4">{getActiveNavItemComponent(pathname)}</div>
    </>
  );
}

function getActiveNavItemComponent(pathname: string): ReactElement {
  const { localRoutes } = config;
  if (pathname.startsWith(localRoutes.settings.email.templates.index)) {
    return <EmailTemplates />;
  }
  if (pathname === localRoutes.settings.email.recipients) {
    return <EmailRecipients />;
  }
  if (pathname === localRoutes.settings.maintenance) {
    return <Maintenance />;
  }
  return <></>;
}
