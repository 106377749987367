import FormSection from '../FormSection';
import { useTranslation } from 'react-i18next';
import { NumberDropdown } from '../NumberDropdown';
import PriceTable from './PriceTable';
import EonBookingEntry from './EonBookingEntry';
import { mapBoardPropertynameToEnum } from 'interfaces/BoardType';
import { useFormContext } from 'react-hook-form';
import { ErrorText } from '../Validatable';
import { getChargesForBoard } from 'helpers/priceCalculation';
import {
  BoardType,
  BookingConfirmationStatus,
  EditMyApplications_GetApplicationQuery,
  HouseBoard,
  PartnerStatus,
  PositionType,
} from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';
import { ApplicationForm_GetVactionOffersHousesQuery } from 'gql/generated/types-and-hooks';
import { unique } from 'helpers/util';

const getOfferIds = (
  bookings: {
    bookingChoiceId?: string | null;
  }[],
): string[] => {
  return unique<string>(bookings.map(x => x.bookingChoiceId ?? ''));
};

const getOnlyUnselectedHouses = (
  houses: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'],
  bookings: EditMyApplications_GetApplicationQuery['application']['eonBookings'],
  includeHouseIds: string[],
) => {
  const bookedBookingIds = getOfferIds(bookings);
  return houses
    .map(house => ({
      ...house,
      eonOffers: house.eonOffers?.filter(
        offer => bookedBookingIds.includes(offer.id) === false,
      ),
    }))
    .filter(
      house =>
        (house.eonOffers && house.eonOffers.length > 0) ||
        includeHouseIds.includes(house.id),
    );
};

const EonCatalogue = ({
  houses,
  editMode,
}: {
  houses: ApplicationForm_GetVactionOffersHousesQuery['eonHouses'];
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useFormContext<
    EditMyApplications_GetApplicationQuery['application'] & {
      nbrOfHawBookings: number;
      nbrOfEonBookings: number;
    }
  >();

  if (houses == null)
    return <div>{t('components.form.eonCatalogue.missingData')}</div>;

  const companyPosition = getValues('companyPosition.position');
  const markBOnPassport = getValues('disability.markBOnPassport');
  const relationshipStatus = getValues('partner.status');
  const furtherGuests = getValues('furtherGuests');

  const eonBookings = getValues('eonBookings').sort((a, b) =>
    a.priority > b.priority ? 1 : -1,
  );

  const handleAddBookings = (count: number) => {
    const oldBookings = [...eonBookings];
    const newBookings = [...eonBookings];
    for (let i = oldBookings.length; i < count; i++) {
      const newBooking: EditMyApplications_GetApplicationQuery['application']['eonBookings'][0] =
        {
          id: '',
          priority: newBookings.length + 1,
          houseId: '',
          board: BoardType.FullBoard,
          bookingChoiceId: '',
          applicationId: '',
          bookingStatus: BookingConfirmationStatus.Pending,
          createdAt: new Date().toISOString(),
        };

      newBookings.push(newBooking);
    }
    setValue('eonBookings', newBookings);
  };

  const handleChangeHouse = (index: number) => (value: string) => {
    const newHouse = houses.find(house => house.id === value);
    let board: BoardType | null = null;

    if (newHouse) {
      const activeBoards = Object.entries(newHouse.charges).reduce(
        (boards, cur) => {
          if (cur == null || cur[0] === '__typename') {
            return boards;
          }
          const board = cur[1] as HouseBoard;
          return board.activated
            ? [...boards, mapBoardPropertynameToEnum(cur[0])]
            : boards;
        },
        [] as BoardType[],
      );
      if (activeBoards.length === 1) {
        board = activeBoards[0];
      }
    }

    setValue(`eonBookings.${index}.bookingChoiceId`, '', {
      shouldValidate: false,
    });
    setValue(`eonBookings.${index}.board`, board, {
      shouldValidate: board ? true : false,
    });
  };

  const offersAvailable = houses.some(
    house => house.activated && house.eonOffers?.some(offer => offer.active),
  );

  return (
    <div className="EonCatalogue">
      <FormSection name={t('components.form.eonCatalogue.sectionTitle')}>
        {offersAvailable === false && eonBookings.length === 0 ? (
          <div>
            {t('components.form.eonCatalogue.noOffers')}
            <ErrorText error={errors?.nbrOfEonBookings} />
          </div>
        ) : (
          <>
            {editMode && (
              <div className="mb-4 grid grid-cols-12 items-center gap-3">
                <label className="col-span-3 col-start-1">
                  {t('components.form.eonCatalogue.selectNumberOfHouses')}
                </label>
                <div className="col-start-5">
                  <NumberDropdown
                    className="w-full"
                    value={eonBookings.length}
                    max={6}
                    onChange={e => {
                      const value = parseInt(e.target.value);
                      if (value > eonBookings.length) {
                        clearErrors('nbrOfEonBookings');
                        clearErrors('nbrOfHawBookings');
                        handleAddBookings(value);
                      } else if (value < eonBookings.length) {
                        const newBookings: EditMyApplications_GetApplicationQuery['application']['eonBookings'] =
                          [];
                        for (let i = 0; i < value; i++) {
                          newBookings.push(eonBookings[i]);
                        }
                        setValue('eonBookings', newBookings);
                      }
                    }}
                  />
                  <ErrorText error={errors?.nbrOfEonBookings} />
                </div>
              </div>
            )}

            {eonBookings.length === 0 ? (
              <div>{t('components.form.eonCatalogue.numberOfHouses')}</div>
            ) : (
              <>
                <div className="grid grid-cols-12 gap-x-2 bg-gray-100 py-2 text-primary">
                  <div className="col-span-1">
                    {/* {t('components.form.eonCatalogue.rank')} */}
                  </div>
                  <div className="col-span-3">
                    {t('components.form.eonCatalogue.house')}
                  </div>
                  <div className="col-span-5">
                    {t('components.form.eonCatalogue.period')}
                  </div>
                  <div className="col-span-3">
                    {t('components.form.eonCatalogue.board')}
                  </div>
                </div>
                {eonBookings.map((booking, index) => {
                  const house = houses.find(x => x.id === booking.houseId);

                  const offer = house?.eonOffers?.find(
                    x => x.id === booking.bookingChoiceId,
                  );

                  const unselectedHouses = getOnlyUnselectedHouses(
                    houses,
                    eonBookings.filter(x => x !== booking),
                    [booking.houseId],
                  );

                  return (
                    <div
                      className={`mb-4 ${
                        index !== eonBookings.length - 1 ? 'border-b-2' : ''
                      }`}
                      key={booking.id || index}
                    >
                      <EonBookingEntry
                        index={index}
                        editMode={editMode}
                        houses={unselectedHouses}
                        onChangeHouse={handleChangeHouse(index)}
                      />

                      <PriceTable
                        position={
                          companyPosition
                            ? companyPosition
                            : PositionType.Employee
                        }
                        calculateVacationPackage={false}
                        markBOnPassport={markBOnPassport}
                        timespan={
                          offer &&
                          offer.bookingDate.arrival &&
                          offer.bookingDate.departure
                            ? {
                                arrival: offer.bookingDate.arrival,
                                departure: offer.bookingDate.departure,
                              }
                            : undefined
                        }
                        travelers={{
                          minorChildren: furtherGuests.familyChildrenUnderage,
                          otherMinors: furtherGuests.nonFamilyChildrenUnderage,
                          relationshipStatus: relationshipStatus
                            ? relationshipStatus
                            : PartnerStatus.Without,
                        }}
                        charges={
                          house
                            ? getChargesForBoard({
                                charges: {
                                  house: house.charges,
                                  vacationPackage: undefined,
                                },
                                board: booking.board
                                  ? booking.board
                                  : BoardType.FullBoard,
                                countervailingBenefit:
                                  offer?.countervailingBenefit,
                              })
                            : undefined
                        }
                      />
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </FormSection>
    </div>
  );
};

export default EonCatalogue;
