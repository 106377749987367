import { isEmpty, uuid } from 'helpers/util';
import React, { HTMLProps } from 'react';

type RadioButtonProps = HTMLProps<HTMLInputElement> & {
  id?: string;
  label?: string;
};

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, ...props }: RadioButtonProps, ref) => {
    // eslint-disable-next-line react/prop-types
    const disabled = props.disabled;
    let inputId = props.id;
    if (isEmpty(inputId)) {
      inputId = uuid();
    }
    const input = (
      <input
        id={inputId}
        {...props}
        ref={ref}
        className={disabled ? 'text-gray-100' : 'text-blue-400'}
        type="radio"
      />
    );
    return !label ? (
      input
    ) : (
      <label
        className={`flex items-center gap-2 ${disabled ? 'text-gray-500' : ''}`}
        htmlFor={inputId}
      >
        {input}
        {label}
      </label>
    );
  },
);
RadioButton.displayName = 'RadioButton';
