import config from 'config/config';
import {
  EonOfferWithHouseIdInput,
  refetchApplicationForm_GetVactionOffersHousesQuery,
  refetchGetEonHousesAndOffersQuery,
  useCreateEonOffersMutation,
} from 'gql/generated/types-and-hooks';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { MatchingEntry, useMatchingController } from './MatchingController';
import { useUploadController } from './UploadController';
import { useTranslation } from 'react-i18next';
import {
  ImportEntryWithoutSocialCreditPointData,
  SocialCreditPointsSetting,
  SocialCreditPointsSettings,
  useSocialCreditPointsController,
} from './SocialCreditPointsController';

export const UploadStepId = Symbol('UploadStep');
export const MatchingStepId = Symbol('MatchingStep');
export const SocialCreditPointsStepId = Symbol('SocialCreditPointsStep');

type IndexControllerResult = {
  upload: ReturnType<typeof useUploadController>;
  matching: ReturnType<typeof useMatchingController>;
  socialCreditPoints: ReturnType<typeof useSocialCreditPointsController>;
  handleFinish: () => void;
  navigateBack: () => void;
  handleUpload: (files: FileList | File[]) => void;
  createEonOffersResult: ReturnType<typeof useCreateEonOffers>[1];
};

export const useIndexController = (): IndexControllerResult => {
  const history = useHistory();

  const navigateBack = useCallback(
    () => history.push(config.localRoutes.eon.index),
    [history],
  );

  const [createEonOffers, createEonOffersResult] = useCreateEonOffers({
    onCompleted: () => {
      navigateBack();
    },
  });

  const socialCreditPoints = useSocialCreditPointsController();

  const matching = useMatchingController({
    onMatchingLoaded: data => {
      socialCreditPoints.reset(data.create);
    },
  });

  const { getMatching, reset: resetMatching } = matching;

  const upload = useUploadController({
    onUploadCompleted: data => {
      getMatching(data.entries);
    },
  });

  const handleUpload = useCallback(
    async (files: FileList | File[]) => {
      resetMatching();
      socialCreditPoints.reset();
      upload.processFiles(files);
    },
    [resetMatching, upload, socialCreditPoints],
  );

  const handleFinish = useCallback(async () => {
    if (matching.data) {
      const entries = matching.data.create ?? [];
      const offers = entries
        .map(entry =>
          mergeMatchingEntryWithSocialCreditPointsSetting(
            entry,
            socialCreditPoints.settings,
          ),
        )
        .map(mapImportEntryToCreateEonOfferInput);
      await createEonOffers({
        variables: {
          offers,
        },
      });
    }
  }, [createEonOffers, matching, socialCreditPoints.settings]);

  return {
    upload,
    matching,
    socialCreditPoints,
    handleFinish,
    navigateBack,
    handleUpload,
    createEonOffersResult,
  };
};

const mergeMatchingEntryWithSocialCreditPointsSetting = (
  entry: MatchingEntry,
  socialCreditPointsSettings: SocialCreditPointsSettings,
): MatchingEntry & SocialCreditPointsSetting => ({
  ...entry,
  ...socialCreditPointsSettings[entry.bookingId],
});

const mapImportEntryToCreateEonOfferInput = (
  entry: ImportEntryWithoutSocialCreditPointData & SocialCreditPointsSetting,
): EonOfferWithHouseIdInput => ({
  active: true,
  countervailingBenefit: {
    childOld: 0,
    childYoung: 0,
    firstAdult: 0,
    perNight: entry.charge,
    secondAdult: 0,
  },
  socialCreditPoints: entry.socialCreditPoints,
  summerHolidays: entry.summerHolidays,
  bookingDate: {
    arrival: entry.arrival.toISOString(),
    departure: entry.departure.toISOString(),
  },
  houseId: entry.houseId,
  eonBookingId: entry.bookingId,
});

const useCreateEonOffers = ({ onCompleted }: { onCompleted: () => void }) => {
  const { t } = useTranslation();

  const [createEonOffers, { error, ...rest }] = useCreateEonOffersMutation({
    refetchQueries: [
      refetchApplicationForm_GetVactionOffersHousesQuery(),
      refetchGetEonHousesAndOffersQuery(),
    ],
    context: {
      debounceKey: 'useCreateEonOffersMutation',
      notification: {
        success: t('components.VacationOffers.import.notifications.success'),
        error: t('components.VacationOffers.import.notifications.error'),
      },
    },
    onCompleted,
  });

  return [
    createEonOffers,
    {
      error: error
        ? t('components.VacationOffers.import.notifications.error')
        : undefined,
      ...rest,
    },
  ] as const;
};
