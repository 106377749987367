import { getUrl } from 'config/config';
import { useRef, useContext, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IdTokenContext } from 'context/IdTokenContext';
import useReactTooltip from 'hooks/useReactTooltip';

const commonClass = 'max-w-full underline truncate';
const disabledClass = 'cursor-not-allowed opacity-50';

type AuthLinkProps = {
  attachment: {
    path: string;
    name: string;
  };
  fetchOptions?: RequestInit;
  disabled?: boolean;
};

const AuthLink = ({
  attachment,
  fetchOptions,
  disabled,
}: AuthLinkProps): ReactElement => {
  const idToken = useContext(IdTokenContext);
  const { t } = useTranslation();
  useReactTooltip();
  const wrapper = useRef<HTMLDivElement>(null);

  const handleAction = async () => {
    const fullUrl = getUrl(attachment.path);

    const result = await fetch(fullUrl, {
      ...fetchOptions,
      headers: {
        Authorization: `Bearer ${idToken.accessToken}`,
        ...fetchOptions?.headers,
      },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    if (wrapper.current) {
      wrapper.current.appendChild(link);
    }

    const contentDisposition = result.headers.get('content-disposition');
    const filename: string | undefined = contentDisposition
      ? getFilenameFromContentDispositionHeader(contentDisposition)
      : undefined;

    link.download = filename || attachment.name + '.xlsx';
    link.href = href;
    link.click();
    link.remove();
  };

  return (
    <div ref={wrapper} className="AuthLink">
      <button
        onClick={handleAction}
        data-tip={t('components.attachment.tooltip.downloadFile')}
        className={commonClass + (disabled ? ' ' + disabledClass : '')}
        disabled={disabled}
      >
        {attachment.name}
      </button>
    </div>
  );
};

function getFilenameFromContentDispositionHeader(
  contentDispositionHeader: string,
): string | undefined {
  // Raw header: Content-Disposition: attachment; filename="Kurtaxenliste.xlsx"; filename*=UTF-8''Kurtaxenliste.xlsx
  const parts = contentDispositionHeader.split(';').map(x => x.trim());
  let filename = parts.find(x => x.startsWith('filename*='));
  if (filename) {
    const value = filename.split('=')[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_encoding, _locale, name] = value.split("'");
    return decodeURIComponent(name);
  }
  filename = parts.find(x => x.startsWith('filename='));
  if (filename) {
    return filename.split('=')[1].replace('"', '');
  }

  return undefined;
}

export default AuthLink;
