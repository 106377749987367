import { PropsWithChildren, ReactElement } from 'react';

export const NonEditableText = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement => (
  <div className="text-gray-500">{children}</div>
);

export const NonEditableTextArea = ({
  children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement => (
  <NonEditableText>
    <div className="whitespace-pre-wrap">{children}</div>
  </NonEditableText>
);
