import BookingModal from 'components/VacationOffers/BookingModal';
import { DeleteButton, EditButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfoContext } from '../../context/UserInfoContext';
import { ToggleActiveButton } from 'components/base/IconButton';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import {
  BookingOfferUpdateInput,
  CountervailingBenefit,
} from 'gql/generated/types-and-hooks';
import { currencyNumberFormat } from 'helpers/util';

const BookingRow = ({
  booking,
  onDeleteBooking,
  onUpdateBooking,
}: {
  booking: {
    id: string;
    active: boolean;
    isBooked: boolean;
    arrival: Date;
    departure: Date;
    socialCreditPoints: boolean;
    summerHolidays: boolean;
    countervailingBenefit: CountervailingBenefit;
  };
  onDeleteBooking: () => void;
  onUpdateBooking: (bookingInput: BookingOfferUpdateInput) => void;
}): ReactElement => {
  const { isAdmin } = useContext(UserInfoContext);
  const { t } = useTranslation();
  const [modelOpen, setModalOpen] = useState(false);
  const handleEdit = () => setModalOpen(true);
  const handleCancel = () => setModalOpen(false);

  const handleConfirm = (data: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    countervailingBenefit: CountervailingBenefit;
  }) => {
    onUpdateBooking({
      active: booking.active,
      bookingDate: {
        arrival: data.arrival.toISOString(),
        departure: data.departure.toISOString(),
      },
      socialCreditPoints: data.socialPoints,
      summerHolidays: data.summer,
      countervailingBenefit: data.countervailingBenefit,
    });
    setModalOpen(false);
  };

  const handleDelete = onDeleteBooking;

  const handleToggleActive = () => {
    onUpdateBooking({
      bookingDate: {
        arrival: booking.arrival.toISOString(),
        departure: booking.departure.toISOString(),
      },
      socialCreditPoints: booking.socialCreditPoints,
      summerHolidays: booking.summerHolidays,
      active: !booking.active,
    });
  };

  if (booking.arrival == null || booking.departure == null) {
    return (
      <tr>
        <td>
          {t('components.VacationOffers.BookingDates.missingBookingDates')}
        </td>
      </tr>
    );
  }

  const arrival = new Date(booking.arrival);
  const departure = new Date(booking.departure);

  return (
    <tr className={!booking.active ? 'bg-gray-300 text-gray-500' : ''}>
      <td>{dateMonthYearFormat.format(arrival)}</td>
      <td>{dateMonthYearFormat.format(departure)}</td>
      <td>
        {booking.socialCreditPoints
          ? t('components.form.yesNoChoice.true')
          : t('components.form.yesNoChoice.false')}
      </td>
      <td>
        {booking.summerHolidays
          ? t('components.form.yesNoChoice.true')
          : t('components.form.yesNoChoice.false')}
      </td>
      <td>
        {currencyNumberFormat.format(booking.countervailingBenefit.firstAdult)}
      </td>
      <td>
        {currencyNumberFormat.format(booking.countervailingBenefit.secondAdult)}
      </td>
      <td>
        {currencyNumberFormat.format(booking.countervailingBenefit.childYoung)}
      </td>
      <td>
        {currencyNumberFormat.format(booking.countervailingBenefit.childOld)}
      </td>
      <td>
        {currencyNumberFormat.format(booking.countervailingBenefit.perNight)}
      </td>
      <td className="w-0">
        <KeyFormArea>
          {isAdmin && (
            <div className="flex gap-x-2">
              <EditButton onClick={handleEdit} />
              <ToggleActiveButton
                active={booking.active}
                onClick={handleToggleActive}
              />
              {!booking.isBooked && <DeleteButton onClick={handleDelete} />}
              <BookingModal
                open={modelOpen}
                booking={{
                  socialPoints: booking.socialCreditPoints,
                  summer: booking.summerHolidays,
                  arrival: arrival,
                  departure: departure,
                  eonBookingId: '',
                  countervailingBenefit: booking.countervailingBenefit,
                }}
                showCountervailingBenefit={{
                  firstAdult: true,
                  secondAdult: true,
                  childYoung: true,
                  childOld: true,
                  perNight: true,
                }}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />
            </div>
          )}
        </KeyFormArea>
      </td>
    </tr>
  );
};

export default BookingRow;
