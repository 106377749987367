import FormSection from '../FormSection';
import HawBookingEntry from './HawBookingEntry';
import { useTranslation } from 'react-i18next';
import { NumberDropdown } from '../NumberDropdown';
import { isEmpty, unique } from 'helpers/util';
import PriceTable from './PriceTable';
import { mapBoardPropertynameToEnum } from 'interfaces/BoardType';
import { useFormContext } from 'react-hook-form';
import { ErrorText } from '../Validatable';
import { getChargesForBoard } from 'helpers/priceCalculation';
import { HawCatalogueData } from 'interfaces/HawCatalogueData';
import {
  BoardType,
  BookingConfirmationStatus,
  EditMyApplications_GetApplicationQuery,
  HouseBoard,
  PartnerStatus,
  PositionType,
} from 'gql/generated/types-and-hooks';
import { ReactElement } from 'react';

const getAllBookings = (catalogue: HawCatalogueData) =>
  catalogue.flatMap(c =>
    c.offers.flatMap(offer => offer.bookings.map(booking => booking)),
  );

const getBookedBookingIds = (
  hawBookings: {
    bookingChoiceId?: string | null;
  }[],
): string[] =>
  hawBookings && hawBookings.length > 0
    ? unique<string>(hawBookings.map(x => x.bookingChoiceId ?? ''))
    : [];

const getUnbookedBookings = (
  catalogue: HawCatalogueData,
  hawBookings: EditMyApplications_GetApplicationQuery['application']['hawBookings'],
) => {
  const bookedBookingIds = getBookedBookingIds(hawBookings);
  const allBookings = getAllBookings(catalogue);
  return allBookings.filter(x => bookedBookingIds.indexOf(x.id) === -1);
};

const filterCatalogue = (
  catalogue: HawCatalogueData,
  bookedOffers: EditMyApplications_GetApplicationQuery['application']['hawBookings'],
) => {
  const bookedBookingIds = getBookedBookingIds(bookedOffers);
  return catalogue
    .map(entry => ({
      ...entry,
      offers: entry.offers
        .map(offer => {
          return {
            ...offer,
            bookings: offer.bookings.filter(
              x => bookedBookingIds.indexOf(x.id) === -1,
            ),
          };
        })
        .filter(offer => offer.bookings.length > 0),
    }))
    .filter(x => x.offers.length > 0);
};

const getOfferForBooking = (
  catalogue: HawCatalogueData,
  bookingId?: string | null,
) => {
  const offers = catalogue.flatMap(c =>
    c.offers.filter(o => o.bookings.some(b => b.id === bookingId)),
  );
  if (offers && offers.length > 0) {
    return offers[0];
  }
  return null;
};

const HawCatalogue = ({
  catalogue,
  editMode,
}: {
  catalogue: HawCatalogueData;
  editMode: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useFormContext<
    EditMyApplications_GetApplicationQuery['application'] & {
      nbrOfHawBookings: number;
      nbrOfEonBookings: number;
    }
  >();

  if (!catalogue)
    return <div>{t('components.form.hawCatalogue.missingData')}</div>;

  const companyPosition = getValues('companyPosition.position');
  const markBOnPassport = getValues('disability.markBOnPassport');
  const relationshipStatus = getValues('partner.status');
  const furtherGuests = getValues('furtherGuests');

  const hawBookingsUnsorted = getValues('hawBookings');
  const hawBookings =
    hawBookingsUnsorted?.sort((a, b) => (a.priority > b.priority ? 1 : -1)) ||
    [];

  const handleAddBookings = (count: number) => {
    const oldBookings = [...hawBookings];
    const newBookings = [...hawBookings];
    for (let i = oldBookings.length; i < count; i++) {
      const unbooked = getUnbookedBookings(catalogue, newBookings);

      if (unbooked.length > 0) {
        const newBooking: EditMyApplications_GetApplicationQuery['application']['hawBookings'][0] =
          {
            id: '',
            priority: newBookings.length + 1,
            bookingChoiceId: '',
            board: null,
            applicationId: '',
            bookingStatus: BookingConfirmationStatus.Pending,
            createdAt: new Date().toISOString(),
          };

        newBookings.push(newBooking);
      }
    }
    setValue('hawBookings', newBookings);
  };

  const handleChangeHouse = (index: number) => (value: string) => {
    let board: BoardType | null = null;
    if (!isEmpty(value)) {
      const cat = catalogue.find(c =>
        c.offers.some(offer => offer.house.id === value),
      );
      const offer = cat?.offers.find(offer => offer.house.id === value);

      if (offer && offer.house) {
        const activeBoards = Object.entries(offer.house.charges).reduce(
          (boards, cur) => {
            if (cur == null || cur[0] === '__typename') {
              return boards;
            }
            const board = cur[1] as HouseBoard;
            return board.activated
              ? [...boards, mapBoardPropertynameToEnum(cur[0])]
              : boards;
          },
          [] as BoardType[],
        );
        if (activeBoards.length === 1) {
          board = activeBoards[0];
        }
      }
    }

    setValue(`hawBookings.${index}.bookingChoiceId`, '', {
      shouldValidate: false,
    });
    setValue(`hawBookings.${index}.board`, board, {
      shouldValidate: board ? true : false,
    });
  };
  const bookingsAvailable = catalogue.some(
    cat =>
      cat.activated &&
      cat.offers.some(
        offer => offer.active && offer.bookings.some(booking => booking.active),
      ),
  );

  return (
    <div className="HawCatalogue">
      <FormSection name={t('components.form.hawCatalogue.sectionTitle')}>
        {!bookingsAvailable && hawBookings.length === 0 ? (
          <div>
            {t('components.form.hawCatalogue.noVacationOffers')}
            <ErrorText error={errors?.nbrOfHawBookings} />
          </div>
        ) : (
          <>
            {editMode && (
              <div className="mb-4 grid grid-cols-12 items-center gap-3">
                <label className="col-span-3 col-start-1">
                  {t('components.form.hawCatalogue.selectNumberOfHouses')}
                </label>
                <div className="col-start-5">
                  <NumberDropdown
                    className="w-full"
                    value={hawBookings.length}
                    max={6}
                    onChange={e => {
                      const value = parseInt(e.target.value);
                      if (value > hawBookings.length) {
                        clearErrors('nbrOfEonBookings');
                        clearErrors('nbrOfHawBookings');
                        handleAddBookings(value);
                      } else if (value < hawBookings.length) {
                        const newBookings: EditMyApplications_GetApplicationQuery['application']['hawBookings'] =
                          [];
                        for (let i = 0; i < value; i++) {
                          newBookings.push(hawBookings[i]);
                        }
                        setValue('hawBookings', newBookings);
                      }
                    }}
                  />
                  <ErrorText error={errors?.nbrOfHawBookings} />
                </div>
              </div>
            )}

            {hawBookings.length === 0 ? (
              <div>{t('components.form.hawCatalogue.numberOfHouses')}</div>
            ) : (
              <>
                <div className="grid grid-cols-12 gap-x-2 bg-gray-100 py-2 text-primary">
                  <div className="col-span-1">
                    {/* {t('components.form.hawCatalogue.rank')} */}
                  </div>
                  <div className="col-span-3">
                    {t('components.form.hawCatalogue.house')}
                  </div>
                  <div className="col-span-5">
                    {t('components.form.hawCatalogue.period')}
                  </div>
                  <div className="col-span-3">
                    {t('components.form.hawCatalogue.board')}
                  </div>
                </div>
                {hawBookings.map((hawBooking, index) => {
                  const filteredCatalogue = filterCatalogue(
                    catalogue,
                    hawBookings.filter(x => x !== hawBooking),
                  );

                  const offer = getOfferForBooking(
                    catalogue,
                    hawBooking.bookingChoiceId,
                  );

                  const booking = offer?.bookings.find(
                    x => x.id === hawBooking.bookingChoiceId,
                  );

                  return (
                    <div
                      className={`mb-4 ${
                        index !== hawBookings.length - 1 ? 'border-b-2' : ''
                      }`}
                      key={hawBooking.id || index}
                    >
                      <HawBookingEntry
                        index={index}
                        editMode={editMode}
                        catalogue={filteredCatalogue}
                        onChangeHouse={handleChangeHouse(index)}
                      />

                      <PriceTable
                        position={
                          companyPosition
                            ? companyPosition
                            : PositionType.Employee
                        }
                        calculateVacationPackage={true}
                        markBOnPassport={markBOnPassport}
                        timespan={
                          booking
                            ? {
                                arrival: booking.from.toISOString(),
                                departure: booking.to.toISOString(),
                              }
                            : undefined
                        }
                        travelers={{
                          minorChildren: furtherGuests.familyChildrenUnderage,
                          otherMinors: furtherGuests.nonFamilyChildrenUnderage,
                          relationshipStatus: relationshipStatus
                            ? relationshipStatus
                            : PartnerStatus.Without,
                        }}
                        charges={
                          offer
                            ? getChargesForBoard({
                                charges: {
                                  house: offer.house.charges,
                                  vacationPackage: offer.charges,
                                },
                                board: hawBooking.board
                                  ? hawBooking.board
                                  : BoardType.FullBoard,
                                countervailingBenefit:
                                  booking?.countervailingBenefit,
                              })
                            : undefined
                        }
                      />
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </FormSection>
    </div>
  );
};

export default HawCatalogue;
