import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import InputCell from 'components/tables/InputCell';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivateBoardButton from './ActivateBoardButton';
import { UserInfoContext } from '../../context/UserInfoContext';
import {
  Boards,
  ChargesFormRow,
  FEHouseBoard,
  mapChargesToForm,
} from 'api/mappers';
import {
  House,
  HouseBoardInput,
  HouseChargesInput,
  useCharges_UpdateHouseChargesMutation,
  refetchHouseForm_GetHouseQuery,
} from 'gql/generated/types-and-hooks';

const boards: Boards[] = [
  'selfSupply',
  'overnightBreakfast',
  'halfBoard',
  'fullBoard',
];

const Row = ({
  label,
  prices,
  editColumn,
  activeBoards,
  onChange,
  isAdmin = false,
}: {
  label: string;
  prices: ChargesFormRow;
  editColumn: Boards | null;
  activeBoards: Record<Boards, boolean>;
  onChange: (board: Boards) => (value: number) => void;
  isAdmin: boolean;
}) => (
  <tr>
    <td>
      <p className="truncate" title={label}>
        {label}
      </p>
    </td>

    {boards.map(board => {
      if (isAdmin || activeBoards[board]) {
        return (
          <InputCell
            key={label + board}
            value={prices[board]}
            edit={editColumn === board}
            disabled={!activeBoards[board]}
            onChange={onChange(board)}
          />
        );
      } else {
        return null;
      }
    })}
  </tr>
);

type Categories = keyof ReturnType<typeof mapChargesToForm>['prices'];

type CategoryCell = {
  label: string;
  category: Categories;
};

type ChargesProps = {
  editable?: boolean;
  house: Pick<House, 'id' | 'charges'>;
};

const Charges = ({ editable, house }: ChargesProps): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const [, enterEditMode, leaveEditmode] = useEditMode(false);
  const [editColumn, setEditColumn] = useState<Boards | null>(null);

  const mappedCharges = useMemo(
    () => mapChargesToForm(house.charges),
    [house.charges],
  );
  const [charges, setCharges] = useState(mappedCharges);
  const [updateHouseCharges] = useCharges_UpdateHouseChargesMutation({
    refetchQueries: [refetchHouseForm_GetHouseQuery({ id: house.id })],
    context: {
      debounceKey: 'useCharges_UpdateHouseChargesMutation',
      notification: {
        success: t('components.charges.notifications.success'),
        error: t('components.charges.notifications.error'),
      },
    },
  });

  useEffect(() => {
    setCharges(mappedCharges);
  }, [mappedCharges]);

  const rows1: CategoryCell[] = [
    {
      label: t('components.charges.row.accomodation.adult'),
      category: 'adult',
    },
    {
      label: t('components.charges.row.accomodation.childYoung'),
      category: 'youngChild',
    },
    {
      label: t('components.charges.row.accomodation.childOld'),
      category: 'oldChild',
    },
    {
      label: t('components.charges.row.accomodation.retiree'),
      category: 'retired',
    },
    {
      label: t('components.charges.row.accomodation.executive'),
      category: 'executive',
    },
    {
      label: t('components.charges.row.accomodation.externalAdult'),
      category: 'externalAdult',
    },
    {
      label: t('components.charges.row.accomodation.externalChild'),
      category: 'externalChild',
    },
  ];

  const rows3: CategoryCell[] = [
    {
      label: t('components.charges.row.furtherPosition.cleaning'),
      category: 'cleaning',
    },
    {
      label: t('components.charges.row.furtherPosition.costsPerNight'),
      category: 'costsPerNight',
    },
    {
      label: t('components.charges.row.furtherPosition.visitorsTaxSummer'),
      category: 'summerTax',
    },
    {
      label: t('components.charges.row.furtherPosition.visitorsTaxWinter'),
      category: 'winterTax',
    },
  ];

  const handleChange =
    (category: Categories) => (board: Boards) => (value: number) => {
      const cost = { ...charges.prices[category], [board]: value };
      const prices = { ...charges.prices, [category]: cost };
      setCharges({ ...charges, prices });
    };

  const handleEditColumn = (column: Boards) => () => {
    setEditColumn(column);
    enterEditMode();
  };

  const sections = [
    {
      data: rows1,
      label: t('components.charges.row.accomodation.name'),
    },
  ];

  sections.push({
    data: rows3,
    label: t('components.charges.row.furtherPosition.name'),
  });

  const handleConfirm = () => {
    updateHouseCharges({
      variables: {
        houseId: house.id,
        charges: mapChargesToApi(charges),
      },
    });
    setEditColumn(null);
    leaveEditmode();
  };

  const handleCancel = () => {
    setCharges(mappedCharges);
    setEditColumn(null);
    leaveEditmode();
  };

  const { active } = mappedCharges;

  const getThStyle = (chargeActive: boolean) =>
    `text-right font-medium ${chargeActive ? '' : 'bg-gray-300 text-gray-500'}`;

  const colGroup = () => (
    <colgroup>
      <col style={{ width: '28%' }} />
      {(active.selfSupply || isAdmin) && <col style={{ width: '18%' }} />}
      {(active.overnightBreakfast || isAdmin) && (
        <col style={{ width: '18%' }} />
      )}
      {(active.halfBoard || isAdmin) && <col style={{ width: '18%' }} />}
      {(active.fullBoard || isAdmin) && <col style={{ width: '18%' }} />}
    </colgroup>
  );

  return (
    <div className="Charges">
      <p className="mb-4 font-medium">{t('components.charges.title')}</p>
      <table className="w-full table-fixed whitespace-nowrap">
        {colGroup()}
        <tbody>
          {sections.map(({ data, label: groupLabel }) => [
            <tr key={groupLabel} className="bg-gray-100 text-left text-primary">
              <th className="truncate font-medium" title={groupLabel}>
                {groupLabel}
              </th>
              <th
                className={getThStyle(active.selfSupply) + ' truncate'}
                hidden={!active.selfSupply && !isAdmin}
              >
                {t('components.charges.column.selfSupply')}
              </th>
              <th
                className={getThStyle(active.overnightBreakfast) + ' truncate'}
                hidden={!active.overnightBreakfast && !isAdmin}
              >
                {t('components.charges.column.overnightBreakfast')}
              </th>
              <th
                className={getThStyle(active.halfBoard) + ' truncate'}
                hidden={!active.halfBoard && !isAdmin}
              >
                {t('components.charges.column.halfBoard')}
              </th>
              <th
                className={getThStyle(active.fullBoard) + ' truncate'}
                hidden={!active.fullBoard && !isAdmin}
              >
                {t('components.charges.column.fullBoard')}
              </th>
            </tr>,
            data.map(({ label, category }) => (
              <Row
                label={label}
                editColumn={editColumn}
                key={groupLabel + category}
                activeBoards={active}
                prices={charges.prices[category]}
                onChange={handleChange(category)}
                isAdmin={isAdmin}
              />
            )),
          ])}
        </tbody>
        {editable && (
          <tfoot>
            <tr>
              <td></td>
              {boards.map(board => (
                <td key={'actions' + board}>
                  {isAdmin && (
                    <div className="flex justify-end">
                      <EditControls
                        editMode={editColumn === board}
                        onEdit={handleEditColumn(board)}
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                      />
                      <ActivateBoardButton
                        active={mappedCharges.active[board]}
                        onToggle={() => {
                          const active = {
                            ...charges.active,
                            [board]: !charges.active[board],
                          };
                          updateHouseCharges({
                            variables: {
                              houseId: house.id,
                              charges: mapChargesToApi({ ...charges, active }),
                            },
                          });
                        }}
                      />
                    </div>
                  )}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

function mapChargesToApi(data: FEHouseBoard): HouseChargesInput {
  return {
    fullBoard: mapBoardToApi('fullBoard', data),
    halfBoard: mapBoardToApi('halfBoard', data),
    overnightBreakfast: mapBoardToApi('overnightBreakfast', data),
    selfSupply: mapBoardToApi('selfSupply', data),
  };
}

function mapBoardToApi(board: Boards, data: FEHouseBoard): HouseBoardInput {
  return {
    activated: data.active[board],
    accommodation: {
      adult: data.prices.adult[board],
      childYoung: data.prices.youngChild[board],
      childOld: data.prices.oldChild[board],
      executive: data.prices.executive[board],
      retiree: data.prices.retired[board],
      externalAdult: data.prices.externalAdult[board],
      externalChild: data.prices.externalChild[board],
    },
    furtherPosition: {
      costsPerNight: data.prices.costsPerNight[board],
      cleaning: data.prices.cleaning[board],
      visitorsTaxSummer: data.prices.summerTax[board],
      visitorsTaxWinter: data.prices.winterTax[board],
    },
  };
}

export default Charges;
