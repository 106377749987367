import { useTranslation } from 'react-i18next';
import ApplicationTable from 'components/application/ApplicationTable';
import ActionHeader, { renderActions } from 'components/application/Actions';
import { defaultFilter } from 'components/application/FilterEntries';
import {
  ActionConfig,
  ApplicationActions,
  ApplicationFilter,
  BookingAndApplication,
  SelectedItem,
} from 'components/application/interfaces';
import {
  pickSelectedItems,
  pickSelectedOffers,
} from 'components/application/data';
import {
  collectAllowedActions,
  isActionAllowed,
  useCreateAssign,
  useCreateCancelApplication,
  useCreateMailTo,
  useCreateUpdateApplicationStatus,
  useFinishApplication,
} from 'components/application/actionHandler';
import Button from 'components/base/Button';
import LoadingOrError from 'components/base/LoadingOrError';
import PaginationBar, { Pagination } from 'components/PaginationBar';
import { CancelApplicationModal } from 'components/application/cancelApplicationModal';
import { ReactElement, useState } from 'react';
import {
  CancellationInput,
  ProcessingStatus,
  SortableColumn,
  SortingOrder,
  useEmployeeApplications_GetApplicationsVactionOffersHousesQuery,
} from 'gql/generated/types-and-hooks';
import { FilterAndExport } from 'components/application/FilterAndExport';
import { ColumnSorting } from 'types/ColumnSorting';

type Sorting = ColumnSorting<SortableColumn>;

const { InReview, Rejected, RejectedConfirmed, Confirmed, SentBack } =
  ProcessingStatus;

const useAdminRelevantApplications = (
  pagination?: Pagination,
  sorting?: Sorting,
  filter?: ApplicationFilter,
) =>
  useEmployeeApplications_GetApplicationsVactionOffersHousesQuery({
    variables: {
      skip: pagination?.offset,
      take: pagination?.limit,
      column: sorting?.column,
      order: sorting?.order,
      filterByHouse: filter?.house,
      filterByMonth: filter?.month,
      filterByYear: filter?.year,
      filterByOffer: filter?.offer,
      filterByPriority: filter?.priority,
      filterByStatus: filter?.processingStatus,
      filterByType: filter?.type,
    },
    fetchPolicy: 'cache-and-network',
  });

export const EmployeeApplications = (): ReactElement => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const resetSelection = () => setSelectedItems([]);
  const [filter, setFilter] = useState<ApplicationFilter>(defaultFilter);
  const [sorting, setSorting] = useState<Sorting>({
    column: SortableColumn.CreatedAt,
    order: SortingOrder.Descending,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: 25,
    offset: 0,
  });
  const [cancelModal, setCancelModal] = useState<{
    open: boolean;
    onConfirm?: (parameters: CancellationInput) => void;
  }>({
    open: false,
    onConfirm: undefined,
  });

  const { data, loading, error, refetch } = useAdminRelevantApplications(
    {
      ...pagination,
      limit:
        pagination.limit === Number.MAX_SAFE_INTEGER ? NaN : pagination.limit,
    },
    sorting,
    filter,
  );

  const numberOfApplications =
    data?.filterAndCountApplications.numberOfApplications;
  const bookings = data?.applications.items || [];
  const total = data?.applications.total;
  const actionConfig: Record<ApplicationActions, ActionConfig> =
    useActionConfig({
      bookings,
      selectedItems,
      onDone: () => {
        resetSelection();
        refetch();
      },
      onOpenCancelModal: cb => {
        setCancelModal({
          open: true,
          onConfirm: cb,
        });
      },
    });

  const onSetFilter = (newFilter: ApplicationFilter) => {
    setSelectedItems([]);
    setFilter({ ...newFilter });
    setPagination({ limit: pagination.limit, offset: 0 });
  };

  const onSetPagination = (pagination: Pagination) => {
    setSelectedItems([]);
    setPagination(pagination);
  };

  const onSetSorting = (sorting: Sorting) => {
    setSelectedItems([]);
    setSorting(sorting);
  };

  return (
    <div className="EmployeeApplications">
      <CancelApplicationModal
        open={cancelModal.open}
        onCancel={() => setCancelModal({ open: false })}
        onConfirm={parameters => {
          if (cancelModal.onConfirm) {
            cancelModal.onConfirm(parameters);
          }
          setCancelModal({ open: false });
        }}
      />
      <ActionHeader>{renderActions(actionConfig)}</ActionHeader>
      <div className="flex flex-row">
        <FilterAndExport
          onSetFilter={onSetFilter}
          filter={filter}
          sorting={sorting}
        />
        <div className="flex flex-1 flex-col overflow-x-auto border-t-2">
          <div className="flex flex-row items-baseline justify-between">
            <PaginationBar
              limit={pagination.limit}
              pageSizes={[25, 50, 75, 100, Number.MAX_SAFE_INTEGER]}
              offset={pagination.offset}
              total={data?.applications.total || 0}
              onChange={onSetPagination}
            />
            <NumberOfApplications {...{ numberOfApplications }} />
          </div>

          <LoadingOrError loading={loading} error={error}>
            {!total ? (
              <div className="bg-gray-200 px-4 py-6 text-center">
                {t('components.applications.filterAndExport.noData')}
              </div>
            ) : bookings.length === 0 ? (
              <div className="flex flex-col items-center bg-gray-200 px-4 py-6">
                <div className="mb-2">
                  {t('components.applications.filterAndExport.noDataFilter')}
                </div>
                <Button
                  onClick={() => onSetFilter({})}
                  label={t(
                    'components.applications.filterAndExport.resetFilter',
                  )}
                />
              </div>
            ) : (
              <ApplicationTable
                rowData={bookings}
                selectedItems={selectedItems}
                onRowSelect={setSelectedItems}
                onSort={onSetSorting}
                sorting={sorting}
              />
            )}
          </LoadingOrError>
        </div>
      </div>
    </div>
  );
};

const useActionConfig = ({
  bookings = [],
  selectedItems,
  onDone,
  onOpenCancelModal,
}: {
  bookings: BookingAndApplication[];
  selectedItems: SelectedItem[];
  onDone: () => void;
  onOpenCancelModal: (
    onConfirm: (parameters: CancellationInput) => void,
  ) => void;
}): Record<ApplicationActions, ActionConfig> => {
  const withOnDone = (handler: () => Promise<void>) => async () => {
    await handler();
    onDone();
  };

  const withCancellation =
    (
      handler: (params: CancellationInput) => Promise<void>,
      parameters: CancellationInput,
    ) =>
    async () => {
      await handler(parameters);
      onDone();
    };
  const withConfirmCancellationModal =
    (handler: (input: CancellationInput) => Promise<void>) => () => {
      onOpenCancelModal(async parameters => {
        await handler(parameters);
        onDone();
      });
    };
  const selectedBookingsAndApplications = pickSelectedItems(
    bookings,
    selectedItems,
  );
  const selectedAssignments = pickSelectedOffers(
    selectedBookingsAndApplications,
    selectedItems,
  );
  const allowedActions = collectAllowedActions(
    selectedBookingsAndApplications.map(
      ({ applicationProcessingStatus }) => applicationProcessingStatus,
    ),
  );
  const allAreConfirmed = selectedBookingsAndApplications.every(
    ({ applicationProcessingStatus }) =>
      applicationProcessingStatus === Confirmed,
  );

  const noneIsConfirmed = selectedBookingsAndApplications.every(
    ({ applicationProcessingStatus }) =>
      applicationProcessingStatus !== Confirmed,
  );

  /* Actions */
  const assign = useCreateAssign(selectedAssignments);
  const sendBack = useCreateUpdateApplicationStatus(
    selectedBookingsAndApplications,
    SentBack,
  );
  const review = useCreateUpdateApplicationStatus(
    selectedBookingsAndApplications,
    InReview,
  );
  const reject = useCreateUpdateApplicationStatus(
    selectedBookingsAndApplications,
    Rejected,
  );
  const RejectedConfirm = useCreateUpdateApplicationStatus(
    selectedBookingsAndApplications,
    RejectedConfirmed,
  );
  const confirm = useCreateUpdateApplicationStatus(
    selectedBookingsAndApplications,
    Confirmed,
  );
  const cancel = useCreateCancelApplication(selectedBookingsAndApplications);
  const finish = useFinishApplication(selectedBookingsAndApplications);
  const mailto = useCreateMailTo(selectedBookingsAndApplications);

  return {
    SentBack: {
      handler: withOnDone(sendBack),
      isAvailable: isActionAllowed(allowedActions, 'SentBack'),
    },
    InReview: {
      handler: withOnDone(review),
      isAvailable: isActionAllowed(allowedActions, 'InReview'),
    },
    Rejected: {
      handler: withOnDone(reject),
      isAvailable: isActionAllowed(allowedActions, 'Rejected'),
    },
    Assigned: {
      handler: () => withOnDone(assign)(),
      isAvailable:
        selectedBookingsAndApplications.length === 1 &&
        selectedAssignments.length === 1 &&
        isActionAllowed(allowedActions, 'Assigned'),
    },
    RejectedConfirmed: {
      handler: withOnDone(RejectedConfirm),
      isAvailable: isActionAllowed(allowedActions, 'RejectedConfirmed'),
    },
    Confirmed: {
      handler: withOnDone(confirm),
      isAvailable: isActionAllowed(allowedActions, 'Confirmed'),
    },
    Cancelled: {
      handler: allAreConfirmed
        ? withConfirmCancellationModal(cancel)
        : withCancellation(cancel, {
            cancellationDate: new Date().toISOString(),
            withCosts: false,
          }),
      isAvailable:
        isActionAllowed(allowedActions, 'Cancelled') &&
        (allAreConfirmed || noneIsConfirmed),
    },
    Finished: {
      handler: withOnDone(finish),
      isAvailable: isActionAllowed(allowedActions, 'Finished'),
    },
    EMAIL: {
      handler: withOnDone(mailto),
      isAvailable: selectedBookingsAndApplications.length > 0,
    },
  };
};

const NumberOfApplications = ({
  numberOfApplications,
}: {
  numberOfApplications?: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className={numberOfApplications == undefined ? 'invisible' : ''}>
      {t('components.applications.filterAndExport.numberOfApplications', {
        numberOfApplications: numberOfApplications,
      })}
    </div>
  );
};
