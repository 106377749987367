import { useCallback, useState } from 'react';
import { MatchingEntry } from './MatchingController';

export type SocialCreditPointsSetting = {
  socialCreditPoints: boolean;
  summerHolidays: boolean;
};

export type SocialCreditPointsSettings = Record<
  string,
  SocialCreditPointsSetting
>;

export type ImportEntryWithoutSocialCreditPointData = Omit<
  MatchingEntry,
  'matchResult' | 'action'
>;

type SocialCreditPointsControllerResult = {
  settings: SocialCreditPointsSettings;
  setCrediting: (value: {
    socialCreditPoints: boolean;
    bookingId: string;
  }) => void;
  setSummerholidays: (value: {
    summerHolidays: boolean;
    bookingId: string;
  }) => void;
  reset: (data?: ImportEntryWithoutSocialCreditPointData[]) => void;
};

export const useSocialCreditPointsController =
  (): SocialCreditPointsControllerResult => {
    const [settings, setSettings] = useState<SocialCreditPointsSettings>({});

    const setCrediting = useCallback(
      ({ socialCreditPoints, bookingId }) =>
        setSettings(state => ({
          ...state,
          [bookingId]: {
            ...state[bookingId],
            socialCreditPoints,
          },
        })),
      [],
    );

    const setSummerholidays = useCallback(
      ({ summerHolidays, bookingId }) =>
        setSettings(state => ({
          ...state,
          [bookingId]: {
            ...state[bookingId],
            summerHolidays,
          },
        })),
      [],
    );

    const reset = useCallback(
      (data: ImportEntryWithoutSocialCreditPointData[] | undefined) => {
        setSettings(initSocialCreditPoints(data));
      },
      [],
    );

    return {
      settings,
      setCrediting,
      setSummerholidays,
      reset,
    };
  };

const initSocialCreditPoints = (
  entries: ImportEntryWithoutSocialCreditPointData[] = [],
) => {
  return entries.reduce(
    (result, entry) => ({
      ...result,
      [entry.bookingId]: {
        socialCreditPoints: true,
        summerHolidays: false,
      },
    }),
    {} as SocialCreditPointsSettings,
  );
};
