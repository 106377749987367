import { ToggleActiveButton } from 'components/base/IconButton';
import { KeyFormArea } from 'context/KeyFormContext';
import { ReactElement } from 'react';

const ActivateBoardButton = ({
  active,
  onToggle,
}: {
  active: boolean;
  onToggle: () => void;
}): ReactElement => {
  return (
    <div className="ActivateBoardButton">
      <KeyFormArea>
        <ToggleActiveButton onClick={onToggle} active={active} />
      </KeyFormArea>
    </div>
  );
};

export default ActivateBoardButton;
