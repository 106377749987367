import { useTranslation } from 'react-i18next';
import { memo, ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { useForm } from 'react-hook-form';
import LoadingOrError from 'components/base/LoadingOrError';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import {
  refetchNews_GetSettingsQuery,
  useNews_GetSettingsQuery,
  useNews_UpdateSettingsMutation,
} from 'gql/generated/types-and-hooks';
import { AvailableCharacters } from 'components/base/AvailableCharacters';
import { EditableTextArea } from 'components/applicationForm/editableInputs';

export const News = memo(function News(): ReactElement {
  const { t } = useTranslation();
  const { data, loading, error } = useNews_GetSettingsQuery({
    pollInterval: 0,
  });
  const [updateSettings] = useNews_UpdateSettingsMutation({
    refetchQueries: [refetchNews_GetSettingsQuery()],
    context: {
      debounceKey: 'useNews_UpdateSettingsMutation',
      notification: {
        success: t('components.information.notifications.success'),
        error: t('components.information.notifications.error'),
      },
    },
  });

  return (
    <LoadingOrError loading={loading} error={error}>
      <NewsField
        news={data?.getSettings.news || ''}
        onSubmit={url => {
          if (data) {
            updateSettings({
              variables: {
                settings: {
                  news: url,
                  name: data.getSettings.name,
                },
              },
            });
          }
        }}
      />
    </LoadingOrError>
  );
});

type NewsFieldProps = {
  news: string;
  onSubmit: (news: string) => void;
};

const NewsField = ({ news, onSubmit }: NewsFieldProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const [edit, enterEditMode, leaveEditmode] = useEditMode(false);
  const {
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      news,
    },
  });
  const onConfirm = handleSubmit(() => {
    onSubmit(getValues('news'));
    leaveEditmode();
  });

  if (!isAdmin && !news) return null;

  if (!isAdmin && news)
    return (
      <div className="bg-notification-info p-4">
        <h2 className="text-xl underline">
          {t('components.information.title')}
        </h2>
        <p className="whitespace-pre-wrap">{news}</p>
      </div>
    );

  return (
    <div className="flex gap-4 bg-notification-info p-4">
      <div className="flex-1">
        <h2 className="mb-2 text-xl underline">
          {t('components.information.title')}
        </h2>
        <ValidationFeedback error={errors.news}>
          <EditableTextArea
            {...register('news', { maxLength: 1000 })}
            maxLength={1000}
            editMode={edit}
            nonEditableValue={news}
          />
        </ValidationFeedback>
        {edit && (
          <AvailableCharacters
            current={watch('news').length}
            maxLength={1000}
          />
        )}
      </div>
      <EditControls
        editMode={edit}
        onEdit={() => enterEditMode()}
        onConfirm={onConfirm}
        onCancel={() => {
          leaveEditmode();
          reset({ news });
        }}
      />
    </div>
  );
};
