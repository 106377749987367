import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMsg, Loading } from 'components/base/LoadingOrError';
import { Show } from 'components/base/Show';
import { Action, MatchResult, MatchingEntry } from './MatchingController';
import { currencyNumberFormat } from 'helpers/util';
import { YesNoIcon } from './YesNoIcon';

type MatchingStepProps = {
  data?: Record<Action, MatchingEntry[]>;
  matchedData?: MatchingEntry[];
  error?: string;
  loading: boolean;
};

export const MatchingStep = memo(function MatchingStep({
  data,
  error,
  loading,
}: MatchingStepProps) {
  const { t } = useTranslation();
  const matching = useMemo(() => {
    return [...(data?.create ?? []), ...(data?.ignore ?? [])];
  }, [data?.create, data?.ignore]);
  return (
    <article className="flex h-full flex-col">
      <header className="py-4">
        <h2 className="text-2xl font-bold">
          {t('components.VacationOffers.import.steps.matching.heading')}
        </h2>
        <h3 className="text-ml">
          {t('components.VacationOffers.import.steps.matching.description', {
            context: data?.create?.length ? 'success' : 'failure',
            all: (data?.create?.length ?? 0) + (data?.ignore?.length ?? 0),
            valid: data?.create?.length ?? 0,
          })}
        </h3>
      </header>
      <section className="flex flex-grow flex-col overflow-y-auto">
        <Loading loading={loading} />
        <Show when={error != null}>
          <ErrorMsg customErrorMessage={error} />
        </Show>
        <Show when={error == null && !loading}>
          <MatchingTable matching={matching} />
        </Show>
      </section>
    </article>
  );
});

const MatchingTable = ({ matching }: { matching: MatchingEntry[] }) => {
  const { t } = useTranslation();

  return (
    <table className="w-full">
      <colgroup>
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>
            {t('components.VacationOffers.import.tableHeaders.bookingId')}
          </th>
          <th>{t('components.VacationOffers.import.tableHeaders.house')}</th>
          <th>{t('components.VacationOffers.import.tableHeaders.arrival')}</th>
          <th>
            {t('components.VacationOffers.import.tableHeaders.departure')}
          </th>
          <th>{t('components.VacationOffers.import.tableHeaders.charge')}</th>
          <th>
            {t('components.VacationOffers.import.tableHeaders.matchResult')}
          </th>
        </tr>
      </thead>
      <tbody>
        {matching.map(entry => (
          <tr key={entry.bookingId}>
            <td>{entry.bookingId}</td>
            <td>{entry.house}</td>
            <td>
              {entry.arrival.toLocaleDateString('de', { dateStyle: 'medium' })}
            </td>
            <td>
              {entry.departure.toLocaleDateString('de', {
                dateStyle: 'medium',
              })}
            </td>
            <td>{currencyNumberFormat.format(entry.charge)}</td>
            <td className="flex flex-row space-x-1">
              <YesNoIcon value={entry.action === 'create'} />
              <ResultText matchResult={entry.matchResult} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ResultText = memo(
  function ResultText({ matchResult }: { matchResult: MatchResult }) {
    const { t } = useTranslation();
    if (matchResult.bookingId) {
      return (
        <span className="text-red-500">
          {t('components.VacationOffers.import.steps.matching.matchError', {
            context: 'bookingId',
          })}
        </span>
      );
    }
    if (!matchResult.house) {
      return (
        <span className="text-red-500">
          {t('components.VacationOffers.import.steps.matching.matchError', {
            context: 'house',
          })}
        </span>
      );
    }
    return (
      <span className="text-green-500">
        {t('components.VacationOffers.import.steps.matching.matchSuccess')}
      </span>
    );
  },
  (prevProps, nextProps) =>
    prevProps.matchResult.bookingId === nextProps.matchResult.bookingId &&
    prevProps.matchResult.house === nextProps.matchResult.house,
);
