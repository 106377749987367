import {
  AccountInfo,
  AuthenticationResult,
  IPublicClientApplication,
  Logger,
} from '@azure/msal-browser';
import { ITokenCache } from '@azure/msal-browser/dist/cache/ITokenCache';

export const TEST_ACCESS_TOKEN = process.env['REACT_APP_ACCESS_TOKEN'] ?? '';

const MOCK_ACCOUNT: AccountInfo = {
  homeAccountId: 'home-account-id',
  localAccountId: 'local-account-id',
  environment: 'login.windows.net',
  tenantId: 'tenant-id',
  username: 'john.doe@contoso.com',
  name: 'John Doe',
};

const MOCK_AUTH_RESULT: AuthenticationResult = {
  authority: '',
  uniqueId: '',
  tenantId: '',
  scopes: [''],
  account: MOCK_ACCOUNT,
  idToken: '',
  idTokenClaims: {
    preferred_username: 'SOME_KID@eon.com',
    email: 'john.doe@contoso.com',
    name: 'John Doe',
  },
  accessToken: TEST_ACCESS_TOKEN,
  fromCache: true,
  expiresOn: new Date(Date.now() + 3600000),
  tokenType: 'Bearer',
  correlationId: '',
};

export class PublicClientApplicationMock implements IPublicClientApplication {
  constructor() {
    console.log('APPLICATION STARTED WITHOUT AUTH');
  }
  acquireTokenPopup(): Promise<AuthenticationResult> {
    throw new Error('Method not implemented.');
  }
  async acquireTokenRedirect(): Promise<void> {
    return void 0;
  }
  async acquireTokenSilent(): Promise<AuthenticationResult> {
    return MOCK_AUTH_RESULT;
  }
  addEventCallback(): string | null {
    return null;
  }
  removeEventCallback(): void {
    throw new Error('Method not implemented.');
  }
  enableAccountStorageEvents(): void {
    throw new Error('Method not implemented.');
  }
  disableAccountStorageEvents(): void {
    throw new Error('Method not implemented.');
  }
  getAccountByHomeId(): AccountInfo | null {
    throw new Error('Method not implemented.');
  }
  getAccountByLocalId(): AccountInfo | null {
    throw new Error('Method not implemented.');
  }
  getAccountByUsername(): AccountInfo | null {
    throw new Error('Method not implemented.');
  }
  getAllAccounts(): AccountInfo[] {
    return [MOCK_ACCOUNT];
  }
  handleRedirectPromise(): Promise<AuthenticationResult | null> {
    return Promise.resolve(MOCK_AUTH_RESULT);
  }
  loginPopup(): Promise<AuthenticationResult> {
    return Promise.resolve(MOCK_AUTH_RESULT);
  }
  loginRedirect(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  logout(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  async logoutRedirect(): Promise<void> {
    return void 0;
  }
  logoutPopup(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  ssoSilent(): Promise<AuthenticationResult> {
    return Promise.resolve(MOCK_AUTH_RESULT);
  }
  getTokenCache(): ITokenCache {
    throw new Error('Method not implemented.');
  }

  logger: Logger = {
    clone: (): Logger => this.logger as unknown as Logger,
    verbose: () => {
      /*  */
    },
  } as unknown as Logger;

  getLogger(): Logger {
    return this.logger as Logger;
  }
  setLogger(): void {
    throw new Error('Method not implemented.');
  }
  setActiveAccount(): void {
    return void 0;
  }
  getActiveAccount(): AccountInfo | null {
    return MOCK_ACCOUNT;
  }
  initializeWrapperLibrary(): void {
    return void 0;
  }
  setNavigationClient(): void {
    throw new Error('Method not implemented.');
  }
}
