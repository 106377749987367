import { EditButton } from 'components/base/IconButton';
import BookingModal from 'components/VacationOffers/BookingModal';
import {
  PriceChanges,
  BoardType,
  ProcessingStatus,
  PartnerStatus,
  PositionType,
  EditMyApplications_GetApplicationQuery,
} from 'gql/generated/types-and-hooks';
import { getFixedChargesForBoard } from 'helpers/priceCalculation';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '../FormSection';
import PriceTableEditable from './PriceTableEditable';
import { dateMonthFormat, dateMonthYearFormat } from 'helpers/dateFormats';
import { getDate, getFormattedDate } from 'helpers/dateHelper';

export const AssignedBookingEntry = ({
  isEditable,
  isEonBooking,
  application,
  assignedBooking,
  assignedOffer,
  onChangeBookingOffer,
  onChangePrices,
}: {
  isEditable: boolean;
  isEonBooking: boolean;
  application: EditMyApplications_GetApplicationQuery['application'];
  assignedBooking: {
    priority: number;
    board: BoardType;
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
  };
  assignedOffer: {
    name: string;
    house: { name: string };
  };
  onChangeBookingOffer: (bookingChanges: {
    summerHolidays: boolean;
    socialCreditPoints: boolean;
    eonBookingId: string;
    bookingDate: { arrival: Date; departure: Date };
  }) => void;
  onChangePrices: (change: PriceChanges) => void;
}): ReactElement | null => {
  const { t } = useTranslation();
  const [modelOpen, setModalOpen] = useState(false);
  const handleEdit = () => setModalOpen(true);
  const handleCancel = () => setModalOpen(false);
  const handleConfirm = (data: {
    arrival: Date;
    departure: Date;
    socialPoints: boolean;
    summer: boolean;
    eonBookingId: string;
  }) => {
    setModalOpen(false);
    onChangeBookingOffer({
      summerHolidays: data.summer,
      socialCreditPoints: data.socialPoints,
      eonBookingId: data.eonBookingId,
      bookingDate: {
        arrival: data.arrival,
        departure: data.departure,
      },
    });
  };

  if (application.bookingChanges == null || application.fixedCharges == null) {
    return null;
  }

  const arrival = getDate(application.bookingChanges.bookingDate.arrival);
  const departure = getDate(application.bookingChanges.bookingDate.departure);

  if (arrival == null || departure == null) {
    return null;
  }

  const charges = getFixedChargesForBoard({
    charges: application.fixedCharges,
    board: assignedBooking.board,
  });

  return (
    <div className="AssignedBookingEntry">
      <FormSection
        name={
          isEonBooking
            ? t('components.form.applicationForm.sectionTitleAssignedEonHouses')
            : t('components.form.applicationForm.sectionTitleAssignedHawHouses')
        }
      >
        <table className="mb-6 w-full">
          <thead>
            <tr>
              <th>{t('components.form.hawCatalogue.rank')}</th>
              <th>{t('components.form.hawCatalogue.house')}</th>
              <th>{t('components.form.hawCatalogue.period')}</th>
              <th>{t('components.form.hawCatalogue.board')}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{assignedBooking?.priority}</td>
              <td>{assignedOffer?.house.name}</td>
              <td>
                {`${dateMonthFormat.format(new Date(assignedBooking.arrival))} -
         ${dateMonthYearFormat.format(new Date(assignedBooking.departure))} ${
                  assignedOffer?.name
                }`}
              </td>
              <td>
                {t(
                  `components.form.pickBoardDropdown.${assignedBooking.board}`,
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="mb-6 w-full">
          <thead>
            <tr>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.arrival',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.departure',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.pointsCrediting',
                )}
              </th>
              <th>
                {t(
                  'components.VacationOffers.BookingDates.TableColumnHeaders.summerHolidays',
                )}
              </th>
              {isEonBooking && (
                <th>
                  {t(
                    'components.VacationOffers.BookingDates.TableColumnHeaders.eonBookingId',
                  )}
                </th>
              )}
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{getFormattedDate(arrival, dateMonthFormat)}</td>
              <td>{getFormattedDate(departure, dateMonthYearFormat)}</td>
              <td>
                {application.bookingChanges.socialCreditPoints
                  ? t('components.form.yesNoChoice.true')
                  : t('components.form.yesNoChoice.false')}
              </td>
              <td>
                {application.bookingChanges.summerHolidays
                  ? t('components.form.yesNoChoice.true')
                  : t('components.form.yesNoChoice.false')}
              </td>
              {isEonBooking && (
                <td>{application.bookingChanges.eonBookingId}</td>
              )}
              <td className="flex w-16 justify-between">
                {isEditable && (
                  <div className="flex gap-x-2">
                    <EditButton onClick={handleEdit} />
                    <BookingModal
                      open={modelOpen}
                      showEonBookingId={isEonBooking}
                      booking={{
                        summer: application.bookingChanges.summerHolidays,
                        socialPoints:
                          application.bookingChanges.socialCreditPoints,
                        arrival,
                        departure,
                        eonBookingId: application.bookingChanges.eonBookingId,
                        countervailingBenefit: {
                          childOld: charges.house.benefitOldChild,
                          childYoung: charges.house.benefitYoungChild,
                          firstAdult: charges.house.benefitAdult1,
                          secondAdult: charges.house.benefitAdult2,
                          perNight: charges.house.countervailingBenefitPerNight,
                        },
                      }}
                      onCancel={handleCancel}
                      onConfirm={handleConfirm}
                    />
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {application.fixedCharges && application.fixedCharges.house ? (
          <PriceTableEditable
            isEditable={isEditable}
            position={
              application.companyPosition.position
                ? application.companyPosition.position
                : PositionType.Employee
            }
            calculateVacationPackage={true}
            markBOnPassport={application.disability.markBOnPassport}
            timespan={{
              arrival: arrival,
              departure: departure,
            }}
            travelers={{
              relationshipStatus: application.partner.status
                ? application.partner.status
                : PartnerStatus.Without,
              minorChildren: application.furtherGuests.familyChildrenUnderage,
              otherMinors: application.furtherGuests.nonFamilyChildrenUnderage,
            }}
            charges={charges}
            changes={
              application.priceChanges ? application.priceChanges : undefined
            }
            wasConfirmedAndIsCanceled={
              application.processingStatus ===
                ProcessingStatus.CancelledConfirmed ||
              application.processingStatus ===
                ProcessingStatus.FinishedCancelledConfirmed
            }
            cancellationDate={
              application.cancellationDate
                ? new Date(application.cancellationDate)
                : undefined
            }
            isEonBooking={isEonBooking}
            onChangesChanged={onChangePrices}
          />
        ) : (
          <div>FixedCharges not set</div>
        )}
      </FormSection>
    </div>
  );
};

export default AssignedBookingEntry;
