import { dateMonthFormat, dateMonthYearFormat } from 'helpers/dateFormats';
import { forwardRef, SelectHTMLAttributes } from 'react';
import { TFunction } from 'react-i18next';

type OfferDropdownProps = SelectHTMLAttributes<HTMLSelectElement> & {
  initValue: string;
  options: {
    name: string;
    active: boolean;
    bookings: { id: string; from: Date; to: Date; active: boolean }[];
  }[];
  t: TFunction<'translation', undefined>;
};

export const OfferDropdown = forwardRef<HTMLSelectElement, OfferDropdownProps>(
  ({ options, t, initValue, ...props }: OfferDropdownProps, ref) => {
    const availableOffersLength = options.reduce(
      (sum, current) => sum + current.bookings.length,
      0,
    );

    const bookings: {
      id: string;
      active: boolean;
      label: string;
      from: Date;
      to: Date;
    }[] = options.flatMap(option =>
      option.bookings.map(booking => {
        return {
          id: booking.id,
          active: option.active && booking.active,
          from: booking.from,
          to: booking.to,
          label: `${dateMonthFormat.format(booking.from)} -
               ${dateMonthYearFormat.format(booking.to)} ${option.name}`,
        };
      }),
    );

    return (
      <select {...{ ...props, ref }}>
        {availableOffersLength > 0 ? (
          <>
            <option key={'default'} value={''}>
              {t('components.form.hawBookingEntry.bookingDropdown.placeholder')}
            </option>
            {bookings
              .filter(booking => booking.active || booking.id === initValue)
              .sort((a, b) => {
                return a.from > b.from
                  ? 1
                  : a.from === b.from
                  ? a.to > b.to
                    ? 1
                    : -1
                  : -1;
              })
              .map(booking => {
                return (
                  <option key={booking.id} value={booking.id}>
                    {`${
                      !booking.active
                        ? t(
                            'components.form.hawBookingEntry.noPlacesLeftMarker',
                          )
                        : ''
                    } ${booking.label}`}
                  </option>
                );
              })}
          </>
        ) : (
          <option key={'default'} value={''}>
            {t('components.form.hawBookingEntry.bookingDropdown.unselect')}
          </option>
        )}
      </select>
    );
  },
);
OfferDropdown.displayName = 'OfferDropdown';

export default OfferDropdown;
