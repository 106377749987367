import config from 'config/config';
import { memo, ReactElement, useContext } from 'react';
import { UserInfoContext } from '../context/UserInfoContext';
import { hasRole, isEonActiveAllowed } from 'helpers/permissionsHelper';
import { TaccountingAreas } from 'interfaces/accountingAreas';
import { NavItem } from './NavItem';

export const MainNavigation = memo(function MainNavigation(): ReactElement {
  const { localRoutes } = config;
  const { isAdmin, roles, accountingArea } = useContext(UserInfoContext);
  return (
    <nav className="navbar border-b-2 px-12">
      <ul className="flex list-none flex-wrap items-center justify-around">
        <NavItem
          to={localRoutes.information}
          label="layouts.mainLayout.menu.information"
        />
        <NavItem
          to={localRoutes.myApplications.index}
          label="layouts.mainLayout.menu.myApplications"
        />
        {isAdmin && (
          <NavItem
            to={localRoutes.employeeApplications.index}
            label="layouts.mainLayout.menu.employeeApplications"
          />
        )}
        <NavItem
          to={localRoutes.vacationOffers.index}
          label="layouts.mainLayout.menu.holiday"
        />
        <NavItem
          to={localRoutes.haw.index}
          label="layouts.mainLayout.menu.houses"
        />
        <NavItem
          to={localRoutes.events}
          label="layouts.mainLayout.menu.healthDays"
        />
        {isEonActiveAllowed({
          isAdmin,
          accountingArea: accountingArea as TaccountingAreas,
        }) && (
          <NavItem
            to={localRoutes.eon.index}
            label="layouts.mainLayout.menu.eon"
          />
        )}
        {hasRole(roles, 'BOARD_BILLING') && (
          <NavItem
            to={localRoutes.boardBilling}
            label="layouts.mainLayout.menu.boardBilling"
          />
        )}
        {isAdmin && (
          <NavItem
            to={localRoutes.settings.email.templates.index}
            label="layouts.mainLayout.menu.settings"
          />
        )}
      </ul>
    </nav>
  );
});
