import React, { HTMLProps, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from './CheckboxInput';
import TextArea from './TextArea';
import TextInput from './TextInput';

type EditableInputElementProps<T> = {
  editMode: boolean;
  nonEditableValue: T;
};

type EditableInputProps = HTMLProps<HTMLInputElement> &
  EditableInputElementProps<string> & {
    type?: 'text' | 'url';
  };

const addHttps = (url: string) => {
  if (url && !url.startsWith('https://')) return `https://${url}`;
  return url;
};

export const EditableInput = React.forwardRef<
  HTMLInputElement,
  EditableInputProps
>(
  (
    { editMode, nonEditableValue, type = 'text', ...props }: EditableInputProps,
    ref,
  ) => {
    return (
      <div className="EditableInput">
        {editMode ? (
          <TextInput {...{ ...props, ref }} />
        ) : (
          <div>
            {type === 'url' ? (
              <Url url={nonEditableValue?.toString() || ''}></Url>
            ) : (
              nonEditableValue || ''
            )}
          </div>
        )}
      </div>
    );
  },
);
EditableInput.displayName = 'EditableInput';

export const Url = ({
  url,
  text,
}: {
  url: string;
  text?: string;
}): ReactElement => (
  <a
    className="text-primary hover:underline"
    href={addHttps(url)}
    target="_blank"
    rel="noreferrer"
  >
    {text || url}
  </a>
);

type EditableTextAreaProps = HTMLProps<HTMLTextAreaElement> &
  EditableInputElementProps<string>;

export const EditableTextArea = React.forwardRef<
  HTMLTextAreaElement,
  EditableTextAreaProps
>(({ editMode, nonEditableValue, ...props }, ref) => {
  return (
    <div className="EditableTextArea">
      {editMode ? (
        <TextArea
          {...{
            ...props,
            ref,
          }}
        />
      ) : (
        <div className="whitespace-pre-wrap">{nonEditableValue || ''}</div>
      )}
    </div>
  );
});
EditableTextArea.displayName = 'EditableTextArea';

type EditableCheckboxProps = HTMLProps<HTMLInputElement> &
  EditableInputElementProps<boolean> & {
    label?: string;
  };

export const EditableCheckbox = React.forwardRef<
  HTMLInputElement,
  EditableCheckboxProps
>(({ editMode, nonEditableValue, label, ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <div className="EditableCheckbox">
      {editMode ? (
        <CheckboxInput {...{ ...props, ref }} label={label} />
      ) : (
        <div>
          {t(`components.form.yesNoChoice.${nonEditableValue === true}`)}
        </div>
      )}
    </div>
  );
});
EditableCheckbox.displayName = 'EditableCheckbox';

type EditableSelectProps = HTMLProps<HTMLSelectElement> &
  EditableInputElementProps<string> & {
    options: { value: string | number; displayValue: string; key: string }[];
  };

export const EditableSelect = React.forwardRef<
  HTMLSelectElement,
  EditableSelectProps
>(({ editMode, options, nonEditableValue, ...props }, ref) => {
  return (
    <div className="EditableSelect">
      {editMode ? (
        <select {...{ ...props, ref }}>
          {options.map(({ value, displayValue, key }) => (
            <option key={key} value={value}>
              {displayValue}
            </option>
          ))}
        </select>
      ) : (
        <div>
          {options.find(o => o.value === nonEditableValue)?.displayValue || ''}
        </div>
      )}
    </div>
  );
});
EditableSelect.displayName = 'EditableSelect';
