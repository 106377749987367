import LoadingOrError from 'components/base/LoadingOrError';
import {
  useEmailTemplates_GetEonConfirmationTemplateQuery,
  useEmailTemplates_UpdateFreeTextMutation,
  refetchEmailTemplates_GetEonConfirmationTemplateQuery,
  FreeTextId,
} from 'gql/generated/types-and-hooks';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationTemplate,
  OnSubmitTemplateForm,
} from './BaseConfirmationTemplate';

export const EonConfirmationTemplate = (): ReactElement => {
  const { data, loading, error } =
    useEmailTemplates_GetEonConfirmationTemplateQuery();
  const { t } = useTranslation();
  const [updateEmailFreetext] = useEmailTemplates_UpdateFreeTextMutation({
    refetchQueries: [refetchEmailTemplates_GetEonConfirmationTemplateQuery()],
    context: {
      debounceKey: 'useEmailTemplates_UpdateFreeTextMutation',
      notification: {
        success: t(
          'components.settings.email.templates.notifications.update.success',
        ),
        error: t(
          'components.settings.email.templates.notifications.update.error',
        ),
      },
    },
  });
  const onSubmit: OnSubmitTemplateForm = useCallback(
    async ({ additionalFreetext, freeText, onComplete, subject }) => {
      await updateEmailFreetext({
        onCompleted: () => onComplete(),
        variables: {
          freeTexts: [
            {
              text: additionalFreetext,
              id: FreeTextId.ConfirmationEonAdditionalFreetext,
            },
            {
              id: FreeTextId.ConfirmationEonSubject,
              text: subject,
            },
            {
              text: freeText,
              id: FreeTextId.ConfirmationEonFreetext,
            },
          ],
        },
      });
    },
    [updateEmailFreetext],
  );
  return (
    <div className="EonConfirmationTemplate">
      <LoadingOrError {...{ loading, error }}>
        <ConfirmationTemplate
          onSubmit={onSubmit}
          templateData={data?.eonConfirmationEmailTemplate}
        />
      </LoadingOrError>
    </div>
  );
};
