import { Boards } from 'api/mappers';
import { BoardType } from 'gql/generated/types-and-hooks';
export function mapBoardPropertynameToEnum(
  propertyName: '' | Boards,
): BoardType {
  switch (propertyName) {
    case 'halfBoard':
      return BoardType.HalfBoard;
    case 'fullBoard':
      return BoardType.FullBoard;
    case 'overnightBreakfast':
      return BoardType.OvernightBreakfast;
    case 'selfSupply':
    default:
      return BoardType.SelfSupply;
  }
}
