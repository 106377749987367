import { calculatePrice } from 'moin-ferienwerk-shared';
import { useTranslation } from 'react-i18next';
import { currencyNumberFormat } from 'helpers/util';
import { Charges } from 'moin-ferienwerk-shared/dist/types/priceCalculation';
import { PartnerStatus, PositionType } from 'gql/generated/types-and-hooks';
import { getPositionType } from 'helpers/priceCalculation';
import { PartnerStatus as PartnerStatusShared } from 'moin-ferienwerk-shared/dist/types/priceCalculation';
import { ReactElement } from 'react';

const getPriceOrDefault = (price: number) =>
  isNaN(price) ? '---' : `${currencyNumberFormat.format(price)}`;

export const PriceTable = ({
  position,
  markBOnPassport,
  calculateVacationPackage,
  timespan,
  charges,
  travelers,
}: {
  position: PositionType;
  markBOnPassport: boolean;
  calculateVacationPackage: boolean;
  timespan?: {
    arrival: string;
    departure: string;
  };
  charges?: Charges;
  travelers?: {
    minorChildren: { dateOfBirth: number }[];
    otherMinors: { dateOfBirth: number }[];
    relationshipStatus: PartnerStatus;
  };
}): ReactElement => {
  const { t } = useTranslation();

  if (travelers == null) {
    return <>Missing travelers data</>;
  }

  let costs: {
    pecuniaryAdvantage: number;
    subsistenceExpenses: number;
    extraCosts: number;
    tax: number;
  } = {
    pecuniaryAdvantage: NaN,
    extraCosts: NaN,
    subsistenceExpenses: NaN,
    tax: NaN,
  };

  if (charges) {
    const calculated = calculatePrice({
      calculateVacationPackage: calculateVacationPackage,
      position: getPositionType(position),
      markBOnPassport: markBOnPassport,
      timespan: timespan
        ? {
            arrival: new Date(timespan.arrival),
            departure: new Date(timespan.departure),
          }
        : undefined,
      travelers: {
        ...travelers,
        relationshipStatus:
          travelers.relationshipStatus === PartnerStatus.Married
            ? PartnerStatusShared.MARRIED
            : PartnerStatusShared.WITHOUT,
      },
      charges: charges,
    });
    costs = calculated;
  }

  const subsistenceExpenses_extraCosts =
    costs.subsistenceExpenses + (costs.extraCosts ? costs.extraCosts : 0);

  return (
    <div className="PriceTable grid grid-cols-12">
      <table className="col-span-11 col-start-2">
        <colgroup>
          <col style={{ width: '27%' }} />
          <col style={{ width: '45.5%' }} />
          <col style={{ width: '27.5%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>{t('components.form.priceTable.costOverview')}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('components.form.priceTable.subsistenceExpenses')}</td>
            <td>{t('components.form.priceTable.netDeduction')}</td>
            <td>{getPriceOrDefault(subsistenceExpenses_extraCosts)}</td>
          </tr>
          <tr className="bg-gray-100">
            <td>{t('components.form.priceTable.tax')}</td>
            <td>{t('components.form.priceTable.netDeduction')}</td>
            <td>{getPriceOrDefault(costs.tax)}</td>
          </tr>
          <tr>
            <td>{t('components.form.priceTable.pecuniaryAdvantage')}</td>
            <td>{t('components.form.priceTable.taxSocialTax')}</td>
            <td>{getPriceOrDefault(costs.pecuniaryAdvantage)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PriceTable;
