import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

export const useReactTooltip = (): {
  rebuild: typeof ReactTooltip.rebuild;
  show: typeof ReactTooltip.show;
  hide: typeof ReactTooltip.hide;
} => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return {
    rebuild: ReactTooltip.rebuild,
    show: ReactTooltip.show,
    hide: ReactTooltip.hide,
  };
};

export default useReactTooltip;
