import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import TextInput from 'components/applicationForm/TextInput';
import { TableCell } from 'components/common/TableElements';
import InputCell from 'components/tables/InputCell';
import { BookingDate, PriceChange } from 'gql/generated/types-and-hooks';
import { dateMonthYearFormat } from 'helpers/dateFormats';
import { currencyNumberFormat } from 'helpers/util';
import useReactTooltip from 'hooks/useReactTooltip';
import { memo, ReactElement, useCallback, useMemo, useState } from 'react';

const getPriceOrDefault = (price: number) =>
  isNaN(price) ? '---' : `${currencyNumberFormat.format(price)}`;

const bookingDateText = (bookingDate: BookingDate) => {
  const arrival = bookingDate.arrival
    ? dateMonthYearFormat.format(new Date(bookingDate.arrival))
    : '---';
  const departure = bookingDate.departure
    ? dateMonthYearFormat.format(new Date(bookingDate.departure))
    : '---';

  return `${arrival} - ${departure}`;
};

export type BoardBillingRowProps = {
  applicationId: string;
  houseName: string;
  vacationOfferName: string;
  employeeName: string;
  bookingDate: BookingDate;
  boardPriceChange: PriceChange;
  onUpdate: (change: PriceChange) => void;
};

export const BoardBillingRow = memo(function BoardBillingRow({
  houseName,
  vacationOfferName,
  employeeName,
  bookingDate,
  boardPriceChange,
  onUpdate,
}: BoardBillingRowProps): ReactElement {
  useReactTooltip();
  // const [isEditable, setIsEditable] = useState(false);
  const [edit, enterEditMode, leaveEditmode] = useEditMode(false);
  const [change, setChange] = useState<PriceChange>(boardPriceChange);
  const label = useMemo(() => bookingDateText(bookingDate), [bookingDate]);

  const handleConfirm = useCallback(
    (newChange: PriceChange) => {
      enterEditMode();
      onUpdate(newChange);
    },
    [enterEditMode, onUpdate],
  );

  const handleClose = useCallback(() => {
    leaveEditmode();
    setChange(boardPriceChange);
  }, [boardPriceChange, leaveEditmode]);

  return (
    <tr className="border-b border-grey">
      <TableCell>{houseName}</TableCell>
      <TableCell>{vacationOfferName}</TableCell>
      <TableCell>{employeeName}</TableCell>
      <TableCell>{label}</TableCell>
      <InputCell
        edit={edit}
        value={change.increment}
        disabled={false}
        onChange={value =>
          setChange(c => ({
            ...c,
            increment: value,
          }))
        }
      />
      <InputCell
        edit={edit}
        value={change.decrement}
        disabled={false}
        onChange={value =>
          setChange(c => ({
            ...c,
            decrement: value,
          }))
        }
      />
      <TableCell align="right">
        {getPriceOrDefault(change.increment - change.decrement)}
      </TableCell>
      <TableCell>
        <div className="w-52">
          {edit ? (
            <TextInput
              value={change.remark}
              onChange={e => {
                const target = e.target as HTMLInputElement;
                setChange(c => ({
                  ...c,
                  remark: target.value ?? c.remark,
                }));
              }}
            />
          ) : (
            <div
              className="max-w-sm truncate whitespace-normal break-normal"
              data-tip={change.remark}
            >
              {change.remark}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <EditControls
          editMode={edit}
          onEdit={enterEditMode}
          onCancel={handleClose}
          onConfirm={() => handleConfirm(change)}
        />
      </TableCell>
    </tr>
  );
});
