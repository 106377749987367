import { sequence } from 'helpers/util';
import React, { SelectHTMLAttributes } from 'react';

type NumberDropdownProps = SelectHTMLAttributes<HTMLSelectElement> & {
  min?: number;
  max: number;
  prefix?: string;
};

export const NumberDropdown = React.forwardRef<
  HTMLSelectElement,
  NumberDropdownProps
>(({ min = 0, max, prefix = '', ...props }: NumberDropdownProps, ref) => {
  return (
    <div className="NumberDropdown">
      <select {...{ ...props, ref }}>
        {sequence(min, max, 1).map(number => (
          <option key={number} value={number}>
            {`${prefix}${number}`}
          </option>
        ))}
      </select>
    </div>
  );
});
NumberDropdown.displayName = 'NumberDropdown';
