import {
  EditMyApplications_GetApplicationQuery,
  ApplicationFor,
} from 'gql/generated/types-and-hooks';

export const chooseGuest = ({
  guestInTemplate,
  applicant,
  applicantInTemplate,
}: {
  applicant: EditMyApplications_GetApplicationQuery['application']['applicant'];
  applicantInTemplate: EditMyApplications_GetApplicationQuery['application']['applicant'];
  guestInTemplate: EditMyApplications_GetApplicationQuery['application']['guest'];
}): {
  guest: EditMyApplications_GetApplicationQuery['application']['guest'];
  applicationFor: ApplicationFor;
} => {
  if (guestInTemplate?.email == undefined) {
    // applicationTemplate has no guest

    if (applicant.email === applicantInTemplate.email) {
      return {
        guest: undefined,
        applicationFor: ApplicationFor.Me,
      };
    }
    return {
      guest: {
        ...applicantInTemplate,
        __typename: 'Guest',
      },
      applicationFor: ApplicationFor.OtherPerson,
    };
  } else {
    // applicationTemplate has guest

    if (applicant.email === guestInTemplate?.email) {
      return {
        guest: undefined,
        applicationFor: ApplicationFor.Me,
      };
    }

    return {
      guest: guestInTemplate,
      applicationFor: ApplicationFor.OtherPerson,
    };
  }
};
