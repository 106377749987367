import { DragEvent, PropsWithChildren, ReactElement } from 'react';

/* Component taken from Messgeräte Strom */

type FileDropAreaProps = {
  onDrop: (files: File[]) => void;
  className?: string;
};

export function FileDropArea({
  onDrop,
  className,
  children,
}: PropsWithChildren<FileDropAreaProps>): ReactElement {
  return (
    <div
      className={'border border-dashed border-gray-500 ' + className ?? ''}
      onDrop={e => {
        const files = onDropHandler(e);
        if (files) {
          onDrop(files);
        }
        removeDragData(e);
      }}
      onDragOver={onDragOverhandler}
      onDragEnter={getDragEnterOrExithandler(true)}
      onDragExit={getDragEnterOrExithandler(false)}
    >
      {children}
    </div>
  );
}

function getDragEnterOrExithandler(enter: boolean) {
  return (e: DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();

    if (enter) {
      e.currentTarget.style.borderStyle = 'solid';
    } else {
      e.currentTarget.style.borderStyle = '';
    }
  };
}

function onDragOverhandler(e: DragEvent<HTMLDivElement>) {
  // Prevent default behavior (Prevent file from being opened)
  e.preventDefault();
}

function onDropHandler(e: DragEvent<HTMLDivElement>): File[] | null {
  // Prevent default behavior (Prevent file from being opened)
  e.preventDefault();

  const filelist: File[] = [];

  if (e.dataTransfer == null) {
    return filelist;
  }

  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (e.dataTransfer.items[i].kind === 'file') {
        const file = e.dataTransfer.items[i].getAsFile();
        if (file) {
          filelist.push(file);
        }
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = e.dataTransfer.files[i];
      filelist.push(file);
    }
  }

  return filelist;
}

function removeDragData(e: DragEvent<HTMLDivElement>) {
  if (e.dataTransfer == null) {
    return;
  }

  // Pass event to removeDragData for cleanup
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to remove the drag data
    e.dataTransfer.items.clear();
  } else {
    // Use DataTransfer interface to remove the drag data
    e.dataTransfer.clearData();
  }

  // Reset the drag over style
  getDragEnterOrExithandler(false)(e);
}
