export const dateMonthFormat = Intl.DateTimeFormat('de', {
  day: '2-digit',
  month: '2-digit',
  timeZone: 'Europe/Berlin',
});

export const dateMonthYearFormat = Intl.DateTimeFormat('de', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'Europe/Berlin',
});

export const convertDateToUTC = (date: Date): Date => {
  const timezoneOffset = date.getTimezoneOffset();
  const d = new Date(date);
  d.setMinutes(d.getMinutes() + -1 * timezoneOffset);
  return d;
};

export const convertDateFromUTC = (date: Date): Date => {
  const timezoneOffset = date.getTimezoneOffset();
  const d = new Date(date);
  d.setMinutes(d.getMinutes() + timezoneOffset);
  return d;
};
