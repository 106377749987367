import SortButton from 'components/base/SortButton';
import { SortingOrder } from 'gql/generated/types-and-hooks';
import { FunctionComponent, ReactElement, PropsWithChildren } from 'react';
import { ColumnSorting } from 'types/ColumnSorting';

type Sorting<T> = ColumnSorting<T>;
type SortableHeaderProps<T> = {
  columnTitle: string;
  column: T;
  sorting: Sorting<T>;
  onSort: (sorting: Sorting<T>) => void;
};
export const SortableHeader = <T,>({
  columnTitle,
  column,
  sorting,
  onSort,
}: SortableHeaderProps<T>): ReactElement => {
  const order = sorting.column === column ? sorting.order : null;
  return (
    <TableHeader>
      <SortButton
        label={columnTitle}
        order={order ? order : SortingOrder.Ascending}
        onClick={(order: SortingOrder) => onSort({ column, order })}
        active={sorting.column === column}
      />
    </TableHeader>
  );
};

export const TableCell = ({
  children,
  align = 'left',
}: PropsWithChildren<{
  align?: 'left' | 'center' | 'right';
}>): ReactElement => <td className={`text-${align}`}>{children}</td>;

export const TableHeader: FunctionComponent = ({ children }): ReactElement => (
  <th>{children}</th>
);
