import { CheckButton, CloseButton } from 'components/base/IconButton';
import { useKeyForm } from 'context/KeyFormContext';
import { uuid } from 'helpers/util';
import { memo, ReactElement, useState } from 'react';
import EditKeyFormButton from './EditKeyFormButton';

export const useEditMode = (
  initialState: boolean,
): [boolean, () => void, () => void] => {
  const [editMode, setEditMode] = useState(initialState);
  const [editKey] = useState(uuid());
  const { clearKey, setKey } = useKeyForm();
  const enterEditMode = () => {
    setEditMode(true);
    setKey(editKey);
  };
  const leaveEditMode = () => {
    setEditMode(false);
    clearKey();
  };
  return [editMode, enterEditMode, leaveEditMode];
};

type EditControlProps = {
  editMode: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onEdit: () => void;
};

const EditControls = ({
  editMode,
  onConfirm = () => null,
  onCancel = () => null,
  onEdit = () => null,
}: EditControlProps): ReactElement => {
  return (
    <div className="EditControls">
      {editMode ? (
        <div className="flex gap-2">
          <CheckButton onClick={onConfirm} />
          <CloseButton onClick={onCancel} />
        </div>
      ) : (
        <EditKeyFormButton onClick={onEdit} />
      )}
    </div>
  );
};

export default memo(EditControls);
