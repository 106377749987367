import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { KeyFormArea } from '../../context/KeyFormContext';
import UploadVacationOfferFile from './UploadVacationOfferFile';
import { getString } from '../../helpers/util';
import {
  FileType,
  refetchVacationOfferForm_GetVacationOfferQuery,
  useVacationOfferAttachments_DeleteFileMutation,
  useVacationOfferAttachments_UpdateDataFileMutation,
  useVacationOfferAttachments_UpdateFileMetadataMutation,
} from 'gql/generated/types-and-hooks';
import { readFile } from 'helpers/fileHelper';
import { AttachmentTable } from 'components/AttachmentTable/AttachmentTable';

const VacationOfferAttachments = ({
  offer,
}: {
  offer: {
    id: string;
    files: {
      id: string;
      type: string;
      description?: string | null;
      path: string;
      name: string;
      order?: number | null;
    }[];
    house: {
      files: {
        id: string;
        type: string;
        description?: string | null;
        path: string;
        name: string;
        order?: number | null;
      }[];
    };
  };
}): ReactElement => {
  const { t } = useTranslation();
  const [deleteFile] = useVacationOfferAttachments_DeleteFileMutation({
    refetchQueries: [
      refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
    ],
    context: {
      debounceKey: 'useVacationOfferAttachments_DeleteFileMutation',
      notification: {
        success: t(
          'components.attachment.notifications.deleteAttachment.success',
        ),
        error: t('components.attachment.notifications.deleteAttachment.error'),
      },
    },
  });
  const [updateAttachment] = useVacationOfferAttachments_UpdateDataFileMutation(
    {
      refetchQueries: [
        refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
      ],
      context: {
        debounceKey: 'useVacationOfferAttachments_UpdateDataFileMutation',
        notification: {
          success: t(
            'components.attachment.notifications.updateAttachment.success',
          ),
          error: t(
            'components.attachment.notifications.updateAttachment.error',
          ),
        },
      },
    },
  );
  const [updateFileMetadata] =
    useVacationOfferAttachments_UpdateFileMetadataMutation({
      refetchQueries: [
        refetchVacationOfferForm_GetVacationOfferQuery({ id: offer.id }),
      ],
      context: {
        debounceKey: 'useVacationOfferAttachments_UpdateFileMetadataMutation',
        notification: {
          success: t(
            'components.attachment.notifications.updateFileMetadata.success',
          ),
          error: t(
            'components.attachment.notifications.updateFileMetadata.error',
          ),
        },
      },
    });

  const handleDelete = (id: string) =>
    deleteFile({
      variables: {
        id,
      },
    });

  const { isAdmin } = useContext(UserInfoContext);

  const handleUpload = async (id: string, file: File) => {
    const result = await readFile(file);
    updateAttachment({
      variables: {
        id,
        file: {
          name: result.name,
          content: result.content,
          type: FileType.Attachment,
        },
      },
    });
  };

  const handleDescriptionChange = (id: string, newDescription: string) => {
    updateFileMetadata({
      variables: {
        id,
        file: {
          description: newDescription,
        },
      },
    });
  };

  const offerFiles = offer?.files || [];
  const houseFiles =
    offer?.house?.files?.filter(x => x.type === 'ATTACHMENT') || [];
  const hasAttachments = offerFiles.length + houseFiles.length > 0;

  return (
    <div className="VacationOfferAttachments">
      <div className="mb-6 flex">
        <div className="flex-auto">
          <h2 className="font-medium">
            {t('components.VacationOffers.VacationOfferAttachments.title')}
          </h2>
        </div>
        <div>
          <KeyFormArea>
            {isAdmin && <UploadVacationOfferFile offer={offer} />}
          </KeyFormArea>
        </div>
      </div>

      {hasAttachments ? (
        <AttachmentTable
          attachments={[...houseFiles, ...offerFiles].map(x => ({
            ...x,
            description: getString(x.description),
          }))}
          editable={isAdmin}
          onUpload={handleUpload}
          onDelete={handleDelete}
          onDescriptionChanged={handleDescriptionChange}
        />
      ) : (
        <div>
          {t('components.VacationOffers.VacationOfferAttachments.noFiles')}
        </div>
      )}
    </div>
  );
};

export default VacationOfferAttachments;
