import { SortingOrder } from 'gql/generated/types-and-hooks';
import useReactTooltip from 'hooks/useReactTooltip';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { IconName, Icon } from './Icon';

type SortButtonProps = {
  label: string;
  order: SortingOrder;
  onClick: (order: SortingOrder) => void;
  active: boolean;
};
export const SortButton = ({
  label,
  order,
  onClick,
  active,
}: SortButtonProps): ReactElement => {
  const tooltip = useTranslation().t(
    `components.IconButton.tooltip.${
      order === SortingOrder.Descending ? 'asc' : 'desc'
    }`,
  );
  useReactTooltip();
  const icon: IconName =
    SortingOrder.Ascending === order ? 'ChevronUpIcon' : 'ChevronDownIcon';

  return (
    <button
      onClick={() => {
        onClick(
          order === SortingOrder.Descending
            ? SortingOrder.Ascending
            : SortingOrder.Descending,
        );
        ReactTooltip.hide();
      }}
      className="group"
      data-tip={tooltip}
    >
      <div className="flex items-center gap-x-2">
        {label}
        <Icon
          icon={icon}
          className={`opacity-0 ${
            active ? 'opacity-100' : 'group-hover:opacity-100'
          }`}
        />
      </div>
    </button>
  );
};

export default SortButton;
