import {
  EditableInput,
  EditableTextArea,
} from 'components/applicationForm/editableInputs';
import EditControls, {
  useEditMode,
} from 'components/applicationForm/EditControls';
import { ValidationFeedback } from 'components/applicationForm/Validatable';
import { AvailableCharacters } from 'components/base/AvailableCharacters';
import {
  FormGrid,
  FormKey,
  FormValue,
  FormControl,
} from 'components/base/FormLayout';
import {
  NonEditableText,
  NonEditableTextArea,
} from 'components/base/NonEditableText';
import { ConfirmationEmailTemplate } from 'gql/generated/types-and-hooks';
import { isEmpty } from 'helpers/util';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AVAILABLE_TEXT_AREA_CHARACTERS,
  AVAILABLE_TEXT_INPUT_CHARACTERS,
} from './common';

export type OnSubmitTemplateForm = (
  data: { additionalFreetext: string; freeText: string; subject: string } & {
    onComplete: () => void;
  },
) => Promise<void>;

export const ConfirmationTemplate = ({
  templateData,
  onSubmit,
}: {
  templateData: ConfirmationEmailTemplate | undefined;
  onSubmit: OnSubmitTemplateForm;
}): ReactElement => {
  const { t } = useTranslation();

  const [editMode, enterEditMode, leaveEditMode] = useEditMode(false);

  const {
    additionalFreetext = '',
    bookingDetails = '',
    freeText = '',
    salutation = '',
    signature = { __typename: 'Signature', managementBoard: '', text: '' },
    subject = '',
    url = '',
  } = templateData || {};

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors: validationErrors },
    watch,
  } = useForm({
    defaultValues: {
      subject,
      freeText,
      additionalFreetext,
    },
  });
  const {
    subject: currentSubject,
    freeText: currentFreeText,
    additionalFreetext: currentAdditionalFreetext,
  } = watch();

  useEffect(() => {
    reset({ additionalFreetext, freeText, subject });
  }, [additionalFreetext, freeText, reset, subject, templateData]);

  const onCancel = useCallback(() => {
    leaveEditMode();
    reset({ subject, additionalFreetext, freeText });
  }, [additionalFreetext, freeText, subject, leaveEditMode, reset]);

  const submitHandler = useCallback(
    async ({ additionalFreetext, freeText, subject }) => {
      await onSubmit({
        onComplete: () => leaveEditMode(),
        additionalFreetext,
        freeText,
        subject,
      });
    },
    [leaveEditMode, onSubmit],
  );
  const onConfirm = useMemo(
    () => handleSubmit(submitHandler),
    [handleSubmit, submitHandler],
  );

  return (
    <div>
      <FormGrid>
        <FormKey label={t('components.settings.email.templates.subject') + ':'}>
          <FormValue>
            <ValidationFeedback error={validationErrors.subject}>
              <EditableInput
                editMode={editMode}
                nonEditableValue={subject}
                maxLength={AVAILABLE_TEXT_INPUT_CHARACTERS}
                {...register('subject', {
                  validate: v =>
                    !isEmpty(v) ||
                    (t(
                      'components.settings.email.templates.subjectValidationHint',
                    ) as string),
                })}
              />
              <AvailableCharacters
                current={currentSubject.length}
                maxLength={AVAILABLE_TEXT_INPUT_CHARACTERS}
                visible={editMode}
              />
            </ValidationFeedback>
          </FormValue>
        </FormKey>
        <FormControl>
          <EditControls
            editMode={editMode}
            onEdit={enterEditMode}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        </FormControl>
        <FormKey label={t('components.settings.email.templates.content') + ':'}>
          <FormValue>
            <NonEditableText>{salutation}</NonEditableText>
          </FormValue>
          <FormValue>
            <EditableTextArea
              editMode={editMode}
              nonEditableValue={freeText}
              maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
              {...register('freeText')}
            />
            <AvailableCharacters
              current={currentFreeText.length}
              maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
              visible={editMode}
            />
          </FormValue>
          <FormValue>
            <NonEditableTextArea>{bookingDetails}</NonEditableTextArea>
          </FormValue>
          <FormValue>
            <EditableTextArea
              {...register('additionalFreetext')}
              editMode={editMode}
              nonEditableValue={additionalFreetext}
              maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
            />
            <AvailableCharacters
              current={currentAdditionalFreetext.length}
              maxLength={AVAILABLE_TEXT_AREA_CHARACTERS}
              visible={editMode}
            />
          </FormValue>
          <FormValue>
            <NonEditableText>{url}</NonEditableText>
          </FormValue>
          <FormValue>
            <NonEditableTextArea>{signature.text}</NonEditableTextArea>
          </FormValue>
          <FormValue>
            <NonEditableTextArea>
              {signature.managementBoard}
            </NonEditableTextArea>
          </FormValue>
        </FormKey>
      </FormGrid>
    </div>
  );
};
