import { getInitials } from '../helpers/util';
import { useTranslation } from 'react-i18next';
import { logOut } from 'index';
import { logoutRequest } from 'authConfig';
import { Popover, Transition } from '@headlessui/react';
import { Icon } from './base/Icon';
import { ReactElement } from 'react';
import { useUserName } from 'context/UserInfoContext';

const UserMenu = (): ReactElement => {
  const { t } = useTranslation();
  const name = useUserName();

  return (
    <Popover className="relative">
      <Popover.Button className="h-9 w-9 rounded-full bg-primary text-white">
        {getInitials(name)}
      </Popover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute right-0 mt-2 w-48 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white shadow-xl">
          <div className="px-4 py-2 text-center font-medium">{name}</div>
          <button
            type="button"
            className="flex w-full items-center px-4 py-2"
            onClick={async () => await logOut(logoutRequest)}
          >
            <Icon icon={'LogoutIcon'} className="mr-2" />
            {t('components.userMenu.logout')}
          </button>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default UserMenu;
