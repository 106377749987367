import VacationOfferEntry from 'components/VacationOffers/VacationOfferEntry';
import NewVacationOfferButton from 'components/VacationOffers/NewVacationOfferButton';
import { useTranslation } from 'react-i18next';
import { ReactElement, useContext } from 'react';
import { UserInfoContext } from '../../context/UserInfoContext';
import { getAlphanumericSort } from 'helpers/util';
import LoadingOrError from 'components/base/LoadingOrError';
import { useIndexVacationOffers_GetVacationOffersListQuery } from 'gql/generated/types-and-hooks';

const alphanumericSort = getAlphanumericSort();

const IndexVacationOffers = (): ReactElement => {
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserInfoContext);
  const { loading, error, data } =
    useIndexVacationOffers_GetVacationOffersListQuery();
  const vacationOffers = data?.vacationOffersForIndex
    ? [...data.vacationOffersForIndex]
    : [];

  return (
    <div className="IndexVacationOffers">
      <LoadingOrError loading={loading} error={error}>
        <div className="mb-8 flex items-center justify-between">
          <p>{t('pages.vacationOffers.IndexVacationOffers.moreDetailsHint')}</p>
          {isAdmin && <NewVacationOfferButton />}
        </div>
        {vacationOffers
          .sort((a, b) => {
            const a_houseName = a.house?.name || '';
            const b_houseName = b.house?.name || '';
            return alphanumericSort(a_houseName + a.name, b_houseName + b.name);
          })
          .map(offer => (
            <VacationOfferEntry key={offer.id} offer={offer} />
          ))}
      </LoadingOrError>
    </div>
  );
};

export default IndexVacationOffers;
