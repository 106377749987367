import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import AccessTokenProvider from 'accessTokenProvider';
import MainLayout from 'layouts/main-layout';
import { PropsWithChildren, ReactElement } from 'react';
import { LoadingOverlayContextProvider } from 'context/LoadingOverlayContext';
import { NotificationProvider } from 'context/NotificationContext';

type AppProps = {
  pca: IPublicClientApplication;
};

const AuthBarrier = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  if (process.env['REACT_APP_SKIP_AUTH'] === 'true') {
    return <>{children}</>;
  }
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {children}
    </MsalAuthenticationTemplate>
  );
};
const Application = ({ pca }: AppProps): ReactElement => {
  return (
    <MsalProvider instance={pca}>
      <AuthBarrier>
        <LoadingOverlayContextProvider>
          <NotificationProvider>
            <AccessTokenProvider>
              <MainLayout />
            </AccessTokenProvider>
          </NotificationProvider>
        </LoadingOverlayContextProvider>
      </AuthBarrier>
    </MsalProvider>
  );
};

export default Application;
