import { BoardType } from 'gql/generated/types-and-hooks';
import { ReactElement, SelectHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PickBoardDropdownProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: BoardType[];
  name: string;
};

export const PickBoardDropdown = ({
  options,
  name,
}: PickBoardDropdownProps): ReactElement => {
  const { t } = useTranslation();
  const { getValues, register } = useFormContext();
  const baseTranslationPath = 'components.form.pickBoardDropdown';
  const placeholder = t('components.form.pickBoardDropdown.placeholder');
  const value = getValues(name) || '';

  return (
    <select
      className="w-full"
      {...register(name, {
        required: t<string>('pages.elements.formInputValidator.required'),
      })}
      value={value}
    >
      {options.length > 0 ? (
        <>
          <option key={'default'} value={''}>
            {placeholder}
          </option>
          {options.map(option => {
            return (
              <option key={option} value={option}>
                {t(`${baseTranslationPath}.${option}`)}
              </option>
            );
          })}
        </>
      ) : (
        <option key={'default'} value={''}>
          {t('components.form.pickBoardDropdown.unselect')}
        </option>
      )}
    </select>
  );
};

export default PickBoardDropdown;
