import { ReactElement } from 'react';
import { Icon, IconName } from './Icon';

interface ButtonProps {
  styleOptions?: Omit<StyleProps, 'disabled'>;
  disabled?: boolean;
  label: string;
  icon?: IconName;
  onClick: () => void;
}

interface StyleProps {
  disabled?: boolean;
  inverted?: boolean;
  wFull?: boolean;
  error?: boolean;
}

export const buttonStyle = ({
  disabled,
  inverted,
  wFull,
  error,
}: StyleProps): string => {
  let baseClasses = `flex items-center justify-center mx-0.5 px-6 py-2 text-sm font-medium rounded-3xl focus:outline-none shadow-sm focus:ring-2 focus:ring-offset-2`;
  if (disabled) {
    baseClasses += ` bg-gray-300 border-2 border-gray-200 cursor-not-allowed`;
  } else {
    baseClasses += ` cursor-pointer`;

    if (inverted) {
      baseClasses += ` bg-white text-primary border-2 border-primary hover:bg-gray-100 focus:ring-primary`;
    } else if (error) {
      baseClasses += ` bg-red-700 text-white hover:bg-opacity-90 focus:ring-red-700`;
    } else {
      baseClasses += ` bg-primary text-white hover:bg-opacity-90 focus:ring-primary`;
    }
  }
  if (wFull) {
    baseClasses += ` w-full`;
  }
  return baseClasses;
};

const Button = ({
  label,
  onClick,
  disabled,
  styleOptions,
  icon,
}: ButtonProps): ReactElement => {
  return (
    <button
      disabled={disabled}
      className={buttonStyle({
        ...styleOptions,
        disabled,
      })}
      onClick={() => onClick()}
    >
      <span className={icon ? `flex flex-row justify-between` : ''}>
        {icon && <Icon className="mr-2" icon={icon} />}
        {label}
      </span>
    </button>
  );
};

export default Button;
