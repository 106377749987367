import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard } from 'components/base/Wizard';
import { UploadStep } from './UploadStep';
import { MatchingStep } from './MatchingStep';
import {
  MatchingStepId,
  SocialCreditPointsStepId,
  UploadStepId,
  useIndexController,
} from './IndexController';
import { SocialCreditPointsStep } from './SocialCreditPointsStep';

export function ImportEon(): ReactElement {
  const { t } = useTranslation();

  const {
    upload,
    matching,
    createEonOffersResult,
    navigateBack,
    handleFinish,
    handleUpload,
    socialCreditPoints,
  } = useIndexController();

  return (
    <Wizard
      header={t('components.VacationOffers.import.header')}
      steps={[
        {
          id: UploadStepId,
          backButtonLabel: '',
          nextButtonLabel: t(
            'components.VacationOffers.import.steps.upload.nextButton',
          ),
          nextDisabled:
            !upload.data?.entries.length ||
            upload.error != null ||
            upload.hasInvalidEntries ||
            upload.loading,
        },
        {
          id: MatchingStepId,
          backButtonLabel: t(
            'components.VacationOffers.import.steps.matching.backButton',
          ),
          nextButtonLabel: t(
            'components.VacationOffers.import.steps.matching.nextButton',
            {
              valid: matching.data?.create.length ?? 0,
              all: upload.data?.entries.length ?? 0,
            },
          ),
          nextDisabled:
            !matching.data?.create?.length ||
            matching.loading ||
            matching.error != null,
        },
        {
          id: SocialCreditPointsStepId,
          backButtonLabel: t(
            'components.VacationOffers.import.steps.socialCreditPoints.backButton',
          ),
          nextButtonLabel: t(
            'components.VacationOffers.import.steps.socialCreditPoints.nextButton',
            {
              all: matching.data
                ? matching.data.ignore.length + matching.data.create.length
                : 0,
              valid: matching.data?.create.length ?? 0,
            },
          ),
          nextDisabled:
            createEonOffersResult.loading ||
            createEonOffersResult.error != null,
        },
      ]}
      onClose={navigateBack}
      onFinish={handleFinish}
    >
      <UploadStep
        handleUpload={handleUpload}
        data={upload.data}
        error={upload.error}
        loading={upload.loading}
        hasInvalidEntries={upload.hasInvalidEntries}
      />
      <MatchingStep
        data={matching.data}
        loading={matching.loading || createEonOffersResult.loading}
        error={matching.error || createEonOffersResult.error}
      />
      <SocialCreditPointsStep
        data={matching.data?.create}
        socialCreditPoints={socialCreditPoints}
      />
    </Wizard>
  );
}
