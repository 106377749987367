import { ReactElement } from 'react';
import { Icon } from './Icon';

const buttonStyle = disabled =>
  `flex items-center px-6 py-1.5 font-medium bg-white border-2 w-50 text-primary border-primary rounded-3xl focus:outline-none ${
    disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
  }`;

type UploadButtonType = {
  label: string;
  onChange: (files: FileList) => void;
  accept?: string;
  disabled?: boolean;
  multiple?: boolean;
};

const UploadButton = ({
  label,
  onChange,
  disabled = false,
  multiple = false,
  accept = 'application/pdf',
}: UploadButtonType): ReactElement => {
  const uploadAttachmentInputId = `uploadAttachmentInput_${(
    Math.random() * 1000
  ).toFixed(0)}`;

  return (
    <>
      <label
        htmlFor={uploadAttachmentInputId}
        className={buttonStyle(disabled)}
      >
        <div className="mr-2">
          <Icon icon={'UploadIcon'} />
        </div>
        {label}
      </label>
      <input
        id={uploadAttachmentInputId}
        multiple={multiple}
        type="file"
        accept={accept}
        className="hidden"
        disabled={disabled}
        onChange={e => {
          if (e.target.files) {
            onChange(e.target.files);
          }
          // Clear value to accept new file selection
          e.target.value = '';
        }}
      />
    </>
  );
};

export default UploadButton;
