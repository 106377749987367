import { ReactElement, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import Button from './Button';

type DeleteWithConfirmationButtonProps = {
  onDelete: () => void;
  title: string;
  text: string;
  label: string;
  inverted: boolean;
};

const DeleteWithConfirmationButton = ({
  onDelete,
  title,
  text,
  label,
  inverted,
}: DeleteWithConfirmationButtonProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const handleClickOpen = () => setOpen(true);

  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  return (
    <div className="DeleteWithConfirmation flex flex-1">
      <ConfirmationDialog
        onConfirm={handleDelete}
        onCancel={handleCancel}
        open={open}
        title={title}
        text={text}
      ></ConfirmationDialog>
      <Button
        onClick={handleClickOpen}
        label={label}
        styleOptions={{
          inverted,
        }}
      />
    </div>
  );
};

export default DeleteWithConfirmationButton;
