import { useState, useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export type ImportEntry = {
  bookingId: string;
  region: string;
  house: string;
  arrival: Date;
  departure: Date;
  charge: number;
  isValid: boolean;
  validationResult: {
    bookingId: boolean;
    region: boolean;
    house: boolean;
    arrival: boolean;
    charge: boolean;
    departure: boolean;
  };
};

type AcceptedFileTypes = 'text/csv';
export const ACCEPTED_FILE_TYPES: AcceptedFileTypes[] = ['text/csv'];

type UploadControllerResult = {
  data?: {
    filename: string;
    entries: ImportEntry[];
  };
  hasInvalidEntries?: boolean;
  error?: string;
  loading: boolean;
  reset: () => void;
  processFiles: (files: FileList | File[]) => void;
};

export function useUploadController({
  onUploadCompleted,
  onUploadFailed,
}: {
  onUploadCompleted?: (data: {
    filename: string;
    entries: ImportEntry[];
  }) => void;
  onUploadFailed?: (error: string) => void;
}): UploadControllerResult {
  const { t } = useTranslation();
  const [state, setState] = useState<
    Pick<
      UploadControllerResult,
      'data' | 'error' | 'loading' | 'hasInvalidEntries'
    >
  >({
    data: undefined,
    error: undefined,
    loading: false,
    hasInvalidEntries: false,
  });

  const processFiles = useCallback(
    async function process(files: FileList | File[]) {
      try {
        if (files.length === 0) {
          throw new Error(t('components.VacationOffers.import.errors.noFiles'));
        }

        setState({ loading: true });

        const filesArray: File[] = [];
        for (let i = 0; i < files.length; i++) {
          filesArray.push(files[i]);
        }

        const file =
          filesArray.find(f =>
            (ACCEPTED_FILE_TYPES as string[]).includes(f.type),
          ) ??
          (() => {
            throw new Error(
              t('components.VacationOffers.import.errors.invalidFormat'),
            );
          })();

        const handler = getFileHandler(file, t);

        const items = await handler(file);

        items.sort((item1, item2) =>
          new Intl.Collator(undefined, { numeric: true }).compare(
            item1.bookingId,
            item2.bookingId,
          ),
        );

        const hasInvalidEntries = items.some(item => !item.isValid);

        const data = {
          filename: file.name,
          entries: items,
        };

        if (onUploadCompleted) {
          onUploadCompleted(data);
        }

        setState({
          loading: false,
          error: undefined,
          data,
          hasInvalidEntries,
        });
      } catch (error) {
        console.error(error);
        const errorMsg =
          error instanceof Error
            ? error.message
            : t('components.VacationOffers.import.errors.unknown');

        if (onUploadFailed) {
          onUploadFailed(errorMsg);
        }

        setState({ loading: false, error: errorMsg });
      }
    },
    [t, onUploadCompleted, onUploadFailed],
  );

  const reset = useCallback(() => {
    setState({
      data: undefined,
      error: undefined,
      loading: false,
      hasInvalidEntries: false,
    });
  }, []);

  return {
    ...state,
    reset,
    processFiles,
  };
}

const getHandleCSVFile = (
  t: TFunction<'translation', undefined>,
): FileTypeHandler<ImportEntry[]> => {
  return async file => {
    let textContent = '';
    try {
      textContent = await file.text();
    } catch (e) {
      console.error(e);
      throw new Error(
        t('components.VacationOffers.import.errors.processingFailed'),
      );
    }

    if (textContent.length === 0) {
      throw new Error(t('components.VacationOffers.import.errors.emptyFile'));
    }

    const [headerRow, ...rows] = textContent.split('\n');

    const expectedHeader = t(
      'components.VacationOffers.import.steps.upload.expectedHeader',
    );

    if (!headerRow.startsWith(expectedHeader)) {
      throw new Error(
        t('components.VacationOffers.import.errors.invalidHeader', {
          expectedHeader,
        }),
      );
    }

    return rows
      .map(row => row.split(';'))
      .filter(isNonEmptyRow)
      .map(mapRowToImportEntry);
  };
};

const getFileHandler = (file: File, t: TFunction<'translation', undefined>) => {
  const handleCSVFile = getHandleCSVFile(t);
  switch (file.type) {
    case 'text/csv':
      return handleCSVFile;
    default:
      throw new Error(
        t('components.VacationOffers.import.errors.invalidFormat'),
      );
  }
};

type FileTypeHandler<T> = (file: File) => Promise<T>;

const mapRowToImportEntry = (row: (string | undefined)[]): ImportEntry => {
  const [
    bookingId = '',
    region = '',
    house = '',
    period = '',
    chargeAsString = '',
  ] = row;
  const { arrival, departure } = parseArrivalAndDeparture(period);
  const charge = parseFloat(chargeAsString?.trim().replace(',', '.'));
  const validationResult = {
    bookingId: !!bookingId.trim().match(/^\d+$/),
    region: region.trim().length > 0,
    house: house.trim().length > 0,
    arrival: !isNaN(arrival.getDate()),
    departure: !isNaN(departure.getDate()),
    charge: !isNaN(charge) && charge >= 0,
  };
  return {
    bookingId,
    region,
    house,
    arrival,
    departure,
    charge,
    isValid: Object.values(validationResult).every(value => value),
    validationResult,
  };
};

const isNonEmptyRow = (row: string[]) =>
  row.some(cell => cell.trim().length > 0);

const parseArrivalAndDeparture = (
  period: string,
): {
  arrival: Date;
  departure: Date;
} => {
  const [arrival, departure] = period.split('-');

  return {
    arrival: parseDate(arrival?.trim()),
    departure: parseDate(departure?.trim()),
  };
};

const parseDate = (date?: string): Date => {
  const [day, month, year] = date?.split('.') ?? [];
  return new Date(`${year}-${month}-${day}`);
};
