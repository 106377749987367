import { clamp } from 'helpers/util';
import { ReactElement } from 'react';

/* Component taken from MG-Strom */

const MIN = 0;
const MAX = 100;

/**
 * ProgressBar
 * @param progress value between 0-100
 */
export function ProgressBar({ progress }: { progress: number }): ReactElement {
  const clampedProgress = clamp(progress, MIN, MAX);
  return (
    <div
      className="h-2 w-full bg-gray-100"
      role="progressbar"
      aria-valuenow={clampedProgress}
      aria-valuemin={MIN}
      aria-valuemax={MAX}
    >
      <div
        className={`h-full bg-primary ${
          clampedProgress < 100 ? 'rounded-r' : ''
        } transition-[width]`}
        style={{ width: clampedProgress + '%' }}
      />
    </div>
  );
}
